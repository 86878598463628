import { labelOperators } from '@/assets/triggers/autocomplete';
import { getLabelCategoryMap, getLabelsByTenant } from '@/state/cache';
import { InputBox } from '@/web/common/forms/InputBox';
import { InputSlider } from '@/web/common/forms/InputSlider';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 * @typedef {object} BasicTypeComponentEditProps
 * @property {(value: BasicTriggerDefinition) => any} onChange
 * @property {import('@/utils/triggers').BasicTriggerDefinition} value
 * @returns
 */

/** @param {BasicTypeComponentEditProps} props */
export function BasicTypeComponentEdit(props) {
  const { value, onChange } = props;
  const label = value?.root?.variable;
  const threshold = value?.root?.intValue ?? value?.root?.floatValue;

  const { tenantId } = useParams();
  const labelMap = useSelector(getLabelsByTenant(tenantId));
  const selectedLabel = labelMap?.[label] || {};

  const labelCategoriesMap = useSelector(getLabelCategoryMap);
  const [labelCategory, setLabelCategory] = useState(selectedLabel?.source);
  const labelOptions = Object.values(labelMap || {}).filter(
    (item) => item.source === labelCategory
  );

  const setVariable = (variable) => {
    onChange({
      variables: [variable],
      root: {
        type: 'BASIC',
        variable,
      },
    });
  };

  const setOperator = (operator) => {
    onChange({
      variables: value?.variables,
      root: {
        ...value?.root,
        type: 'BASIC',
        operator,
      },
    });
  };

  const setThreshold = (threshold) => {
    if (threshold < selectedLabel.minValue) return;
    if (threshold > selectedLabel.maxValue) return;
    onChange({
      variables: value?.variables,
      root: {
        type: 'BASIC',
        variable: value?.root?.variable,
        operator: value?.root?.operator,
        intValue: parseInt(threshold),
        floatValue: parseFloat(threshold),
      },
    });
  };

  return (
    <>
      <SelectBox
        label="Source*"
        // emptyValueText="Select a source"
        value={labelCategory}
        onChange={(value) => {
          setLabelCategory(value);
          onChange();
        }}
        options={Object.values(labelCategoriesMap || {})}
        itemText="name"
        itemValue="id"
      />
      {labelCategory && labelOptions?.length > 0 && (
        <>
          <SelectBox
            label="Label"
            placeholder="Select Label"
            value={value?.root?.variable}
            onChange={setVariable}
            options={labelOptions}
            itemValue="labelId"
            itemText="name"
            required
          />
          {selectedLabel && (
            <>
              <SelectBox
                label="Operator"
                placeholder="Select Operator"
                value={value?.root?.operator}
                onChange={setOperator}
                options={Object.entries(labelOperators || {})}
                itemValue={0}
                itemText={1}
                required
              />
              {selectedLabel.triggerThresholdInputType === 'SLIDER' ? (
                <InputSlider
                  label={`Threshold ~ ${labelMap?.[label]?.unit}`}
                  min={selectedLabel.minValue}
                  max={selectedLabel.maxValue}
                  unit={labelMap?.[label]?.unit}
                  value={threshold}
                  onChange={setThreshold}
                  required
                />
              ) : (
                <InputBox
                  label={`Threshold ~ ${selectedLabel?.unit}`}
                  type="number"
                  min={selectedLabel.minValue}
                  max={selectedLabel.maxValue}
                  value={threshold}
                  onChange={setThreshold}
                  required
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
