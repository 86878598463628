import { useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';
import { FileUploadModal } from './upload-modal';

/**
 * @typedef {object} FileUploadOptions
 * @property {number} [maxFileSizeBytes]
 * @property {(type: string) => bool} [validateFileType]
 */

/**
 * @typedef {object} FileUploaderInputProps
 * @property {string} label
 * @property {FileModel} value
 * @property {(value: FileModel) => any} onChange
 * @property {string} [modalTitle]
 * @property {FileUploadOptions} [options]
 * @property {(previous: FileUploadCredentialModel?, cancelToken: any) => Promise<FileUploadCredentialModel>} credentialFetcher
 * @property {(data: FileAddRequest, cancelToken: any) => Promise<FileModel>} entryCreator
 */

/** @param {FileUploaderInputProps} props  */
export function FileUploaderInput(props) {
  const { value, label, modalTitle, options, onChange, credentialFetcher, entryCreator } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <FloatingLabel label={label} className="mb-2 d-flex gap-2" onClick={() => setShowModal(true)}>
        <Form.Control readOnly value={value || value?.id || ''} />
        <Button variant="success" style={{ width: 125 }}>
          <FaUpload /> Upload
        </Button>
      </FloatingLabel>

      {showModal && (
        <FileUploadModal
          title={modalTitle}
          options={options}
          onChange={onChange}
          credentialFetcher={credentialFetcher}
          entryCreator={entryCreator}
          onDone={() => setShowModal(false)}
        />
      )}
    </>
  );
}
