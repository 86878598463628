import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useVisibleRoutes } from '@/web/pages/routes';
import isFunction from 'lodash/isFunction';
import startCase from 'lodash/startCase';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { matchRoutes, Navigate, useLocation } from 'react-router-dom';
import { PagedGridItemLarge } from './PagedGridItemLarge';

/**
 * @typedef {object} PageItem
 * @property {string} path Full path of this page
 * @property {any} [icon] Optional icon to display
 * @property {any} [title] Page title
 * @property {any} [subtitle] Page description
 * @property {any} [content] Content to render
 */

/**
 * @typedef {object} PagedNavigationProps
 * @property {string} [defaultPage] The default selected page
 */

/** @param {PagedNavigationProps} props */
export function PagedNavigation(props) {
  const { defaultPage, layout, ...otherProps } = props;

  const location = useLocation();
  const routes = useVisibleRoutes();
  const matched = matchRoutes(routes, location.pathname);

  const [pages, selectedPage] = useMemo(() => {
    /** @type {Array.<PageItem>} */
    const items = [];

    /** @type {PageItem} */
    let selectedPage = null;

    matched?.[0]?.route?.children?.forEach((item) => {
      /** @type {PageItem} */
      const page = {
        content: item.element,
        path: matched[0].pathname + '/' + item.path,
        icon: isFunction(item.icon) ? item.icon() : item.icon,
        subtitle: isFunction(item.description) ? item.description() : item.description,
        title:
          (isFunction(item.breadcrumb) ? item.breadcrumb() : item.breadcrumb) ||
          startCase(item.path),
      };
      items.push(page);
      if (location.pathname === page.path) {
        selectedPage = page;
      }
    });
    return [items, selectedPage];
  }, [matched, location.pathname]);

  if (selectedPage) {
    return selectedPage.content;
  }

  if (!selectedPage) {
    const page = pages.find((el) => el.path === defaultPage);
    if (page) return <Navigate replace to={page.path} />;
  }

  if (!selectedPage) {
    const children = (
      <Row className="g-2 mt-1">
        {pages.map((item) => (
          <Col xs="12" md="6" lg="4" xl="3" key={item.path}>
            <PagedGridItemLarge item={item} color={otherProps.color} />
          </Col>
        ))}
      </Row>
    );

    if (isFunction(layout)) {
      return layout({ ...otherProps, children });
    }

    return (
      <BreadcrumbLayout {...otherProps}>
        <BreadcrumbLayout.Header />
        {children}
      </BreadcrumbLayout>
    );
  }
}
