import api from '@/api';
import { getAiAcceleratorMap } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { timeFromNow } from '@/utils/formatting';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

export function AiModelListView(props) {
  const { tenantId } = useParams();
  const { status } = props;
  const navigate = useNavigate();
  const acceleratorMap = useSelector(getAiAcceleratorMap);

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (pageSize, continuationToken) => {
        const request = api.sai.v1['ai-model'].$get({
          params: {
            pageSize,
            continuationToken,
            tenantId,
            status,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {AiModel} item */
        (item) => navigate(`./${item.modelId}`)
      }
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'version',
          align: 'center',
          width: 120,
        },
        {
          key: 'type',
          align: 'center',
          width: 120,
        },
        {
          key: 'status',
          align: 'center',
          width: 120,
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          title: 'AI Accelerators',
          disableAction: true,
          builder: ({ aiAcceleratorIds }) => {
            const accelerator = acceleratorMap[aiAcceleratorIds[0]];
            return (
              accelerator && (
                <Link to={`/super-admin/ai-accelerator/${accelerator.codeName}`}>
                  {accelerator.name}
                </Link>
              )
            );
          },
        },
        {
          key: 'vendor',
          minwidth: 150,
        },
        {
          key: 'description',
          minWidth: 200,
        },
        {
          title: 'Last Updated',
          builder: ({ updatedAt }) => timeFromNow(updatedAt) || 'never',
        },
      ]}
    />
  );
}
