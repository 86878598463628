import { getTenantId } from '@/state/auth';
import { getGroup, getProduct, getTenant } from '@/state/cache';
import { formatDateTime } from '@/utils/formatting';
import { USER_ACCESS_DEVICE_UPDATE_ONBOARDED_DEVICE } from '@/web/common/AccessRights';
import { ColorfulBadge } from '@/web/common/ColorfulBadge';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useContext } from 'react';
import { FaDesktop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeviceViewContext } from '../@components/DeviceViewLayout';
import { EditOnboardDeviceModal } from './@components/EditOnboardDeviceModal';

export function ViewDevicePageGeneral() {
  const tenantId = useSelector(getTenantId);

  /** @type {{device : DeviceDetail}}  */
  const { device } = useContext(DeviceViewContext);
  const owner = useSelector(getTenant(tenantId));
  const product = useSelector(getProduct(device.productId));
  const group = useSelector(
    getGroup(
      device?.onboardedDevice?.deploymentGroupIds?.[
        device?.onboardedDevice?.deploymentGroupIds?.length - 1
      ],
      tenantId
    )
  );

  return (
    <>
      <BreadcrumbLayout.Header icon={<FaDesktop />} title="General Information">
        {device.deviceStatus === 'ONBOARDED' && (
          <USER_ACCESS_DEVICE_UPDATE_ONBOARDED_DEVICE>
            <EditOnboardDeviceModal />
          </USER_ACCESS_DEVICE_UPDATE_ONBOARDED_DEVICE>
        )}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={device}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'deviceId',
              title: 'ID',
            },
            {
              title: 'Label',
              builder: ({ onboardedDevice }) => onboardedDevice?.label,
            },
            {
              key: 'deviceStatus',
              title: 'Status',
              builder: ({ deviceStatus }) =>
                deviceStatus && <ColorfulBadge suffix={73}>{deviceStatus}</ColorfulBadge>,
            },
            {
              key: 'tag',
              title: 'Tag',
              builder: ({ tag }) => tag && <ColorfulBadge>{tag}</ColorfulBadge>,
            },
            {
              title: 'Owner Tenant',
              builder: ({ tenantId }) =>
                owner && <Link to={`/settings/tenants/${tenantId}`}>{owner.name}</Link>,
            },
            {
              title: 'Product',
              builder: ({ productId }) =>
                product && <Link to={`/super-admin/products/${productId}`}>{product.name}</Link>,
            },
            {
              title: 'Group',
              builder: () =>
                group?.name && (
                  <Link to={`/admin/groups/${tenantId}/${group.groupId}`}>{group.name}</Link>
                ),
            },
            {
              title: 'Onboarding Time',
              builder: ({ onboardedDevice }) => formatDateTime(onboardedDevice?.onboardTime),
            },
            {
              title: 'Parking Status',
              builder: ({ onboardedDevice }) => onboardedDevice?.parkingStatus,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
