import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function InputCardLayout(props) {
  const { icon, children, input, source, color, footer, inputType, ...otherProps } = props;
  return (
    <Card
      {...otherProps}
      className={`${otherProps.className || ''} input-card input-card-${inputType.toLowerCase()}`}
      style={{
        minWidth: 200,
        boxShadow: '0 0 4px #00000025',
        ...otherProps.style,
      }}
    >
      {input && (
        <Card.Header className="text-capitalize text-center" title={input.description}>
          <span style={{ fontSize: 24, color }}>{icon}</span>{' '}
          <span className="fw-bold">{input.name}</span>
        </Card.Header>
      )}
      <Card.Body className="p-1 d-flex align-items-center">{children}</Card.Body>
      {footer ||
        (source && (
          <Card.Footer className="text-capitalize text-center">
            <Link
              title={source.description}
              to={`/ai/ai-models/${source.ownerTenantId}/${source.modelId}`}
            >
              {source.name}
            </Link>
          </Card.Footer>
        ))}
    </Card>
  );
}
