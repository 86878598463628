import { Card } from 'react-bootstrap';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { CreateTenantForm } from './@components/CreateTenantForm';

export function CreateTenant() {
  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title>Create New Tenant</Card.Title>
        </Card.Header>
        <CreateTenantForm />
      </Card>
    </BreadcrumbLayout>
  );
}
