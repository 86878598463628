import api from '@/api';
import { getAiAcceleratorList } from '@/state/cache';
import { displayError, displaySuccess, displayWarning } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FaDesktop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { ProductViewContext } from '../../../@components/ProductViewLayout';

export function EditProductButton(props) {
  /** @type {{product: Product, updateProduct: () => any}} */
  const { product, updateProduct } = useContext(ProductViewContext);

  const accelerators = useSelector(getAiAcceleratorList);

  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(product.name);
  const [serialNo, setSerialNo] = useState(product.serialNo);
  const [description, setDescription] = useState(product.description);
  const [website, setWebsite] = useState(product.website);
  const [productManual, setProductManual] = useState(product.productManual);
  const [firmwareComponents, setFirmwareComponents] = useState(product.firmwareComponents);
  const [aiAcceleratorId, setAiAcceleratorId] = useState(product.aiAcceleratorIds?.[0]);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!name.trim()) {
      displayWarning('Product name is required', 'Invalid value');
    }
    if (!serialNo.trim()) {
      displayWarning('Product serial is required', 'Invalid value');
    }

    const request = api.sai.v1.product.$productId(product.productId).$patch({
      data: {
        name,
        serialNo,
        description,
        website,
        productManual,
        firmwareComponents,
        aiAcceleratorIds: [aiAcceleratorId],
      },
    });
    try {
      setLoading(true);
      await request.process();
      setShowEdit(false);
      setLoading(false);
      displaySuccess('Product updated');
      updateProduct();
    } catch (err) {
      console.error(err);
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <>
      <Button onClick={() => setShowEdit(true)} variant="primary" style={{ width: 132 }}>
        Edit
      </Button>

      <Modal size="lg" show={showEdit} onHide={() => setShowEdit(false)}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FaDesktop /> General Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputBox
              label="Product name"
              placeholder="Enter product name"
              value={name}
              required
              onChange={setName}
            />
            <InputBox
              label="Product serial"
              placeholder="Enter product serial"
              value={serialNo}
              required
              onChange={setSerialNo}
            />
            <InputBox
              label="Description"
              placeholder="Enter description"
              value={description}
              onChange={setDescription}
              textarea
            />
            <InputBox
              label="Website link"
              placeholder="Enter website link"
              value={website}
              onChange={setWebsite}
            />
            <InputBox
              label="Product manual link"
              placeholder="Enter product manual link"
              value={productManual}
              onChange={setProductManual}
            />
            <SelectBox
              label="AI Accelerator"
              placeholder="Select AI Accelerator"
              value={aiAcceleratorId}
              onChange={setAiAcceleratorId}
              options={accelerators}
              itemValue="codeName"
              itemText="name"
              required
            />
            <div className="mb-2 border rounded-1" style={{ padding: 12 }}>
              <label className="small text-muted mb-1">Firmware components</label>
              <CreatableSelect
                isMulti
                isClearable={false}
                placeholder="Add firmware components"
                defaultValue={firmwareComponents?.map((value) => ({ value, label: value }))}
                onChange={(selected) => setFirmwareComponents(selected.map(({ value }) => value))}
                noOptionsMessage={({ inputValue }) =>
                  inputValue && `"${inputValue}" is already added`
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div />
            <Button type="submit" style={{ width: 130 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
