import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';
import { DeleteLabelButton } from '../DeleteLabelButton';
import { timeFromNow } from '@/utils/formatting';
import { getLabel, getLabelCategory, getTenant } from '@/state/cache';
import { DiscontinueLabelButton } from '../DiscontinueLabelButton';
import { PublishLabelButton } from '../PublishLabelButton';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { makeColorFrom } from '@/utils';

/** @param {{item: Label}} props */
export function LabelView(props) {
  const { item } = props;
  const { tenantId } = useParams();

  const owner = useSelector(getTenant(tenantId));
  const aliasedLabel = useSelector(getLabel(item.aliasedLabelId, tenantId));
  const labelCategory = useSelector(getLabelCategory(item.source));

  const canPublish = item.status === 'DRAFT';
  const canDiscontinue = item.status === 'DRAFT' || item.status === 'PUBLISHED';

  return (
    <>
      <BreadcrumbLayout.Header title={<span>General Information</span>}>
        {item.status === 'DRAFT' && (
          <Stack className="justify-content-end" direction="horizontal" gap={2}>
            <Button as={Link} to="./edit" variant="primary" style={{ width: 132 }}>
              Edit
            </Button>
            <DeleteLabelButton item={item} />
          </Stack>
        )}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={item}
          width={300}
          minWidth={175}
          headers={[
            {
              key: 'name',
              title: 'Name',
            },
            {
              key: 'code',
              title: 'Code',
            },
            {
              key: 'labelType',
              title: 'Label type',
            },
            {
              key: 'aliasedLabelId',
              title: 'Alias Label',
              builder: ({ aliasedLabelId }) =>
                aliasedLabel && (
                  <Link to={`/events/labels/${tenantId}/${aliasedLabelId}`}>
                    {aliasedLabel.name}
                  </Link>
                ),
            },
            {
              key: 'source',
              title: 'Label Category',
              builder: () =>
                labelCategory && (
                  <Link to={`/events/label-categories/${labelCategory.id}`}>
                    {labelCategory.name}
                  </Link>
                ),
            },
            {
              key: 'minValue',
              title: 'Min Value',
            },
            {
              key: 'maxValue',
              title: 'Max Value',
            },
            {
              key: 'inputStepSize',
              title: 'Input Step Size',
            },
            {
              key: 'triggerThresholdInputType',
              title: 'Trigger Threshold Input Type',
            },
            {
              key: 'description',
              title: 'Description',
            },
            {
              key: 'status',
              builder: ({ status }) => (
                <Stack direction="horizontal" gap="2">
                  <div className="badge me-4" style={{ background: makeColorFrom(status, 73) }}>
                    {status}
                  </div>
                  {canPublish && <PublishLabelButton item={item} />}
                  {canDiscontinue && <DiscontinueLabelButton item={item} />}
                </Stack>
              ),
            },
            {
              key: 'tenantId',
              title: 'Owner Tenant',
              builder: ({ ownerTenantId }) =>
                owner && <Link to={`/settings/tenants/${ownerTenantId}`}>{owner.name}</Link>,
            },
            {
              key: 'createdAt',
              title: 'Created',
              builder: ({ createdAt }) => timeFromNow(createdAt),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
