import { createContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { DomainInputForm } from '../DomainInputForm';
import { DomainItemView } from '../DomainItemView';

export const DomainListContext = createContext();

/**
 * @typedef {object} EditDomainListProps
 * @property {Array.<string>} adding Domains that are being added
 * @property {Array.<string>} removing Domains that are being removed
 * @property {Array.<string>} domains List of current domains
 * @property {(domains: Array.<string>) => void} setDomains Set list of domains
 */

/**  @param {EditDomainListProps} props */
export function EditDomainList(props) {
  const { adding, removing, domains, setDomains } = props;

  /**
   * @param {string} value The new value, or null to delete the existing
   */
  const addDomain = (value) => {
    setDomains([...domains, value].sort());
  };

  /**
   * @param {string} value The new value, or null to delete the existing
   * @param {string} previous The previous value.
   */
  const updateDomain = (value, previous) => {
    value = value?.trim();
    if (value) {
      // update the existing value
      setDomains(domains.map((x) => (x === previous ? value : x)));
    } else {
      // remove the existing value
      setDomains(domains.filter((x) => x !== previous));
    }
  };

  return (
    <DomainListContext.Provider value={domains}>
      <hr className="my-2" />

      <DomainInputForm onSubmit={addDomain} />

      <hr className="my-2" />

      <label className="mb-2 fw-bold small text-secondary">Allowed email domains:</label>
      <ListGroup>
        {domains.map((domain) => (
          <DomainItemView
            key={domain}
            value={domain}
            onChange={updateDomain}
            color={adding.includes(domain) ? 'blue' : null}
          />
        ))}
        {domains.length === 0 && (
          <ListGroup.Item className="text-center text-muted small py-4">
            <span>No domains are on the list.</span>
          </ListGroup.Item>
        )}
      </ListGroup>

      {removing.length > 0 && (
        <>
          <hr className="my-2" />

          <label className="mb-2 fw-bold small text-muted">Restore removed domains:</label>
          <ListGroup>
            {removing.map((domain) => (
              <DomainItemView key={domain} value={domain} onChange={addDomain} disabled />
            ))}
          </ListGroup>
        </>
      )}
    </DomainListContext.Provider>
  );
}
