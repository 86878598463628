import { fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { FaFirstdraft, FaHandshakeAltSlash, FaList, FaRocket } from 'react-icons/fa';
import { SuperAdminDonutGroup } from '../../@components/SuperAdminDonutGroup';
import { SharedProductListView } from './@components/SharedProductListView';

export function SharedProductListViewWithTenant() {
  useDispatchLoader(fetchAllTenants);

  return (
    <SidebarPlotLayout sidebar={<SuperAdminDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title=" Shared Products"></BreadcrumbLayout.Header>
      <TabsNavigation
        replace
        tabs={[
          {
            key: 'all',
            title: (
              <div>
                <FaList /> All
              </div>
            ),
            content: <SharedProductListView />,
          },
          {
            key: 'draft',
            title: (
              <div>
                <FaFirstdraft /> Draft
              </div>
            ),
            content: <SharedProductListView status={'UNRELEASED'} />,
          },
          {
            key: 'published',
            title: (
              <div>
                <FaRocket /> Published
              </div>
            ),
            content: <SharedProductListView status={'RELEASED'} />,
          },
          {
            key: 'discontinued',
            title: (
              <div>
                <FaHandshakeAltSlash /> Discontinued
              </div>
            ),
            content: <SharedProductListView status={'DISCONTINUED'} />,
          },
        ]}
      />
    </SidebarPlotLayout>
  );
}
