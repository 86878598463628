import api from '@/api';
import { ListGroup } from 'react-bootstrap';
import { PlatformAdminListItem } from '../PlatformAdminListItem';
import { OffsetBasedPagination } from '@/web/common/pagination/OffsetBasedPagination';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenPlatformAdmin>} RequestType */

export function PlatformAdminListView() {
  return (
    <OffsetBasedPagination
      request={(limit, offset) =>
        api.sai.v1['platform-admin'].$get({
          params: {
            offset,
            limit,
          },
        })
      }
      resultLength={
        /** @param {RequestType} request */
        (request) => request.result?.result?.results?.length || 0
      }
      success={
        /** @param {RequestType} request */
        (request) => {
          const users = request.result.result.results;
          return (
            <ListGroup>
              {users.length > 0 ? (
                users.map((item) => <PlatformAdminListItem key={item.id} item={item} />)
              ) : (
                <ListGroup.Item className="text-center text-muted py-3">
                  No more platform admin users
                </ListGroup.Item>
              )}
            </ListGroup>
          );
        }
      }
    />
  );
}
