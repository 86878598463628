import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import { nanoid } from '@reduxjs/toolkit';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { RoleListContext } from '../..';

/**
 * @param {{name: string, disabled: boolean}} props
 */
export function DeleteRoleButton(props) {
  const name = props.name;
  const [loading, setLoading] = useState(false);
  const [componentId, changeId] = useContext(RoleListContext);

  const deleteRole = async () => {
    const request = api.sai.v1.role.$name(name).$delete();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      changeId(nanoid());
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      key={componentId}
      type="button"
      variant="danger"
      style={{ width: 100 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: deleteRole,
          title: `Delete ${name}?`,
          message: 'It will affect all resources using this role',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
