import { donutCharColors } from '@/assets/colors/autocomplete';
import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchTenantAdminDonutData } from '@/state/chart/fetchers/tenant-admins';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function TenantAdminDonut(props) {
  const { sidebar } = props;
  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchTenantAdminDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.tenantAdmins?.total || 0,
    points: [
      ['data1', 'data2'],
      ['Tenant Admins', chartData.tenantAdmins?.total || 0],
    ],
  };
  return (
    <DashboardItem sidebar={sidebar} header={'Tenant Admins'} url={'/internal/tenant-admin'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'Tenant Admins' }}
        legends={[{ name: 'Tenant Admins', className: 'blue' }]}
        colors={[donutCharColors.BLUE]}
        data={data.points}
      />
    </DashboardItem>
  );
}
