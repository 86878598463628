import { fetchAllLabelCategories, fetchAllLabelsByTenant } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaFirstdraft, FaHandshakeAltSlash, FaList, FaPlus, FaRocket } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { EventDonutGroup } from '../../@components/EventDonutGroup';
import { LabelListView } from './@components/LabelListView';

export function LabelListViewWithTenant() {
  const { tenantId } = useParams();

  useDispatchLoader(() => fetchAllLabelsByTenant(tenantId), [tenantId]);
  useDispatchLoader(fetchAllLabelCategories);

  return (
    <SidebarPlotLayout sidebar={<EventDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Labels">
        <Button as={Link} variant="outline-primary" to={'./create'} style={{ width: 175 }}>
          <FaPlus /> Label
        </Button>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <TabsNavigation
          replace
          tabs={[
            {
              key: 'all',
              title: (
                <div>
                  <FaList /> All
                </div>
              ),
              content: <LabelListView />,
            },
            {
              key: 'draft',
              title: (
                <div>
                  <FaFirstdraft /> Draft
                </div>
              ),
              content: <LabelListView status={'DRAFT'} />,
            },
            {
              key: 'published',
              title: (
                <div>
                  <FaRocket /> Published
                </div>
              ),
              content: <LabelListView status={'PUBLISHED'} />,
            },
            {
              key: 'discontinued',
              title: (
                <div>
                  <FaHandshakeAltSlash /> Discontinued
                </div>
              ),
              content: <LabelListView status={'DISCONTINUED'} />,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
