import uniq from 'lodash/uniq';
import React from 'react';
import { Spinner } from 'react-bootstrap';

/** @param {{status: string, borderless: boolean}} props */
export function PageLoadingSpinner(props) {
  const { status, borderless, ...extraProps } = props;

  const classNames = uniq([
    'rounded-1',
    'bg-white',
    'text-muted',
    'text-center',
    'p-5',
    borderless ? null : 'border',
    ...(extraProps.className || '')?.split(' '),
  ]).filter((x) => Boolean(x));

  return (
    <div {...extraProps} className={classNames.join(' ')}>
      <Spinner animation="border" />
      {status && <div className="small py-2">{status}</div>}
    </div>
  );
}
