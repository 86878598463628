import api from '@/api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getBearerToken } from '@/state/auth';
import { displayError } from '@/utils/toaster';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { CreateEndUserForm } from './@components/CreateEndUserForm';
import { CreateEndUserPlaceholder } from './@components/CreateEndUserPlaceholder';

export function CreateEndUser() {
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const bearerToken = useSelector(getBearerToken);

  const [tenant, setTenant] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      setLoading(true);
      const request = api.sai.v1.tenant.$tenantId(tenantId).$get();
      request
        .process()
        .then(() => {
          setTenant(request.result.result);
        })
        .catch((err) => {
          displayError(err);
          navigate('./..');
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [bearerToken, tenantId]
  );

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      {loading ? <CreateEndUserPlaceholder /> : <CreateEndUserForm tenant={tenant} />}
    </BreadcrumbLayout>
  );
}
