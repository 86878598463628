import { Card, ListGroup, Placeholder } from 'react-bootstrap';

export function EndUserViewPlaceholder() {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">End User</Card.Title>
      </Card.Header>
      <Placeholder as={Card.Body} animation="glow">
        <Placeholder as={ListGroup} animation="glow">
          <ListGroup>
            <ListGroup.Item>
              <Placeholder xs={6} />
            </ListGroup.Item>
            <ListGroup.Item>
              <Placeholder xs={6} />
            </ListGroup.Item>
            <ListGroup.Item>
              <Placeholder xs={6} />
            </ListGroup.Item>
          </ListGroup>
        </Placeholder>
      </Placeholder>
      <Placeholder
        as={Card.Footer}
        animation="glow"
        className="d-flex align-items-center justify-content-between"
      >
        <div className="text-muted">Loading...</div>
        <Placeholder.Button variant="primary" xs={2} />
      </Placeholder>
    </Card>
  );
}
