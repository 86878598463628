import api from '@/api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState, createContext } from 'react';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { getBearerToken } from '@/state/auth';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { DataItemTable } from '@/web/common/DataItemTable';
import { UndeployAiContainerButton } from '../UndeployAiContainerButton';
import { USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER } from '@/web/common/AccessRights';
import { formatDateTime } from '@/utils/formatting';

export const DeployedAiContainerContext = createContext();

export function DeployedView() {
  const { groupId } = useParams();
  const [id, setId] = useState(Date.now());

  const updateParent = () => {
    setId(Date.now());
  };

  const bearerToken = useSelector(getBearerToken);

  const [deployment, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['deployment-group']
      .$groupId(groupId)
      ['ai-container-deployment'].$get({
        params: {
          metaData: true,
        },
      });
    await request.process();
    return request.result.result;
  }, [id, bearerToken]);

  if (loading) {
    return <PageLoadingSpinner status={`Loading deployed AI Container...`} />;
  }

  if (error) {
    return (
      <PageLoadingFailure message={'Failed to load deployed AI Container'}>
        {error + ''}
      </PageLoadingFailure>
    );
  }

  if (!deployment.aiContainerId) {
    return <PageLoadingFailure message={'No deployment available'} />;
  }

  return (
    <DeployedAiContainerContext.Provider value={updateParent}>
      <DataItemTable
        data={deployment}
        width={225}
        minWidth={150}
        headers={[
          {
            key: 'aiContainerName',
            title: 'Name',
          },
          {
            key: 'aiContainerType',
            title: 'Type',
          },
          {
            key: 'aiContainerVersion',
            title: 'Version',
          },
          {
            key: 'deploymentTime',
            title: 'Deployment Time',
            /** @param {AiContainerDeploymentModel} [item]*/
            builder: (item) => formatDateTime(item.deploymentTime),
          },
        ]}
      />
      <USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER>
        <div className="d-flex justify-content-around">
          <UndeployAiContainerButton item={deployment} />
        </div>
      </USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER>
    </DeployedAiContainerContext.Provider>
  );
}
