import api from '@/api';
import { OffsetBasedPagination } from '@/web/common/pagination/OffsetBasedPagination';
import { useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { RoleListContext } from '../..';
import { RoleListItem } from '../RoleListItem';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenRole>} RequestType */

export function RoleListView() {
  const [componentId] = useContext(RoleListContext);

  return (
    <OffsetBasedPagination
      key={componentId}
      request={(limit, offset) =>
        api.sai.v1.role.$get({
          params: {
            offset,
            limit,
          },
        })
      }
      resultLength={
        /** @param {RequestType} request */
        (request) => request.result?.result?.results?.length || 0
      }
      success={
        /** @param {RequestType} request */
        (request) => {
          const roles = request.result.result.results;
          return (
            <ListGroup>
              {roles.length > 0 ? (
                roles.map((item) => <RoleListItem key={item.name} item={item} />)
              ) : (
                <ListGroup.Item className="text-center text-muted py-3">
                  No more roles
                </ListGroup.Item>
              )}
            </ListGroup>
          );
        }
      }
    />
  );
}
