import { getTenantId, isLoggedIn } from '@/state/auth';
import { useAccessRightChecker } from '@/utils/hooks/AccessChecker';
import { routes as adminRoutes } from '@/web/pages/admin/routes';
import { routes as aiRoutes } from '@/web/pages/ai/routes';
import { routes as dashboardRoutes } from '@/web/pages/dashboard/routes';
import { routes as eventsRoutes } from '@/web/pages/events/routes';
import { routes as internalRoutes } from '@/web/pages/internal/routes';
import { routes as loginRoutes } from '@/web/pages/login/routes';
import { routes as settingsRoutes } from '@/web/pages/settings/routes';
import { routes as superAdminRoutes } from '@/web/pages/super-admin/routes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} ExtendedRouteProperties
 * @property {any | () => any} [icon] Icon to display
 * @property {string | () => string} [breadcrumb] The name or callback to get name of the breadcrumb
 * @property {string | () => string} [description] A detailed description about this route
 * @property {bool} [public=false]
 * @property {Array.<string>} rights
 * @property {'AND'|'OR'} mode
 */

/**
 * @typedef {import('react-router').RouteObject & ExtendedRouteProperties} ExtendedRoute
 */

/** @type {Array.<ExtendedRoute>} */
const routes = [
  ...loginRoutes,
  ...dashboardRoutes,
  ...aiRoutes,
  ...eventsRoutes,
  ...adminRoutes,
  ...superAdminRoutes,
  ...settingsRoutes,
  ...internalRoutes,
];

export function useVisibleRoutes() {
  const loggedIn = useSelector(isLoggedIn);
  const tenantId = useSelector(getTenantId);
  const rightChecker = useAccessRightChecker();

  const visible = useMemo(() => {
    return routes.filter((route) => {
      if (route.public) {
        return !loggedIn;
      }
      if (route.path === '/login/tenants') {
        return loggedIn && !tenantId;
      }
      if (!loggedIn || !tenantId) {
        return false;
      }
      if (route.rights) {
        return rightChecker(route.rights, route.mode);
      }
      return true;
    });
  }, [loggedIn, tenantId, rightChecker]);
  return visible;
}
