import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { FaCar } from 'react-icons/fa';

export function DeviceVehicleListView() {
  return (
    <>
      <BreadcrumbLayout.Header title="Device Vehicles" icon={<FaCar />} />
      <BreadcrumbLayout.Body>
        <div>View device vehicles</div>
      </BreadcrumbLayout.Body>
    </>
  );
}
