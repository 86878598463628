import api from '@/api';
import { getTenantId } from '@/state/auth';
import { getProductMap } from '@/state/cache';
import { displayWarning } from '@/utils/toaster';
import { ColorfulBadge } from '@/web/common/ColorfulBadge';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenDeviceDetail>} RequestType */

export function DeviceListView() {
  const navigate = useNavigate();
  const tenantId = useSelector(getTenantId);
  const productMap = useSelector(getProductMap);

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (pageSize, continuationToken) => {
        const request = api.sai.v1.device.$get({
          params: {
            tenantId,
            pageSize,
            continuationToken,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={(device) => {
        if (device.deviceStatus === 'ONBOARDED') {
          navigate(`./${device.deviceId}`);
        } else {
          displayWarning('Device is not onboarded');
        }
      }}
      headers={[
        {
          key: 'deviceId',
          title: 'ID',
          width: 150,
        },
        {
          key: 'onboardedDevice',
          title: 'Label',
          align: 'center',
          width: 150,
          builder: ({ onboardedDevice }) => onboardedDevice?.label,
        },
        {
          key: 'deviceStatus',
          width: 125,
          title: 'Status',
          align: 'center',
          builder: ({ deviceStatus }) =>
            deviceStatus && <ColorfulBadge suffix={73}>{deviceStatus}</ColorfulBadge>,
        },
        {
          key: 'tag',
          width: 125,
          title: 'Tag',
          align: 'center',
          builder: ({ tag }) => tag && <ColorfulBadge>{tag}</ColorfulBadge>,
        },
        {
          title: 'Product',
          width: 125,
          align: 'center',
          disableAction: true,
          builder: ({ productId }) =>
            productMap.hasOwnProperty(productId) && (
              <Link to={`/super-admin/products/${tenantId}/${productId}`}>
                {productMap[productId].name}
              </Link>
            ),
        },
      ]}
    />
  );
}
