import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} AccessTokenData
 * @property {'PLATFORM_ADMIN'|'TENANT_ADMIN'|'END_USER'} subject_type
 * @property {'PLATFORM'|'TENANT'} auth_scope
 * @property {number} exp
 * @property {boolean} has_additional_rights
 * @property {Array.<string>} rights
 * @property {string} role
 * @property {string} user_id
 */

/**
 * @typedef {PlatformAdmin & TenantAdmin} UserData
 */

/**
 * @typedef {object} InitialState
 * @property {string} [avatar] User avatar
 * @property {UserType} [userType]
 * @property {LoginResponseModel} [login]
 * @property {UserData} [user]
 * @property {string} [tenantId] Selected tenant id
 * @property {AccessTokenData} [tokenData]
 */

/** @type {InitialState} */
const initialState = {
  // secretToken: 'AWKFIJRMLOFORMUG78234KJD',
  avatar: null,
  userType: null,
  login: null,
  user: null,
  tenantId: null,
  tokenData: null,
};

const reducers = {
  /**
   * Update user avatar
   *
   * @param {import('.').AuthState} state
   * @param {{type: string, payload: string}} action
   */
  setAvatar(state, action) {
    state.avatar = action.payload;
  },
  /**
   * Update the user details
   *
   * @param {import('.').AuthState} state
   * @param {{type: string, payload: UserData?}} action
   */
  setUser(state, action) {
    state.user = action.payload;
  },
  /**
   * Update the tenant id
   *
   * @param {import('.').AuthState} state
   * @param {{type: string, payload: string?}} action
   */
  setTenantId(state, action) {
    state.tenantId = action.payload;
  },
  /**
   * Update the authentication token
   *
   * @param {import('.').AuthState} state
   * @param {{type: string, payload: LoginResponseModel?}} action
   */
  setLoginData(state, action) {
    state.login = action.payload;
    if (!action.payload?.accessToken) {
      state.tokenData = null;
      state.tenantId = null;
      return;
    }
    const source = window.atob(action.payload.accessToken.split('.')[1]);
    // console.log(source);
    state.tokenData = JSON.parse(source);
    if (state.tokenData.subject_type === 'TENANT_ADMIN') {
      state.tenantId = action.payload.tenantId;
    }
  },
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers,
});
