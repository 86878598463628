import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { DeployAiContainerGroupForm } from '../DeployAiContainerGroupForm';
import { SelectDeploymentType } from '../SelectDeploymentType';

const items = [
  {
    title: 'Group',
    details: 'Deploy ai container to a single group',
  },
];

/** @param {{item: AiContainer, disabled: boolean}} props */
export function DeployAiContainerButton(props) {
  const { item, disabled } = props;
  const [showModal, setShowModal] = useState(false);
  const [deploymentType, setDeploymentType] = useState(0);
  const [searchParams] = useSearchParams();
  const groupId = searchParams?.get('groupId');

  return (
    <>
      <Button
        size="sm"
        type="button"
        variant="outline-primary"
        style={{ width: 120 }}
        disabled={disabled}
        onClick={() => setShowModal(true)}
      >
        Deploy
      </Button>

      <Modal size={'md'} show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Deploy AI Container</Modal.Title>
        </Modal.Header>
        <SelectDeploymentType items={items} value={deploymentType} onSelect={setDeploymentType} />
        <hr className="my-0" />
        {deploymentType === 0 && (
          <DeployAiContainerGroupForm
            isGroupFixed={!!groupId}
            groupId={groupId}
            item={item}
            onClose={() => setShowModal(false)}
          />
        )}
      </Modal>
    </>
  );
}
