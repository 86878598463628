/* eslint-disable no-unused-vars */
import { AxiosRequestConfig } from 'axios';

const urlPath = Symbol('api url');

/**
 * @param {Object.<string, object>} api
 * @returns {string|undefined}
 */
export function getUrl(api) {
  if (api[urlPath]) {
    return api[urlPath]();
  }
  return undefined;
}

/**
 * @param {Object.<string, object>} api
 * @param {(config: AxiosRequestConfig) => any} [builder]
 */
function process(api, builder) {
  for (const key in api) {
    if (key === '$baseURL') continue;
    if (key.startsWith('$')) {
      if (typeof api[key] !== 'function') {
        console.warn('Expected function instead of object: ' + key);
        continue;
      }

      // for parameterized path fragment
      const next = api[key]();
      if (next !== null) {
        process(next, builder);
        api[key] = (value) => {
          next[urlPath] = () => api[urlPath]() + `/${value}`;
          return next;
        };
        continue;
      }

      api[key] = (config) => {
        const cfg = config || {};
        cfg.url = api[urlPath]();
        cfg.method = key.substring(1).toUpperCase();
        return builder ? builder(cfg) : undefined;
      };
    } else if (key.startsWith(':')) {
      // for action path fragment
      api[key][urlPath] = () => api[urlPath]() + `${key}`;
      process(api[key], builder);
    } else {
      // for normal path fragment
      const value = key === '.' ? '' : key;
      api[key][urlPath] = () => api[urlPath]() + `/${value}`;
      process(api[key], builder);
    }
  }
}

/**
 * Implement API stub to make it available for use
 *
 * @param {Object.<string, object>} api
 * @param {(config: AxiosRequestConfig) => any} [builder]
 */
export function implementAPI(api, builder) {
  if (!/^https?:\/\/.*$/g.test(api.$baseURL)) {
    throw new Error('Invalid base url');
  }
  api[urlPath] = () => api.$baseURL;
  process(api, builder);
}
