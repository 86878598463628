import api from '@/api';
import { getBearerToken, getTenantId } from '@/state/auth';
import { fetchAllRoles } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { CreateTenantAdminForm } from './@components/CreateTenantAdminForm';
import { CreateTenantAdminPlaceholder } from './@components/CreateTenantAdminPlaceholder';

export function CreateTenantAdmin() {
  const tenantId = useSelector(getTenantId);
  const bearerToken = useSelector(getBearerToken);
  useDispatchLoader(fetchAllRoles, [bearerToken]);

  const [tenant, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.tenant.$tenantId(tenantId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, tenantId]);

  if (error) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      {loading ? <CreateTenantAdminPlaceholder /> : <CreateTenantAdminForm tenant={tenant} />}
    </BreadcrumbLayout>
  );
}
