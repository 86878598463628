import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { refreshUserTokenPeriodically } from './@subscriptions/access-token';
import { authPersistConfig, AuthSlice } from './auth';
import { cachePersistConfig, CacheSlice } from './cache';
import { chartPersistConfig, ChartSlice } from './chart';

// --------------------------------------------------------------------------------------------- //
//                                         TYPE HINTS                                            //
// --------------------------------------------------------------------------------------------- //

/**
 * @template
 * @typedef {object} StoreState
 * @property {import('./auth').AuthState} auth
 * @property {import('./cache').CacheState} cache
 * @property {import('./chart').ChartState} chart
 */

// --------------------------------------------------------------------------------------------- //
//                                     STORE CONFIGURATION                                       //
// --------------------------------------------------------------------------------------------- //

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, AuthSlice.reducer),
    cache: persistReducer(cachePersistConfig, CacheSlice.reducer),
    chart: persistReducer(chartPersistConfig, ChartSlice.reducer),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// --------------------------------------------------------------------------------------------- //
//                                     STORE SUBSCRIPTIONS                                       //
// --------------------------------------------------------------------------------------------- //

refreshUserTokenPeriodically(store);
