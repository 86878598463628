import { useContext } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProduct, getTenant } from '@/state/cache';
import { GroupViewContext } from '../@components/GroupViewLayout';
import { DeviceListView } from './DeviceListView';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';

export function ViewGroupPageDevice() {
  /** @type {{group: DeploymentGroup}} props */
  const { group } = useContext(GroupViewContext);
  const owner = useSelector(getTenant(group?.tenantId));
  const product = useSelector(getProduct(group?.productId));
  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaPaperPlane />}
        title={
          <>
            Devices of <b>{group.name}</b>
          </>
        }
        subtitle={
          <div className="small">
            {group.description} &bull; Product:{' '}
            {product ? (
              <Link to={`/super-admin/products/${product.productId}`}>{product.name}</Link>
            ) : (
              <code>N/A</code>
            )}{' '}
            &bull; Owner:{' '}
            {owner ? (
              <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
            ) : (
              <code>N/A</code>
            )}
          </div>
        }
      ></BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DeviceListView />
      </BreadcrumbLayout.Body>
    </>
  );
}
