import { Actions } from '@/web/common/AccessRights';
import { TenantSelect } from '@/web/common/TenantSelect';
import { FaCameraRetro, FaDesktop, FaMemory, FaNetworkWired, FaPaperPlane } from 'react-icons/fa';
import { SuperAdmin } from '.';
import { SuperAdminAiAccelerator } from './ai-accelerator';
import { CreateAiAccelerator } from './ai-accelerator/create';
import { CreateAiAcceleratorWithTenant } from './ai-accelerator/create/_tenantId';
import { ViewAiAccelerator } from './ai-accelerator/_codeName';
import { EditAiAccelerator } from './ai-accelerator/_codeName/edit';
import { SuperAdminProducts } from './products';
import { CreateProductPage } from './products/create/_tenantId';
import { ViewProductPage } from './products/_productId';
import { ViewProductPageConnectivity } from './products/_productId/connectivity';
import { ViewProductPageFirmware } from './products/_productId/firmware';
import { ViewProductPageGeneral } from './products/_productId/general';
import { ViewProductPageHardware } from './products/_productId/hardware';
import { ViewProductPageOutputs } from './products/_productId/outputs';
import { SharedProductListViewWithTenant } from './shared-products/_tenantId';

export const aiAcceleratorRoutes = [
  {
    path: '/super-admin/ai-accelerator',
    element: <SuperAdminAiAccelerator />,
    breadcrumb: 'AI Accelerator',
    rights: [Actions.AI_ACCELERATOR_LIST_READ],
  },
  {
    path: '/super-admin/ai-accelerator/create',
    element: <CreateAiAccelerator />,
    breadcrumb: 'Owner Selection',
    rights: [Actions.AI_ACCELERATOR_CREATE],
  },
  {
    path: '/super-admin/ai-accelerator/create/:ownerTenantId',
    element: <CreateAiAcceleratorWithTenant />,
    caseSensitive: true,
    breadcrumb: 'Create',
    rights: [Actions.AI_ACCELERATOR_CREATE],
  },
  {
    path: '/super-admin/ai-accelerator/:codeName',
    element: <ViewAiAccelerator />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.AI_ACCELERATOR_READ],
  },
  {
    path: '/super-admin/ai-accelerator/:codeName/edit',
    element: <EditAiAccelerator />,
    caseSensitive: true,
    breadcrumb: 'Edit',
    rights: [Actions.AI_ACCELERATOR_UPDATE],
  },
];

export const productsRoutes = [
  {
    path: '/super-admin/products',
    element: (
      <TenantSelect title="Choose Tenant" subtitle="Select a tenant to manage their products" />
    ),
    breadcrumb: 'Products',
    rights: [Actions.PRODUCT_LIST_READ],
  },
  {
    path: '/super-admin/products/:tenantId',
    element: <SuperAdminProducts />,
    breadcrumb: 'Tenants',
    rights: [Actions.PRODUCT_LIST_READ],
  },
  {
    path: '/super-admin/products/create',
    element: (
      <TenantSelect title={'Select Owner Tenant'} subtitle={'Select a tenant to add a product'} />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.PRODUCT_CREATE],
  },
  {
    path: '/super-admin/products/:tenantId/create',
    element: <CreateProductPage />,
    breadcrumb: 'Create',
    rights: [Actions.PRODUCT_CREATE],
  },
  {
    path: '/super-admin/products/:tenantId/:productId',
    element: <ViewProductPage />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.PRODUCT_READ],
    children: [
      {
        path: 'general',
        element: <ViewProductPageGeneral />,
        breadcrumb: 'General',
        description: 'General information about this product',
        icon: <FaDesktop />,
      },
      {
        path: 'hardware',
        element: <ViewProductPageHardware />,
        breadcrumb: 'Hardware',
        description: 'Manage connectivity capabilities',
        icon: <FaNetworkWired />,
      },
      {
        path: 'connectivity',
        element: <ViewProductPageConnectivity />,
        breadcrumb: 'Connectivity',
        description: 'Manage hardware capabilities',
        icon: <FaMemory />,
      },
      {
        path: 'outputs',
        element: <ViewProductPageOutputs />,
        breadcrumb: 'Imager / Sensor Inputs',
        description: 'Manage audio, video, or sensor inputs',
        icon: <FaCameraRetro />,
      },
      {
        path: 'firmware',
        element: <ViewProductPageFirmware />,
        breadcrumb: 'Deployments',
        icon: <FaPaperPlane />,
        description: 'View firmware deployments and history',
      },
    ],
  },
];

export const sharedProductsRoutes = [
  {
    path: '/super-admin/shared-products',
    element: (
      <TenantSelect
        title="Choose Tenant"
        subtitle="Select a tenant to manage their shared Products"
      />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.PRODUCT_SHARE_LIST_READ],
  },
  {
    path: '/super-admin/shared-products/:tenantId',
    element: <SharedProductListViewWithTenant />,
    breadcrumb: 'Shared Products',
    rights: [Actions.PRODUCT_SHARE_LIST_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/super-admin',
    element: <SuperAdmin />,
    breadcrumb: 'Super Admin',
    rights: [
      Actions.PRODUCT_LIST_READ,
      Actions.AI_ACCELERATOR_LIST_READ,
      Actions.PRODUCT_SHARE_LIST_READ,
    ],
  },
  ...aiAcceleratorRoutes,
  ...productsRoutes,
  ...sharedProductsRoutes,
];
