import { USER_ACCESS_AI_ACCELERATOR_CREATE } from '@/web/common/AccessRights';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SuperAdminDonutGroup } from '../@components/SuperAdminDonutGroup';
import { AiAccelaratorListView } from './@components/AiAccelaratorListView';

export function SuperAdminAiAccelerator() {
  return (
    <SidebarPlotLayout sidebar={<SuperAdminDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="AI Accelarators">
        <USER_ACCESS_AI_ACCELERATOR_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> New AI Accelarator
          </Button>
        </USER_ACCESS_AI_ACCELERATOR_CREATE>
      </BreadcrumbLayout.Header>
      <AiAccelaratorListView />
    </SidebarPlotLayout>
  );
}
