import { inputOutputSources } from '@/assets/theme/colors';
import { CloseButton } from 'react-bootstrap';
import { DroppableDiv } from '@/web/common/DragDropComponents';
import { HorizontalConnectionArrow } from '../../HorizontalConnectionArrow';
import { InputCard } from '@/web/common/input-output-sources/InputCard';
import { OutputCard } from '@/web/common/input-output-sources/OutputCard';
import './index.scss';

/**
 * @typedef {object} InputOutputMappingProps
 * @property {'VIDEO'|'AUDIO'|'SENSOR'} inputType
 * @property {VideoInput & AudioInput & SensorInput} input
 * @property {AiModel} source
 * @property {InputMapModel} mapping
 * @property {string} color
 * @property {(e: Event) => any} onClear
 * @property {(output: InputMapModel) => any} onDrop
 */

/** @param {InputOutputMappingProps} props */
export function DroppableInputOutputMapping(props) {
  const { inputType, input, source, mapping, color, onClear, onDrop, ...otherProps } = props;
  const _mapping = mapping || { inputType };
  const _inputColor = color || inputOutputSources[inputType];
  const _outputColor = color || inputOutputSources[_mapping.inputType];

  if (!input || !source) {
    return null;
  }

  return (
    <div {...otherProps}>
      <div className="input-output-mapping-container" style={{ borderColor: _inputColor }}>
        <div className="position-relative">
          <DroppableDiv
            color={_outputColor}
            onDrop={onDrop}
            canDrop={
              /** @param {InputMapModel} output */
              (output) => output.inputType === inputType
            }
          >
            <OutputCard {...{ mapping, color: _outputColor }} />
          </DroppableDiv>
          {mapping?.mappedOutputCode && (
            <div className="position-absolute top-0 end-0 p-2">
              <CloseButton title="Remove" onClick={onClear} />
            </div>
          )}
        </div>
        <HorizontalConnectionArrow color={_inputColor} />
        <InputCard {...{ input, source, inputType, color: _inputColor }} />
      </div>
    </div>
  );
}
