import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { AiContainerContext } from '../..';

/** @param {{item: AiContainer, disabled: boolean}} props */
export function EditAiContainerButton(props) {
  const { item } = props;

  const updateParent = useContext(AiContainerContext);

  const [editing, setEditing] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(item.name);
  const [version, setVersion] = useState(item.version);
  const [type, setType] = useState(item.type);
  const [description, setDescription] = useState(item.description);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const request = api.sai.v1['ai-container'].$containerId(item.containerId).$put({
      data: {
        ...item,
        name: name === item.name ? null : name,
        type: type === item.type ? null : type,
        version: version === item.version ? null : version,
        description: description === item.description ? null : description,
      },
    });
    try {
      setLoading(true);
      await request.process();
      iziToast.success({
        title: 'Success',
        message: `Saved changes to <b>${item.name}</b>`,
      });
      setEditing(false);
      updateParent();
    } catch (err) {
      setError(request.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="primary"
        style={{ width: 120 }}
        disabled={props.disabled || loading}
        onClick={() => setEditing(true)}
      >
        {loading ? <Spinner animation="border" size="sm" /> : 'Edit'}
      </Button>

      <Modal show={editing} onHide={() => setEditing(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create AI Container</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <InputBox label="Name" value={name} onChange={setName} required />
            <InputBox label="Version" value={version} onChange={setVersion} required />
            <InputBox label="Type" value={type} onChange={setType} required />
            <InputBox
              textarea
              label="Description"
              value={description}
              onChange={setDescription}
              style={{ height: '75px' }}
            />

            {error && (
              <>
                <hr className="my-2" />
                <Alert variant="danger" className="my-0 py-2">
                  <FaExclamation /> {error + ''}
                </Alert>
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-end">
            <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
