import api from '@/api';
import { setUser } from '.';

/**
 * Fetch user details
 *
 * @returns {Promise<void>}
 */
export const fetchUserData = () => async (dispatch, getState) => {
  /** @type {import('.').AuthState} */
  const auth = getState().auth;
  if (!auth.login || !auth.login.accessToken) {
    throw new Error('Unauthorized');
  }

  function buildRequest() {
    switch (auth.tokenData?.subject_type) {
      case 'PLATFORM_ADMIN':
        return api.sai.v1['platform-admin'].$id(auth.login.userId).$get({
          headers: {
            Authorization: `Bearer ${auth.login.accessToken}`,
          },
        });
      case 'TENANT_ADMIN':
        return api.sai.v1.tenant
          .$tenantId(auth.login.tenantId)
          .admin.$adminId(auth.login.userId)
          .$get({
            headers: {
              Authorization: `Bearer ${auth.login.accessToken}`,
            },
          });
      default:
        throw new Error(`Invalid subject type: ${auth.tokenData?.subject_type}`);
    }
  }

  try {
    const request = buildRequest();
    await request.process();
    const user = request.result.result;
    await dispatch(setUser(user));
  } catch (err) {
    throw new Error((err.response && err.response.data.message) || err.message);
  }
};
