import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { EditTenantContext } from '../..';

/** @param {{tenant: Tenant}} props */
export function EditTenantForm(props) {
  let [tenant, setTenant] = useState(props.tenant);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(tenant.name);
  const [address, setAddress] = useState(tenant.address);
  const [picture, setPicture] = useState(tenant.picture);
  const updateParent = useContext(EditTenantContext);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.tenant.$tenantId(tenant.tenantId).$patch({
        data: {
          name,
          address,
          picture,
        },
      });
      await request.process();
      iziToast.info({
        title: `Tenant updated successfully`,
      });
      setLoading(false);
      setTenant(request.result.result);
      updateParent();
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Body>
        <InputBox
          label="Name"
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={setName}
          required
        />
        <InputBox
          label="Picture URL"
          type="text"
          placeholder="Enter picture url"
          value={picture}
          onChange={setPicture}
        />
        <InputBox
          label="Address"
          type="text"
          placeholder="Enter address"
          value={address}
          onChange={setAddress}
          required
        />
        {error && (
          <>
            <hr className="my-2" />
            <Alert variant="danger" className="my-0 py-2">
              <FaExclamation /> {error + ''}
            </Alert>
          </>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Save General'}
        </Button>
      </Card.Footer>
    </Form>
  );
}
