import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { DeployedTriggerContext } from '../DeployedListView';

/** @param {{item: TriggerDeploymentModel, disabled: boolean}} props */
export function UndeployTriggerButton(props) {
  const [loading, setLoading] = useState(false);
  const updateParent = useContext(DeployedTriggerContext);

  const item = props.item;
  const { groupId } = useParams();

  const undeployTrigger = async () => {
    const request = api.sai.v1['deployment-group'].$groupId(groupId)['trigger-deployment'].$delete({
      data: {
        triggerId: item?.triggerId,
      },
    });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Undeployed Trigger from group!`,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      size="sm"
      type="button"
      variant="outline-danger"
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: undeployTrigger,
          title: `Undeploy ${item.triggerName}  from this group?`,
          message: 'It will affect all resources using this Trigger',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Undeploy'}
    </Button>
  );
}
