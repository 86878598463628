import api from '@/api';
import { setTenant, setTenants } from '..';

/**
 * Fetch and cache all tenants
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchAllTenants = (refresh) => async (dispatch, getState) => {
  const state = getState();
  if (!state.auth.login?.accessToken) return;

  if (state.cache.tenantsExpiresAt > Date.now()) {
    if (!refresh && Object.keys(state.cache.tenants).length > 0) return;
  }

  if (state.auth.tokenData?.subject_type === 'TENANT_ADMIN') {
    const tenantId = state.auth.login.tenantId;
    const request = api.sai.v1.tenant.$tenantId(tenantId).$get();
    await request.process();
    const tenant = request.result.result;
    await dispatch(setTenant(tenant));
    return;
  }

  const pageSize = 50;
  const tenants = [];

  let hasMore = true;
  let continuationToken = null;
  while (hasMore) {
    const request = api.sai.v1.tenant.$get({
      params: {
        pageSize,
        continuationToken,
      },
      headers: {
        Authorization: `Bearer ${state.auth.login.accessToken}`,
      },
    });
    await request.process();
    const result = request.result.result.results;
    tenants.push(...result);
    continuationToken = request.result.result.continuationToken;
    hasMore = Boolean(continuationToken) && result.length >= pageSize;
  }

  tenants.sort((a, b) => a.name.localeCompare(b.name));
  await dispatch(setTenants(tenants));
};
