import { paramNames } from '@/assets/ai-models/autocomplete';
import { Col, Row } from 'react-bootstrap';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';

/**
 * @param {{item: ModelInput, onChange: () => {}, editing: boolean}} props
 */
export function ModelInputFields(props) {
  const { item, onChange, editing } = props;

  /**
   * @param {string} fieldName
   * @returns {[string, (value: string) => any]}
   */
  const changeObserver = (fieldName) => {
    const value = (item && item[fieldName]) || '';
    const setValue = (v) => onChange({ ...item, [fieldName]: v });
    return [value, setValue];
  };

  const [code, setCode] = changeObserver('code');
  const [name, setName] = changeObserver('name');
  const [description, setDescription] = changeObserver('description');

  return (
    <Row className="gx-2">
      <Col lg={6}>
        <InputBox
          disabled={editing}
          label="Code"
          type="text"
          required
          value={code}
          onChange={setCode}
        />
      </Col>
      <Col lg={6}>
        <SelectBox
          label="Name"
          value={name}
          onChange={setName}
          options={Object.entries(paramNames || {})}
          itemValue={0}
          itemText={1}
          required
        />
      </Col>
      <Col lg={12}>
        <InputBox label="Description" type="text" value={description} onChange={setDescription} />
      </Col>
    </Row>
  );
}
