import api from '@/api';
import { displayError } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DeviceViewContext } from '../../../@components/DeviceViewLayout';

export function EditOnboardDeviceModal() {
  const { deviceId } = useParams();

  /** @type {{device : DeviceDetail}}  */
  const { device, updateDevice } = useContext(DeviceViewContext);
  const onboardedDevice = device.onboardedDevice;

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(onboardedDevice?.label);
  const [parkingStatus, setParkingStatus] = useState(onboardedDevice?.parkingStatus);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const request = api.sai.v1.device.$deviceId(deviceId)['onboarded-device'].$patch({
        data: {
          label,
          parkingStatus,
        },
      });
      await request.process();
      setLoading(false);
      setModal(false);
      updateDevice();
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
  };

  return (
    <>
      <Button style={{ width: 135 }} variant="primary" onClick={() => setModal(true)}>
        Edit
      </Button>

      <Modal key={deviceId} show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modify Onboard Details</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <InputBox label="Device Label" value={label} onChange={setLabel} />
            <SelectBox
              label="Parking Status"
              value={parkingStatus}
              onChange={setParkingStatus}
              options={[
                { value: 'ON', text: 'On' },
                { value: 'OFF', text: 'Off' },
                { value: 'POWER_SAVE', text: 'Power Saving' },
              ]}
              itemValue={'value'}
              itemText={'text'}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setModal(false)}
              style={{ width: 100 }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" style={{ width: 100 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
