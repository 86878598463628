import { MainPageLayout } from '@/web/layouts/MainPageLayout';
import { Row } from 'react-bootstrap';
import { DashboardDonutGroup } from './@components/DashboardDonutGroup';

export function Dashboard() {
  return (
    <MainPageLayout>
      <div className="dashboard-donut-container">
        <Row className="mt-sm-2">
          <DashboardDonutGroup />
        </Row>
      </div>
    </MainPageLayout>
  );
}
