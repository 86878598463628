import api from '@/api';
import { makeColorFrom } from '@/utils';
import { buildSummary, formatTriggerType } from '@/utils/triggers';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { DeployTriggerButton } from '@/web/pages/ai/@components/DeployTriggerButton';
import { useNavigate, useParams } from 'react-router';

/** @param {{status: string}} props  */
export function TriggerListView(props) {
  const { tenantId } = useParams();
  const { status } = props;
  const navigate = useNavigate();

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (limit, token) => {
        const request = api.sai.v1['trigger'].$get({
          params: {
            pageSize: limit,
            continuationToken: token,
            tenantId,
            status,
          },
        });

        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {TriggerWithLabels} [trigger] */
        (trigger) => navigate(`./${trigger.triggerId}`)
      }
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          width: 125,
          title: 'Type',
          align: 'center',
          builder: ({ triggerDefinition }) => (
            <span>{formatTriggerType(triggerDefinition.root.type)}</span>
          ),
        },
        {
          width: 125,
          title: 'Status',
          align: 'center',
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          minWidth: 125,
          title: 'Summary',
          builder: buildSummary,
        },
        {
          title: 'Actions',
          disableAction: true,
          width: 150,
          builder: (item) => {
            return (
              <div className="text-center">
                {item.status === 'DISCONTINUED' ? null : <DeployTriggerButton item={item} />}
              </div>
            );
          },
        },
      ]}
    />
  );
}
