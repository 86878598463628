import { USER_ACCESS_ROLE_UPDATE } from '@/web/common/AccessRights';
import { useState } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { EditRoleModal } from '../EditRoleModal';

/** @param {{item: Role}} props */
export function RoleListItem(props) {
  const [role, setRole] = useState(props.item);
  const [editing, setEditing] = useState(false);

  return (
    <>
      <ListGroup.Item
        action
        onClick={() => setEditing(true)}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="ms-lg-2">
          <div className="fw-bold">{role.name}</div>
          <div className="text-muted">{role.description}</div>
        </div>
        <Badge bg="info" text="dark" pill>
          {role.rights.length} Rights
        </Badge>
      </ListGroup.Item>
      {editing && (
        <USER_ACCESS_ROLE_UPDATE>
          <EditRoleModal
            role={role}
            onDone={(role) => setRole(role)}
            onHide={() => setEditing(false)}
          />
        </USER_ACCESS_ROLE_UPDATE>
      )}
    </>
  );
}
