/**
 * @template T
 * @param {(chart: import(".").ChartState) => T} callback
 * @returns {(state: import("..").StoreState) => T}
 */
const withCacheState = (callback) => (state) => callback(state.chart);

export const getChartData = withCacheState((chart) => chart.data);

/**
 * @param {string} key The chart data key name
 * @returns {(state: import("..").StoreState) => boolean}
 */
export const checkIsCacheValid = (key) => (state) => {
  const expiry = state.chart.expiry[key];
  if (typeof expiry !== 'number' || expiry < Date.now()) return false;

  const data = state.chart.data[key];
  if (!data) return false;
  if (data.tenantId && data.tenantId !== state.auth.tenantId) return false;

  return true;
};
