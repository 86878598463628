import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useContext } from 'react';
import { GiArtificialHive } from 'react-icons/gi';
import { DeviceViewContext } from '../@components/DeviceViewLayout';
import { DeployedAiContainerView } from './@components/DeployedAiContainerView';

export function ViewDevicePageAiContainer() {
  /** @type {{device: DeviceDetail}} props */
  const { device } = useContext(DeviceViewContext);
  const groupId =
    device?.onboardedDevice?.deploymentGroupIds[
      device?.onboardedDevice?.deploymentGroupIds?.length - 1
    ];

  return (
    <>
      <BreadcrumbLayout.Header icon={<GiArtificialHive />} title="Deployed AI Container" />
      <BreadcrumbLayout.Body>
        <DeployedAiContainerView groupId={groupId} />
      </BreadcrumbLayout.Body>
    </>
  );
}
