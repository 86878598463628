import { isCurrentTenant } from '@/state/auth';
import { getProduct, getTenant } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { timeFromNow } from '@/utils/formatting';
import {
  USER_ACCESS_FIRMWARE_DELETE,
  USER_ACCESS_FIRMWARE_DISCONTINUE,
  USER_ACCESS_FIRMWARE_PUBLISH,
  USER_ACCESS_FIRMWARE_SHARE_CREATE,
  USER_ACCESS_FIRMWARE_SHARE_DELETE,
  USER_ACCESS_FIRMWARE_UPDATE,
} from '@/web/common/AccessRights';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Button, Stack } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { DeleteFirmwareButton } from '../DeleteFirmwareButton';
import { RemoveShareFirmwareButton } from '../RemoveShareFirmwareButton';
import { ShareFirmwareButton } from '../ShareFirmwareButton';
import { DiscontinueFirmwareButton } from './../DiscontinueFirmwareButton';
import { PublishFirmwareButton } from './../PublishFirmwareButton';

/** @param {{item: FirmwareDetailsInfo}} props */
export function FirmwareView(props) {
  const { item } = props;
  const { tenantId: ownerTenantId } = useParams();

  const owner = useSelector(getTenant(ownerTenantId));
  const product = useSelector(getProduct(item.productIds[0]));
  const isShared = !useSelector(isCurrentTenant(item.ownerTenantId));

  const canPublish = item.status === 'DRAFT';
  const canDiscontinue = item.status === 'DRAFT' || item.status === 'PUBLISHED';
  const canShare = item.status !== 'DISCONTINUED';

  return (
    <>
      <BreadcrumbLayout.Header title={<span>General Information</span>}>
        {item.status === 'DRAFT' && !isShared && (
          <Stack className="justify-content-end" direction="horizontal" gap={2}>
            <USER_ACCESS_FIRMWARE_UPDATE>
              <Button as={Link} to="./edit" variant="primary" style={{ width: 132 }}>
                Edit
              </Button>
            </USER_ACCESS_FIRMWARE_UPDATE>
            <USER_ACCESS_FIRMWARE_DELETE>
              <DeleteFirmwareButton item={item} />
            </USER_ACCESS_FIRMWARE_DELETE>
          </Stack>
        )}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={item}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'name',
              title: 'Name',
            },
            {
              key: 'version',
              title: 'Version',
            },
            {
              key: 'type',
              title: 'Type',
            },
            {
              key: 'description',
              title: 'Description',
            },
            {
              key: 'productId',
              title: 'Product',
              builder: ({ productId }) =>
                product && <Link to={`/super-admin/products/${productId}`}>{product.name}</Link>,
            },
            {
              key: 'status',
              builder: ({ status }) => (
                <Stack direction="horizontal" gap="2">
                  <div className="badge me-4" style={{ background: makeColorFrom(status, 73) }}>
                    {status}
                  </div>
                  {canPublish && !isShared && (
                    <USER_ACCESS_FIRMWARE_PUBLISH>
                      <PublishFirmwareButton item={item} />
                    </USER_ACCESS_FIRMWARE_PUBLISH>
                  )}
                  {canDiscontinue && !isShared && (
                    <USER_ACCESS_FIRMWARE_DISCONTINUE>
                      <DiscontinueFirmwareButton item={item} />
                    </USER_ACCESS_FIRMWARE_DISCONTINUE>
                  )}
                </Stack>
              ),
            },
            {
              key: 'ownerTenantId',
              title: 'Owner Tenant',
              builder: () =>
                owner && (
                  <Stack direction="horizontal" gap="4">
                    <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
                    {!isShared && canShare && (
                      <USER_ACCESS_FIRMWARE_SHARE_CREATE>
                        <ShareFirmwareButton item={item} />
                      </USER_ACCESS_FIRMWARE_SHARE_CREATE>
                    )}
                    {isShared && (
                      <USER_ACCESS_FIRMWARE_SHARE_DELETE>
                        <RemoveShareFirmwareButton item={item} />
                      </USER_ACCESS_FIRMWARE_SHARE_DELETE>
                    )}
                  </Stack>
                ),
            },
            {
              title: 'Package',
              builder: (item) =>
                item.packageDetails && (
                  <div className="d-flex align-items-center gap-2">
                    <FaDownload />
                    <a target="_blank" rel="noreferrer" href={item.packageDetails.downloadUrl}>
                      {item.packageDetails.fileName || 'Download Firmware'}
                    </a>
                  </div>
                ),
            },
            {
              title: 'Created',
              builder: ({ createdAt }) => timeFromNow(createdAt),
            },
            {
              title: 'Updated',
              builder: ({ updatedAt }) => timeFromNow(updatedAt),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
