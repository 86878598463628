import { donutCharColors } from '@/assets/colors/autocomplete';
import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchPlatformAdminDonutData } from '@/state/chart/fetchers/platform-admins';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function PlatformAdminDonut(props) {
  const { sidebar } = props;
  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchPlatformAdminDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.platformAdmins?.total || 0,
    points: [
      ['data1', 'data2'],
      ['Platform Admins', chartData.platformAdmins?.total || 0],
    ],
  };
  return (
    <DashboardItem sidebar={sidebar} header={'Platform Admins'} url={'/internal/platform-admin'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'Platform Admins' }}
        legends={[{ name: 'Platform Admins', className: 'orange' }]}
        colors={[donutCharColors.ORANGE]}
        data={data.points}
      />
    </DashboardItem>
  );
}
