import api from '@/api';
import { fetchAllAiAccelerators, getAiAcceleratorList } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { displayError } from '@/utils/toaster';
import { CheckBox } from '@/web/common/forms/CheckBox';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaDesktop, FaMemory, FaNetworkWired } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export function CreateProductPage() {
  const { tenantId: ownerTenantId } = useParams();

  const navigate = useNavigate();
  const accelerators = useSelector(getAiAcceleratorList);

  useDispatchLoader(fetchAllAiAccelerators);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [description, setDescription] = useState(null);
  const [website, setWebsite] = useState(null);
  const [productManual, setProductManual] = useState(null);
  const [cpu, setCpu] = useState(null);
  const [gpu, setGpu] = useState(null);
  const [npu, setNpu] = useState(null);
  const [ram, setRam] = useState(null);
  const [storage, setStorage] = useState(null);
  const [bluetooth, setBluetooth] = useState(null);
  const [wifi, setWifi] = useState(null);
  const [ethernet, setEthernet] = useState(null);
  const [cellular, setCellular] = useState(null);
  const [aiAcceleratorId, setAiAcceleratorId] = useState(null);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      if (loading) return;
      setLoading(true);
      if (!name.trim()) {
        iziToast.warning({ title: 'Invalid', message: 'Product name can not be empty' });
      }
      if (!serialNo.trim()) {
        iziToast.warning({ title: 'Invalid', message: 'Product serial can not be empty' });
      }
      const request = api.sai.v1.product.$post({
        data: {
          ownerTenantId,
          name,
          serialNo,
          description,
          website,
          productManual,
          cpu,
          gpu,
          npu,
          ram,
          storage,
          bluetooth,
          wifi,
          ethernet,
          cellular,
          aiAcceleratorIds: [aiAcceleratorId],
        },
      });
      await request.process();
      setLoading(false);
      navigate(`./..`);
      iziToast.success({ title: 'Successfully created new product' });
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
    return false;
  };

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title className="my-1">Create New Product</Card.Title>
        </Card.Header>
        <Form onSubmit={handleSubmit}>
          <Card.Body>
            <div>
              <h5 className="text-primary">
                <FaDesktop /> General Information
              </h5>
              <hr className="mt-1" />
              <SelectBox
                label="AI Accelerator"
                placeholder="Select AI Accelerator"
                value={aiAcceleratorId}
                onChange={setAiAcceleratorId}
                options={accelerators}
                itemValue="codeName"
                itemText="name"
                required
              />
              <InputBox label="Product name" value={name} required onChange={setName} />
              <InputBox label="Product serial" value={serialNo} required onChange={setSerialNo} />
              <InputBox
                textarea
                label="Description"
                value={description}
                onChange={setDescription}
              />
              <InputBox label="Website link" value={website} onChange={setWebsite} />
              <InputBox
                label="Product manual link"
                value={productManual}
                onChange={setProductManual}
              />
            </div>

            <div>
              <h5 className="mt-4 text-primary">
                <FaMemory /> Hardware Capabilities
              </h5>
              <hr className="mt-1" />
              <InputBox label="CPU" value={cpu} onChange={setCpu} />
              <InputBox label="GPU" value={gpu} onChange={setGpu} />
              <InputBox label="NPU" value={npu} onChange={setNpu} />
              <InputBox label="RAM" value={ram} onChange={setRam} />
              <InputBox label="Storage" value={storage} onChange={setStorage} />
            </div>

            <div>
              <h5 className="mt-4 text-primary">
                <FaNetworkWired /> Connectivity Capabilities
              </h5>
              <hr className="mt-1" />
              <CheckBox label="Wi-Fi" value={wifi} onChange={setWifi} />
              <CheckBox label="Bluetooth" value={bluetooth} onChange={setBluetooth} />
              <CheckBox label="Cellular (LTE / 4G)" value={cellular} onChange={setCellular} />
              <CheckBox label="Ethernet (LAN)" value={ethernet} onChange={setEthernet} />
            </div>
          </Card.Body>

          <Card.Footer className="d-flex justify-content-between">
            <div />
            <Button type="submit" style={{ width: 130 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </BreadcrumbLayout>
  );
}
