import { fetchAllProducts } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { USER_ACCESS_DEVICE_ADD_USER_ACCESS } from '@/web/common/AccessRights';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { DashboardDonutGroup } from '../@components/DashboardDonutGroup';
import { DeviceListView } from './@components/DeviceListView';

export function DeviceListViewWithTenant() {
  useDispatchLoader(fetchAllProducts);

  return (
    <SidebarPlotLayout sidebar={<DashboardDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Devices">
        <USER_ACCESS_DEVICE_ADD_USER_ACCESS>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> New Device
          </Button>
        </USER_ACCESS_DEVICE_ADD_USER_ACCESS>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DeviceListView />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
