/**
 * @template T
 * @param {(auth: import(".").AuthState) => T} callback
 * @returns {(state: import("..").StoreState) => T}
 */
const withAuthState = (callback) => (state) => callback(state.auth);

export const isLoggedIn = withAuthState((auth) =>
  Boolean(auth.login && auth.user && auth.login.accessToken)
);

export const getUserAvatar = withAuthState(
  (auth) => auth.avatar || '/images/navbar/default_avatar.png'
);

export const getBearerToken = withAuthState(
  (auth) => auth.login && `Bearer ${auth.login.accessToken}`
);

export const getTenantId = withAuthState((auth) => auth.tenantId);

export const getUser = withAuthState((auth) => auth.login && auth.user);

export const getTokenData = withAuthState((auth) => auth.tokenData);

export const isCurrentTenant = (tenantId) => withAuthState((auth) => tenantId === auth.tenantId);
