import md5 from 'spark-md5';

/**
 * Returns a color generated using the value and the suffix.
 *
 * @param {any} value A value to use a get a color
 * @param {any} [suffix] Extra salt to add with the value
 * @returns {string} A color in hexadecimal format. i.e.: #123456
 */
export function makeColorFrom(value, suffix = '') {
  const hash = md5.hash(value + '' + suffix).toString('hex');
  return '#' + hash.substring(0, 6);
}

/**
 * Returns a color generated using the value and the suffix.
 *
 * @param {any} value A value to use a get a color
 * @param {any} [suffix] Extra salt to add with the value
 * @param {number} [minValue] Minimum red / green / value value
 * @param {number} [maxValue] Maximum red / green / value value
 * @returns {string} A color in rgb(R, G, B) format.
 */
export function makeColorFromRanged(value, suffix = '', minValue = 0, maxValue = 255) {
  minValue = minValue || 0;
  maxValue = maxValue || 255;
  const diff = Math.abs(maxValue - minValue);
  if (minValue + diff > 255) {
    throw Error('minValue and maxValue must be between 0 and 255');
  }

  const hash = md5.hash(value + '' + suffix).toString('hex');
  const color = Number.parseInt(hash.substring(0, 6), 16);
  let r = (color >> 16) % 256;
  let g = (color >> 8) % 256;
  let b = color % 256;

  r = Math.round((r / 256) * diff + minValue);
  g = Math.round((g / 256) * diff + minValue);
  b = Math.round((b / 256) * diff + minValue);

  return `rgb(${r},${g},${b})`;
}

/**
 * Generate any random color.
 *
 * @param {number} minValue Minimum red / green / value value
 * @param {number} maxValue Maximum red / green / value value
 * @returns {string} A color in rgb(R, G, B) format.
 */
export function getRandomColor(minValue, maxValue) {
  minValue = minValue || 0;
  maxValue = maxValue || 255;
  const diff = Math.abs(maxValue - minValue);
  if (minValue + diff > 255) {
    throw Error('minValue and maxValue must be between 0 and 255');
  }
  const r = Math.round(Math.random() * diff) + minValue;
  const g = Math.round(Math.random() * diff) + minValue;
  const b = Math.round(Math.random() * diff) + minValue;
  return `rgb(${r},${g},${b})`;
}
