import { Navigate } from 'react-router-dom';
import { displayError } from '@/utils/toaster';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { CreateAiModelForm } from './@components/CreateAiModelForm';
import { fetchAllAiAccelerators, fetchAllTenants } from '@/state/cache';

export function CreateAiModelWithTenant() {
  useDispatchLoader(fetchAllTenants);
  const [acceleratorLoading, acceleratorError] = useDispatchLoader(fetchAllAiAccelerators);

  if (acceleratorLoading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <PageLoadingSpinner status="Loading AI Accelerators..." />
      </BreadcrumbLayout>
    );
  }

  if (acceleratorError) {
    displayError(acceleratorError);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <BreadcrumbLayout.Body>
        <CreateAiModelForm />
      </BreadcrumbLayout.Body>
    </BreadcrumbLayout>
  );
}
