import api from '@/api';
import { getBearerToken, getTenantId } from '@/state/auth';
import { fetchAllGroupsByTenant, fetchAllProducts, fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const DeviceViewContext = createContext();

export function DeviceViewLayout(props) {
  const { deviceId } = useParams();
  const tenantId = useSelector(getTenantId);

  const [id, setId] = useState();
  const updateDevice = () => {
    setId(Date.now());
  };

  useDispatchLoader(fetchAllTenants);
  useDispatchLoader(fetchAllProducts);
  useDispatchLoader(() => fetchAllGroupsByTenant(tenantId), [tenantId]);

  const bearerToken = useSelector(getBearerToken);
  const [device, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.device.$deviceId(deviceId).$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, deviceId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading device...'} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    return (
      <BreadcrumbLayout>
        <PageLoadingFailure message={'Failed to load device'}>{error + ''}</PageLoadingFailure>
      </BreadcrumbLayout>
    );
  }

  return (
    <BreadcrumbLayout
      title={device?.onboardedDevice?.label || device.deviceId}
      subtitle={device?.onboardedDevice?.label || device.deviceId}
    >
      <DeviceViewContext.Provider value={{ device, updateDevice }}>
        {props.children}
      </DeviceViewContext.Provider>
    </BreadcrumbLayout>
  );
}
