import api from '@/api';
import { getProductList } from '@/state/cache';
import { displayError } from '@/utils/toaster';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

/** @param {{item: Firmware}} props */
export function DeployFirmwareProductForm(props) {
  const { item } = props;

  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(item.productIds?.[0] || '');

  const products = useSelector(getProductList);

  const deployFirmware = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const request = api.sai.v1.product.$productId(productId)['firmware-deployment'].$post({
      data: {
        firmwareId: item.firmwareId,
        firmwareType: item.type,
      },
    });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Deployed Firmware <b>${item.name}</b>`,
      });
      props.onClose();
    } catch (err) {
      setLoading(false);
      displayError(request?.error || err);
    }
  };

  return (
    <Form onSubmit={deployFirmware}>
      <Modal.Body>
        <SelectBox
          label="Product"
          placeholder="Select Product"
          value={productId}
          onChange={setProductId}
          options={products?.filter((product) => product.productId === item?.productIds[0])}
          itemValue="productId"
          itemText="name"
          required
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button type="submit" disabled={loading} variant="primary" style={{ width: 100 }}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Deploy'}
        </Button>
      </Modal.Footer>
    </Form>
  );
}
