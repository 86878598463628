import api from '@/api';
import { getProductMap } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { timeFromNow } from '@/utils/formatting';
import { USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER } from '@/web/common/AccessRights';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { DeployAiContainerButton } from '@/web/pages/ai/@components/DeployAiContainerButton';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

export function AiContainerListView(props) {
  const { status } = props;
  const { tenantId } = useParams();
  const [searchParams] = useSearchParams();
  const productId = searchParams?.get('productId');

  const navigate = useNavigate();
  const productMap = useSelector(getProductMap);

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (pageSize, continuationToken) => {
        const request = api.sai.v1['ai-container'].$get({
          params: {
            pageSize,
            continuationToken,
            tenantId,
            productId,
            status,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={(item) => navigate(`./${item.containerId}`)}
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'version',
          width: 100,
          align: 'center',
        },
        {
          key: 'type',
          width: 100,
          align: 'center',
        },
        {
          key: 'status',
          width: 125,
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          title: 'Product',
          minWidth: 150,
          align: 'center',
          disableAction: true,
          builder: ({ productId }) =>
            productMap.hasOwnProperty(productId) && (
              <Link to={`/super-admin/products/${productId}`}>{productMap[productId].name}</Link>
            ),
        },
        {
          key: 'description',
          minWidth: 200,
        },
        {
          title: 'Last Updated',
          builder: ({ updatedAt }) => timeFromNow(updatedAt) || 'never',
        },
        {
          title: 'Actions',
          disableAction: true,
          width: 150,
          builder: (item) => {
            return (
              <USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER>
                <div className="text-center">
                  {item.status === 'DISCONTINUED' ? null : <DeployAiContainerButton item={item} />}
                </div>
              </USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER>
            );
          },
        },
      ]}
    />
  );
}
