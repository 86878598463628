import { Actions } from '@/web/common/AccessRights';
import { TenantSelect } from '@/web/common/TenantSelect';
import { FaCog, FaDesktop, FaMicrochip, FaPaperPlane } from 'react-icons/fa';
import { GiArtificialHive, GiArtificialIntelligence } from 'react-icons/gi';
import { Admin } from '.';
import { DevicePoolListViewWithTenant } from './device-pools/_tenantId';
import { CreateDevicePoolWithTenant } from './device-pools/_tenantId/create';
import { ViewDevicePoolWithTenant } from './device-pools/_tenantId/_poolId';
import { ViewDevicePoolPageFirmware } from './device-pools/_tenantId/_poolId/firmware';
import { ViewDevicePoolPageGeneral } from './device-pools/_tenantId/_poolId/general';
import { FirmwareListViewWithTenant } from './firmwares/_tenantId';
import { CreateFirmwareWithTenant } from './firmwares/_tenantId/create';
import { ViewFirmwareWithTenant } from './firmwares/_tenantId/_firmwareId';
import { EditFirmwareWithTenant } from './firmwares/_tenantId/_firmwareId/edit';
import { GroupListviewWithTenant } from './groups/_tenantId';
import { CreateGroupWithTenant } from './groups/_tenantId/create';
import { ViewGroupWithTenant } from './groups/_tenantId/_groupId';
import { ViewGroupPageAiContainer } from './groups/_tenantId/_groupId/ai-container';
import { ViewGroupPageDevice } from './groups/_tenantId/_groupId/device';
import { ViewGroupPageFirmware } from './groups/_tenantId/_groupId/firmware';
import { ViewGroupPageGeneral } from './groups/_tenantId/_groupId/general';
import { SettingsPage } from './groups/_tenantId/_groupId/settings';
import { ViewGroupPageTrigger } from './groups/_tenantId/_groupId/trigger';
import { SharedFirmwareListWithTenant } from './shared-firmwares';

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const firmwareRoutes = [
  {
    path: '/admin/firmwares',
    element: (
      <TenantSelect title="Choose Tenant" subtitle="Select a tenant to manage their firmwares" />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.FIRMWARE_LIST_READ],
  },
  {
    path: '/admin/firmwares/:tenantId',
    element: <FirmwareListViewWithTenant />,
    breadcrumb: 'Firmwares',
    rights: [Actions.FIRMWARE_LIST_READ],
  },
  {
    path: '/admin/firmwares/:tenantId/create',
    element: <CreateFirmwareWithTenant />,
    breadcrumb: 'Create',
    rights: [Actions.FIRMWARE_CREATE],
  },
  {
    path: '/admin/firmwares/:tenantId/:firmwareId/edit',
    element: <EditFirmwareWithTenant />,
    breadcrumb: 'Edit',
    rights: [Actions.FIRMWARE_UPDATE],
  },
  {
    path: '/admin/firmwares/:tenantId/:firmwareId',
    element: <ViewFirmwareWithTenant />,
    breadcrumb: 'View',
    rights: [Actions.FIRMWARE_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const sharedFirmwareRoutes = [
  {
    path: '/admin/shared-firmwares',
    element: <SharedFirmwareListWithTenant />,
    breadcrumb: 'Shared Firmwares',
    rights: [Actions.FIRMWARE_SHARE_LIST_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const groupRoutes = [
  {
    path: '/admin/groups',
    element: (
      <TenantSelect title="Choose Tenant" subtitle="Select a tenant to manage their groups" />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.DEPLOYMENT_GROUP_LIST_READ],
  },
  {
    path: '/admin/groups/:tenantId',
    element: <GroupListviewWithTenant />,
    breadcrumb: 'Groups',
    rights: [Actions.DEPLOYMENT_GROUP_LIST_READ],
  },
  {
    path: '/admin/groups/:tenantId/create',
    element: <CreateGroupWithTenant />,
    breadcrumb: 'Create',
    rights: [Actions.DEPLOYMENT_GROUP_CREATE],
  },
  {
    path: '/admin/groups/:tenantId/:groupId',
    element: <ViewGroupWithTenant />,
    breadcrumb: 'View',
    rights: [Actions.DEPLOYMENT_GROUP_READ],
    children: [
      {
        path: 'general',
        element: <ViewGroupPageGeneral />,
        breadcrumb: 'General',
        icon: <FaDesktop />,
        description: 'View general details',
      },
      {
        path: 'device',
        element: <ViewGroupPageDevice />,
        breadcrumb: 'Devices',
        icon: <FaMicrochip />,
        description: 'View devices assigned to this group',
      },
      {
        path: 'firmware',
        element: <ViewGroupPageFirmware />,
        breadcrumb: 'Firmware Deployments',
        icon: <FaPaperPlane />,
        description: 'View firmware deployments and history',
      },
      {
        path: 'ai-container',
        element: <ViewGroupPageAiContainer />,
        breadcrumb: 'AI Container Deployments',
        icon: <GiArtificialHive />,
        description: 'View AI Container deployments and history',
      },
      {
        path: 'trigger',
        element: <ViewGroupPageTrigger />,
        breadcrumb: 'Trigger Deployments',
        icon: <GiArtificialIntelligence />,
        description: 'View Trigger deployments and history',
      },
      {
        path: 'settings',
        element: <SettingsPage />,
        breadcrumb: 'Settings',
        icon: <FaCog />,
        description: 'View and configure group settings',
      },
    ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const devicePoolRoutes = [
  {
    path: '/admin/device-pools',
    element: (
      <TenantSelect title="Choose Tenant" subtitle="Select a tenant to manage their device pools" />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.DEVICE_POOL_LIST_READ],
  },
  {
    path: '/admin/device-pools/:tenantId',
    element: <DevicePoolListViewWithTenant />,
    breadcrumb: 'Device Pools',
    rights: [Actions.DEVICE_POOL_LIST_READ],
  },
  {
    path: '/admin/device-pools/:tenantId/create',
    element: <CreateDevicePoolWithTenant />,
    breadcrumb: 'Create',
    rights: [Actions.DEVICE_POOL_CREATE],
  },
  {
    path: '/admin/device-pools/:tenantId/:poolId',
    element: <ViewDevicePoolWithTenant />,
    breadcrumb: 'View',
    rights: [Actions.DEVICE_POOL_READ],
    children: [
      {
        path: 'general',
        element: <ViewDevicePoolPageGeneral />,
        breadcrumb: 'General',
        icon: <FaDesktop />,
        description: 'View general details',
      },
      {
        path: 'firmware',
        element: <ViewDevicePoolPageFirmware />,
        breadcrumb: 'Firmware Deployments',
        icon: <FaPaperPlane />,
        description: 'View firmware deployments and history',
      },
    ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/admin',
    element: <Admin />,
    breadcrumb: 'Admin',
    rights: [
      Actions.FIRMWARE_LIST_READ,
      Actions.FIRMWARE_SHARE_LIST_READ,
      Actions.DEVICE_POOL_LIST_READ,
      Actions.DEPLOYMENT_GROUP_LIST_READ,
    ],
  },
  ...firmwareRoutes,
  ...groupRoutes,
  ...sharedFirmwareRoutes,
  ...devicePoolRoutes,
];
