import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { AiContainerContext } from '../../';

/** @param {{item: AiContainer, disabled: boolean}} props */
export function DiscontinueAiContainerButton(props) {
  const { item } = props;
  const updateParent = useContext(AiContainerContext);

  const [loading, setLoading] = useState(false);

  const discontinueAiContainer = async () => {
    const request = api.sai.v1['ai-container']
      .$containerId(item.containerId)
      [':discontinue'].$post({});
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Discontinued AI Container <b>${item.name}</b>`,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      size="sm"
      variant="outline-danger"
      style={{ width: 120 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: discontinueAiContainer,
          title: `Discontinue ${item.name} (${item.version})?`,
          message: 'It will affect all resources using this AI Container',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Discontinue'}
    </Button>
  );
}
