import './index.scss';
const { version } = require('@/assets/version.json');

export function MainFooter() {
  return (
    <footer id="main-footer">
      <div className="d-flex justify-content-between">
        <div>Smarter AI &copy; 2021</div>
        <div>v{version}</div>
      </div>
    </footer>
  );
}
