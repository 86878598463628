import api from '@/api';
import { getTenantId } from '@/state/auth';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { OffsetBasedPagination } from '@/web/common/pagination/OffsetBasedPagination';
import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { TenantAdminListItem } from '../TenantAdminListItem';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenTenantAdmin>} RequestType */

export function TenantAdminListView() {
  const tenantId = useSelector(getTenantId);
  return (
    <OffsetBasedPagination
      key={tenantId}
      loadingText="Loading tenant admins..."
      request={(limit, offset) =>
        api.sai.v1.tenant.$tenantId(tenantId).admin.$get({
          params: {
            offset,
            limit,
          },
        })
      }
      resultLength={
        /** @param {RequestType} request */
        (request) => request.result?.result?.results?.length || 0
      }
      success={
        /** @param {RequestType} request */
        (request) => {
          const users = request.result.result.results;
          if (users.length === 0) {
            return <PageLoadingFailure message="No tenant admin users" />;
          }
          return (
            <ListGroup>
              {users.map((item) => (
                <TenantAdminListItem key={item.adminId} item={item} />
              ))}
            </ListGroup>
          );
        }
      }
    />
  );
}
