import { isCurrentTenant } from '@/state/auth';
import { USER_ACCESS_TRIGGER_DELETE, USER_ACCESS_TRIGGER_UPDATE } from '@/web/common/AccessRights';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useSelector } from 'react-redux';
import { DeleteTriggerButton } from '../DeleteTriggerButton';
import { EditTriggerButton } from '../EditTriggerButton';
import { TriggerGeneralDetails } from '../TriggerGeneralDetails';
import { TriggerJobDetails } from '../TriggerJobDetails';
import { TriggerWorkflowDetails } from '../TriggerWorkflowDetails';

/** @param {{item: TriggerWithLabels}} props */
export function TriggerView(props) {
  const { item } = props;
  const isShared = !useSelector(isCurrentTenant(item.ownerTenantId));

  return (
    <>
      <BreadcrumbLayout.Header title={<span>General Information</span>}>
        {!isShared && item.status === 'DRAFT' && (
          <div className="d-flex justify-content-end gap-2">
            <USER_ACCESS_TRIGGER_UPDATE>
              <EditTriggerButton item={item} />
            </USER_ACCESS_TRIGGER_UPDATE>
            <USER_ACCESS_TRIGGER_DELETE>
              <DeleteTriggerButton item={item} />
            </USER_ACCESS_TRIGGER_DELETE>
          </div>
        )}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <TriggerGeneralDetails item={item} />
      </BreadcrumbLayout.Body>

      <BreadcrumbLayout.Header color="2" title={<span className="fs-3">Trigger Definition</span>} />
      <BreadcrumbLayout.Body>
        <TriggerWorkflowDetails item={item} />
      </BreadcrumbLayout.Body>

      {item.triggerDefinition?.root?.type === 'BASIC' && (
        <>
          <BreadcrumbLayout.Header color="6" title={<span className="fs-3">Job Details</span>} />
          <BreadcrumbLayout.Body>
            <TriggerJobDetails item={item} />
          </BreadcrumbLayout.Body>
        </>
      )}
    </>
  );
}
