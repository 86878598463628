import { useDispatch } from 'react-redux';
import GoogleLogin from 'react-google-login';
import startCase from 'lodash/startCase';
import { GOOGLE_CLIENT_ID } from '../../../../../config';
import { setAvatar } from '@/state/auth';
import { GoogleButton } from './GoogleButton';
import './index.scss';

/**
 * @typedef {object} GoogleLoginButtonProps
 * @property {boolean} [disabled]
 * @property {(email: string, idToken: string) => void} [onLogin]
 * @property {(error: string) => any} [onError]
 */

/**
 * @param {GoogleLoginButtonProps} props
 */
export function GoogleLoginButton(props) {
  const dispatch = useDispatch();

  /**
   * @param {object} googleUser
   */
  function handleSuccess(googleUser) {
    const profile = googleUser.getBasicProfile();
    const response = googleUser.getAuthResponse();
    const email = profile.getEmail();
    const imageUrl = profile.getImageUrl();
    const idToken = response.id_token;
    dispatch(setAvatar(imageUrl));
    props.onLogin && props.onLogin(email, idToken);
  }

  /**
   * @param {{error: string, details: string}} response
   */
  function handleFailure(response) {
    console.debug('Google login error', response);
    const message = response.details || startCase(response.error);
    props.onError && props.onError(message);
  }

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      disabled={props.disabled}
      render={GoogleButton}
    />
  );
}
