import api from '@/api';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { getBearerToken } from '@/state/auth';
import { fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { AiAccelaratorView } from './@components/AiAccelaratorView';

export function ViewAiAccelerator() {
  const { codeName } = useParams();

  useDispatchLoader(fetchAllTenants);

  const bearerToken = useSelector(getBearerToken);
  const [item, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['ai-accelerator'].$codeName(codeName).$get();
    await request.process();
    return request.result.result;
  }, [codeName, bearerToken]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading AI accelerator...'} />
      </BreadcrumbLayout>
    );
  }

  if (error || !item) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout>
      <AiAccelaratorView item={item} />
    </BreadcrumbLayout>
  );
}
