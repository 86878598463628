import { triggerOperators } from '@/assets/triggers/autocomplete';
import { getTriggersByTenant } from '@/state/cache';
import { exportVariables } from '@/utils/triggers';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function findTriggerByDefinition(triggerMap, root) {
  if (!root || !triggerMap) return null;
  root = JSON.stringify(root);
  for (const [id, item] of Object.entries(triggerMap)) {
    if (JSON.stringify(item.triggerDefinition?.root) === root) return id;
  }
  return null;
}

/**
 * @typedef {object} ComplexTypeComponentEditProps
 * @property {import('@/utils/triggers').ComplexTriggerDefinition} value
 * @property {(value: ComplexTriggerDefinition) => any} onChange
 * @returns
 */

/** @param {ComplexTypeComponentEditProps} props */
export function ComplexTypeComponentEdit(props) {
  const { value, onChange } = props;

  const { tenantId } = useParams();
  const triggerMap = useSelector(getTriggersByTenant(tenantId));

  const [triggerLeft, setTriggerLeft] = useState();
  const [triggerRight, setTriggerRight] = useState();

  const setOperator = (operator) => {
    if (operator === value?.root?.operator) return;
    onChange({
      ...value,
      root: {
        ...value?.root,
        type: 'COMPLEX',
        operator,
      },
    });
  };

  console.log(triggerLeft, triggerRight);

  useEffect(
    () => {
      if (!value?.root?.children || !triggerMap) return;
      const left = findTriggerByDefinition(triggerMap, value.root.children?.[0]);
      const right = findTriggerByDefinition(triggerMap, value.root.children?.[1]);
      if (left !== triggerLeft) setTriggerLeft(left);
      if (right !== triggerRight) setTriggerRight(right);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerMap, value]
  );

  useEffect(
    () => {
      if (!triggerMap) return;
      const definition1 = triggerMap[triggerLeft]?.triggerDefinition?.root;
      const definition2 = triggerMap[triggerRight]?.triggerDefinition?.root;
      const root = {
        ...value?.root,
        type: 'COMPLEX',
        children: [definition1, definition2],
      };
      const variables = uniq(exportVariables(root));
      const newValue = { root, variables };
      if (JSON.stringify(value) !== JSON.stringify(newValue)) {
        onChange(newValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerLeft, triggerRight, triggerMap]
  );

  return (
    <>
      <SelectBox
        label="First Trigger"
        value={triggerLeft}
        onChange={setTriggerLeft}
        options={Object.values(triggerMap || {})}
        itemValue="triggerId"
        itemText="name"
        emptyValueText="Select first trigger"
        required
      />
      {triggerLeft && (
        <>
          <SelectBox
            label="Operator"
            value={value?.root?.operator}
            onChange={setOperator}
            options={Object.entries(triggerOperators || {})}
            itemValue={0}
            itemText={1}
            required
          />
          <SelectBox
            label="Second Trigger"
            value={triggerRight}
            onChange={setTriggerRight}
            options={Object.values(triggerMap || {}).filter((x) => x.triggerId !== triggerLeft)}
            itemValue="triggerId"
            itemText="name"
            emptyValueText="Select second trigger"
            required
          />
        </>
      )}
    </>
  );
}
