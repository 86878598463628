import { Actions } from '@/web/common/AccessRights';
import { TenantSelect } from '@/web/common/TenantSelect';
import { FaDesktop } from 'react-icons/fa';
import { MdLogin, MdLogout, MdTune } from 'react-icons/md';
import { AIPage } from '.';
import { AiContainerListViewWithTenant } from './ai-containers/_tenantId';
import { CreateAiContainerWithTenant } from './ai-containers/_tenantId/create';
import { ViewAiContainerWithTenant } from './ai-containers/_tenantId/_containerId';
import { AiModelListViewWithTenant } from './ai-models/_tenantId';
import { CreateAiModelWithTenant } from './ai-models/_tenantId/create';
import { AiModelDetails } from './ai-models/_tenantId/_modelId';
import { AiModelConfigParams } from './ai-models/_tenantId/_modelId/configuration-parameters';
import { AiModelGeneral } from './ai-models/_tenantId/_modelId/general';
import { AiModelInputSources } from './ai-models/_tenantId/_modelId/input-sources';
import { AiModelOutputSources } from './ai-models/_tenantId/_modelId/output-sources';
import { SharedAiContainerListViewWithTenant } from './shared-ai-containers/_tenantId';
import { SharedAiModelListViewWithTenant } from './shared-ai-models/_tenantId';

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const aiContainerRoutes = [
  {
    path: '/ai/ai-containers',
    element: (
      <TenantSelect
        title="Choose Tenant"
        subtitle="Select a tenant to manage their AI containers"
      />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.AI_CONTAINER_LIST_READ],
  },
  {
    path: '/ai/ai-containers/:tenantId',
    element: <AiContainerListViewWithTenant />,
    caseSensitive: true,
    breadcrumb: 'AI Containers',
    rights: [Actions.AI_CONTAINER_LIST_READ],
  },
  {
    path: '/ai/ai-containers/:tenantId/create',
    element: <CreateAiContainerWithTenant />,
    caseSensitive: true,
    breadcrumb: 'Create',
    rights: [Actions.AI_CONTAINER_CREATE],
  },
  {
    path: '/ai/ai-containers/:tenantId/:containerId',
    element: <ViewAiContainerWithTenant />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.AI_CONTAINER_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const sharedAiContainerRoutes = [
  {
    path: '/ai/shared-ai-containers',
    element: (
      <TenantSelect
        title="Choose Tenant"
        subtitle="Select a tenant to manage their shared AI containers"
      />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.AI_CONTAINER_SHARE_LIST_READ],
  },
  {
    path: '/ai/shared-ai-containers/:tenantId',
    element: <SharedAiContainerListViewWithTenant />,
    breadcrumb: 'Shared AI Containers',
    rights: [Actions.AI_CONTAINER_SHARE_LIST_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const aiModelRoutes = [
  {
    path: '/ai/ai-models',
    element: (
      <TenantSelect title="Choose Tenant" subtitle="Select a tenant to manage their AI models" />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.AI_MODEL_LIST_READ],
  },
  {
    path: '/ai/ai-models/:tenantId',
    element: <AiModelListViewWithTenant />,
    caseSensitive: true,
    breadcrumb: 'AI Models',
    rights: [Actions.AI_MODEL_LIST_READ],
  },
  {
    path: '/ai/ai-models/:tenantId/create',
    element: <CreateAiModelWithTenant />,
    caseSensitive: true,
    breadcrumb: 'Create',
    rights: [Actions.AI_MODEL_CREATE],
  },
  {
    path: '/ai/ai-models/:tenantId/:modelId',
    element: <AiModelDetails />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.AI_MODEL_READ],
    children: [
      {
        path: 'general',
        element: <AiModelGeneral />,
        icon: <FaDesktop />,
        breadcrumb: 'General',
        description: 'General information about this AI Model',
      },
      {
        path: 'input-sources',
        element: <AiModelInputSources />,
        icon: <MdLogin />,
        breadcrumb: 'Input Sources',
        description: 'Input sources of this AI Model',
      },
      {
        path: 'output-sources',
        element: <AiModelOutputSources />,
        icon: <MdLogout />,
        breadcrumb: 'OutputSources',
        description: 'Output sources of this AI Model',
      },
      {
        path: 'configuration-parameters',
        element: <AiModelConfigParams />,
        icon: <MdTune />,
        breadcrumb: 'Configuration Parameters',
        description: 'Configuration parameters of this AI Model',
      },
    ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const sharedAiModelRoutes = [
  {
    path: '/ai/shared-ai-models',
    element: (
      <TenantSelect
        title="Choose Tenant"
        subtitle="Select a tenant to manage their shared AI models"
      />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.AI_MODEL_SHARE_LIST_READ],
  },
  {
    path: '/ai/shared-ai-models/:tenantId',
    element: <SharedAiModelListViewWithTenant />,
    caseSensitive: true,
    breadcrumb: 'Shared AI Models',
    rights: [Actions.AI_MODEL_SHARE_LIST_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/ai',
    element: <AIPage />,
    breadcrumb: 'AI',
    rights: [
      Actions.AI_MODEL_LIST_READ,
      Actions.AI_CONTAINER_LIST_READ,
      Actions.AI_MODEL_SHARE_LIST_READ,
      Actions.AI_CONTAINER_SHARE_LIST_READ,
    ],
  },
  ...aiContainerRoutes,
  ...sharedAiContainerRoutes,
  ...aiModelRoutes,
  ...sharedAiModelRoutes,
];
