import { isCurrentTenant } from '@/state/auth';
import { getTenant } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { formatTriggerType } from '@/utils/triggers';
import {
  USER_ACCESS_TRIGGER_DISCONTINUE,
  USER_ACCESS_TRIGGER_PUBLISH,
  USER_ACCESS_TRIGGER_SHARE_CREATE,
  USER_ACCESS_TRIGGER_SHARE_DELETE,
} from '@/web/common/AccessRights';
import { DataItemTable } from '@/web/common/DataItemTable';
import { Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { DiscontinueTriggerButton } from '../DiscontinueTriggerButton';
import { PublishTriggerButton } from '../PublishTriggerButton';
import { RemoveShareTriggerButton } from '../RemoveShareTriggerButton';
import { ShareTriggerButton } from '../ShareTriggerButton';

/** @param {{item: TriggerWithLabels}} props */
export function TriggerGeneralDetails(props) {
  const { item } = props;
  const { tenantId } = useParams();

  const canPublish = item.status === 'DRAFT';
  const canDiscontinue = item.status === 'DRAFT' || item.status === 'PUBLISHED';

  const owner = useSelector(getTenant(tenantId));
  const isShared = !useSelector(isCurrentTenant(item.ownerTenantId));
  const canShare = !isShared && item.status !== 'DISCONTINUED';

  return (
    <DataItemTable
      data={item}
      width={300}
      minWidth={175}
      headers={[
        {
          key: 'ownerTenantId',
          title: 'Tenant',
          builder: () =>
            owner && (
              <Stack direction="horizontal" gap="4">
                <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
                {canShare && (
                  <USER_ACCESS_TRIGGER_SHARE_CREATE>
                    <ShareTriggerButton item={item} />
                  </USER_ACCESS_TRIGGER_SHARE_CREATE>
                )}
                {isShared && (
                  <USER_ACCESS_TRIGGER_SHARE_DELETE>
                    <RemoveShareTriggerButton item={item} />
                  </USER_ACCESS_TRIGGER_SHARE_DELETE>
                )}
              </Stack>
            ),
        },
        {
          key: 'name',
        },
        {
          title: 'Type',
          builder: ({ triggerDefinition }) => formatTriggerType(triggerDefinition.root.type),
        },
        {
          key: 'chime',
          title: 'Chime',
        },
        {
          key: 'tts',
          title: 'Text to play in dashcam',
        },
        {
          key: 'mediaType',
          title: 'Media type',
        },
        {
          key: 'coolDownTimer',
          title: 'Cooldown timer',
          builder: ({ coolDownTimer }) => <span>{coolDownTimer}</span>,
        },
        {
          key: 'critical',
          title: 'Critical',
          builder: ({ critical }) => (critical ? 'Yes' : 'No'),
        },
        {
          key: 'triggerDurationThreshold',
          title: 'Trigger duration threshold',
          builder: ({ triggerDurationThreshold }) => <span>{triggerDurationThreshold}</span>,
        },
        {
          key: 'status',
          builder: ({ status }) => (
            <Stack direction="horizontal" gap="1">
              <div className="badge me-4" style={{ background: makeColorFrom(status, 73) }}>
                {status}
              </div>
              {canPublish && (
                <USER_ACCESS_TRIGGER_PUBLISH>
                  <PublishTriggerButton item={item} />
                </USER_ACCESS_TRIGGER_PUBLISH>
              )}
              {canDiscontinue && (
                <USER_ACCESS_TRIGGER_DISCONTINUE>
                  <DiscontinueTriggerButton item={item} />
                </USER_ACCESS_TRIGGER_DISCONTINUE>
              )}
            </Stack>
          ),
        },
      ]}
    />
  );
}
