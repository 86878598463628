import api from '@/api';
import { displayError, displaySuccess } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FaMemory } from 'react-icons/fa';
import { ProductViewContext } from '../../../@components/ProductViewLayout';

export function EditProductHardwareButton() {
  /** @type {{product: Product, updateProduct: () => any}} */
  const { product, updateProduct } = useContext(ProductViewContext);

  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cpu, setCpu] = useState(product.cpu);
  const [gpu, setGpu] = useState(product.gpu);
  const [npu, setNpu] = useState(product.npu);
  const [ram, setRam] = useState(product.ram);
  const [storage, setStorage] = useState(product.storage);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const request = api.sai.v1.product.$productId(product.productId).$patch({
      data: {
        cpu,
        gpu,
        npu,
        ram,
        storage,
      },
    });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      setShowEdit(false);
      displaySuccess('Successfully saved all changes');
      updateProduct();
    } catch (err) {
      console.error(err);
      setLoading(false);
      displayError(request.error);
    }
    return false;
  };

  return (
    <>
      <Button onClick={() => setShowEdit(true)} variant="primary" style={{ width: 132 }}>
        Edit
      </Button>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FaMemory /> Hardware Capabilities
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputBox label="CPU" value={cpu} onChange={setCpu} />
            <InputBox label="GPU" value={gpu} onChange={setGpu} />
            <InputBox label="NPU" value={npu} onChange={setNpu} />
            <InputBox label="RAM" value={ram} onChange={setRam} />
            <InputBox label="Storage" value={storage} onChange={setStorage} />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div />
            <Button type="submit" style={{ width: 130 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
