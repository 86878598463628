import { MdSurroundSound } from 'react-icons/md';
import {
  getAudioCodec,
  getAudioTypes as getAudioType,
  getChannel,
  getQuantization,
  getSampleRate,
} from '@/utils/ai-models';
import { DataItemTable } from '@/web/common/DataItemTable';
import { InputCardLayout } from '../InputCardLayout';

/** @param {{input: AudioInput, source: AiModel }} props */
export function AudioInputCard(props) {
  return (
    <InputCardLayout {...props} icon={<MdSurroundSound />}>
      <DataItemTable
        dense
        border={false}
        data={props.input}
        className="small fira-code"
        minWidth={100}
        headers={[
          {
            key: 'channel',
            builder: ({ channel }) => getChannel(channel),
          },
          {
            key: 'sampleRate',
            builder: ({ sampleRate }) => getSampleRate(sampleRate),
          },
          {
            key: 'quantization',
            builder: ({ quantization }) => getQuantization(quantization),
          },
          {
            key: 'codec',
            builder: ({ codec }) => getAudioCodec(codec),
          },
          {
            key: 'type',
            builder: ({ type }) => getAudioType(type),
          },
        ]}
      />
    </InputCardLayout>
  );
}
