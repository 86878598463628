import {
  USER_ACCESS_PLATFORM_ADMIN_LIST_READ,
  USER_ACCESS_TENANT_ADMIN_LIST_READ,
} from '@/web/common/AccessRights';
import { PlatformAdminDonut } from '../PlatformAdminDonut';
import { TenantAdminDonut } from '../TenantAdminDonut';

export function InternalDonutGroup(props) {
  return (
    <>
      <USER_ACCESS_TENANT_ADMIN_LIST_READ>
        <TenantAdminDonut {...props} />
      </USER_ACCESS_TENANT_ADMIN_LIST_READ>
      <USER_ACCESS_PLATFORM_ADMIN_LIST_READ>
        <PlatformAdminDonut {...props} />
      </USER_ACCESS_PLATFORM_ADMIN_LIST_READ>
    </>
  );
}
