import { donutCharColors } from '@/assets/colors/autocomplete';
import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchEventDonutData } from '@/state/chart/fetchers/events';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function EventsDonut(props) {
  const { sidebar } = props;

  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchEventDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.events?.total || 0,
    points: [
      ['data1', 'data2'],
      ['Events', chartData.events?.total || 0],
    ],
  };
  return (
    <DashboardItem sidebar={sidebar} header={'Events'} url={'/dashboard/events'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'Total Events' }}
        legends={[{ name: 'Events', className: 'orange' }]}
        colors={[donutCharColors.ORANGE]}
        data={data.points}
      />
    </DashboardItem>
  );
}
