import { getTenantId, isLoggedIn } from '@/state/auth';
import { useVisibleRoutes } from '@/web/pages/routes';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';

export function NotFoundPage() {
  const location = useLocation();
  const loggedIn = useSelector(isLoggedIn);
  const tenantId = useSelector(getTenantId);
  const routes = useVisibleRoutes();

  if (!loggedIn) {
    return <Navigate replace to="/login" state={{ referrer: location }} />;
  }
  if (!tenantId) {
    return <Navigate replace to="/login/tenants" state={{ referrer: '/' }} />;
  }
  const refferer = location.state?.referrer || routes[0].path;
  return <Navigate replace to={refferer} />;
}
