import api from '@/api';
import { setProducts } from '..';

/**
 * Fetch and cache all products
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchAllProducts = (refresh) => async (dispatch, getState) => {
  const state = getState();
  if (!state.auth.login?.accessToken) return;

  const tenantId = state.auth.tenantId;

  if (state.cache?.productsExpiresAt[tenantId] > Date.now()) {
    if (!refresh && Object.keys(state.cache.products).length > 0) return;
  }

  const pageSize = 50;
  const products = [];

  // Get products of the tenant
  let hasMore = true;
  let continuationToken = null;
  while (hasMore) {
    const request = api.sai.v1.product.$get({
      params: {
        tenantId,
        pageSize,
        continuationToken,
      },
    });
    await request.process();
    const result = request.result.result.results;
    products.push(...result);
    continuationToken = request.result.result.continuationToken;
    hasMore = Boolean(continuationToken) && result.length >= pageSize;
  }

  // Get shared products of the tenant
  hasMore = true;
  continuationToken = null;
  while (hasMore) {
    const request = api.sai.v1['product-share'].$get({
      params: {
        tenantId,
        pageSize,
        continuationToken,
      },
      headers: {
        Authorization: `Bearer ${state.auth.login.accessToken}`,
      },
    });
    await request.process();
    const result = request.result.result.results;
    products.push(...result);
    continuationToken = request.result.result.continuationToken;
    hasMore = Boolean(continuationToken) && result.length >= pageSize;
  }

  await dispatch(setProducts({ tenantId, products }));
};
