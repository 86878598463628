import api from '@/api';
import { getRoles } from '@/state/cache';
import { displayError } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { PasswordInputBox } from '@/web/common/forms/PasswordInputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * @param {{ roles: Array.<Role>, tenants: Array.<Tenant> }} props
 */
export function CreatePlatformAdminForm(props) {
  const navigate = useNavigate();
  const roles = useSelector(getRoles);

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [company, setCompany] = useState('');
  const [description, setDescription] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState(roles[0]?.name);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      const request = api.sai.v1['platform-admin'].$post({
        data: {
          email,
          username,
          password,
          address,
          company,
          description,
          displayName,
          firstName,
          lastName,
          phone,
          role,
          pictureFileId: '1fc359d7-a85f-4f3c-88b3-c6f6d8154add', // TODO: revise
        },
      });
      await request.process();
      setLoading(false);
      iziToast.info({
        title: 'Success',
        message: 'Created a new platform admin',
      });
      const userId = request.result.result.id;
      navigate({ pathname: `./../${userId}` });
    } catch (err) {
      displayError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">Create Platform Admin</Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit} id="platform-admin-edit-form">
        <Card.Body>
          <SelectBox
            label="Role"
            value={role}
            onChange={setRole}
            options={roles}
            itemValue="name"
            disabled={!roles?.length}
            required
          />
          <InputBox label="Email Address" type="email" value={email} onChange={setEmail} required />
          <InputBox
            label="Username"
            value={username}
            onChange={setUsername}
            autoComplete="username"
            required
          />
          <PasswordInputBox
            label="Password"
            value={password}
            onChange={setPassword}
            autoComplete="new-password"
            required
          />
          <div className="d-lg-flex gap-2">
            <div className="flex-fill">
              <InputBox label="First Name" value={firstName} onChange={setFirstName} />
            </div>
            <div className="flex-fill">
              <InputBox label="Last Name" value={lastName} onChange={setLastName} />
            </div>
          </div>
          <InputBox label="Display Name" value={displayName} onChange={setDisplayName} />
          <InputBox label="Description" textarea value={description} onChange={setDescription} />
          <InputBox label="Company name" value={company} onChange={setCompany} />
          <InputBox label="Phone number" value={phone} onChange={setPhone} />
          <InputBox label="Address" value={address} onChange={setAddress} />
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div />
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
