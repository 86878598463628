import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { fetchAllGroupsByTenant, getGroupsByTenantAndProduct } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { displayError } from '@/utils/toaster';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

/** @param {{item: AiContainer, disabled: boolean}} props */
export function DeployAiContainerGroupForm(props) {
  const { item, isGroupFixed } = props;
  const { tenantId } = useParams();

  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState(props.groupId || '');

  const bearerToken = useSelector(getBearerToken);

  const [groupsLoading, groupsError] = useDispatchLoader(
    () => fetchAllGroupsByTenant(tenantId),
    [tenantId, bearerToken]
  );
  const groups = useSelector(getGroupsByTenantAndProduct(tenantId, item.productId));

  const deployAiContainer = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const request = api.sai.v1['deployment-group']
      .$groupId(groupId)
      ['ai-container-deployment'].$post({
        data: {
          aiContainerId: item.containerId,
        },
      });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Deployed Ai Container <b>${item.name}</b>`,
      });
      props.onClose();
    } catch (err) {
      setLoading(false);
      displayError(request?.error || err);
    }
  };

  return (
    <Form onSubmit={deployAiContainer}>
      <Modal.Body>
        {groupsLoading ? (
          <PageLoadingSpinner status="Loading groups..." className="py-2" />
        ) : groupsError ? (
          <PageLoadingFailure message="Failed to load groups" className="py-2">
            {groupsError + ''}
          </PageLoadingFailure>
        ) : (
          <SelectBox
            label="Group"
            disabled={isGroupFixed}
            placeholder="Select Group"
            value={groupId}
            onChange={setGroupId}
            options={groups}
            itemValue="groupId"
            itemText="name"
            required
          />
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          type="submit"
          variant="primary"
          disabled={loading || !groups}
          style={{ width: 100 }}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Deploy'}
        </Button>
      </Modal.Footer>
    </Form>
  );
}
