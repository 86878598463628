import qs from 'query-string';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectBox } from '../SelectBox';

/**
 * @typedef {object} SelectBoxInQueryBaseProps
 * @property {string} [paramName]
 * @property {(value: string) => any} [beforeNavigate]
 */

/**
 * @template T
 * @typedef {SelectBoxInQueryBaseProps & import("../SelectBox").SelectBoxProps<T>} SelectBoxInQueryProps
 */

/**
 * @template T
 * @param {SelectBoxInQueryProps<T>} props
 */
export function SelectBoxInQuery(props) {
  const { paramName, beforeNavigate, value, onChange, ...otherProps } = props;

  const name = typeof paramName === 'string' && paramName ? paramName : 's';

  const navigate = useNavigate();
  const location = useLocation();
  const query = qs.parse(location.search);

  const selected = query[name] || '';
  delete query[name];

  const setSelected = (val) => {
    if (val === selected) {
      return;
    }
    beforeNavigate?.call(val);
    navigate({
      ...location,
      search: qs.stringify({
        ...query,
        [name]: val || undefined,
      }),
    });
  };

  useEffect(() => {
    if (value !== selected) {
      onChange(selected);
    }
  }, [selected, value, onChange]);

  return <SelectBox hideLabel {...otherProps} value={selected} onChange={setSelected} />;
}
