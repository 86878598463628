import { doLogin } from '@/state/auth';
import { LoginPageLayout } from '@/web/layouts/LoginPageLayout';
import { useState } from 'react';
import { Alert, Button, Card, Form, NavLink, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { GoogleLoginButton } from './@components/GoogleLoginButton';
import { MicrosoftLoginButton } from './@components/MicrosoftLoginButton';
import './index.scss';

export function Login() {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tenantCode, setTenantCode] = useState('');

  const [loginAs, setLoginAs] = useState('platform-admin');

  /**
   * @param {any} error
   */
  function handleError(error) {
    setError('' + error);
  }

  /**
   * @param {object} action
   */
  async function handleLogin(action) {
    try {
      setError(false);
      setLoading(true);
      await dispatch(action);
    } catch (err) {
      handleError(err.message);
      setLoading(false);
    }
  }

  /**
   * Handler for login form submit
   *
   * @param {Event} e
   */
  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    handleLogin(doLogin(loginAs, 'PASSWORD', email, password, null, tenantCode));
  }

  /**
   * @param {string} email
   * @param {string} token
   */
  function loginGoogle(email, token) {
    handleLogin(doLogin(loginAs, 'GOOGLE', email, null, token, tenantCode));
  }

  /**
   * @param {string} email
   * @param {string} token
   */
  function loginMicrosoft(email, token) {
    handleLogin(doLogin(loginAs, 'MICROSOFT', email, null, token, tenantCode));
  }

  return (
    <LoginPageLayout>
      <Card id="login-card" className="shadow">
        <Card.Title>LOG IN TO YOUR ACCOUNT</Card.Title>
        <Card.Body>
          {error && !loading && (
            <Alert variant="danger" className="p-2 mx-2">
              {error}
            </Alert>
          )}

          <Form onSubmit={onSubmit}>
            <Form.Select
              required
              placeholder="Login As*"
              value={loginAs}
              onChange={(e) => setLoginAs(e.currentTarget.value)}
            >
              <option value="platform-admin">Platform Admin</option>
              <option value="tenant-admin">Tenant Admin</option>
            </Form.Select>

            {loginAs === 'tenant-admin' && (
              <Form.Control
                required
                type="text"
                autoComplete="name"
                placeholder="Tenant Code"
                value={tenantCode}
                onChange={(e) => setTenantCode(e.currentTarget.value)}
              />
            )}

            <div className="divider" />

            <Form.Control
              required
              type="email"
              autoComplete="email"
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <Form.Control
              required
              type="password"
              placeholder="Password*"
              autoComplete="password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />

            <NavLink as={Link} to="/login/recover" className="forget-password-link mb-3 p-0">
              Forgot password?
            </NavLink>

            <Button type="submit" size="lg" className="login-button" disabled={loading}>
              {loading ? <Spinner animation="border" /> : <span>Login</span>}
            </Button>
          </Form>

          <h6 className="or-divider">OR</h6>

          <div className="social-button-container">
            <GoogleLoginButton disabled={loading} onLogin={loginGoogle} onError={handleError} />
            <MicrosoftLoginButton
              disabled={loading}
              onLogin={loginMicrosoft}
              onError={handleError}
            />
          </div>
        </Card.Body>
      </Card>
    </LoginPageLayout>
  );
}
