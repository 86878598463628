import { getTenantId, getUser } from '@/state/auth';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { TenantSelectionPage } from './TenantSelectionPage';

export function TenantSelect(props) {
  const location = useLocation();
  const user = useSelector(getUser);
  const tenantId = useSelector(getTenantId);

  if ((tenantId || user.tenantId) && !location.state?.breadcrumb) {
    return <Navigate replace to={`./${tenantId}`} />;
  } else {
    return <TenantSelectionPage {...props} />;
  }
}
