import api from '@/api';
import { formatDateTime } from '@/utils/formatting';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { Snapshot } from '@/web/common/Snapshot';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { FaVideo } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';

export function EventListViewWithDevice() {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <BreadcrumbLayout.Header icon={<FaVideo />} title="Device Events" />
      <BreadcrumbLayout.Body>
        <TokenBasedInfiniteScrollTable
          fetcher={async (pageSize, continuationToken) => {
            const request = api.sai.v1.event.$get({
              params: {
                deviceId,
                pageSize,
                continuationToken,
              },
            });
            await request.process();
            return {
              results: request.result.result.results,
              token: request.result.result.continuationToken,
            };
          }}
          onClick={
            /** @param {EventModel} item */
            (item) => navigate(`/dashboard/events/${item.eventId}`)
          }
          headers={[
            {
              key: 'timestamp',
              title: 'Time',
              builder: ({ timestamp }) => formatDateTime(timestamp),
              minWidth: 200,
            },
            {
              key: 'triggerName',
              title: 'Trigger',
              minWidth: 150,
            },
            {
              key: 'snapshots',
              title: 'Snapshot',
              minWidth: 150,
              disableAction: true,
              builder: ({ snapshots }) =>
                snapshots && (
                  <div className="d-flex" style={{ gap: '2rem' }}>
                    {snapshots.map((item) => (
                      <Snapshot
                        key={item.filePath}
                        url={item.downloadUrl}
                        style={{
                          width: '50%',
                          height: 125,
                          minWidth: 150,
                          maxWidth: 200,
                        }}
                      />
                    ))}
                  </div>
                ),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
