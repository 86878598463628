import api from '@/api';
import { FileUploaderInput } from '@/web/common/forms/FileUploader';

/** @param {{value: object, packageDetails: FileDownloadInfo , onChange: (value: string) => any}} props */
export function AiModelFileInput(props) {
  const { value, packageDetails, onChange } = props;

  return (
    <FileUploaderInput
      label="AI Model Package"
      value={value?.actualFileName || packageDetails?.fileName}
      onChange={onChange}
      modalTitle="Upload Model"
      options={{
        maxFileSizeBytes: 500 * 1024 * 1024,
      }}
      credentialFetcher={async (creds, cancelToken) => {
        if (creds && creds.urlExpiresAt - Date.now() > 5 * 60 * 1000) {
          return creds;
        }
        const request = creds
          ? api.sai.v1['ai-model']['*']['file-credentials'][':refresh'].$post({
              data: {
                packageFileCredentials: creds,
              },
            })
          : api.sai.v1['ai-model']['*']['file-credentials'].$get({
              params: {
                packageFile: true,
              },
            });
        if (cancelToken) {
          request.cancelToken = cancelToken;
        }
        await request.process();
        return request.result?.result?.packageFileCredentials;
      }}
      entryCreator={async (data, cancelToken) => {
        const request = api.sai.v1.file.$post({
          data,
        });
        if (cancelToken) {
          request.cancelToken = cancelToken;
        }
        await request.process();
        return request.result?.result;
      }}
    />
  );
}
