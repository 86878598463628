import { PagedNavigation } from '@/web/common/PagedNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { AiModelViewLayout } from './@components/AiModelViewLayout';

export function AiModelDetails() {
  return (
    <AiModelViewLayout>
      <PagedNavigation
        layout={(props) => (
          <>
            <BreadcrumbLayout.Header subtitle="Select a section to view more about this AI Model" />
            <BreadcrumbLayout.Body>{props.children}</BreadcrumbLayout.Body>
          </>
        )}
      />
    </AiModelViewLayout>
  );
}
