import { selectTenant } from '@/state/auth/select-tenant';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { BsChevronRight } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/** @param {{tenant: Tenant}} props */
export function TenantGridItem(props) {
  const { tenant } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pictureError, setPictureError] = useState();

  /** @param {Event} e */
  const handleClick = (e) => {
    dispatch(selectTenant(tenant.tenantId));
    navigate(`./${tenant.tenantId}`);
  };

  return (
    <Card
      onClick={handleClick}
      className="text-decoration-none list-group-item-action"
      style={{ cursor: 'pointer', height: '100%' }}
    >
      {' '}
      {pictureError ? (
        <Card.Header className="px-2 py-4 text-center">
          <FaUserCircle size={64} color="teal" />
        </Card.Header>
      ) : (
        <Card.Header className="p-1">
          <img
            src={tenant.picture}
            alt={tenant.name}
            onError={() => setPictureError(true)}
            style={{
              width: '100%',
              height: 100,
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        </Card.Header>
      )}
      <Card.Body className="d-flex align-items-center">
        <div
          className="text-capitalize"
          title={`${tenant.name} (${tenant.tenantCode})`}
          style={{
            fontSize: 14,
            fontWeight: 600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'capitalize',
          }}
        >
          {tenant.name}
        </div>
        <div className="flex-fill" />
        <BsChevronRight />
      </Card.Body>
    </Card>
  );
}
