import {
  basicOperators,
  labelOperators,
  triggerOperators,
  triggerTypes,
} from '@/assets/triggers/autocomplete';

/** @param {string} type */
export function formatTriggerType(type) {
  return triggerTypes[type];
}

/** @param {string} type */
export function formatComplexOperator(type) {
  return triggerOperators[type];
}

/** @param {string} type */
export function formatBasicOperator(type) {
  return labelOperators[type];
}

/** @param {number} n */
export function isInt(n) {
  return n % 1 === 0;
}

/** @param {JsonNode} root */
export function formatOperator(root) {
  if (root.type === 'BASIC') {
    return basicOperators[root.operator];
  } else if (root.type === 'COMPLEX') {
    return triggerOperators[root.operator];
  } else {
    return root.operator;
  }
}

/**
 * @param {TriggerWithLabels} trigger
 * @param {TriggerDefinitionRoot} [root]
 */
export function buildSummary(trigger, root) {
  root = root || trigger.triggerDefinition?.root || {};
  if (root?.type === 'BASIC') {
    // job : {type, operator, intValue, variable}
    const label = trigger.labelList?.find((x) => x.labelId === root.variable);
    if (!label) return 'Label not found';
    const operator = basicOperators[root.operator];
    const value = `${root.intValue || root.floatValue || 0}`;
    const status = label.status === 'DISCONTINUED' ? '[Discontinued]' : '';
    return `${label.name} ${operator} ${value} ${label.unit} ${status}`.trim();
  } else if (root?.type === 'COMPLEX') {
    // workflow : {type, operator, children}
    const trigger1 = root.children?.[0];
    const trigger2 = root.children?.[1];
    if (!trigger1 || !trigger2) return 'Triggers not found';
    const value1 = buildSummary(trigger, trigger1);
    const value2 = buildSummary(trigger, trigger2);
    const type1 = formatTriggerType(trigger1.type);
    const type2 = formatTriggerType(trigger2.type);
    return `{${value1} (${type1}) ${root.operator} ${value2} (${type2})}`.trim();
  }
  return null;
}

/**
 * Exports all variables from a root trigger description
 *
 * @param {TriggerDefinitionRoot} root
 * @returns {Array<string>}
 */
export function exportVariables(root) {
  const result = [];
  if (root?.variable) {
    result.push(root.variable);
  }
  if (root?.children) {
    root.children.map(exportVariables).forEach((x) => result.push(...x));
  }
  return result;
}

/**
 * @typedef {object} BasicTriggerRoot
 * @property {'BASIC'|'COMPLEX'} type
 * @property {string} operator
 * @property {string} [variable]
 * @property {number} [intValue]
 * @property {number} [floatValue]
 */

/**
 * @typedef {object} ComplexTriggerRoot
 * @property {'BASIC'|'COMPLEX'} type
 * @property {string} operator
 * @property {Array<TriggerDefinitionRoot>} [children]
 */

/**
 * @template T
 * @typedef {object} TriggerDefinition<T>
 * @property {T} root
 * @property {Array.<string>} variables
 */

/**
 * @typedef {BasicTriggerRoot & ComplexTriggerRoot} TriggerDefinitionRoot
 */

/**
 * @typedef {TriggerDefinition<BasicTriggerRoot>} BasicTriggerDefinition
 */

/**
 * @typedef {TriggerDefinition<ComplexTriggerRoot>} ComplexTriggerDefinition
 */
