import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export function CreateLabelCategoryForm() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate();

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['label-category'].$post({
        data: {
          name,
          description,
        },
      });
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Created Label Category`,
      });
      navigate({ pathname: './..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Create Label Category</Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <InputBox
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
            required
          />
          <InputBox
            label="Description"
            placeholder="Enter description"
            value={description}
            onChange={setDescription}
            style={{ height: '75px' }}
            textarea
          />
          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-end">
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
