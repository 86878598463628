import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { InputBox } from '../InputBox';
import './index.scss';

/** @param {import('../InputBox').InputBoxProps & import('react-bootstrap').FormControlProps} props */
export function PasswordInputBox(props) {
  const [visible, setVisible] = useState(false);

  return (
    <div className="position-relative password-input-box">
      <InputBox {...props} type={visible ? 'text' : 'password'} />
      <div className="password-visibility-button" onClick={() => setVisible(!visible)}>
        {visible ? <FaEye /> : <FaEyeSlash />}
      </div>
    </div>
  );
}
