import { useContext } from 'react';
import { ButtonGroup, Card, Col, Row } from 'react-bootstrap';
import { FaRandom } from 'react-icons/fa';
import { makeColorFrom } from '@/utils';
import { ProductViewContext } from '../../../@components/ProductViewLayout';
import { AddOutputSourceButton } from '../AddOutputSourceButton';
import { DeleteOutputSourceButton } from '../DeleteOutputSourceButton';
import { EditOutputSourceButton } from '../EditOutputSourceButton';
import './index.scss';

/** @param {{label: string, icon: any, value: 'videoOutputs'|'audioOutputs'|'sensorOutputs'}} props */
export function OutputSourcesView(props) {
  /** @type {{product: Product, updateProduct: () => any}} */
  const { product } = useContext(ProductViewContext);

  const { label, icon, value } = props;
  const color = makeColorFrom(label, 1);
  const outputs = product[value];

  return (
    <div key={label} className="product-output">
      <div className="title mb-2">
        {label} ({outputs.length} item{outputs.length > 1 && 's'})
      </div>

      <Row className="g-2">
        {outputs.map((item) => (
          <Col xs="12" sm="6" md="4" lg="3" xl="2" key={item.code}>
            <OutputSourceItem item={item} color={color} icon={icon} value={value} label={label} />
          </Col>
        ))}
        <Col xs="12" sm="6" md="4" lg="3" xl="2">
          <AddOutputSourceButton value={value} label={label} />
        </Col>
      </Row>
    </div>
  );
}

export function OutputSourceItem(props) {
  const { value, item, label, color, icon } = props;
  return (
    <Card title={`${item.name} (${item.code})`}>
      <Card.Header className="text-center" style={{ lineHeight: '93px' }}>
        <span style={{ fontSize: 64, color }}>{icon || <FaRandom />}</span>
      </Card.Header>
      <Card.Body className="text-center fw-bold p-2">{item.name}</Card.Body>
      <ButtonGroup size="sm">
        <EditOutputSourceButton item={item} value={value} label={label} />
        <DeleteOutputSourceButton item={item} value={value} label={label} />
      </ButtonGroup>
    </Card>
  );
}
