import { useState } from 'react';
import Image from 'react-bootstrap/Image';

const DEFAULT_THUMBNAIL = '/images/placeholder.png';

/** @param {{src: string}} props */
export function Snapshot(props) {
  const { url, ...extraProps } = props;
  const [src, setSrc] = useState(url || DEFAULT_THUMBNAIL);

  const handleError = () => {
    setSrc(DEFAULT_THUMBNAIL);
  };

  return (
    <Image
      fluid
      thumbnail
      src={src}
      loading="lazy"
      onError={handleError}
      alt="Video Thumbnail"
      {...extraProps}
    />
  );
}
