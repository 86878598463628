export default function Label({ label, disableLegend, sidebar }) {
  return (
    <div
      className="overlay-content text-center"
      style={disableLegend && sidebar ? { left: '1%' } : null}
    >
      <p className="header">{label.header.toLocaleString()}</p>
      <p className="sub-header">{label.subHeader}</p>
    </div>
  );
}
