import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchAiContainerDonutData } from '@/state/chart/fetchers/ai-containers';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function AiContainerDonut(props) {
  const { sidebar } = props;

  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchAiContainerDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.aiContainers?.total || 0,
    points: [
      ['data1', 'data2'],
      ['AI Containers', chartData.aiContainers?.total || 0],
    ],
  };
  return (
    <DashboardItem sidebar={sidebar} header={'AI Containers'} url={'/ai/ai-containers'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'AI Containers' }}
        legends={[{ name: 'AI Containers', className: 'sensor' }]}
        colors={['#4CB43B']}
        data={data.points}
      />
    </DashboardItem>
  );
}
