import api from '@/api';
import { getAiAcceleratorList } from '@/state/cache';
import { displaySuccess } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Alert, Button, Container, Form, Modal, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { AiModelFileInput } from '../../../../@components/AiModelFileInput';
import { AiModelContext } from '../../../@components/AiModelViewLayout';

export function EditAiModelButton() {
  /** @type {{item: AiModelDetailsInfo, updateItem: () => any}} */
  const { item, updateItem } = useContext(AiModelContext);

  const aiAccelerators = useSelector(getAiAcceleratorList);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(item?.name || '');
  const [description, setDescription] = useState(item?.description || '');
  const [packageFile, setPackageFile] = useState({ id: item.packageFileId });
  const [version, setVersion] = useState(item?.version || '');
  const [vendor, setVendor] = useState(item?.vendor || '');
  const [type, setType] = useState(item?.type || '');
  const [inputLayer, setInputLayer] = useState(item?.inputLayer || '');
  const [outputLayer, setOutputLayer] = useState(item?.outputLayer || '');
  const [aiAccelerator, setAiAccelerator] = useState(
    item?.aiAcceleratorIds?.length === 0 ? 'NONE' : item?.aiAcceleratorIds[0]
  );

  const isValidForm = () => {
    if (!name) return false;
    if (!version) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isValidForm()) {
      return setError('Please enter all required fields');
    }
    try {
      setLoading(true);
      const request = api.sai.v1['ai-model'].$modelId(item.modelId).$put({
        data: {
          name,
          description,
          packageFileId: packageFile?.id,
          version,
          vendor,
          type,
          inputLayer,
          outputLayer,
          aiAcceleratorIds: [aiAccelerator],
          videoInputs: item.videoInputs,
          audioInputs: item.audioInputs,
          sensorInputs: item.sensorInputs,
          modelInputs: item.modelInputs,
          sensorOutputs: item.sensorOutputs,
          videoOutputs: item.videoOutputs,
          audioOutputs: item.audioOutputs,
        },
      });
      await request.process();
      displaySuccess(`AI Model updated`);
      setLoading(false);
      setOpen(false);
      updateItem();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="primary" style={{ width: 100 }}>
        Edit
      </Button>

      <Modal size={'lg'} show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit General Information</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Container className="mb-2 py-3 px-4">
              <SelectBox
                label="AI Accelerator"
                placeholder="Select AI Accelerator"
                value={aiAccelerator}
                onChange={setAiAccelerator}
                options={aiAccelerators}
                itemValue="codeName"
                itemText="name"
                required
              />
              <InputBox
                label="Name"
                placeholder="Enter name"
                value={name}
                onChange={setName}
                required
              />
              <InputBox
                label="Version"
                placeholder="Enter version"
                value={version}
                onChange={setVersion}
                required
              />
              <InputBox
                label="Vendor"
                placeholder="Enter vendor"
                value={vendor}
                onChange={setVendor}
              />
              <InputBox
                label="Type"
                placeholder="Enter AI Model type"
                value={type}
                onChange={setType}
              />
              <InputBox
                label="Input Layer (Comma separated)"
                placeholder="One or more comma separated values"
                value={inputLayer}
                onChange={setInputLayer}
                textarea
              />
              <InputBox
                label="Output Layer (Comma separated)"
                placeholder="One or more comma separated values"
                value={outputLayer}
                onChange={setOutputLayer}
                textarea
              />
              <AiModelFileInput
                packageDetails={item.packageDetails}
                value={packageFile}
                onChange={setPackageFile}
              />
              <InputBox
                label="Description"
                placeholder="Enter description"
                value={description}
                onChange={setDescription}
                style={{ height: '75px' }}
                textarea
              />

              {error && (
                <>
                  <hr className="my-2" />
                  <Alert variant="danger" className="my-0 py-2">
                    <FaExclamation /> {error + ''}
                  </Alert>
                </>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <div className="px-1" />
            <Button disabled={loading} type="submit" variant="primary" style={{ width: 132 }}>
              {loading && <Spinner animation="border" size="sm" />} Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
