import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DeployFirmwareGroupForm } from '../DeployFirmwareGroupForm';
import { DeployFirmwareProductForm } from '../DeployFirmwareProductForm';
import { DeployFirmwareDevicePoolForm } from '../DeployFirmwareDevicePoolForm';
import { SelectDeploymentType } from '../SelectDeploymentType';
import { useSearchParams } from 'react-router-dom';
import {
  USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_FIRMWARE,
  USER_ACCESS_DEVICE_POOL_DEPLOY_FIRMWARE,
  USER_ACCESS_PRODUCT_DEPLOY_FIRMWARE,
} from '@/web/common/AccessRights';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';

const deploymentTypes = [
  {
    title: 'Group',
    details: 'Deploy firmware to a single group',
  },
  {
    title: 'Product',
    details: 'Deploy firmware to a single product',
  },
  {
    title: 'Device Pool',
    details: 'Deploy firmware to a device pool',
  },
];

/** @param {{item: Firmware, disabled: boolean}} props */
export function DeployFirmwareButton(props) {
  const { item, disabled } = props;
  const [showModal, setShowModal] = useState(false);
  const [deploymentType, setDeploymentType] = useState(0);
  const [searchParams] = useSearchParams();
  const groupId = searchParams?.get('groupId');
  const poolId = searchParams?.get('poolId');

  useEffect(() => {
    if (groupId) {
      setDeploymentType(0);
    }
  }, [groupId]);

  useEffect(() => {
    if (poolId) {
      setDeploymentType(2);
    }
  }, [poolId]);

  return (
    <>
      <Button
        size="sm"
        type="button"
        style={{ width: 120 }}
        variant="outline-primary"
        onClick={() => setShowModal(true)}
        disabled={disabled}
      >
        Deploy
      </Button>

      <Modal size={'md'} show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Deploy Firmware</Modal.Title>
        </Modal.Header>
        <SelectDeploymentType
          items={deploymentTypes}
          value={deploymentType}
          onSelect={setDeploymentType}
        />
        <hr className="my-0" />
        {deploymentType === 0 && (
          <USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_FIRMWARE
            fallback={
              <PageLoadingFailure message="You do not have permission to deploy firmware to group" />
            }
          >
            <DeployFirmwareGroupForm
              item={item}
              onClose={() => setShowModal(false)}
              isGroupFixed={!!groupId}
              groupId={groupId}
            />
          </USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_FIRMWARE>
        )}
        {deploymentType === 1 && (
          <USER_ACCESS_PRODUCT_DEPLOY_FIRMWARE
            fallback={
              <PageLoadingFailure message="You do not have permission to deploy firmware to product" />
            }
          >
            <DeployFirmwareProductForm item={item} onClose={() => setShowModal(false)} />
          </USER_ACCESS_PRODUCT_DEPLOY_FIRMWARE>
        )}
        {deploymentType === 2 && (
          <USER_ACCESS_DEVICE_POOL_DEPLOY_FIRMWARE
            fallback={
              <PageLoadingFailure message="You do not have permission to deploy firmware to device pool" />
            }
          >
            <DeployFirmwareDevicePoolForm
              isGroupFixed={!!poolId}
              poolId={poolId}
              item={item}
              onClose={() => setShowModal(false)}
            />
          </USER_ACCESS_DEVICE_POOL_DEPLOY_FIRMWARE>
        )}
      </Modal>
    </>
  );
}
