import iziToast from 'izitoast';
import { DraggableDiv } from '@/web/common/DragDropComponents';
import { OutputCard } from '@/web/common/input-output-sources/OutputCard';
import { GiMagicBroom } from 'react-icons/gi';

/** @param {{sources: Array.<InputMapModel> }} props */
export function DraggableOutputSources(props) {
  const { sources } = props;
  return (
    <div className="d-flex d-md-grid gap-2 justify-content-md-center align-items-center fixed-scroll output-sources">
      {sources.map((source) => (
        <DraggableDiv
          data={source}
          key={source.sourceId + source.mappedOutputCode}
          onSelect={() => {
            iziToast.info({
              displayMode: 2,
              title: 'Click on an input source item to place it',
            });
          }}
        >
          <OutputCard mapping={{ ...source }} hideLink />
        </DraggableDiv>
      ))}

      {sources.length === 0 && (
        <div className="text-center text-muted">
          <GiMagicBroom size={64} color="teal" />
          <div className="py-2 small">No output sources available</div>
        </div>
      )}
    </div>
  );
}
