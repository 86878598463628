import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';

/** @param {{item: AiContainer, disabled: boolean}} props */
export function DeleteAiContainerButton(props) {
  const { item } = props;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const deleteAiContainer = async () => {
    const request = api.sai.v1['ai-container'].$containerId(item.containerId).$delete();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Deleted AI Container <b>${item.name}</b>`,
      });
      navigate({ pathname: './..' });
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      variant="danger"
      style={{ width: 120 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: deleteAiContainer,
          title: `Delete ${item.name} (${item.version})?`,
          message: 'It will affect all resources using this AI Container',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
