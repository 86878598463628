import api from '@/api';
import { setLoginData, setTenantId } from '.';
import { doLogout } from './do-logout';

/**
 * Select specific tenant
 *
 * @param {string} [tenantId]
 * @returns {Promise<void>}
 */
export const selectTenant = (tenantId) => async (dispatch, getState) => {
  await dispatch(setTenantId(tenantId));
  return;

  /* eslint no-unreachable: off */
  // TODO: waiting for backend changes to enable this logic.

  /** @type {import('.').AuthState} */
  const auth = getState().auth;
  if (!auth.login || !auth.login.accessToken) {
    return console.error('Unauthorized');
  }

  if (!tenantId || auth.tokenData?.subject_type !== 'PLATFORM_ADMIN') {
    await dispatch(setTenantId(null));
    return;
  }

  try {
    const request = api.sai.v1.auth['platform-admin'].refresh.$post({
      params: {
        tenantId,
      },
      data: {
        refreshToken: auth.login.refreshToken,
      },
    });
    await request.process();
    await dispatch(setLoginData(request.result.result));
    await dispatch(setTenantId(tenantId));
  } catch (err) {
    console.error(err);
    await dispatch(doLogout());
  }
};
