import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { CreateLabelCategoryForm } from './@components/CreateLabelCategoryForm';

export function CreateLabelCategory() {
  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <CreateLabelCategoryForm />
    </BreadcrumbLayout>
  );
}
