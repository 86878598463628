import { Actions } from '@/web/common/AccessRights';
import { Settings } from '.';
import { RolesManagement } from './roles';
import { TenantManagement } from './tenants';
import { CreateTenant } from './tenants/create';
import { ViewTenant } from './tenants/_tenantId';
import { EditTenant } from './tenants/_tenantId/edit';

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const rolesRoutes = [
  {
    path: '/settings/roles',
    element: <RolesManagement />,
    breadcrumb: 'Roles',
    rights: [Actions.ROLE_LIST_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const tenantsRoutes = [
  {
    path: '/settings/tenants',
    element: <TenantManagement />,
    breadcrumb: 'Tenants',
    rights: [Actions.TENANT_LIST_READ],
  },
  {
    path: '/settings/tenants/create',
    element: <CreateTenant />,
    breadcrumb: 'Create',
    rights: [Actions.TENANT_CREATE],
  },
  {
    path: '/settings/tenants/:tenantId',
    element: <ViewTenant />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.TENANT_READ],
  },
  {
    path: '/settings/tenants/:tenantId/edit',
    element: <EditTenant />,
    caseSensitive: true,
    breadcrumb: 'Edit',
    rights: [Actions.TENANT_UPDATE],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/settings',
    element: <Settings />,
    breadcrumb: 'Settings',
    rights: [Actions.ROLE_LIST_READ, Actions.TENANT_LIST_READ],
  },
  ...rolesRoutes,
  ...tenantsRoutes,
];
