import api from '@/api';
import { paramNames } from '@/assets/ai-models/autocomplete';
import { displayError, displaySuccess } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { AiModelContext } from '../../../@components/AiModelViewLayout';

/** @param {{value: 'videoOutputs'|'audioOutputs'|'sensorOutputs', item: VideoOutput & AudioOutput & SensorOutput}} props */
export function OutputSourceModalForm(props) {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item: model, updateItem } = useContext(AiModelContext);
  const { label, value, item, onDone } = props;

  const editing = Boolean(item);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(item?.code);
  const [name, setName] = useState(item?.name);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);

      const newItem = { code, name };
      const outputs = editing
        ? model[value].map((el) => (el.code === item.code ? newItem : el))
        : [...model[value], newItem];
      outputs.sort((a, b) => a.name.localeCompare(b.name));

      const request = api.sai.v1['ai-model'].$modelId(model.modelId).$put({
        data: {
          [value]: outputs,
        },
      });
      await request.process();

      setLoading(false);
      onDone();
      displaySuccess(`Output source ${editing ? 'updated' : 'added'}`);
      updateItem();
    } catch (err) {
      console.error(err);
      setLoading(false);
      displayError(err);
    }
    return false;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Header>
        <Modal.Title>
          {editing ? 'Edit' : 'Add'} {label || ''} Source
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputBox
          label="Code"
          placeholder="Enter code"
          disabled={editing}
          value={code}
          onChange={setCode}
          required
        />
        <SelectBox
          label="Name"
          placeholder="Enter source name"
          value={name}
          onChange={setName}
          options={Object.entries(paramNames)}
          itemValue={0}
          itemText={1}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type="reset" variant="secondary" style={{ width: 132 }} onClick={onDone}>
          Cancel
        </Button>
        <Button type="submit" style={{ width: 132 }} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : editing ? 'Save' : 'Add'}
        </Button>
      </Modal.Footer>
    </Form>
  );
}
