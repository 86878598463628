import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';

/** @param {{item: TriggerWithLabels, disabled: boolean}} props */
export function DeleteTriggerButton(props) {
  const { item } = props;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const deleteTrigger = async () => {
    const request = api.sai.v1.trigger.$triggerId(item.triggerId).$delete();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Deleted Trigger <b>${item.name}</b>`,
      });
      navigate({ pathname: './../' });
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      variant="danger"
      style={{ width: 132 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: deleteTrigger,
          title: `Delete ${item.name}?`,
          message: 'It will affect all resources using this Trigger',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
