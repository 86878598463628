import { useContext } from 'react';
import { FaMemory } from 'react-icons/fa';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { ProductViewContext } from '../@components/ProductViewLayout';
import { EditProductHardwareButton } from './@components/EditProductHardwareButton';

export function ViewProductPageHardware() {
  /** @type {{product: Product, updateProduct: () => any}} */
  const { product } = useContext(ProductViewContext);

  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaMemory />}
        title={'Hardware Capabilities'}
        subtitle={
          <>
            Hardware capabilities of <b>{product.name}</b>
          </>
        }
      >
        <EditProductHardwareButton />
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={product}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'cpu',
              title: 'CPU',
            },
            {
              key: 'gpu',
              title: 'GPU',
            },
            {
              key: 'npu',
              title: 'NPU',
            },
            {
              key: 'ram',
              title: 'RAM',
            },
            {
              key: 'storage',
              title: 'Storage',
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
