import { bitDepths, colorTypes, resolutions, videoCodecs } from '@/assets/ai-models/autocomplete';
import { Col, Row } from 'react-bootstrap';
import { CheckBox } from '@/web/common/forms/CheckBox';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';

/**
 * @param {{item: VideoInput, onChange: () => {}, editing: boolean}} props
 */
export function VideoInputFields(props) {
  const { item, onChange, editing } = props;

  /**
   * @param {string} fieldName
   * @returns {[string, (value: string) => any]}
   */
  const changeObserver = (fieldName) => {
    const value = (item && item[fieldName]) || '';
    const setValue = (v) => onChange({ ...item, [fieldName]: v });
    return [value, setValue];
  };

  const [name, setName] = changeObserver('name');
  const [description, setDescription] = changeObserver('description');
  const [resolution, setResolution] = changeObserver('resolution');
  const [colorType, setColorType] = changeObserver('colorType');
  const [bitDepth, setBitDepth] = changeObserver('bitDepth');
  const [fps, setFps] = changeObserver('fps');
  const [bitrate, setBitrate] = changeObserver('bitrate');
  const [codec, setCodec] = changeObserver('codec');
  const [code, setCode] = changeObserver('code');
  const [fovX, setfovX] = changeObserver('fovX');
  const [fovY, setfovY] = changeObserver('fovY');
  const [grayScaleAmount, setGayScaleAmount] = changeObserver('grayScaleAmount');
  const [depthSensorAvailable, setDepthSensorAvailable] = changeObserver('depthSensorAvailable');

  return (
    <Row className="gx-2">
      <Col lg={6}>
        <InputBox
          disabled={editing}
          label="Code"
          type="text"
          required
          value={code}
          onChange={setCode}
        />
      </Col>
      <Col lg={6}>
        <InputBox label="Name" type="text" value={name} onChange={setName} required />
      </Col>
      <Col lg={12}>
        <InputBox label="Description" type="text" value={description} onChange={setDescription} />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Resolution"
          value={resolution}
          onChange={setResolution}
          options={Object.entries(resolutions || {})}
          itemValue={0}
          itemText={1}
          required
        />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Bit Depth"
          value={bitDepth}
          onChange={setBitDepth}
          options={Object.entries(bitDepths || {})}
          itemValue={0}
          itemText={1}
          required
        />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Color Type"
          value={colorType}
          onChange={setColorType}
          options={Object.entries(colorTypes || {})}
          itemValue={0}
          itemText={1}
          required
        />
      </Col>
      {colorType === 'GRAY_SCALE' && (
        <Col lg={4}>
          <InputBox
            label="Grayscale amount"
            type="number"
            value={grayScaleAmount}
            onChange={setGayScaleAmount}
          />
        </Col>
      )}
      <Col lg={4}>
        <InputBox
          label="FPS (Frames Per Second)"
          type="number"
          value={fps}
          onChange={setFps}
          required
        />
      </Col>
      <Col lg={4}>
        <InputBox label="Bitrate (Kbps)" type="number" value={bitrate} onChange={setBitrate} />
      </Col>
      <Col lg={4}>
        <InputBox
          label={<>Field of View (X&deg;)</>}
          type="number"
          value={fovX}
          onChange={setfovX}
        />
      </Col>
      <Col lg={4}>
        <InputBox
          label={<>Field of View (Y&deg;)</>}
          type="number"
          value={fovY}
          onChange={setfovY}
        />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Codec"
          value={codec}
          onChange={setCodec}
          options={Object.entries(videoCodecs || {})}
          itemValue={0}
          itemText={1}
        />
      </Col>
      <Col lg={4} className="my-auto">
        <CheckBox
          label="Depth Sensor"
          checked={depthSensorAvailable}
          onChange={setDepthSensorAvailable}
        />
      </Col>
    </Row>
  );
}
