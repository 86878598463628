import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTenant } from '@/state/cache';
import { timeFromNow } from '@/utils/formatting';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { DeleteAiAccelaratorButton } from '../DeleteAiAccelaratorButton';
import {
  USER_ACCESS_AI_ACCELERATOR_DELETE,
  USER_ACCESS_AI_ACCELERATOR_UPDATE,
} from '@/web/common/AccessRights';

/** @param {{item: AiAccelerator, owner: Tenant}} props */
export function AiAccelaratorView(props) {
  const { item } = props;
  const owner = useSelector(getTenant(item.ownerTenantId));

  return (
    <>
      <BreadcrumbLayout.Header title={item.name} subtitle={'View and modify this AI Accelerator'}>
        <USER_ACCESS_AI_ACCELERATOR_UPDATE>
          <Button as={Link} to="./edit" variant="primary" style={{ width: 100 }}>
            Edit
          </Button>
        </USER_ACCESS_AI_ACCELERATOR_UPDATE>
        <USER_ACCESS_AI_ACCELERATOR_DELETE>
          <DeleteAiAccelaratorButton item={item} />
        </USER_ACCESS_AI_ACCELERATOR_DELETE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={item}
          width={150}
          headers={[
            {
              title: 'Owner Tenant',
              builder: () =>
                owner && <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>,
            },
            {
              key: 'name',
            },
            {
              key: 'codeName',
            },
            {
              key: 'type',
            },
            {
              key: 'vendor',
            },
            {
              key: 'description',
            },
            {
              title: 'Created',
              builder: () => timeFromNow(item.createdAt),
            },
            {
              title: 'Last Updated',
              builder: () => timeFromNow(item.createdAt),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
