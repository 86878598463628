import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { OutputSourceModalForm } from '../OutputSourceModalForm';

export function EditOutputSourceButton(props) {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        className="rounded-0"
        variant="outline-primary"
        style={{ width: '50%' }}
      >
        Edit
      </Button>

      {showForm && (
        <Modal show={true} onHide={() => setShowForm(false)}>
          <OutputSourceModalForm {...props} onDone={() => setShowForm(false)} />
        </Modal>
      )}
    </>
  );
}
