import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ConfigurationModalForm } from '../ConfigurationModalForm';

export function AddConfigurationButton() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Add Parameter
      </Button>
      {showModal && <ConfigurationModalForm onDone={() => setShowModal(false)} />}
    </>
  );
}
