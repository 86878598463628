import api from '@/api';
import { useSelector } from 'react-redux';
import { getBearerToken } from '@/state/auth';
import { Card } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { EditFirmwareFormPlaceholder } from './@components/EditFirmwareFormPlaceholder';
import { EditFirmwareForm } from './@components/EditFirmwareForm';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { fetchAllProducts } from '@/state/cache';

export function EditFirmwareWithTenant() {
  const { firmwareId } = useParams();

  const bearerToken = useSelector(getBearerToken);
  const [productsLoading, productsError] = useDispatchLoader(fetchAllProducts);

  const [firmware, firmwareLoading, firmwareError] = useFutureLoader(async () => {
    const request = api.sai.v1.firmware.$firmwareId(firmwareId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, firmwareId]);

  if (firmwareLoading || productsLoading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <EditFirmwareFormPlaceholder />
      </BreadcrumbLayout>
    );
  }

  if (firmwareError || productsError) {
    displayError(firmwareError);
    displayError(productsError);
    return <Navigate replace to="./../.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title className="my-1">
            Edit <b>{firmware.name}</b>
          </Card.Title>
        </Card.Header>
        <EditFirmwareForm item={firmware} />
      </Card>
    </BreadcrumbLayout>
  );
}
