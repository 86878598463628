import { fetchAllProducts, fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { FaFirstdraft, FaHandshakeAltSlash, FaList, FaRocket } from 'react-icons/fa';
import { AiDonutGroup } from '../../@components/AiDonutGroup';
import { SharedAiContainerListView } from './@components/SharedAiContainerListView';

export function SharedAiContainerListViewWithTenant() {
  useDispatchLoader(fetchAllProducts);
  useDispatchLoader(fetchAllTenants);

  return (
    <SidebarPlotLayout sidebar={<AiDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title=" Shared AI Containers"></BreadcrumbLayout.Header>
      <TabsNavigation
        replace
        tabs={[
          {
            key: 'all',
            title: (
              <div>
                <FaList /> All
              </div>
            ),
            content: <SharedAiContainerListView />,
          },
          {
            key: 'draft',
            title: (
              <div>
                <FaFirstdraft /> Draft
              </div>
            ),
            content: <SharedAiContainerListView status={'DRAFT'} />,
          },
          {
            key: 'published',
            title: (
              <div>
                <FaRocket /> Published
              </div>
            ),
            content: <SharedAiContainerListView status={'PUBLISHED'} />,
          },
          {
            key: 'discontinued',
            title: (
              <div>
                <FaHandshakeAltSlash /> Discontinued
              </div>
            ),
            content: <SharedAiContainerListView status={'DISCONTINUED'} />,
          },
        ]}
      />
    </SidebarPlotLayout>
  );
}
