import { useEffect, useState } from 'react';

/**
 * A hook to resolve a promise and getting the result.
 * The promise is resolved inside `useEffect` hook.
 *
 * Returns a stateful value, loading, and error.
 *
 * @template T
 * @param {() => Promise<T>} [future] A builder for future to resolve.
 * @param {import('react').DependencyList} [deps] If present, effect will only activate if the values in the list change. If absent, effect will only activate once per component lifetime.
 * @returns {[T, boolean, Error?]} [selected value, loading, error]
 */
export function useFutureLoader(future, deps) {
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      setLoading(true);
      future()
        .then(setValue)
        .catch(setError)
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    deps || [future]
  );

  return [value, loading, error];
}
