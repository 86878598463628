import {
  USER_ACCESS_TENANT_UPDATE_RETENTION,
  USER_ACCESS_TENANT_UPDATE,
  USER_ACCESS_TENANT_UPDATE_REGISTRATION_SETTING,
} from '@/web/common/AccessRights';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import { EditTenantForm } from '../EditTenantForm';
import { EditTenantFormRegistrationSetting } from '../EditTenantFormRegistrationSetting';
import { EditTenantFormRetentionPeriod } from '../EditTenantFormRetentionPeriod';

export function EditTenantTabs(props) {
  return (
    <TabsNavigation
      tabs={[
        {
          key: 'general',
          title: 'General',
          content: (
            <USER_ACCESS_TENANT_UPDATE
              fallback={
                <PageLoadingFailure message="You do not have permission to modify tenant" />
              }
            >
              <EditTenantForm {...props} />
            </USER_ACCESS_TENANT_UPDATE>
          ),
        },
        {
          key: 'retention',
          title: 'Retention Period',
          content: (
            <USER_ACCESS_TENANT_UPDATE_RETENTION
              fallback={
                <PageLoadingFailure message="You do not have permission to modify retention" />
              }
            >
              <EditTenantFormRetentionPeriod {...props} />
            </USER_ACCESS_TENANT_UPDATE_RETENTION>
          ),
        },
        {
          key: 'registration',
          title: 'Registration Settings',
          content: (
            <USER_ACCESS_TENANT_UPDATE_REGISTRATION_SETTING
              fallback={
                <PageLoadingFailure message="You do not have permission to modify registration setting" />
              }
            >
              <EditTenantFormRegistrationSetting {...props} />
            </USER_ACCESS_TENANT_UPDATE_REGISTRATION_SETTING>
          ),
        },
      ]}
    />
  );
}
