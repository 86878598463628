import api from '@/api';
import { fetchUserData, setLoginData } from '.';

/**
 * Login to a new session
 *
 * @param {'platform-admin' | 'tenant-admin'} loginAs
 * @param {'PASSWORD'|'GOOGLE'|'MICROSOFT'} source
 * @param {string} email
 * @param {string?} password
 * @param {string?} token
 * @param {string?} tenantCode
 * @returns {Promise<void>}
 */
export const doLogin =
  (loginAs, source, email, password, token, tenantCode) => async (dispatch) => {
    function buildRequest() {
      let authProvider = null;
      let authType = null;

      switch (source) {
        case 'PASSWORD':
          authType = 'PASSWORD';
          break;
        case 'GOOGLE':
          authProvider = 'GOOGLE';
          authType = 'THIRD_PARTY';
          break;
        case 'MICROSOFT':
          authProvider = 'MICROSOFT';
          authType = 'THIRD_PARTY';
          break;
        default:
          throw new Error('Invalid source: ' + source);
      }

      switch (loginAs) {
        case 'platform-admin':
          return api.sai.v1.auth['platform-admin'].login.$post({
            data: {
              email,
              password,
              token,
              authProvider,
              authType,
            },
          });
        case 'tenant-admin':
          return api.sai.v1.auth['tenant-admin'].login.$post({
            data: {
              email,
              password,
              token,
              authProvider,
              authType,
              tenantCode,
            },
          });
        default:
          throw new Error('Invalid login type: ' + loginAs);
      }
    }

    try {
      const request = buildRequest();
      await request.process();
      await dispatch(setLoginData(request.result.result));
      await dispatch(fetchUserData());
    } catch (err) {
      await dispatch(setLoginData());
      throw new Error((err.response && err.response.data.message) || err.message);
    }
  };
