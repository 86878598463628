import api from '@/api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState, createContext } from 'react';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { getBearerToken } from '@/state/auth';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { DeployedTableView } from '../DeployedTableView';

export const DeployedTriggerContext = createContext();

export function DeployedListView() {
  const { groupId } = useParams();
  const [id, setId] = useState(Date.now());

  const updateParent = () => {
    setId(Date.now());
  };

  const bearerToken = useSelector(getBearerToken);
  const [deployments, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['deployment-group'].$groupId(groupId)['trigger-deployment'].$get({
      params: {
        metaData: true,
        details: true,
      },
    });
    await request.process();
    return request.result.result;
  }, [id, bearerToken]);

  if (loading) {
    return <PageLoadingSpinner status={`Loading deployed triggers...`} />;
  }

  if (error) {
    return (
      <PageLoadingFailure message={'Failed to load deployed triggers'}>
        {error + ''}
      </PageLoadingFailure>
    );
  }

  if (deployments.length === 0) {
    return <PageLoadingFailure message={'No deployments available'} />;
  }

  return (
    <DeployedTriggerContext.Provider value={updateParent}>
      <DeployedTableView deployments={deployments} />
    </DeployedTriggerContext.Provider>
  );
}
