import { logLevels, onOffOptions, usbModes, carNames } from '@/assets/ai-models/autocomplete';
import { CheckBox } from '@/web/common/forms/CheckBox';
import { InputBox } from '@/web/common/forms/InputBox';
import { InputSlider } from '@/web/common/forms/InputSlider';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Device(props) {
  const { updateSettings } = useContext(SettingsContext);
  const { settings, defaultSettings, loadingOnUpdate } = props;

  const [ttsEnabled, setTtsEnabled] = useState(settings.ttsEnabled ?? defaultSettings.ttsEnabled);
  const [chimeEnabled, setChimeEnabled] = useState(
    settings.chimeEnabled ?? defaultSettings.chimeEnabled
  );
  const [deviceVolume, setDeviceVolume] = useState(
    settings.deviceVolume ?? defaultSettings.deviceVolume
  );
  const [logLevel, setLogLevel] = useState(settings.logLevel ?? defaultSettings.logLevel);
  const [allocatedLogSpace, setAllocatedLogSpace] = useState(
    settings.allocatedLogSpace ?? defaultSettings.allocatedLogSpace
  );
  const [carName, setCarName] = useState(settings.carName ?? defaultSettings.carName);
  const [usbMode, setUsbMode] = useState(settings.usbMode ?? defaultSettings.usbMode);
  const [usbAdb, setUsbAdb] = useState(settings.usbAdb ?? defaultSettings.usbAdb);
  const [usbHostMode, setUsbHostMode] = useState(
    settings.usbHostMode ?? defaultSettings.usbHostMode
  );
  const [usbFileTransfer, setUsbFileTransfer] = useState(
    settings.usbFileTransfer ?? defaultSettings.usbFileTransfer
  );

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = {
      ttsEnabled,
      chimeEnabled,
      deviceVolume,
      logLevel,
      allocatedLogSpace,
      carName,
      usbMode,
      usbAdb,
      usbHostMode,
      usbFileTransfer,
    };
    updateSettings(data);
  };

  const isFileTransferDisabled = usbMode !== 'FILE_TRANSFER';

  const handleUsbMode = (value) => {
    setUsbMode(value);
    if (value !== 'FILE_TRANSFER') setUsbFileTransfer(false);
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col lg={4} className="my-auto">
            <CheckBox
              className="my-auto"
              type="switch"
              label="TTS"
              checked={ttsEnabled}
              onChange={setTtsEnabled}
            />
          </Col>
          <Col lg={4} className="my-auto">
            <CheckBox
              className="my-auto"
              type="switch"
              label="Chime"
              checked={chimeEnabled}
              onChange={setChimeEnabled}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={0}
              max={100}
              label="Device Volume"
              unit={'%'}
              value={deviceVolume}
              onChange={setDeviceVolume}
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Log Level"
              value={logLevel}
              onChange={setLogLevel}
              options={Object.entries(logLevels || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <InputBox
              label="Allocated Log Space (MB)"
              type="number"
              value={allocatedLogSpace}
              required
              onChange={setAllocatedLogSpace}
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Car Name"
              value={carName}
              onChange={setCarName}
              options={Object.entries(carNames || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
        </Row>
        <h6 className="my-4">
          <u>USB Management</u>
        </h6>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="USB Mode"
              value={usbMode}
              onChange={handleUsbMode}
              options={Object.entries(usbModes || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="ADB"
              value={usbAdb}
              onChange={setUsbAdb}
              options={Object.entries(onOffOptions || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Host Mode"
              value={usbHostMode}
              onChange={setUsbHostMode}
              options={Object.entries(onOffOptions || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4} className="my-auto d-flex" style={{ height: '35px' }}>
            <CheckBox
              disabled={isFileTransferDisabled}
              type="switch"
              label="File Transfer"
              className="my-auto"
              checked={usbFileTransfer}
              onChange={setUsbFileTransfer}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
