import { useContext } from 'react';
import { FaHourglassHalf, FaList, FaPaperPlane, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProduct, getTenant } from '@/state/cache';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { DeployedListView } from './DeployedListView';
import { DeploymentHistoryListView } from './DeploymentHistoryListView';
import { GroupViewContext } from '../@components/GroupViewLayout';
import { Button } from 'react-bootstrap';

export function ViewGroupPageFirmware() {
  /** @type {{group: DeploymentGroup}} props */
  const { group } = useContext(GroupViewContext);
  const owner = useSelector(getTenant(group?.tenantId));
  const product = useSelector(getProduct(group?.productId));
  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaPaperPlane />}
        title={
          <>
            Firmwares of <b>{group.name}</b>
          </>
        }
        subtitle={
          <div className="small">
            {group.description} &bull; Product:{' '}
            {product ? (
              <Link to={`/super-admin/products/${product.productId}`}>{product.name}</Link>
            ) : (
              <code>N/A</code>
            )}{' '}
            &bull; Owner:{' '}
            {owner ? (
              <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
            ) : (
              <code>N/A</code>
            )}
          </div>
        }
      >
        <Button
          as={Link}
          size="sm"
          style={{ width: 125 }}
          variant="outline-primary"
          state={{ productId: product?.productId, deploymentType: 'firmware' }}
          to={`/admin/firmwares/${group?.tenantId}?groupId=${group.groupId}&productId=${group.productId}`}
        >
          <FaPlus /> Deploy
        </Button>
        <Button
          as={Link}
          size="sm"
          style={{ width: 150 }}
          variant="outline-primary"
          state={{ group, deploymentType: 'firmware' }}
          to={`/admin/shared-firmwares?groupId=${group.groupId}&productId=${group.productId}`}
        >
          <FaPlus /> Deploy Shared
        </Button>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <TabsNavigation
          tabs={[
            {
              key: 'deployments',
              title: (
                <div>
                  <FaList /> Deployments
                </div>
              ),
              content: <DeployedListView />,
            },
            {
              key: 'deployment-history',
              title: (
                <div>
                  <FaHourglassHalf /> Deployment History
                </div>
              ),
              content: <DeploymentHistoryListView />,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
