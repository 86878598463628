import { makeColorFrom, makeColorFromRanged } from '@/utils';
import { formatOperator, formatTriggerType } from '@/utils/triggers';
import { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * @template T
 * @typedef {object} ComponentCardProps<T>
 * @property {TriggerWithLabels} item
 * @property {T} root
 */

/** @param {{item: TriggerWithLabels}} props */
export function TriggerWorkflowDetails(props) {
  const { item } = props;
  const root = item.triggerDefinition?.root || {};

  return (
    <div className="d-flex mb-3">
      <ComponentCard item={item} root={root} />
    </div>
  );
}

/** @param {ComponentCardProps<import('@/utils/triggers').TriggerDefinitionRoot>} props */
function ComponentCard(props) {
  const { root } = props;
  if (root.type === 'BASIC') {
    return <BasicComponentCard {...props} />;
  } else if (root.type === 'COMPLEX') {
    return <ComplexComponentCard {...props} />;
  } else {
    return null;
  }
}

/** @param {ComponentCardProps<import('@/utils/triggers').ComplexTriggerRoot>} props */
function ComplexComponentCard(props) {
  const { item, root } = props;
  const background = useMemo(() => makeColorFromRanged(root.operator, 8, 200, 245), [root]);

  return (
    <Card>
      <Card.Header className="fw-bold text-center" style={{ background }}>
        {formatTriggerType(root.type)}
      </Card.Header>
      <Card.Body className="d-flex flex-wrap align-items-center justify-content-center gap-2">
        <ComponentCard item={item} root={root.children[0]} />
        <TriggerOperator root={root} />
        <ComponentCard item={item} root={root.children[1]} />
      </Card.Body>
    </Card>
  );
}

/** @param {ComponentCardProps<import('@/utils/triggers').BasicTriggerRoot>} props */
function BasicComponentCard(props) {
  const { item, root } = props;
  const background = useMemo(() => makeColorFromRanged(root.operator, 5, 200, 245), [root]);

  const label = item.labelList?.find((x) => x.labelId === root.variable);
  if (!label) {
    return 'No such variable';
  }

  return (
    <Card>
      <Card.Header className="fw-bold text-center" style={{ background }}>
        {formatTriggerType(root.type)}
      </Card.Header>
      <Card.Body className="text-center">
        <div>
          <Link to={`/events/labels/${item.ownerTenantId}/${root.variable}`}>{label.name}</Link>
        </div>
        <TriggerOperator root={root} className="m-1" />
        <div>
          <b>{root.intValue ?? root.floatValue}</b> {label.unit}
        </div>
        {label.status === 'DISCONTINUED' && (
          <div
            className="badge"
            style={{
              fontSize: '10px',
              background: makeColorFrom(label.status, 73),
            }}
          >
            {label.status}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

/** @param {{root: import('@/utils/triggers').TriggerDefinitionRoot} & import('react').HTMLAttributes} props */
function TriggerOperator(props) {
  const { root, ...extraProps } = props;
  const background = useMemo(() => makeColorFromRanged(root.operator, 0, 10, 150), [root]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <span style={{ background }} {...extraProps} className={`badge ${extraProps.className}`}>
        {formatOperator(root)}
      </span>
    </div>
  );
}
