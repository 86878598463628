import { formatDateTime } from '@/utils/formatting';
import { getFirmwareTypeName } from '@/utils/products';
import { USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE } from '@/web/common/AccessRights';
import { DataListTable } from '@/web/common/DataListTable';
import { UndeployFirmwareButton } from '../UndeployFirmwareButton';

/** @param {{deployments: Array.<FirmwareDeploymentModel>}} props */
export function DeployedTableView(props) {
  const deployments = props.deployments;
  return (
    <DataListTable
      data={deployments}
      headers={[
        {
          key: 'deploymentTime',
          title: 'Deployment Time',
          width: 225,
          /** @param {FirmwareDeploymentModel} [item]*/
          builder: (item) => formatDateTime(item.deploymentTime),
        },
        {
          key: 'firmwareName',
          title: 'Name',
        },
        {
          key: 'firmwareType',
          title: 'Type',
          /** @param {FirmwareDeploymentModel} [item]*/
          builder: (item) => getFirmwareTypeName(item.firmwareType),
        },
        {
          key: 'firmwareVersion',
          title: 'Version',
        },
        {
          title: 'Actions',
          disableAction: true,
          width: 120,
          builder: (item) => (
            <USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE>
              <UndeployFirmwareButton item={item} />
            </USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE>
          ),
        },
      ]}
    />
  );
}
