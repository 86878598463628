import { Actions, UserAccess } from '.';

/** @param {import('./generated').AccessRightProps} props */
export function USER_ACCESS_DASHBOARD_PAGE(props) {
  const rights = [
    Actions.DEVICE_LIST_READ,
    Actions.END_USER_LIST_READ,
    Actions.EVENT_LIST_READ_BY_TENANT,
    Actions.STREAM_LIST_READ,
  ];
  return UserAccess({ ...props, rights });
}

/** @param {import('./generated').AccessRightProps} props */
export function USER_ACCESS_AI_PAGE(props) {
  const rights = [
    Actions.AI_MODEL_LIST_READ,
    Actions.AI_CONTAINER_LIST_READ,
    Actions.AI_MODEL_SHARE_LIST_READ,
    Actions.AI_CONTAINER_SHARE_LIST_READ,
  ];
  return UserAccess({ ...props, rights });
}

/** @param {import('./generated').AccessRightProps} props */
export function USER_ACCESS_EVENTS_PAGE(props) {
  const rights = [
    Actions.EVENT_LIST_READ_BY_TENANT,
    Actions.TRIGGER_LIST_READ,
    Actions.LABEL_LIST_READ,
    Actions.TRIGGER_SHARE_LIST_READ,
  ];
  return UserAccess({ ...props, rights });
}

/** @param {import('./generated').AccessRightProps} props */
export function USER_ACCESS_ADMIN_PAGE(props) {
  const rights = [
    Actions.FIRMWARE_LIST_READ,
    Actions.FIRMWARE_SHARE_LIST_READ,
    Actions.DEVICE_POOL_LIST_READ,
    Actions.DEPLOYMENT_GROUP_LIST_READ,
  ];
  return UserAccess({ ...props, rights });
}

/** @param {import('./generated').AccessRightProps} props */
export function USER_ACCESS_SUPER_ADMIN_PAGE(props) {
  const rights = [
    Actions.PRODUCT_LIST_READ,
    Actions.AI_ACCELERATOR_LIST_READ,
    Actions.PRODUCT_SHARE_LIST_READ,
  ];
  return UserAccess({ ...props, rights });
}

/** @param {import('./generated').AccessRightProps} props */
export function USER_ACCESS_SETTINGS_PAGE(props) {
  const rights = [
    Actions.ROLE_LIST_READ, //
    Actions.TENANT_LIST_READ,
  ];
  return UserAccess({ ...props, rights });
}

/** @param {import('./generated').AccessRightProps} props */
export function USER_ACCESS_INTERNAL_PAGE(props) {
  const rights = [
    Actions.TENANT_ADMIN_LIST_READ, //
    Actions.PLATFORM_ADMIN_LIST_READ,
  ];
  return UserAccess({ ...props, rights });
}
