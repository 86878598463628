import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { OutputSourceModalForm } from '../OutputSourceModalForm';

export function AddOutputSourceButton(props) {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        variant="outline-secondary"
        style={{ height: 200, width: '100%', userSelect: 'none', cursor: 'pointer' }}
        className="d-flex flex-column gap-3 align-items-center justify-content-center"
      >
        <FaPlus size={64} />
        <div className="fw-bold">Add New</div>
      </Button>

      {showForm && (
        <Modal show={true} onHide={() => setShowForm(false)}>
          <OutputSourceModalForm {...props} onDone={() => setShowForm(false)} />
        </Modal>
      )}
    </>
  );
}
