import { UserAccess } from '.';

/**
 * @typedef {object} AccessRightProps
 * @property {any} [children]
 * @property {any} [fallback]
 * @property {import('history').To} [redirect]
 */

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_ACCELERATOR_CREATE(props) {
  const rights = ['AI_ACCELERATOR_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_ACCELERATOR_UPDATE(props) {
  const rights = ['AI_ACCELERATOR_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_ACCELERATOR_READ(props) {
  const rights = ['AI_ACCELERATOR_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_ACCELERATOR_LIST_READ(props) {
  const rights = ['AI_ACCELERATOR_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_ACCELERATOR_DELETE(props) {
  const rights = ['AI_ACCELERATOR_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_CREATE(props) {
  const rights = ['AI_CONTAINER_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_READ(props) {
  const rights = ['AI_CONTAINER_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_LIST_READ(props) {
  const rights = ['AI_CONTAINER_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_UPDATE(props) {
  const rights = ['AI_CONTAINER_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_DELETE(props) {
  const rights = ['AI_CONTAINER_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_PUBLISH(props) {
  const rights = ['AI_CONTAINER_PUBLISH'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_DISCONTINUE(props) {
  const rights = ['AI_CONTAINER_DISCONTINUE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_GET_FILE_CREDENTIAL(props) {
  const rights = ['AI_CONTAINER_GET_FILE_CREDENTIAL'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_DEPLOYMENT_CREATE(props) {
  const rights = ['AI_CONTAINER_DEPLOYMENT_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_DEPLOYMENT_LIST_READ(props) {
  const rights = ['AI_CONTAINER_DEPLOYMENT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_SHARE_CREATE(props) {
  const rights = ['AI_CONTAINER_SHARE_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_SHARE_READ(props) {
  const rights = ['AI_CONTAINER_SHARE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_SHARE_LIST_READ(props) {
  const rights = ['AI_CONTAINER_SHARE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_SHARE_UPDATE(props) {
  const rights = ['AI_CONTAINER_SHARE_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_CONTAINER_SHARE_DELETE(props) {
  const rights = ['AI_CONTAINER_SHARE_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_CREATE(props) {
  const rights = ['AI_MODEL_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_UPDATE(props) {
  const rights = ['AI_MODEL_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_READ(props) {
  const rights = ['AI_MODEL_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_LIST_READ(props) {
  const rights = ['AI_MODEL_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_DELETE(props) {
  const rights = ['AI_MODEL_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_PUBLISH(props) {
  const rights = ['AI_MODEL_PUBLISH'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_DISCONTINUE(props) {
  const rights = ['AI_MODEL_DISCONTINUE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_GET_FILE_CREDENTIAL(props) {
  const rights = ['AI_MODEL_GET_FILE_CREDENTIAL'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_SHARE_CREATE(props) {
  const rights = ['AI_MODEL_SHARE_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_SHARE_UPDATE(props) {
  const rights = ['AI_MODEL_SHARE_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_SHARE_READ(props) {
  const rights = ['AI_MODEL_SHARE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_SHARE_LIST_READ(props) {
  const rights = ['AI_MODEL_SHARE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_AI_MODEL_SHARE_DELETE(props) {
  const rights = ['AI_MODEL_SHARE_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_CREATE(props) {
  const rights = ['FIRMWARE_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_READ(props) {
  const rights = ['FIRMWARE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_LIST_READ(props) {
  const rights = ['FIRMWARE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_UPDATE(props) {
  const rights = ['FIRMWARE_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_DELETE(props) {
  const rights = ['FIRMWARE_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_PUBLISH(props) {
  const rights = ['FIRMWARE_PUBLISH'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_DISCONTINUE(props) {
  const rights = ['FIRMWARE_DISCONTINUE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_GET_FILE_CREDENTIAL(props) {
  const rights = ['FIRMWARE_GET_FILE_CREDENTIAL'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_DEPLOYMENT_CREATE(props) {
  const rights = ['FIRMWARE_DEPLOYMENT_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_DEPLOYMENT_LIST_READ(props) {
  const rights = ['FIRMWARE_DEPLOYMENT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_SHARE_CREATE(props) {
  const rights = ['FIRMWARE_SHARE_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_SHARE_UPDATE(props) {
  const rights = ['FIRMWARE_SHARE_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_SHARE_READ(props) {
  const rights = ['FIRMWARE_SHARE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_SHARE_LIST_READ(props) {
  const rights = ['FIRMWARE_SHARE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_FIRMWARE_SHARE_DELETE(props) {
  const rights = ['FIRMWARE_SHARE_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_CREATE(props) {
  const rights = ['LABEL_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_UPDATE(props) {
  const rights = ['LABEL_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_READ(props) {
  const rights = ['LABEL_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_LIST_READ(props) {
  const rights = ['LABEL_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_DELETE(props) {
  const rights = ['LABEL_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_PUBLISH(props) {
  const rights = ['LABEL_PUBLISH'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_DISCONTINUE(props) {
  const rights = ['LABEL_DISCONTINUE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_CATEGORY_CREATE(props) {
  const rights = ['LABEL_CATEGORY_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_CATEGORY_READ(props) {
  const rights = ['LABEL_CATEGORY_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_CATEGORY_LIST_READ(props) {
  const rights = ['LABEL_CATEGORY_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_LABEL_CATEGORY_UPDATE(props) {
  const rights = ['LABEL_CATEGORY_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_DEPLOY_FIRMWARE(props) {
  const rights = ['PRODUCT_DEPLOY_FIRMWARE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_UNDEPLOY_FIRMWARE(props) {
  const rights = ['PRODUCT_UNDEPLOY_FIRMWARE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_CREATE(props) {
  const rights = ['PRODUCT_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_READ(props) {
  const rights = ['PRODUCT_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_LIST_READ(props) {
  const rights = ['PRODUCT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_UPDATE(props) {
  const rights = ['PRODUCT_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_DELETE(props) {
  const rights = ['PRODUCT_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_RELEASE(props) {
  const rights = ['PRODUCT_RELEASE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_DISCONTINUE(props) {
  const rights = ['PRODUCT_DISCONTINUE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_SHARE_CREATE(props) {
  const rights = ['PRODUCT_SHARE_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_SHARE_UPDATE(props) {
  const rights = ['PRODUCT_SHARE_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_SHARE_READ(props) {
  const rights = ['PRODUCT_SHARE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_SHARE_LIST_READ(props) {
  const rights = ['PRODUCT_SHARE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PRODUCT_SHARE_DELETE(props) {
  const rights = ['PRODUCT_SHARE_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_CREATE(props) {
  const rights = ['TENANT_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_READ(props) {
  const rights = ['TENANT_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_LIST_READ(props) {
  const rights = ['TENANT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_UPDATE(props) {
  const rights = ['TENANT_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_UPDATE_REGISTRATION_SETTING(props) {
  const rights = ['TENANT_UPDATE_REGISTRATION_SETTING'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_UPDATE_RETENTION(props) {
  const rights = ['TENANT_UPDATE_RETENTION'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_CREATE(props) {
  const rights = ['TRIGGER_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_UPDATE(props) {
  const rights = ['TRIGGER_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_READ(props) {
  const rights = ['TRIGGER_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_LIST_READ(props) {
  const rights = ['TRIGGER_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_DELETE(props) {
  const rights = ['TRIGGER_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_PUBLISH(props) {
  const rights = ['TRIGGER_PUBLISH'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_DISCONTINUE(props) {
  const rights = ['TRIGGER_DISCONTINUE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_DEPLOYMENT_CREATE(props) {
  const rights = ['TRIGGER_DEPLOYMENT_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_DEPLOYMENT_LIST_READ(props) {
  const rights = ['TRIGGER_DEPLOYMENT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_SHARE_CREATE(props) {
  const rights = ['TRIGGER_SHARE_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_SHARE_UPDATE(props) {
  const rights = ['TRIGGER_SHARE_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_SHARE_READ(props) {
  const rights = ['TRIGGER_SHARE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_SHARE_LIST_READ(props) {
  const rights = ['TRIGGER_SHARE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TRIGGER_SHARE_DELETE(props) {
  const rights = ['TRIGGER_SHARE_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_STREAM_LIST_READ(props) {
  const rights = ['STREAM_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_EVENT_LIST_READ_BY_TENANT(props) {
  const rights = ['EVENT_LIST_READ_BY_TENANT'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_EVENT_LIST_READ_BY_DEVICE(props) {
  const rights = ['EVENT_LIST_READ_BY_DEVICE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_EVENT_LIST_READ_BY_USER(props) {
  const rights = ['EVENT_LIST_READ_BY_USER'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_EVENT_READ(props) {
  const rights = ['EVENT_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_EVENT_UPDATE(props) {
  const rights = ['EVENT_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_CREATE(props) {
  const rights = ['DEPLOYMENT_GROUP_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_READ(props) {
  const rights = ['DEPLOYMENT_GROUP_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_LIST_READ(props) {
  const rights = ['DEPLOYMENT_GROUP_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_UPDATE(props) {
  const rights = ['DEPLOYMENT_GROUP_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_FIRMWARE(props) {
  const rights = ['DEPLOYMENT_GROUP_DEPLOY_FIRMWARE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE(props) {
  const rights = ['DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_TRIGGER(props) {
  const rights = ['DEPLOYMENT_GROUP_DEPLOY_TRIGGER'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER(props) {
  const rights = ['DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER(props) {
  const rights = ['DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER(props) {
  const rights = ['DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ(props) {
  const rights = ['DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ(props) {
  const rights = ['DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_TRIGGER_DEPLOYMENT_LIST_READ(props) {
  const rights = ['DEVICE_TRIGGER_DEPLOYMENT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_KEY_CREATE(props) {
  const rights = ['DEVICE_KEY_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_KEY_UPDATE(props) {
  const rights = ['DEVICE_KEY_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_READ(props) {
  const rights = ['DEVICE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_STREAM(props) {
  const rights = ['DEVICE_STREAM'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_GET_MEDIA_CREDENTIAL(props) {
  const rights = ['DEVICE_GET_MEDIA_CREDENTIAL'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_ADD_MEDIA(props) {
  const rights = ['DEVICE_ADD_MEDIA'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_READ_MEDIA_LIST(props) {
  const rights = ['DEVICE_READ_MEDIA_LIST'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_ADD_FIRMWARE_INFO(props) {
  const rights = ['DEVICE_ADD_FIRMWARE_INFO'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_READ_FIRMWARE_INFO(props) {
  const rights = ['DEVICE_READ_FIRMWARE_INFO'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_PRESENCE_READ(props) {
  const rights = ['DEVICE_PRESENCE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_REBOOT(props) {
  const rights = ['DEVICE_REBOOT'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_AI_CONTAINER_DOWNLOAD(props) {
  const rights = ['DEVICE_AI_CONTAINER_DOWNLOAD'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_LIST_READ(props) {
  const rights = ['DEVICE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_UPDATE_ONBOARDED_DEVICE(props) {
  const rights = ['DEVICE_UPDATE_ONBOARDED_DEVICE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_RESET(props) {
  const rights = ['DEVICE_RESET'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_ADD_TO_DEPLOYMENT_GROUP(props) {
  const rights = ['DEVICE_ADD_TO_DEPLOYMENT_GROUP'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP(props) {
  const rights = ['DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_ADD_USER_ACCESS(props) {
  const rights = ['DEVICE_ADD_USER_ACCESS'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_REMOVE_USER_ACCESS(props) {
  const rights = ['DEVICE_REMOVE_USER_ACCESS'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_ADD_TO_GROUP(props) {
  const rights = ['DEVICE_ADD_TO_GROUP'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_REMOVE_FROM_GROUP(props) {
  const rights = ['DEVICE_REMOVE_FROM_GROUP'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_TRANSFER_DEVICE(props) {
  const rights = ['DEVICE_TRANSFER_DEVICE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP(props) {
  const rights = ['DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_LIST_READ_BY_USER_ID(props) {
  const rights = ['DEVICE_LIST_READ_BY_USER_ID'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_LIST_READ_IN_GROUP(props) {
  const rights = ['DEVICE_LIST_READ_IN_GROUP'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_LOG_SAVE(props) {
  const rights = ['DEVICE_LOG_SAVE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_LOG_READ(props) {
  const rights = ['DEVICE_LOG_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_POOL_CREATE(props) {
  const rights = ['DEVICE_POOL_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_POOL_UPDATE(props) {
  const rights = ['DEVICE_POOL_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_POOL_READ(props) {
  const rights = ['DEVICE_POOL_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_POOL_LIST_READ(props) {
  const rights = ['DEVICE_POOL_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_POOL_DEPLOY_FIRMWARE(props) {
  const rights = ['DEVICE_POOL_DEPLOY_FIRMWARE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_DEVICE_POOL_UNDEPLOY_FIRMWARE(props) {
  const rights = ['DEVICE_POOL_UNDEPLOY_FIRMWARE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_ACCESS_RIGHT_LIST_READ(props) {
  const rights = ['ACCESS_RIGHT_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_END_USER_CREATE(props) {
  const rights = ['END_USER_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_END_USER_READ(props) {
  const rights = ['END_USER_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_END_USER_UPDATE(props) {
  const rights = ['END_USER_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_END_USER_LIST_READ(props) {
  const rights = ['END_USER_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_END_USER_GET_FILE_CREDENTIAL(props) {
  const rights = ['END_USER_GET_FILE_CREDENTIAL'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_END_USER_LOG_SAVE(props) {
  const rights = ['END_USER_LOG_SAVE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_END_USER_LOG_READ(props) {
  const rights = ['END_USER_LOG_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PLATFORM_ADMIN_CREATE(props) {
  const rights = ['PLATFORM_ADMIN_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PLATFORM_ADMIN_READ(props) {
  const rights = ['PLATFORM_ADMIN_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PLATFORM_ADMIN_UPDATE(props) {
  const rights = ['PLATFORM_ADMIN_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PLATFORM_ADMIN_TENANT_ACCESS_UPDATE(props) {
  const rights = ['PLATFORM_ADMIN_TENANT_ACCESS_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PLATFORM_ADMIN_DELETE(props) {
  const rights = ['PLATFORM_ADMIN_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PLATFORM_ADMIN_LIST_READ(props) {
  const rights = ['PLATFORM_ADMIN_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_PLATFORM_ADMIN_GET_FILE_CREDENTIAL(props) {
  const rights = ['PLATFORM_ADMIN_GET_FILE_CREDENTIAL'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_ROLE_CREATE(props) {
  const rights = ['ROLE_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_ROLE_READ(props) {
  const rights = ['ROLE_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_ROLE_LIST_READ(props) {
  const rights = ['ROLE_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_ROLE_UPDATE(props) {
  const rights = ['ROLE_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_ROLE_DELETE(props) {
  const rights = ['ROLE_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_ADMIN_CREATE(props) {
  const rights = ['TENANT_ADMIN_CREATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_ADMIN_READ(props) {
  const rights = ['TENANT_ADMIN_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_ADMIN_LIST_READ(props) {
  const rights = ['TENANT_ADMIN_LIST_READ'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_ADMIN_UPDATE(props) {
  const rights = ['TENANT_ADMIN_UPDATE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_ADMIN_DELETE(props) {
  const rights = ['TENANT_ADMIN_DELETE'];
  return UserAccess({ ...props, rights });
}

/** @param {AccessRightProps} props */
export function USER_ACCESS_TENANT_ADMIN_GET_FILE_CREDENTIAL(props) {
  const rights = ['TENANT_ADMIN_GET_FILE_CREDENTIAL'];
  return UserAccess({ ...props, rights });
}
