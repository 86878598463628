import { InputBox } from '@/web/common/forms/InputBox';
import { useContext, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from './../../';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Onboarding(props) {
  const { updateSettings } = useContext(SettingsContext);
  const { settings, defaultSettings, loadingOnUpdate } = props;
  const [pairingAfterOnboard, setPairingAfterOnboard] = useState(
    settings.pairingAfterOnboard ?? defaultSettings.pairingAfterOnboard
  );
  const [pairingOnConnectivityLost, setPairingOnConnectivityLost] = useState(
    settings.pairingOnConnectivityLost ?? defaultSettings.pairingOnConnectivityLost
  );
  const [connectivityTimeout, setConnectivityTimeout] = useState(
    settings.connectivityTimeout ?? defaultSettings.connectivityTimeout
  );

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = { pairingAfterOnboard, pairingOnConnectivityLost, connectivityTimeout };
    updateSettings(data);
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col lg={4} className="my-auto">
            <Form.Check
              type="switch"
              label="Pairing after onboard"
              checked={pairingAfterOnboard}
              onChange={(e) => setPairingAfterOnboard(e.currentTarget.checked)}
              className="mb-2 py-2"
            />
          </Col>
          <Col lg={4} className="my-auto">
            <Form.Check
              type="switch"
              label="Pairing on connectivity lost"
              checked={pairingOnConnectivityLost}
              onChange={(e) => setPairingOnConnectivityLost(e.currentTarget.checked)}
              className="mb-2 py-2"
            />
          </Col>
          <Col lg={4}>
            <InputBox
              label="Connectivity Timeout (Min)"
              type="number"
              value={connectivityTimeout}
              required
              onChange={setConnectivityTimeout}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
