import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ConfigurationModalForm } from '../ConfigurationModalForm';

/** @param {{params: ConfigurationParameter}} props */
export function EditConfigurationButton(props) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button variant="outline-primary" style={{ width: 65 }} onClick={() => setShowModal(true)}>
        Edit
      </Button>
      {showModal && <ConfigurationModalForm {...props} onDone={() => setShowModal(false)} />}
    </>
  );
}
