import api from '@/api';
import { setRoles } from '..';

/**
 * Fetch and cache all roles
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchAllRoles = (refresh) => async (dispatch, getState) => {
  const state = getState();
  if (!state.auth.login?.accessToken) return;

  if (state.cache.rolesExpiresAt > Date.now()) {
    if (!refresh && Object.keys(state.cache.roles).length > 0) return;
  }

  let offset = 0;
  const limit = 50;
  let hasMore = true;
  const roles = [];
  while (hasMore) {
    const request = api.sai.v1.role.$get({
      params: {
        offset,
        limit,
      },
      headers: {
        Authorization: `Bearer ${state.auth.login.accessToken}`,
      },
    });
    await request.process();
    const result = request.result.result.results;
    offset += limit;
    roles.push(...result);
    hasMore = roles.length >= limit;
  }

  await dispatch(setRoles(roles));
};
