import { ProductsDonut } from '../ProductsDonut';
import { AiAcceleratorDonut } from '../AiAcceleratorDonut';
import {
  USER_ACCESS_AI_ACCELERATOR_LIST_READ,
  USER_ACCESS_PRODUCT_LIST_READ,
  USER_ACCESS_PRODUCT_SHARE_LIST_READ,
} from '@/web/common/AccessRights';
import { SharedProductsDonut } from '../SharedProductsDonut';

export function SuperAdminDonutGroup(props) {
  return (
    <>
      <USER_ACCESS_PRODUCT_LIST_READ>
        <ProductsDonut {...props} />
      </USER_ACCESS_PRODUCT_LIST_READ>
      <USER_ACCESS_AI_ACCELERATOR_LIST_READ>
        <AiAcceleratorDonut {...props} />
      </USER_ACCESS_AI_ACCELERATOR_LIST_READ>
      <USER_ACCESS_PRODUCT_SHARE_LIST_READ>
        <SharedProductsDonut {...props} />
      </USER_ACCESS_PRODUCT_SHARE_LIST_READ>
    </>
  );
}
