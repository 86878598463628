import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { USER_ACCESS_TENANT_UPDATE } from '@/web/common/AccessRights';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { TenantConfig } from './@components/TenantConfig';
import { TenantGeneralDetails } from './@components/TenantGeneralDetails';

export function ViewTenant() {
  const { tenantId } = useParams();
  useDispatchLoader(fetchAllTenants);

  const bearerToken = useSelector(getBearerToken);
  const [tenant, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.tenant.$tenantId(tenantId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, tenantId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading tenant...'} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout>
      <BreadcrumbLayout.Header title={tenant.name} subtitle={'View and modify tenant details'}>
        <USER_ACCESS_TENANT_UPDATE>
          <Button as={Link} to="./edit" variant="primary" style={{ width: 132 }}>
            Edit
          </Button>
        </USER_ACCESS_TENANT_UPDATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <TenantGeneralDetails tenant={tenant} />
        <TenantConfig tenant={tenant} />
      </BreadcrumbLayout.Body>
    </BreadcrumbLayout>
  );
}
