import { useMemo } from 'react';
import { CloseButton, ListGroup } from 'react-bootstrap';
import { makeColorFrom } from '@/utils';

/** @param {{products: Array.<Product>, value: string, onSelect: (id: string) => any}} props */
export function SelectProduct(props) {
  const { products, value, onSelect } = props;
  const product = value && products.find((el) => el.productId === value);

  if (product) {
    return (
      <ListGroup.Item
        title={product.description}
        className="flex-fill position-relative top-item"
        style={{ background: '#f5f5f5' }}
      >
        <div className="fw-bold text-capitalize">
          {product.name}{' '}
          <sup>
            <TextBadge text={product.productStatus} suffix={22} />
          </sup>
        </div>
        <div className="position-absolute top-0 end-0 mt-2 me-2">
          <CloseButton onClick={() => onSelect(null)} />
        </div>
      </ListGroup.Item>
    );
  }

  return (
    <ListGroup>
      <ListGroup.Item style={{ background: '#00000016' }}>
        Please select the product:
      </ListGroup.Item>
      {products.map((el) => (
        <ListGroup.Item key={el.productId} action onClick={() => onSelect(el.productId)}>
          <div className="fw-bold text-capitalize">
            {el.name}{' '}
            <sup>
              <TextBadge text={el.productStatus} suffix={22} />
            </sup>
          </div>
          <div className="small">{el.description}</div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

function TextBadge(props) {
  const { text, suffix } = props;
  const color = useMemo(() => makeColorFrom(text, suffix), [text, suffix]);
  return (
    <div className="badge rounded-pill text-uppercase px-2" style={{ background: color }}>
      {text}
    </div>
  );
}
