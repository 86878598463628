import { fetchAllProducts, fetchAllTenants, getTenant } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { FaFirstdraft, FaHandshakeAltSlash, FaList, FaRocket } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { AdminDonutGroup } from '../@components/AdminDonutGroup';
import { SharedFirmwareListView } from './@components/SharedFirmwareListView';

export function SharedFirmwareListWithTenant() {
  const { tenantId } = useParams();

  useDispatchLoader(fetchAllProducts);
  useDispatchLoader(fetchAllTenants);
  const tenant = useSelector(getTenant(tenantId));

  return (
    <SidebarPlotLayout sidebar={<AdminDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header
        title="Shared Firmwares"
        subtitle={
          tenant && (
            <>
              Manage firmwares shared to{' '}
              <Link to={`/settings/tenants/${tenant.tenantId}`}>{tenant.name}</Link>
            </>
          )
        }
      />
      <BreadcrumbLayout.Body>
        <TabsNavigation
          retain
          tabs={[
            {
              key: 'all',
              title: (
                <div>
                  <FaList /> All
                </div>
              ),
              content: <SharedFirmwareListView />,
            },
            {
              key: 'draft',
              title: (
                <div>
                  <FaFirstdraft /> Draft
                </div>
              ),
              content: <SharedFirmwareListView status={'DRAFT'} />,
            },
            {
              key: 'published',
              title: (
                <div>
                  <FaRocket /> Published
                </div>
              ),
              content: <SharedFirmwareListView status={'PUBLISHED'} />,
            },
            {
              key: 'discontinued',
              title: (
                <div>
                  <FaHandshakeAltSlash /> Discontinued
                </div>
              ),
              content: <SharedFirmwareListView status={'DISCONTINUED'} />,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
