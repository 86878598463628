import api from '@/api';
import { getAiAcceleratorList, getTenant } from '@/state/cache';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiModelFileInput } from '../../../@components/AiModelFileInput';

export function CreateAiModelForm() {
  const navigate = useNavigate();
  const { tenantId: ownerTenantId } = useParams();
  const aiAccelerators = useSelector(getAiAcceleratorList);
  const owner = useSelector(getTenant(ownerTenantId));

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [version, setVersion] = useState('');
  const [packageFile, setPackageFile] = useState('');
  const [vendor, setVendor] = useState('');
  const [type, setType] = useState('');
  const [inputLayer, setInputLayer] = useState('');
  const [outputLayer, setOutputLayer] = useState('');
  const [description, setDescription] = useState('');

  /** @type {[AiAccelerator, (aiAccelerator: AiAccelerator) => any]} */
  const [aiAccelerator, setAiAccelerator] = useState('NONE');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['ai-model'].$post({
        data: {
          name,
          version,
          vendor,
          type,
          packageFileId: packageFile?.id,
          ownerTenantId,
          description,
          aiAcceleratorIds: [aiAccelerator],
          inputLayer,
          outputLayer,
          file: 'www.google.com',
        },
      });
      await request.process();
      setLoading(false);
      iziToast.success({ title: 'Success', message: `Created AI Model` });
      navigate(`./../${request.result.result.modelId}`);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Create AI Model{' '}
          {owner && (
            <>
              for <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
            </>
          )}
        </Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <SelectBox
            label="AI Accelerator"
            placeholder="Select AI Accelerators"
            value={aiAccelerator}
            onChange={setAiAccelerator}
            options={aiAccelerators}
            itemValue="codeName"
            itemText="name"
            required
          />
          <InputBox
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
            required
          />
          <InputBox
            label="Version"
            placeholder="Enter version"
            value={version}
            onChange={setVersion}
            required
          />
          <InputBox
            label="Vendor"
            placeholder="Enter vendor name"
            value={vendor}
            onChange={setVendor}
          />
          <InputBox
            label="Type"
            placeholder="Enter type"
            value={type}
            onChange={setType}
            required
          />
          <InputBox
            label="Input Layer (comma separated values)"
            placeholder="Enter one or more comma separated values"
            value={inputLayer}
            onChange={setInputLayer}
            textarea
          />
          <InputBox
            label="Output Layer (comma separated values)"
            placeholder="Enter one or more comma separated values"
            value={outputLayer}
            onChange={setOutputLayer}
            textarea
          />
          <AiModelFileInput value={packageFile} onChange={setPackageFile} />
          <InputBox
            label="Description"
            placeholder="Enter description"
            value={description}
            onChange={setDescription}
            style={{ height: '75px' }}
            textarea
          />

          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-end">
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
