import api from '@/api';
import { fetchAllProducts, getProductList } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { displayError } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

export function CreateGroupWithTenant() {
  const navigate = useNavigate();
  const { tenantId } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [productId, setProductId] = useState('');

  const [productsLoading, productsError] = useDispatchLoader(fetchAllProducts);
  const products = useSelector(getProductList);

  if (productsLoading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <PageLoadingSpinner status={'Loading products...'} />
      </BreadcrumbLayout>
    );
  }

  if (productsError) {
    displayError(productsError);
    return <Navigate replace to="./.." />;
  }

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['deployment-group'].$post({
        data: {
          name,
          description,
          productId,
          tenantId,
        },
      });
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Created Group`,
      });
      navigate({ pathname: './..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <BreadcrumbLayout.Header title="Create Group" />
      <BreadcrumbLayout.Body>
        <Form onSubmit={handleSubmit}>
          <Card.Body>
            <InputBox
              label="Name"
              placeholder="Enter name"
              value={name}
              onChange={setName}
              required
            />
            <FloatingLabel label="Product*" className="mb-2">
              <Form.Select
                value={productId}
                placeholder="Select Product"
                onChange={(e) => {
                  setProductId(e.currentTarget.value);
                }}
                required
              >
                <option value={''} disabled>
                  Select Product
                </option>
                {products?.map((item) => (
                  <option key={item.productId} value={item.productId}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <InputBox
              label="Description"
              textarea
              placeholder="Enter description"
              value={description}
              onChange={setDescription}
              style={{ height: '75px' }}
            />
            {error && (
              <>
                <hr className="my-2" />
                <Alert variant="danger" className="my-0 py-2">
                  <FaExclamation /> {error + ''}
                </Alert>
              </>
            )}
          </Card.Body>
          <Card.Footer className="d-flex align-items-center justify-content-end">
            <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
            </Button>
          </Card.Footer>
        </Form>
      </BreadcrumbLayout.Body>
    </BreadcrumbLayout>
  );
}
