import api from '@/api';
import { labelTypes, labelUnits, triggerThresholdInputTypes } from '@/assets/labels/autocomplete';
import { getLabelCategoryMap, getLabelsByTenant } from '@/state/cache';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export function CreateLabelForm() {
  const { tenantId } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [unit, setUnit] = useState('');
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [triggerThresholdInputType, setTriggerThresholdInputType] = useState('');
  const [inputStepSize, setInputStepSize] = useState('');
  const [aliasedLabelId, setAliasedLabelId] = useState('');
  const [source, setSource] = useState('');
  const [labelType, setLabelType] = useState('');
  const [description, setDescription] = useState('');

  const labels = useSelector(getLabelsByTenant(tenantId));
  const labelCategoriesMap = useSelector(getLabelCategoryMap);
  const navigate = useNavigate();

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['label'].$post({
        data: {
          name,
          ownerTenantId: tenantId,
          description,
          code,
          unit,
          maxValue,
          minValue,
          source,
          triggerThresholdInputType,
          inputStepSize,
          labelType,
          aliasedLabelId,
          status: 'DRAFT',
        },
      });
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Created Label`,
      });
      navigate({ pathname: './..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Create Label</Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <InputBox
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
            required
          />
          <InputBox
            label="Code"
            placeholder="Enter code"
            value={code}
            onChange={setCode}
            required
          />
          <SelectBox
            label="Type"
            value={labelType}
            onChange={setLabelType}
            options={Object.entries(labelTypes || {})}
            itemValue={0}
            itemText={1}
            required
          />
          {labelType === 'ALIAS' && (
            <SelectBox
              label="Alias Label"
              placeholder="Select Label"
              value={aliasedLabelId}
              onChange={setAliasedLabelId}
              options={Object.values(labels || {}).filter((item) => item.labelType === 'PRIMARY')}
              itemValue="labelId"
              itemText="name"
              required
            />
          )}
          <SelectBox
            label="Label Category"
            value={source}
            onChange={setSource}
            options={Object.values(labelCategoriesMap || {})}
            itemValue="id"
            itemText="name"
            required
          />
          <InputBox
            label="Min Value"
            placeholder="Enter min value"
            value={minValue}
            onChange={setMinValue}
            type="number"
            required
          />
          <InputBox
            label="Max Value"
            placeholder="Enter max value"
            value={maxValue}
            onChange={setMaxValue}
            type="number"
            required
          />
          <InputBox
            label="Input Step Size"
            placeholder="Enter step size"
            value={inputStepSize}
            onChange={setInputStepSize}
            type="number"
            required
          />
          <SelectBox
            label="Trigger Threshold Input Type"
            value={triggerThresholdInputType}
            onChange={setTriggerThresholdInputType}
            options={Object.entries(triggerThresholdInputTypes || {})}
            itemValue={0}
            itemText={1}
            required
          />
          <SelectBox
            label="Unit"
            value={unit}
            onChange={setUnit}
            options={Object.entries(labelUnits || {})}
            itemValue={0}
            itemText={1}
            required
          />
          <InputBox
            label="Description"
            placeholder="Enter description"
            value={description}
            onChange={setDescription}
            style={{ height: '75px' }}
            textarea
          />
          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-end">
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
