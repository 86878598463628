import { USER_ACCESS_PLATFORM_ADMIN_CREATE } from '@/web/common/AccessRights/generated';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { InternalDonutGroup } from '../@components/InternalDonutGroup';
import { PlatformAdminListView } from './@components/PlatformAdminListView';

export function PlatformAdminManagement() {
  return (
    <SidebarPlotLayout sidebar={<InternalDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Manage Platform Admins">
        <USER_ACCESS_PLATFORM_ADMIN_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> Add New
          </Button>
        </USER_ACCESS_PLATFORM_ADMIN_CREATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <PlatformAdminListView />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
