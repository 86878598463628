import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import qs from 'query-string';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ChangeTenantAdminPasswordModal } from '../ChangeTenantAdminPasswordModal';

/** @param {{user: TenantAdmin }} props */
export function EditTenantAdminForm(props) {
  const user = props.user;
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState(user.username || '');
  const [address, setAddress] = useState(user.address || '');
  const [company, setCompany] = useState(user.company || '');
  const [description, setDescription] = useState(user.description || '');
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [phone, setPhone] = useState(user.phone || '');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.tenant
        .$tenantId(user.tenantId)
        .admin.$adminId(user.adminId)
        .$patch({
          data: {
            address: address !== user.address ? address : null,
            company: company !== user.company ? company : null,
            description: description !== user.description ? description : null,
            displayName: displayName !== user.displayName ? displayName : null,
            firstName: firstName !== user.firstName ? firstName : null,
            lastName: lastName !== user.lastName ? lastName : null,
            phone: phone !== user.phone ? phone : null,
            username: username !== user.username ? username : null,
            role: user.role,
          },
        });
      await request.process();
      setLoading(false);
      navigate({
        pathname: './..',
        search: qs.stringify({ adminId: user.adminId }),
      });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">
          <b>{user.email}</b> / <code>{user.username}</code>
        </Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <InputBox
            label="Username"
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={setUsername}
          />
          <div className="d-lg-flex gap-2">
            <div style={{ flex: '1 1' }}>
              <InputBox
                label="First Name"
                type="text"
                placeholder="Enter first name"
                value={firstName}
                onChange={setFirstName}
              />
            </div>
            <div style={{ flex: '1 1' }}>
              <InputBox
                label="Last Name"
                type="text"
                placeholder="Enter last name"
                value={lastName}
                onChange={setLastName}
              />
            </div>
          </div>
          <InputBox
            label="Display Name"
            type="text"
            placeholder="Enter display name"
            value={displayName}
            onChange={setDisplayName}
          />
          <InputBox
            label="Description"
            textarea
            placeholder="Enter description"
            value={description}
            onChange={setDescription}
          />
          <InputBox
            label="Company name"
            type="text"
            placeholder="Enter company name"
            value={company}
            onChange={setCompany}
          />
          <InputBox
            label="Phone number"
            type="text"
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
          />
          <InputBox
            label="Address"
            type="text"
            placeholder="Enter address"
            value={address}
            onChange={setAddress}
          />
          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <ChangeTenantAdminPasswordModal user={user} />
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
