import api from '@/api';
import { makeColorFrom } from '@/utils';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useNavigate } from 'react-router-dom';

export function TenantListView() {
  const navigate = useNavigate();
  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (limit, token) => {
        const request = api.sai.v1.tenant.$get({
          params: {
            pageSize: limit,
            continuationToken: token,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {Tenant} [tenant] */
        (tenant) => navigate(`./${tenant?.tenantId}`)
      }
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'tenantCode',
          title: 'Code',
          minWidth: 150,
          builder: /** @param {Tenant} tenant */ (tenant) => {
            return <code>{tenant.tenantCode}</code>;
          },
        },
        {
          key: 'tenantStatus',
          title: 'Status',
          align: 'center',
          minWidth: 80,
          builder: /** @param {Tenant} tenant */ (tenant) => {
            return (
              <div className="badge" style={{ background: makeColorFrom(tenant.tenantStatus, 13) }}>
                {tenant.tenantStatus}
              </div>
            );
          },
        },
        {
          key: 'logRetentionPeriod',
          align: 'center',
        },
        {
          key: 'eventRetentionPeriod',
          align: 'center',
        },
        {
          key: 'allowOpenRegistration',
          title: 'Allow Registrations',
          align: 'center',
          builder: /** @param {Tenant} tenant */ (tenant) => {
            return tenant.allowOpenRegistration ? 'Yes' : 'No';
          },
        },
      ]}
    />
  );
}
