import { FloatingLabel, FormControl } from 'react-bootstrap';

/**
 * @typedef {object} InputBoxProps
 * @property {any} value
 * @property {(value: any) => any} onChange
 * @property {string} [label]
 * @property {boolean} [textarea]
 * @property {boolean} [required]
 * @returns
 */

/**
 * Renders a input control surrounded by floating label.
 *
 * @param {InputBoxProps & import('react-bootstrap').FormControlProps} props
 */
export function InputBox(props) {
  let { textarea, value, onChange, label, required, ...extraProps } = props;
  if (label && required && !label.endsWith('*')) {
    label = label + '*';
  }

  return (
    <FloatingLabel label={label}>
      <FormControl
        value={value || ''}
        onInput={(e) => onChange(e.currentTarget.value)}
        {...(textarea ? { as: 'textarea' } : { type: 'text' })}
        className="mb-2"
        {...extraProps}
        required={required}
      />
    </FloatingLabel>
  );
}
