import { fetchAllRoles } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { CreatePlatformAdminForm } from './@components/CreatePlatformAdminForm';

export function CreatePlatformAdmin() {
  useDispatchLoader(fetchAllRoles);

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <CreatePlatformAdminForm />
    </BreadcrumbLayout>
  );
}
