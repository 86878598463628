import { PagedNavigation } from '@/web/common/PagedNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { ProductViewLayout } from './@components/ProductViewLayout';

export function ViewProductPage() {
  return (
    <ProductViewLayout>
      <PagedNavigation
        layout={(props) => (
          <>
            <BreadcrumbLayout.Header subtitle={'Select a section to view more'} />
            <BreadcrumbLayout.Body>{props.children}</BreadcrumbLayout.Body>
          </>
        )}
      />
    </ProductViewLayout>
  );
}
