import {
  USER_ACCESS_AI_CONTAINER_LIST_READ,
  USER_ACCESS_AI_CONTAINER_SHARE_LIST_READ,
  USER_ACCESS_AI_MODEL_LIST_READ,
  USER_ACCESS_AI_MODEL_SHARE_LIST_READ,
} from '@/web/common/AccessRights';
import { AiContainerDonut } from '../AiContainerDonut';
import { AiModelsDonut } from '../AiModelsDonut';
import { SharedAiContainerDonut } from '../SharedAiContainerDonut';
import { SharedAiModelsDonut } from '../SharedAiModelsDonut';

export function AiDonutGroup(props) {
  return (
    <>
      <USER_ACCESS_AI_MODEL_LIST_READ>
        <AiModelsDonut {...props} />
      </USER_ACCESS_AI_MODEL_LIST_READ>
      <USER_ACCESS_AI_CONTAINER_LIST_READ>
        <AiContainerDonut {...props} />
      </USER_ACCESS_AI_CONTAINER_LIST_READ>
      <USER_ACCESS_AI_MODEL_SHARE_LIST_READ>
        <SharedAiModelsDonut {...props} />
      </USER_ACCESS_AI_MODEL_SHARE_LIST_READ>
      <USER_ACCESS_AI_CONTAINER_SHARE_LIST_READ>
        <SharedAiContainerDonut {...props} />
      </USER_ACCESS_AI_CONTAINER_SHARE_LIST_READ>
    </>
  );
}
