import { useMemo } from 'react';
import { makeColorFromRanged } from '@/utils';

/**
 * Returns a color generated using the value and the suffix.
 *
 * @param {any} value A value to use a get a color
 * @param {any} [suffix] Extra salt to add with the value
 * @param {number} [minValue] Minimum red / green / value value
 * @param {number} [maxValue] Maximum red / green / value value
 * @returns {string} A color in rgb(R, G, B) format.
 */
export function useRangedColor(value, suffix = '', minValue = 0, maxValue = 255) {
  const color = useMemo(
    () => makeColorFromRanged(value, suffix, minValue, maxValue),
    [value, suffix, minValue, maxValue]
  );
  return color;
}
