import api from '@/api';
import { getTenantList } from '@/state/cache';
import { displayError } from '@/utils/toaster';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

/** @param {{item: Firmware, disabled: boolean}} props */
export function ShareFirmwareForm(props) {
  const { item } = props;
  const { tenantId: ownerTenantId } = useParams();
  const [loading, setLoading] = useState(false);
  const [tenantId, setTenantId] = useState('');

  const tenants = useSelector(getTenantList);

  const shareFirmware = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const request = api.sai.v1.firmware.$firmwareId(item.firmwareId).share.$post({
      data: {
        tenantId,
        role: 'ADMIN',
      },
    });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Shared Firmware <b>${item.name}</b>`,
      });
      props.onClose();
    } catch (err) {
      setLoading(false);
      displayError(request?.error || err);
    }
  };

  return (
    <Form onSubmit={shareFirmware}>
      <Modal.Body>
        <SelectBox
          label="Tenant"
          placeholder="Select Tenant"
          value={tenantId}
          onChange={setTenantId}
          options={tenants?.filter((item) => item.parentTenantId === ownerTenantId)}
          itemValue="tenantId"
          itemText="name"
          required
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          type="submit"
          variant="primary"
          disabled={loading || !tenants}
          style={{ width: 100 }}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Share'}
        </Button>
      </Modal.Footer>
    </Form>
  );
}
