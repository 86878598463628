import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { EditTriggerModal } from '../EditTriggerModal';

/** @param {{item: TriggerWithLabels}} props */
export function EditTriggerButton(props) {
  const { item, ...buttonProps } = props;

  const [modal, setModal] = useState(false);

  return (
    <>
      <Button
        type="button"
        variant="primary"
        style={{ width: 132 }}
        {...buttonProps}
        onClick={() => setModal(true)}
      >
        Edit
      </Button>

      {modal && (
        <EditTriggerModal size="lg" item={item} show={true} onHide={() => setModal(false)} />
      )}
    </>
  );
}
