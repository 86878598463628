import { secondsToTimeStyle } from '@/utils/events';
import { getPlayerSVGIcons } from '@/utils/events/svgs';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../@styles/_custom-common-videos.scss';

let intervalId = null;

// TODO: add jsdoc comment
export function CommonPlayerControls(props) {
  const [volume, setVolume] = useState(1);
  const [isPlay, setIsPlay] = useState(false);
  const [totalPlayableDurationInSec, setTotalPlayableDurationInSec] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [toolTipValue, setToolTipValue] = useState(0);
  const [toolTipPosition, setToolTipPosition] = useState(0);

  const onVolumechange = (e) => {
    let vol = Number(e.target.value);
    setVolume(vol);
    props.volume(vol);
  };

  const onVolumeButtonClick = (e) => {
    let vol = volume === 0 ? 1 : 0;
    setVolume(vol);
    props.volume(vol);
    // e.preventDefault();
  };

  //for siblings contact
  // VideoControls => CustomVideoPlayer
  const sendPlayRef = () => {
    // eslint-disable-next-line no-unused-expressions
    props?.playOne_ref?.current();
    // eslint-disable-next-line no-unused-expressions
    props?.playTwo_ref?.current();
  };

  const handlePlayPause = (e) => {
    let isVideoPlay = !isPlay;
    setTimeout(() => {
      setIsPlay(isVideoPlay);
    }, 300);
    sendPlayRef();

    if (Math.floor(currentPosition) === Math.floor(totalPlayableDurationInSec)) {
      setCurrentPosition(0.01);
    }

    if (isVideoPlay) {
      playTimer();
    } else {
      pauseTimer();
    }
  };

  const seekHandler = (e) => {
    pauseTimer();
    let seek = Number(e.target.value);
    setCurrentPosition(seek);
    props.seek(seek);
    setTimeout(() => {
      setIsPlay(true);
      playTimer();
    }, 400);
  };

  const updateSeekTooltip = (event) => {
    event.stopPropagation();
    const skipTo = Math.round(
      (event.nativeEvent.offsetX / event.target.clientWidth) *
        parseInt(event.target.getAttribute('max') || 0, 10)
    );
    setToolTipValue(skipTo);
    const videoContainerElem = document.getElementById(`events-video-container-common`);
    const rect = videoContainerElem.getBoundingClientRect();
    setToolTipPosition(event.pageX - rect.left - 100);
  };

  const playTimer = () => {
    intervalId = setInterval(() => {
      setCurrentPosition((prev) => {
        if (prev >= totalPlayableDurationInSec) {
          pauseTimer();
          return totalPlayableDurationInSec;
        } else {
          return prev + 1;
        }
      });
    }, 1000);
  };

  const pauseTimer = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      clearInterval(intervalId);
    };
  }, []);

  useEffect(
    () => {
      if (props.currentPlayingTime) {
        pauseTimer();
        props.seek(props.currentPlayingTime);
        setCurrentPosition(props.currentPlayingTime);
        setIsPlay(true);
        playTimer();
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [props.currentPlayingTime]
  );

  useEffect(() => {
    if (currentPosition === Math.floor(totalPlayableDurationInSec)) {
      setIsPlay(false);
    }
  }, [currentPosition, totalPlayableDurationInSec]);

  useEffect(() => {
    setTotalPlayableDurationInSec(props.totalEventTimeInSec);
  }, [props.totalEventTimeInSec]);

  //for last time icon vieability
  useEffect(
    () => {
      pauseTimer();
      setIsPlay(props.currentPlayingStatus);
      if (props.currentPlayingStatus === true) {
        playTimer();
      } else {
        pauseTimer();
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [props.currentPlayingStatus]
  );

  return (
    <React.Fragment>
      <div className="container-common" style={{ flexWrap: 'wrap' }}>
        <div
          className="video-container-common"
          id="events-video-container-common"
          style={{ width: '100%', height: '60px', zIndex: 10000, marginTop: '10px' }}
        >
          <div className="video-controls-common" id={`video-controls-common`}>
            <Row>
              <Col md={1}>
                <button
                  data-title={isPlay ? 'Pause' : 'Play'}
                  id={`play-common`}
                  onClick={handlePlayPause}
                >
                  <svg className="playback-icons-common" id={`playback-icons-common`}>
                    <use href={isPlay ? `#pause-common` : `#play-icon-common`} />
                  </svg>
                </button>
              </Col>
              <Col md={9}>
                <Row>
                  <Col md={10} style={{ paddingTop: '13px' }}>
                    <div className="video-progress-common">
                      <progress
                        id={`progress-bar-common`}
                        value={currentPosition}
                        max={totalPlayableDurationInSec}
                      />
                      <input
                        className="seek-common"
                        id={`seek-common`}
                        value={currentPosition}
                        min="0"
                        type="range"
                        step="1"
                        max={totalPlayableDurationInSec}
                        onChange={seekHandler}
                        onMouseMove={updateSeekTooltip}
                      />
                      <div
                        style={{ left: toolTipPosition + 'px' }}
                        className="seek-tooltip-common"
                        id={`seek-tooltip-common`}
                      >
                        {secondsToTimeStyle(toolTipValue)}
                      </div>
                    </div>
                  </Col>
                  <Col style={{ paddingTop: '6px' }}>
                    <div className="time-common">
                      <time id={`time-elapsed-common`}>{secondsToTimeStyle(currentPosition)}</time>
                      <span> / </span>
                      <time id={`total-duration-common`}>
                        {secondsToTimeStyle(totalPlayableDurationInSec)}
                      </time>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={2}>
                <div className="bottom-controls-common">
                  <div className="left-controls-common">
                    <div className="volume-controls-common">
                      <button
                        data-title={volume === 0 ? 'Unmute' : 'Mute'}
                        className="volume-button-common"
                        id={`volume-button-common`}
                        onClick={onVolumeButtonClick}
                      >
                        <svg>
                          <use
                            href={
                              volume >= 0.5
                                ? `#volume-high-common`
                                : volume === 0
                                ? `#volume-mute-common`
                                : `#volume-low-common`
                            }
                          />
                        </svg>
                      </button>
                      <input
                        className="volume-common"
                        id={`volume-common`}
                        data-mute="0.5"
                        type="range"
                        max="1"
                        min="0"
                        value={volume}
                        step="0.01"
                        onChange={onVolumechange}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {getPlayerSVGIcons('common')}
    </React.Fragment>
  );
}
