import { useRoutes } from 'react-router-dom';
import { NotFoundPage } from '../common/NotFoundPage';
import { useVisibleRoutes } from './routes';

export function Pages() {
  const routes = useVisibleRoutes();
  return useRoutes([
    ...routes,
    {
      path: '*',
      index: false,
      element: <NotFoundPage />,
    },
  ]);
}
