import { formatFileSize } from '@/utils/formatting';
import { Button, ListGroupItem, Modal } from 'react-bootstrap';
import { BsArrowDown } from 'react-icons/bs';
import { FaCloud, FaFileArchive, FaUpload } from 'react-icons/fa';
import { DataItemTable } from '../../DataItemTable';

/**
 * @typedef {object} UploadPreviewModalBodyProps
 * @property {File} file
 * @property {string} md5sum
 * @property {FileUploadCredentialModel} credentials
 * @property {() => any} onCancel
 * @property {() => any} onSubmit
 */

/** @param {UploadPreviewModalBodyProps} props*/
export function UploadPreviewModalBody(props) {
  const { file, md5sum, credentials, onCancel, onSubmit } = props;

  return (
    <>
      <Modal.Body>
        <FilePreviewItem icon={<FaFileArchive size={24} color="#a29232" />} name={file.name} />
        <DataItemTable
          dense
          minWidth={150}
          style={{ fontSize: 14 }}
          headers={[
            {
              title: 'File Name',
              builder: () => file.name,
            },
            {
              title: 'File Size',
              builder: () => formatFileSize(file.size) + ` (${file.size} bytes)`,
            },
            {
              title: 'Checksum',
              builder: () => md5sum,
            },
          ]}
        />

        <div className="text-center">
          <BsArrowDown size={36} color="#7e8286" />
        </div>

        <FilePreviewItem icon={<FaCloud size={24} color="#a29232" />} name={credentials.filePath} />
        <DataItemTable
          dense
          minWidth={150}
          style={{ fontSize: 14 }}
          headers={[
            {
              title: 'Storage Provider',
              builder: () => credentials.storageProvider,
            },
            {
              title: 'Container Name',
              builder: () => credentials.containerName,
            },
            {
              title: 'Store Path',
              builder: () => credentials.filePath,
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button variant="secondary" style={{ width: 125 }} onClick={onCancel}>
          Clear
        </Button>
        <Button variant="primary" style={{ width: 125 }} onClick={onSubmit}>
          <FaUpload /> Upload
        </Button>
      </Modal.Footer>
    </>
  );
}

/** @param {{icon: any, name: str}} props */
function FilePreviewItem(props) {
  const { icon, name, maxLength = 42 } = props;
  const shortName =
    name.length < maxLength ? name : '...' + name.substring(name.length - maxLength);
  return (
    <ListGroupItem
      title={name}
      className="d-flex gap-2 align-items-center fw-bold"
      style={{ background: '#dee2e6' }}
    >
      {icon} <small>{shortName}</small>
    </ListGroupItem>
  );
}
