import { formatDateTime } from '@/utils/formatting';
import { formatTriggerType } from '@/utils/triggers';
import { USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER } from '@/web/common/AccessRights';
import { DataListTable } from '@/web/common/DataListTable';
import { UndeployTriggerButton } from '../UndeployTriggerButton';

/** @param {{deployments: Array.<TriggerDeploymentModel>}} props */
export function DeployedTableView(props) {
  const deployments = props.deployments;
  return (
    <DataListTable
      data={deployments}
      headers={[
        {
          key: 'deploymentTime',
          title: 'Deployment Time',
          width: 225,
          /** @param {TriggerDeploymentModel} [item]*/
          builder: (item) => formatDateTime(item.deploymentTime),
        },
        {
          key: 'triggerName',
          title: 'Name',
        },
        {
          key: 'triggerType',
          title: 'Type',
          /** @param {TriggerDeploymentModel} [item]*/
          builder: (item) => formatTriggerType(item.triggerType),
        },
        {
          title: 'Actions',
          disableAction: true,
          width: 120,
          /** @param {TriggerDeploymentModel} [item]*/
          builder: (item) => (
            <USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER>
              <UndeployTriggerButton item={item} />
            </USER_ACCESS_DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER>
          ),
        },
      ]}
    />
  );
}
