import { Login } from '.';
import { LoginRecover } from './recover';
import { LoginTenant } from './tenants';

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/login',
    element: <Login />,
    breadcrumb: 'Login',
    public: true,
  },
  {
    path: '/login/recover',
    element: <LoginRecover />,
    breadcrumb: 'Recover',
    public: true,
  },
  {
    path: '/login/tenants',
    element: <LoginTenant />,
    breadcrumb: 'Tenants',
  },
];
