import { fetchAllProducts } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { USER_ACCESS_AI_CONTAINER_CREATE } from '@/web/common/AccessRights';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaFirstdraft, FaHandshakeAltSlash, FaList, FaPlus, FaRocket } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AiDonutGroup } from '../../@components/AiDonutGroup';
import { AiContainerListView } from './@components/AiContainerListView';

export function AiContainerListViewWithTenant() {
  useDispatchLoader(fetchAllProducts);

  return (
    <SidebarPlotLayout sidebar={<AiDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="AI Containers">
        <USER_ACCESS_AI_CONTAINER_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> AI Containers
          </Button>
        </USER_ACCESS_AI_CONTAINER_CREATE>
      </BreadcrumbLayout.Header>

      <TabsNavigation
        retain
        tabs={[
          {
            key: 'all',
            title: (
              <div>
                <FaList /> All
              </div>
            ),
            content: <AiContainerListView />,
          },
          {
            key: 'draft',
            title: (
              <div>
                <FaFirstdraft /> Draft
              </div>
            ),
            content: <AiContainerListView status={'DRAFT'} />,
          },
          {
            key: 'published',
            title: (
              <div>
                <FaRocket /> Published
              </div>
            ),
            content: <AiContainerListView status={'PUBLISHED'} />,
          },
          {
            key: 'discontinued',
            title: (
              <div>
                <FaHandshakeAltSlash /> Discontinued
              </div>
            ),
            content: <AiContainerListView status={'DISCONTINUED'} />,
          },
        ]}
      />
    </SidebarPlotLayout>
  );
}
