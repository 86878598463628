import { useContext } from 'react';
import { FaHourglassHalf, FaList, FaPaperPlane } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTenant } from '@/state/cache';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { DeployedListView } from './DeployedListView';
import { DeploymentHistoryListView } from './DeploymentHistoryListView';
import { ProductViewContext } from '../@components/ProductViewLayout';

export function ViewProductPageFirmware() {
  const { product } = useContext(ProductViewContext);
  const owner = useSelector(getTenant(product?.ownerTenantId));

  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaPaperPlane />}
        title={
          <>
            Firmwares of <b>{product.name}</b>
          </>
        }
        subtitle={
          <div className="small">
            {product.description} &bull; Product:{' '}
            {product ? (
              <Link to={`/super-admin/products/${product.productId}`}>{product.name}</Link>
            ) : (
              <code>N/A</code>
            )}{' '}
            &bull; Owner:{' '}
            {owner ? (
              <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
            ) : (
              <code>N/A</code>
            )}
          </div>
        }
      />
      <BreadcrumbLayout.Body>
        <TabsNavigation
          tabs={[
            {
              key: 'deployments',
              title: (
                <div>
                  <FaList /> Deployments
                </div>
              ),
              content: <DeployedListView />,
            },
            {
              key: 'deployment-history',
              title: (
                <div>
                  <FaHourglassHalf /> Deployment History
                </div>
              ),
              content: <DeploymentHistoryListView />,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
