import { getTokenData } from '@/state/auth';
import { ActionScopes, Rights } from '@/web/common/AccessRights/rights';
import flatten from 'lodash/flatten';
import { useSelector } from 'react-redux';

/**
 * Check if the current user has access to certain rights.
 *
 * @returns {(actions: Array.<string>, mode: 'OR'|'AND') => boolean}
 */
export function useAccessRightChecker() {
  const tokenData = useSelector(getTokenData);
  return (actions, mode) => {
    if (typeof actions !== 'object') {
      return false;
    }
    if (mode === 'AND') {
      for (const action of flatten(actions || [])) {
        if (!checkActionAccess(action, tokenData)) {
          return false;
        }
      }
      return true;
    } else {
      for (const action of flatten(actions || [])) {
        if (checkActionAccess(action, tokenData)) {
          return true;
        }
      }
      return false;
    }
  };
}

/**
 * Check if user has access to specific action
 *
 * @param {string} action
 * @param {import('@/state/auth/auth-slice').AccessTokenData} tokenData
 * @returns {boolean}
 */
function checkActionAccess(action, tokenData) {
  const scope = ActionScopes[action];
  for (const right of Rights[action] || []) {
    if (tokenData?.rights?.find((el) => el === right)) {
      switch (tokenData?.auth_scope) {
        case 'TENANT':
          if (scope === 'TENANT') {
            return true;
          }
          break;
        case 'PLATFORM':
          if (scope === 'PLATFORM') {
            return true;
          } else if (scope === 'TENANT') {
            return true;
          }
          break;
        default:
          break;
      }
    }
  }
  return false;
}
