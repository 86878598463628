import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { DeployedAiContainerContext } from '../DeployedView';

/** @param {{item: AiContainerDeploymentModel, disabled: boolean}} props */
export function UndeployAiContainerButton(props) {
  const [loading, setLoading] = useState(false);
  const updateParent = useContext(DeployedAiContainerContext);

  const item = props.item;
  const { groupId } = useParams();

  const undeployAiContainer = async () => {
    const request = api.sai.v1['deployment-group']
      .$groupId(groupId)
      ['ai-container-deployment'].$delete({
        data: {
          aiContainerId: item?.aiContainerId,
        },
      });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Undeployed AI Container from group!`,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      size="sm"
      type="button"
      variant="outline-danger"
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: undeployAiContainer,
          title: `Undeploy ${item.aiContainerName} (${item.aiContainerVersion}) from this group?`,
          message: 'It will affect all resources using this AI Container',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Undeploy'}
    </Button>
  );
}
