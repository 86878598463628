import { Form } from 'react-bootstrap';

export function CheckBox(props) {
  const { label, value, onChange, ...extraProps } = props;
  return (
    <Form.Check
      type="switch"
      label={label}
      onChange={(e) => onChange(e.currentTarget.checked)}
      checked={typeof value === 'string' ? value === 'true' : value}
      className="mb-2 py-2"
      {...extraProps}
    />
  );
}
