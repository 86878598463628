import { MdBrokenImage } from 'react-icons/md';

export function DefaultInputCard(props) {
  const { input, source, color, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      style={{
        width: 200,
        height: 200,
        borderRadius: 5,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        boxShadow: '0 0 4px #00000025',
        ...otherProps.style,
      }}
    >
      <MdBrokenImage size={64} color="#e5e5e8" />
    </div>
  );
}
