import { getAiModelMap, getProduct } from '@/state/cache';
import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DraggableOutputSources } from '../DraggableOutputSources';
import { DroppableInputSources } from '../DroppableInputSources';
import { SelectAiModel } from '../SelectAiModel';
import { SelectProduct } from '../SelectProduct';
import './index.scss';

/**
 * @typedef {object} InputMappingEditorProps
 * @property {AiContainer} container
 * @property {AiContainerComponentModel} [component]
 * @property {AiContainerComponentModel} [editing]
 * @property {(component: AiContainerComponentModel) => any} onChange
 */

/** @param {InputMappingEditorProps} props */
export function InputMappingEditor(props) {
  const { container, component, editing, onChange } = props;

  const modelMap = useSelector(getAiModelMap);
  const product = useSelector(getProduct(container.productId));
  const accelerator = useMemo(() => product.aiAcceleratorIds[0], [product]);

  const [modelId, setModelId] = useState(component?.modelId);
  const [inputMapping, setInputMapping] = useState(component?.inputMapping || {});

  const firstMapping = Object.values(inputMapping || {})[0];
  const [sourceId, setSourceId] = useState(firstMapping?.sourceId);
  const [inputSource, setInputSource] = useState(firstMapping?.inputSource);

  const containerModels = useMemo(() => {
    return (container.components || [])
      .map((el) => modelMap[el.modelId])
      .filter((el) => accelerator === el.aiAcceleratorIds[0] && el.modelId !== editing?.modelId);
  }, [modelMap, accelerator, container, editing]);

  const models = useMemo(() => {
    return Object.values(modelMap).filter((el) => accelerator === el.aiAcceleratorIds[0]);
  }, [modelMap, accelerator]);

  /** @type {Array.<InputMapModel>} */
  const outputSources = useMemo(() => {
    /** @type {Product & AiModel} */
    let source = null;
    switch (inputSource) {
      case 'MODEL':
        source = modelMap[sourceId];
        break;
      case 'PRODUCT':
        source = product;
        break;
      default:
        break;
    }

    const outputMap = {
      VIDEO: source?.videoOutputs,
      AUDIO: source?.audioOutputs,
      SENSOR: source?.sensorOutputs,
    };

    const mapping = [];
    Object.entries(outputMap).forEach(([inputType, outputs]) => {
      outputs?.forEach((el) => {
        /** @type {InputMapModel} */
        const item = {
          inputSource,
          inputType,
          mappedOutputCode: el.code,
          sourceId,
        };
        mapping.push(item);
      });
    });
    return mapping;
  }, [sourceId, inputSource, modelMap, product]);

  /** @param {string} value */
  const handleSelectModel = (value) => {
    onChange({ modelId: value, inputMapping: {} });
    setModelId(value);
    setInputMapping({});
  };

  /** @param {Object.<string, InputMapModel>} value */
  const handleInputMapping = (value) => {
    Object.keys(value).forEach((code) => {
      if (!value[code]?.mappedOutputCode) {
        delete value[code];
      }
    });
    onChange({ modelId, inputMapping: value });
    setInputMapping(value);
  };

  return (
    <Row className="g-2 input-mapping-editor">
      <Col md="4" className="output-content">
        {(!sourceId || inputSource === 'PRODUCT') && (
          <SelectProduct
            products={[product]}
            value={sourceId}
            onSelect={(id) => {
              setInputSource('PRODUCT');
              setSourceId(id);
            }}
          />
        )}
        {!sourceId && containerModels.length > 0 && (
          <div className="d-flex align-items-center">
            <hr className="w-100" />
            <b className="px-3 text-muted">OR</b>
            <hr className="w-100" />
          </div>
        )}
        {(!sourceId || inputSource === 'MODEL') && containerModels.length > 0 && (
          <SelectAiModel
            models={containerModels}
            value={sourceId}
            onSelect={(id) => {
              setInputSource('MODEL');
              setSourceId(id);
            }}
          />
        )}
        {sourceId && <DraggableOutputSources sources={outputSources} />}
      </Col>
      <Col md="8" className="input-content">
        <SelectAiModel
          models={models}
          value={modelId}
          onSelect={handleSelectModel}
          disabledModels={containerModels}
        />
        {modelId && inputMapping && (
          <DroppableInputSources
            source={modelMap[modelId]}
            mappings={inputMapping}
            onChange={handleInputMapping}
          />
        )}
      </Col>
    </Row>
  );
}
