import api from '@/api';
import { setAiModels } from '..';

/**
 * Fetch and cache all aiModels
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchAllAiModels = (refresh) => async (dispatch, getState) => {
  const state = getState();
  if (!state.auth.login?.accessToken) return;

  if (state.cache.aiModelsExpiresAt > Date.now()) {
    if (!refresh && Object.keys(state.cache.aiModels).length > 0) return;
  }

  let hasMore = true;
  const pageSize = 50;
  let continuationToken = null;
  const aiModels = [];
  while (hasMore) {
    const request = api.sai.v1['ai-model'].$get({
      params: {
        pageSize,
        continuationToken,
      },
      headers: {
        Authorization: `Bearer ${state.auth.login.accessToken}`,
      },
    });
    await request.process();
    const result = request.result.result.results;
    aiModels.push(...result);
    continuationToken = request.result.result.continuationToken;
    hasMore = Boolean(continuationToken) && result.length >= pageSize;
  }

  await dispatch(setAiModels(aiModels));
};
