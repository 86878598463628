import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { InputSourceModalForm } from '../InputSourceModalForm';

export function EditInputSourceButton(props) {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        className="rounded-0"
        variant="outline-primary"
        style={{ width: '50%' }}
      >
        Edit
      </Button>

      {showForm && <InputSourceModalForm {...props} onDone={() => setShowForm(false)} />}
    </>
  );
}
