import api from '@/api';
import { getTenantMap } from '@/state/cache';
import { formatDateTime, timeFromNow } from '@/utils/formatting';
import { ColorfulBadge } from '@/web/common/ColorfulBadge';
import { SearchBoxInQuery } from '@/web/common/forms/SearchBoxInQuery';
import { SelectBoxInQuery } from '@/web/common/forms/SelectBoxInQuery';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import './index.scss';

export function DeviceDiagnosticsListView(props) {
  const { module } = props;
  const { deviceId } = useParams();
  const tenantMap = useSelector(getTenantMap);
  const [searchText, setSearchText] = useState();
  const [level, setLevel] = useState();
  const [cancelToken, setCancelToken] = useState(null);

  return (
    <>
      <div id="device-diagnostics-list-view-filter-area">
        <div className="search-diagnostics">
          <SearchBoxInQuery
            clearable
            value={searchText}
            onChange={setSearchText}
            placeholder="Filter diagnostics..."
          />
        </div>
        <div className="filter-by-level">
          <label>Severity:</label>
          <SelectBoxInQuery
            paramName="level"
            value={level}
            onChange={setLevel}
            options={[
              { value: '', text: 'All' },
              { value: 'TRACE', text: 'Trace' },
              { value: 'DEBUG', text: 'Debug' },
              { value: 'INFO', text: 'Info' },
              { value: 'WARN', text: 'Warn' },
              { value: 'ERROR', text: 'Error' },
              { value: 'FATAL', text: 'Fatal' },
            ]}
            itemText="text"
            itemValue="value"
          />
        </div>
      </div>
      <TokenBasedInfiniteScrollTable
        key={JSON.stringify([module, deviceId, searchText, level])}
        fetcher={async (pageSize, continuationToken) => {
          cancelToken?.cancel();
          const request = api.sai.v1.device.$deviceId(deviceId).log.$get({
            params: {
              pageSize,
              continuationToken,
              searchText,
              module,
              level,
              // fromTimestamp,
              // toTimestamp,
              // offset,
            },
          });
          setCancelToken(request.cancelToken);
          await request.process();
          return {
            results: request.result.result.results,
            token: request.result.result.continuationToken,
          };
        }}
        headers={[
          {
            key: 'deviceId',
            title: 'ID',
            width: 100,
            align: 'right',
          },
          {
            key: 'reportingTimestamp',
            title: 'Timestamp',
            width: 200,
            builder: ({ reportingTimestamp }) => formatDateTime(reportingTimestamp),
          },
          {
            key: 'level',
            title: 'Severity',
            align: 'center',
            width: 100,
            builder: ({ level }) => level && <ColorfulBadge>{level}</ColorfulBadge>,
          },
          {
            key: 'module',
            align: 'center',
            width: 150,
            builder: ({ module }) => module && <ColorfulBadge light>{module}</ColorfulBadge>,
          },
          {
            key: 'tenantId',
            title: 'Owner Tenant',
            align: 'center',
            width: 200,
            builder: ({ tenantId }) =>
              tenantMap &&
              tenantMap[tenantId] && (
                <Link to={`/settings/tenants/${tenantId}`}>{tenantMap[tenantId].name}</Link>
              ),
          },
          {
            key: 'logId',
            title: 'Log ID',
            width: 175,
          },
          {
            key: 'fields',
            builder: ({ fields }) => JSON.stringify(fields),
          },
        ]}
      />
    </>
  );
}
