import { useContext } from 'react';
import { FaCameraRetro, FaMicrophone } from 'react-icons/fa';
import { MdLogout, MdSensors } from 'react-icons/md';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { AiModelContext } from '../@components/AiModelViewLayout';
import { OutputSourcesView } from './@components/OutputSourcesView';

export function AiModelOutputSources() {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item } = useContext(AiModelContext);

  return (
    <>
      <BreadcrumbLayout.Header
        icon={<MdLogout />}
        title="Output Sources"
        subtitle={
          <>
            Manage audio, video, or sensor output sources of <b>{item.name}</b>
          </>
        }
      />
      <BreadcrumbLayout.Body>
        <OutputSourcesView label="Video" value="videoOutputs" icon={<FaCameraRetro />} />
        <hr />
        <OutputSourcesView label="Audio" value="audioOutputs" icon={<FaMicrophone />} />
        <hr />
        <OutputSourcesView label="Sensor" value="sensorOutputs" icon={<MdSensors />} />
      </BreadcrumbLayout.Body>
    </>
  );
}
