import api from '@/api';
import { getTenantMap } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenEndUser>} RequestType */

export function EndUserListView() {
  const { tenantId } = useParams();
  const tenantMap = useSelector(getTenantMap);
  const navigate = useNavigate();

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (limit, token) => {
        const request = api.sai.v1.tenant.$tenantId(tenantId)['end-user'].$get({
          params: {
            pageSize: limit,
            emailOffset: token,
          },
        });
        await request.process();
        const users = request.result.result.results;
        const nextToken = users.length < limit ? null : users[users.length - 1].email;
        return {
          results: users.slice(0, users.length - 1),
          token: nextToken,
        };
      }}
      onClick={
        /** @param {EndUser} [endUser] */
        (endUser) => navigate(`./${endUser.userId}`)
      }
      headers={[
        {
          key: 'userId',
        },
        {
          key: 'name',
        },
        {
          key: 'username',
        },
        {
          key: 'email',
          disableAction: true,
          builder: ({ email }) => (
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
              {email}
            </a>
          ),
        },
        {
          key: 'status',
          align: 'center',
          width: 120,
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          key: 'tenantId',
          title: 'Owner Tenant',
          disableAction: true,
          builder: ({ tenantId }) =>
            tenantMap[tenantId] && (
              <Link to={`/settings/tenants/${tenantId}`}>{tenantMap[tenantId].name}</Link>
            ),
        },
      ]}
    />
  );
}
