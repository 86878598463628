import { useContext } from 'react';
import { useLocation } from 'react-router';
import { useRangedColor } from '@/utils/hooks/RangedColor';
import { TitledPageHeaderContext } from '.';

/**
 * @typedef {object} TitledPageHeaderProps
 * @property {string} [color]
 * @property {boolean} [hideBar]
 * @property {Component} [icon]
 * @property {Component} [title]
 * @property {Component} [subtitle]
 * @property {Component} [children]
 */

/** @param {TitledPageHeaderProps} props */
export function PageHeader(props) {
  const location = useLocation();

  const parentProps = useContext(TitledPageHeaderContext);
  const combinedProps = Object.assign({}, parentProps || {}, props || {});
  const { icon, title, hideBar, subtitle, color, children, ...otherProps } = combinedProps;

  const borderColor = useRangedColor(location.pathname, color, 50, 175);

  const divProps = otherProps || {};
  divProps.className = `${divProps.className || ''} titled-page-header`;
  divProps.style = { borderColor, ...divProps.style };
  if (hideBar) {
    divProps.style.border = 'none';
  }

  return (
    <div {...divProps}>
      <div className="titled-page-icon" style={{ color: borderColor }}>
        {icon}
      </div>
      <h1 className="titled-page-title">
        {title}
        {subtitle && <div className="titled-page-subtitle">{subtitle}</div>}
      </h1>
      <div className="titled-page-actions">{children}</div>
    </div>
  );
}
