import { donutCharColors } from '@/assets/colors/autocomplete';
import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchTriggerDonutData } from '@/state/chart/fetchers/triggers';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function TriggersDonut(props) {
  const { sidebar } = props;

  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchTriggerDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.triggers?.total || 0,
    points: [
      ['data1', 'data2'],
      ['Triggers', chartData.triggers?.total || 0],
    ],
  };
  return (
    <DashboardItem sidebar={sidebar} header={'Triggers'} url={'/events/triggers'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'Triggers' }}
        legends={[{ name: 'Triggers', className: 'orange' }]}
        colors={[donutCharColors.ORANGE]}
        data={data.points}
      />
    </DashboardItem>
  );
}
