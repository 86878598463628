import api from '@/api';
import { ListGroup } from 'react-bootstrap';
import { TokenBasedPagination } from '@/web/common/pagination/TokenBasedPagination';
import { AiAccelaratorListItem } from '../AiAccelaratorListItem';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenAiAccelerator>} RequestType */

export function AiAccelaratorListView() {
  return (
    <TokenBasedPagination
      request={(limit, token) =>
        api.sai.v1['ai-accelerator'].$get({
          params: {
            pageSize: limit,
            continuationToken: token,
          },
        })
      }
      nextToken={
        /** @param {RequestType} request */
        (request) => request.result?.result?.continuationToken
      }
      success={
        /** @param {RequestType} request */
        (request) => {
          const items = request.result?.result?.results || [];
          return (
            <ListGroup>
              {items.length > 0 ? (
                items.map((item) => <AiAccelaratorListItem key={item.codeName} item={item} />)
              ) : (
                <ListGroup.Item className="text-center text-muted py-3">
                  No more items
                </ListGroup.Item>
              )}
            </ListGroup>
          );
        }
      }
    />
  );
}
