import api from '@/api';
import { TokenBasedPagination } from '@/web/common/pagination/TokenBasedPagination';
import { DevicePoolTableView } from '../DevicePoolTableView';
import { useParams } from 'react-router';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenDevicePool>} RequestType */

export function DevicePoolListView() {
  const { tenantId } = useParams();

  return (
    <TokenBasedPagination
      request={(limit, token) =>
        api.sai.v1['device-pool'].$get({
          params: {
            tenantId,
            pageSize: limit,
            continuationToken: token,
          },
        })
      }
      nextToken={
        /** @param {RequestType} request */
        (request) => request.result.result.continuationToken
      }
      success={
        /** @param {RequestType} request */
        (request) => {
          let devicePools = request.result.result.results;
          if (devicePools.length === 0) {
            return <div className="text-center text-muted py-3">No more device pools</div>;
          }
          return <DevicePoolTableView devicePools={devicePools} />;
        }
      }
    />
  );
}
