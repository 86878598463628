import api from '@/api';
import { getBearerToken, getTenantId } from '@/state/auth';
import { fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { TenantAdminView } from './@components/TenantAdminView';

export function ViewTenantAdmin() {
  const { userId } = useParams();
  const tenantId = useSelector(getTenantId);
  const bearerToken = useSelector(getBearerToken);
  useDispatchLoader(fetchAllTenants, [bearerToken]);

  const [user, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.tenant.$tenantId(tenantId).admin.$adminId(userId).$get({});
    await request.process();
    return request.result.result;
  }, [bearerToken, tenantId, userId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <BreadcrumbLayout.Header title="Tenant Admin" />
        <BreadcrumbLayout.Body>
          <PageLoadingSpinner status="Loading  user..." />
        </BreadcrumbLayout.Body>
      </BreadcrumbLayout>
    );
  }
  if (error) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout>
      <TenantAdminView user={user} />
    </BreadcrumbLayout>
  );
}
