import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { PagedNavigation } from '@/web/common/PagedNavigation';
import { GroupViewLayout } from './@components/GroupViewLayout';

export function ViewGroupWithTenant() {
  return (
    <GroupViewLayout>
      <PagedNavigation
        layout={(props) => (
          <>
            <BreadcrumbLayout.Header subtitle={'Select a section to view more'} />
            <BreadcrumbLayout.Body>{props.children}</BreadcrumbLayout.Body>
          </>
        )}
      />
    </GroupViewLayout>
  );
}
