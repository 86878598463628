import { Card, Form, Placeholder, FloatingLabel } from 'react-bootstrap';

export function EditAiAccelaratorPlaceholder() {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">Edit AI Accelarator</Card.Title>
      </Card.Header>
      <Placeholder as={Card.Body} animation="glow">
        <FloatingLabel label="Name" className="mb-2" style={{ flex: '1 1' }}>
          <Placeholder as={Form.Control} />
        </FloatingLabel>
        <FloatingLabel label="Type" className="mb-2" style={{ flex: '1 1' }}>
          <Placeholder as={Form.Control} />
        </FloatingLabel>
        <FloatingLabel label="Vendor" className="mb-2" style={{ flex: '1 1' }}>
          <Placeholder as={Form.Control} />
        </FloatingLabel>
        <FloatingLabel label="Description" className="mb-2" style={{ flex: '1 1' }}>
          <Placeholder as={Form.Control} style={{ height: '150px' }} />
        </FloatingLabel>
      </Placeholder>
      <Placeholder
        as={Card.Footer}
        animation="glow"
        className="d-flex align-items-center justify-content-between"
      >
        <div className="text-muted">Loading...</div>
        <Placeholder.Button variant="primary" xs={2} />
      </Placeholder>
    </Card>
  );
}
