import { getTenantMap } from '@/state/cache';
import {
  USER_ACCESS_TENANT_ADMIN_DELETE,
  USER_ACCESS_TENANT_ADMIN_UPDATE,
} from '@/web/common/AccessRights';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeleteTenantAdminButton } from '../DeleteTenantAdminButton';

/** @param {{user: TenantAdmin }} props */
export function TenantAdminView(props) {
  const { user } = props;
  const tenantMap = useSelector(getTenantMap);

  return (
    <>
      <BreadcrumbLayout.Header title={user.displayName || user.username} subtitle={user.email}>
        <USER_ACCESS_TENANT_ADMIN_DELETE>
          <DeleteTenantAdminButton user={user} />
        </USER_ACCESS_TENANT_ADMIN_DELETE>
        <USER_ACCESS_TENANT_ADMIN_UPDATE>
          <Button as={Link} to="./edit" variant="primary" style={{ width: 132 }}>
            Edit
          </Button>
        </USER_ACCESS_TENANT_ADMIN_UPDATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={user}
          headers={[
            {
              key: 'adminId',
              title: 'ID',
            },
            {
              title: 'Owner Tenant',
              builder: ({ tenantId }) => (
                <Link to={`/settings/tenants/${tenantId}`}>
                  {tenantMap[tenantId]?.name || tenantId}
                </Link>
              ),
            },
            {
              key: 'role',
            },
            {
              key: 'username',
            },
            {
              key: 'email',
            },
            {
              key: 'firstName',
            },
            {
              key: 'lastName',
            },
            {
              key: 'description',
            },
            {
              key: 'company',
            },
            {
              key: 'phone',
            },
            {
              key: 'address',
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
