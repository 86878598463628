import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { fetchAllLabelCategories, fetchAllLabelsByTenant } from '@/state/cache';
import { fetchAllTriggersByTenant } from '@/state/cache/fetchers/triggers';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { TriggerView } from './@components/TriggerView';

export const TriggerContext = createContext();

export function ViewTriggerWithTenant() {
  const { triggerId, tenantId } = useParams();

  const [id, setId] = useState(null);
  const updateParent = () => {
    setId(Date.now());
  };

  useDispatchLoader(fetchAllLabelCategories);
  useDispatchLoader(() => fetchAllLabelsByTenant(tenantId), [tenantId]);
  useDispatchLoader(() => fetchAllTriggersByTenant(tenantId), [tenantId]);

  const bearerToken = useSelector(getBearerToken);
  const [trigger, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.trigger.$triggerId(triggerId).$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, triggerId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={loading} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    displayError(error);
    return <Navigate replace to="./../.." />;
  }

  return (
    <BreadcrumbLayout>
      <TriggerContext.Provider value={updateParent}>
        <TriggerView item={trigger} />
      </TriggerContext.Provider>
    </BreadcrumbLayout>
  );
}
