import api from '@/api';
import { getBearerToken, getTenantId } from '@/state/auth';
import { formatAccessLevel } from '@/utils/formatting';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { DataListTable } from '@/web/common/DataListTable';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenEventModel>} RequestType */

export function UserListView() {
  const { deviceId } = useParams();
  const tenantId = useSelector(getTenantId);
  const bearerToken = useSelector(getBearerToken);
  const navigate = useNavigate();

  const [users, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.device.$deviceId(deviceId).user.$get();
    await request.process();
    return request.result.result;
  }, [bearerToken]);

  if (loading) {
    return <PageLoadingSpinner status="Loading users..." />;
  }

  if (error) {
    return <PageLoadingFailure title="Failed to load users" message={error} />;
  }

  return (
    <DataListTable
      data={users}
      onClick={
        /** @param {DeviceUserModel} [user] */
        (user) => navigate(`/dashboard/end-user/${tenantId}/${user.userId}`)
      }
      headers={[
        {
          key: 'userId',
          title: 'ID',
          width: 200,
        },
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'userEmail',
          minWidth: 200,
        },
        {
          key: 'accessLevel',
          minWidth: 150,
          builder: /** @param {DeviceUserModel} user */ ({ accessLevel }) => {
            return formatAccessLevel(accessLevel);
          },
        },
      ]}
    />
  );
}
