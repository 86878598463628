import { PagedNavigation } from '@/web/common/PagedNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { DeviceDetailsActions } from './@components/DeviceDetailsActions';
import { DeviceViewLayout } from './@components/DeviceViewLayout';

export function ViewDeviceWithTenant() {
  return (
    <DeviceViewLayout>
      <PagedNavigation
        layout={(props) => (
          <>
            <BreadcrumbLayout.Header subtitle={'Select a section to view more'}>
              <DeviceDetailsActions />
            </BreadcrumbLayout.Header>
            <BreadcrumbLayout.Body>{props.children}</BreadcrumbLayout.Body>
          </>
        )}
      />
    </DeviceViewLayout>
  );
}
