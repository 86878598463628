import api from '@/api';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { getBearerToken } from '@/state/auth';
import { displayError } from '@/utils/toaster';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { AiContainerGeneralView } from './@components/AiContainerGeneralView';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { ContainerComponentListView } from './@components/ContainerComponentListView';
import { fetchAllTenants, fetchAllProducts, fetchAllAiModels } from '@/state/cache';

export const AiContainerContext = createContext();

export function ViewAiContainerWithTenant() {
  const { containerId } = useParams();

  const [id, setId] = useState(null);
  const updateParent = () => {
    setId(Date.now());
  };

  const bearerToken = useSelector(getBearerToken);
  const [item, itemLoading, itemError] = useFutureLoader(async () => {
    const request = api.sai.v1['ai-container'].$containerId(containerId).$get();
    await request.process();
    return request.result.result;
  }, [id, containerId, bearerToken]);

  useDispatchLoader(fetchAllTenants);
  const [modelsLoading, modelsError] = useDispatchLoader(fetchAllAiModels);
  const [productsLoading, productsError] = useDispatchLoader(fetchAllProducts);

  if (itemLoading || modelsLoading || productsLoading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading data...'} />
      </BreadcrumbLayout>
    );
  }

  if (itemError || modelsError || productsError) {
    displayError(itemError);
    displayError(modelsError);
    displayError(productsError);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout>
      <AiContainerContext.Provider value={updateParent}>
        <AiContainerGeneralView item={item} />
        <ContainerComponentListView item={item} />
      </AiContainerContext.Provider>
    </BreadcrumbLayout>
  );
}
