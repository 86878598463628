import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { TriggerContext } from '../..';

/** @param {{item: Trigger, disabled: boolean}} props */
export function DiscontinueTriggerButton(props) {
  const { item } = props;

  const updateParent = useContext(TriggerContext);
  const [loading, setLoading] = useState(false);

  const discontinueTrigger = async () => {
    const request = api.sai.v1.trigger.$triggerId(item.triggerId)[':discontinue'].$post();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Discontinued Trigger <b>${item.name}</b>`,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      size="sm"
      type="button"
      variant="outline-danger"
      style={{ width: 132 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: discontinueTrigger,
          title: `Discontinue ${item.name}?`,
          message: 'It will affect all resources using this Trigger',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Discontinue'}
    </Button>
  );
}
