import api from '@/api';
import { useSelector } from 'react-redux';
import { getBearerToken } from '@/state/auth';
import { Card } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { EditLabelForm } from './@components/EditLabelForm';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { fetchAllLabelCategories, fetchAllLabelsByTenant } from '@/state/cache';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';

export function EditLabelWithTenant() {
  const { labelId, tenantId } = useParams();

  const bearerToken = useSelector(getBearerToken);

  const [labelCategoriesLoading, labelCategoriesError] = useDispatchLoader(fetchAllLabelCategories);
  const [labelsLoading, labelsError] = useDispatchLoader(
    () => fetchAllLabelsByTenant(tenantId),
    [tenantId]
  );
  const [label, labelLoading, labelError] = useFutureLoader(async () => {
    const request = api.sai.v1['label'].$labelId(labelId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, labelId]);

  if (labelLoading || labelCategoriesLoading || labelsLoading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <PageLoadingSpinner status={'Loading label...'} />
      </BreadcrumbLayout>
    );
  }

  if (labelError || labelCategoriesError || labelsError) {
    displayError(labelError);
    displayError(labelCategoriesError);
    displayError(labelsError);
    return <Navigate replace to="./../.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title className="my-1">
            Edit <b>{label.name}</b>
          </Card.Title>
        </Card.Header>
        <EditLabelForm item={label} />
      </Card>
    </BreadcrumbLayout>
  );
}
