import { Link } from 'react-router-dom';
import { Badge, ListGroup } from 'react-bootstrap';

/** @param {{item: PlatformAdmin}} props */
export function PlatformAdminListItem(props) {
  const user = props.item;

  return (
    <>
      <ListGroup.Item
        action
        as={Link}
        to={{
          pathname: `./${user.id}`,
        }}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="ms-lg-2">
          <div className="fw-bold">{user.email}</div>
          <div className="text-muted">
            <code>{user.username}</code>
            &nbsp;&middot;&nbsp;
            <small>
              <b>
                {user.firstName} {user.lastName}
              </b>
            </small>
            {user.displayName && (
              <>
                &nbsp;
                <small>({user.displayName})</small>
              </>
            )}
            {user.company && (
              <>
                &nbsp;&middot;&nbsp;
                <small>Company: {user.company}</small>
              </>
            )}
            {user.phone && (
              <>
                &nbsp;&middot;&nbsp;
                <small>Phone: {user.phone}</small>
              </>
            )}
          </div>
          <div className="text-muted">{user.description}</div>
        </div>
        <Badge bg="info" text="dark" pill>
          {user.role}
        </Badge>
      </ListGroup.Item>
    </>
  );
}
