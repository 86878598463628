import { useAccessRightChecker } from '@/utils/hooks/AccessChecker';
import { Navigate } from 'react-router-dom';

/**
 * @typedef {'PLATFORM'|'TENANT'|'END_USER'|'DEVICE'} AccessRightScope
 */

/**
 * @typedef {object} UserAccessProps
 * @property {any} [children]
 * @property {any} [fallback]
 * @property {'OR'|'AND'} mode
 * @property {Array.<string>} rights
 * @property {import('history').To} [redirect]
 */

/** @param {UserAccessProps} props */
export function UserAccess(props) {
  const { rights, mode, redirect, children, fallback } = props;

  const checker = useAccessRightChecker();
  if (checker(rights, mode)) {
    return children;
  }

  if (redirect) {
    return <Navigate replace to={redirect} />;
  }

  return fallback ?? <></>;
}
