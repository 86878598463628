import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { fetchAllRoles, fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { USER_ACCESS_PLATFORM_ADMIN_UPDATE } from '@/web/common/AccessRights';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { EditPlatformAdminForm } from './@components/EditPlatformAdminForm';
import { EditPlatformAdminPlaceholder } from './@components/EditPlatformAdminPlaceholder';

export function EditPlatformAdmin() {
  const { userId } = useParams();
  const bearerToken = useSelector(getBearerToken);
  useDispatchLoader(fetchAllRoles, [bearerToken]);
  useDispatchLoader(fetchAllTenants, [bearerToken]);

  const [user, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['platform-admin'].$id(userId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, userId]);

  if (loading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <EditPlatformAdminPlaceholder />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title className="my-1">
            <b>{user.email}</b> / <code>{user.username}</code>
          </Card.Title>
        </Card.Header>
        <TabsNavigation
          tabs={[
            {
              key: 'general',
              title: 'General',
              content: (
                <USER_ACCESS_PLATFORM_ADMIN_UPDATE
                  fallback={
                    <PageLoadingFailure message="You do not have permission to modify platform admins" />
                  }
                >
                  <EditPlatformAdminForm user={user} />
                </USER_ACCESS_PLATFORM_ADMIN_UPDATE>
              ),
            },
            // TODO: disabled untill backend is ready
            // {
            //   key: 'tenant-access',
            //   title: 'Tenant Access',
            //   content: (
            //     <USER_ACCESS_PLATFORM_ADMIN_TENANT_ACCESS_UPDATE
            //       fallback={
            //         <PageLoadingFailure message="You do not have permission to modify tenant access list of platform admins" />
            //       }
            //     >
            //       <EditTenantAccessListForm user={user} />,
            //     </USER_ACCESS_PLATFORM_ADMIN_TENANT_ACCESS_UPDATE>
            //   ),
            // },
          ]}
        />
      </Card>
    </BreadcrumbLayout>
  );
}
