import { CloseButton } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import './index.scss';

/**
 * @typedef {object} SearchBoxBaseProps
 * @property {string} value
 * @property {(v: string) => any} onChange
 * @property {any} [prefixIcon]
 * @property {any} [suffixIcon]
 * @property {boolean} [clearable]
 * @property {boolean} [ignoreCase]
 */

/**
 * @typedef {SearchBoxBaseProps & import('react-bootstrap').FormControlProps} SearchBoxProps
 */

/** @param {SearchBoxProps} props */
export function SearchBox(props) {
  const { value, onChange, prefix, suffix, clearable, ignoreCase, ...extraProps } = props;

  const setValue = (val) => {
    val ||= '';
    if (ignoreCase) {
      val = val.toLowerCase();
    }
    onChange(val);
  };

  return (
    <div className="search-box">
      {prefix || <FaSearch color="grey" />}
      <input {...extraProps} value={value || ''} onInput={(e) => setValue(e.currentTarget.value)} />
      {clearable && value && <CloseButton onClick={() => setValue()} />}
      {suffix}
    </div>
  );
}
