import { InputBox } from '@/web/common/forms/InputBox';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Vision(props) {
  const { updateSettings } = useContext(SettingsContext);
  const { settings, defaultSettings, loadingOnUpdate } = props;
  const [metadataReportingThreshold, setMetadataReportingThreshold] = useState(
    settings.metadataReportingThreshold ?? defaultSettings.metadataReportingThreshold
  );
  const [aiContainerUpdateCheckInterval, setAiContainerUpdateCheckInterval] = useState(
    settings.aiContainerUpdateCheckInterval ?? defaultSettings.aiContainerUpdateCheckInterval
  );

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = { aiContainerUpdateCheckInterval, metadataReportingThreshold };
    updateSettings(data);
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col lg={4}>
            <InputBox
              label="Metadata reporting threshold (%)"
              type="number"
              value={metadataReportingThreshold}
              required
              onChange={setMetadataReportingThreshold}
            />
          </Col>
          <Col lg={4}>
            <InputBox
              label="AI Container update check interval (min)"
              type="number"
              value={aiContainerUpdateCheckInterval}
              required
              onChange={setAiContainerUpdateCheckInterval}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
