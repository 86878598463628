import api from '@/api';
import { getBearerToken, getTenantId } from '@/state/auth';
import { getTenant } from '@/state/cache';
import { formatDateTime } from '@/utils/formatting';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { VideoContainer } from '../VideoContainer';

/** @param {{item: EventModel}} props */
export function EventView(props) {
  const { item } = props;

  const tenantId = useSelector(getTenantId);
  const owner = useSelector(getTenant(tenantId));
  const bearerToken = useSelector(getBearerToken);
  const [mediaList, mediaListLoading, mediaListError] = useFutureLoader(async () => {
    const request = api.sai.v1.device.$deviceId(item.deviceId).media.$get({
      params: {
        pageSize: 20,
        fromTimestamp: item.recordingStartTimestamp,
        toTimestamp: item.recordingEndTimestamp,
      },
    });
    await request.process();
    return request.result.result;
  }, [item.deviceId, bearerToken]);

  return (
    <>
      {/*<BreadcrumbLayout.Header title={<h6>Event Details</h6>}></BreadcrumbLayout.Header>*/}
      <BreadcrumbLayout.Body>
        <Row>
          <Col>
            <div style={{ borderStyle: 'solid', borderColor: '#ededf1' }}>
              <VideoContainer
                eventObj={item}
                loading={mediaListLoading}
                error={mediaListError}
                list={mediaList}
              />
            </div>
          </Col>
          <Col md="3">
            <DataItemTable
              data={item || {}}
              headers={[
                {
                  key: 'deviceName',
                },
                {
                  key: 'timestamp',
                  title: 'Time',
                  builder: ({ timestamp }) => formatDateTime(timestamp),
                },
                {
                  key: 'triggerName',
                },
                {
                  key: 'tenantId',
                  title: 'Tenant',
                  builder: ({ tenantId }) =>
                    owner && <Link to={`/settings/tenants/${tenantId}`}>{owner.name}</Link>,
                },
              ]}
            />
          </Col>
        </Row>
      </BreadcrumbLayout.Body>
    </>
  );
}
