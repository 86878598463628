import api from '@/api';
import { getTenantId } from '@/state/auth';
import { formatDateTime } from '@/utils/formatting';
import { SearchBoxInQuery } from '@/web/common/forms/SearchBoxInQuery';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { Snapshot } from '@/web/common/Snapshot';
import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenEventModel>} RequestType */

export function EventListView() {
  const navigate = useNavigate();
  const tenantId = useSelector(getTenantId);

  const [searchText, setSearchText] = useState();
  const cancelToken = axios.CancelToken.source();

  return (
    <>
      <SearchBoxInQuery
        value={searchText}
        onChange={setSearchText}
        beforeNavigate={() => cancelToken.cancel('query changed')}
        placeholder="Search events..."
        clearable
      />

      <div className="mb-2" />

      <TokenBasedInfiniteScrollTable
        key={searchText}
        style={{ height: 'calc(100vh - 285px)' }}
        fetcher={async (limit, token) => {
          const request = api.sai.v1.event.$get({
            params: {
              tenantId,
              pageSize: limit,
              continuationToken: token,
              searchText: searchText || undefined,
            },
          });
          request.cancelToken = cancelToken;
          await request.process();
          return {
            results: request.result.result.results,
            token: request.result.result.continuationToken,
          };
        }}
        onClick={
          /** @param {EventModel} [event] */
          (event) => navigate(`./${event.eventId}`)
        }
        headers={[
          {
            key: 'deviceName',
            title: 'Device',
            minWidth: 175,
          },
          {
            key: 'timestamp',
            title: 'Time',
            builder: ({ timestamp }) => formatDateTime(timestamp),
            minWidth: 175,
          },
          {
            key: 'triggerName',
            title: 'Trigger',
            minWidth: 150,
          },
          {
            key: 'snapshots',
            title: 'Snapshot',
            minWidth: 250,
            disableAction: true,
            builder: ({ snapshots }) => (
              <div className="d-flex gap-2">
                {snapshots
                  ?.filter((item) => item.source !== 'sensor')
                  ?.map((item) => (
                    <Snapshot
                      key={item.filePath}
                      url={item.downloadUrl}
                      style={{
                        width: '50%',
                        height: 125,
                        minWidth: 150,
                        maxWidth: 200,
                      }}
                    />
                  ))}
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
