import { fetchAllAiAccelerators } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { USER_ACCESS_PRODUCT_CREATE } from '@/web/common/AccessRights';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaFirstdraft, FaHandshakeAltSlash, FaList, FaPlus, FaRocket } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SuperAdminDonutGroup } from '../@components/SuperAdminDonutGroup';
import { ProductListView } from './@components/ProductListView';

export function SuperAdminProducts() {
  useDispatchLoader(fetchAllAiAccelerators);
  return (
    <SidebarPlotLayout sidebar={<SuperAdminDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Products">
        <USER_ACCESS_PRODUCT_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> New Product
          </Button>
        </USER_ACCESS_PRODUCT_CREATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <TabsNavigation
          replace
          tabs={[
            {
              key: 'all',
              title: (
                <div>
                  <FaList /> All
                </div>
              ),
              content: <ProductListView />,
            },
            {
              key: 'draft',
              title: (
                <div>
                  <FaFirstdraft /> Unreleased
                </div>
              ),
              content: <ProductListView status={'UNRELEASED'} />,
            },
            {
              key: 'published',
              title: (
                <div>
                  <FaRocket /> Released
                </div>
              ),
              content: <ProductListView status={'RELEASED'} />,
            },
            {
              key: 'discontinued',
              title: (
                <div>
                  <FaHandshakeAltSlash /> Discontinued
                </div>
              ),
              content: <ProductListView status={'DISCONTINUED'} />,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
