import { Card, Spinner } from 'react-bootstrap';

export function EditFirmwareFormPlaceholder() {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">Edit Tenant</Card.Title>
      </Card.Header>
      <div className="py-5 text-center text-muted">
        <Spinner animation="border" className="my-5" />
      </div>
    </Card>
  );
}
