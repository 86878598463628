import api from '@/api';
import { confirmDialog, displayError, displaySuccess } from '@/utils/toaster';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { AiModelContext } from '../../../@components/AiModelViewLayout';

export function PublishAiModelButton() {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item, updateItem } = useContext(AiModelContext);

  const [loading, setLoading] = useState(false);

  const publishAiModel = async () => {
    const request = api.sai.v1['ai-model'].$modelId(item.modelId)[':publish'].$post();
    try {
      setLoading(true);
      await request.process();
      displaySuccess(`AI Model <b>${item.name}</b> has been published`);
      setLoading(false);
      updateItem();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      size={'sm'}
      variant="outline-primary"
      style={{ width: 120 }}
      disabled={loading}
      onClick={() =>
        confirmDialog({
          onConfirm: publishAiModel,
          title: `Publish ${item.name} (${item.version})?`,
          message: 'You can not revert this operation!',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Publish'}
    </Button>
  );
}
