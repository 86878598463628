import axios from 'axios';
import isFunction from 'lodash/isFunction';
import { formatDuration, formatFileSize } from '../formatting';

export class ApiRequestProgress {
  #current = 0;
  #total = 0;
  #start = Date.now();

  constructor(current = 0, total = 0, startAt = Date.now()) {
    this.#current = current;
    this.#total = total;
    this.#start = startAt;
  }

  get now() {
    return this.#current;
  }

  get max() {
    return this.#total;
  }

  get progress() {
    return (100 * this.#current) / this.#total;
  }

  get label() {
    return isNaN(this.progress) ? '-' : `${this.progress.toFixed(0)}%`;
  }

  get speed() {
    const val = (1000 * this.#current) / (Date.now() - this.#start);
    return `${formatFileSize(val)}/s`;
  }

  get remaining() {
    return formatDuration((this.#total * (Date.now() - this.#start)) / this.#current);
  }
}

/** @template T */
export class ApiRequest {
  #upload = new ApiRequestProgress();
  #download = new ApiRequestProgress();
  cancelToken = axios.CancelToken.source();

  /** @type {import('axios').AxiosRequestConfig<T>} */
  static defaultOptions = {
    method: 'GET',
    headers: {},
    params: {},
  };

  /** @type {LoadingState} */
  #status = 'pending';

  /** @type {import('axios').AxiosRequestConfig<T>} */
  #request;

  /** @type {import('axios').AxiosResponse<T> | null} */
  #response;

  /** @type {Error | null} */
  #error;

  /**
   * @param {import('axios').AxiosRequestConfig<T>} config
   */
  constructor(config) {
    this.#request = Object.assign({}, ApiRequest.defaultOptions, config);
  }

  get status() {
    return this.#status;
  }

  get request() {
    return this.#request;
  }

  get response() {
    return this.#response || null;
  }

  get error() {
    return this.#error;
  }

  /** @type {T | null} */
  get result() {
    return this.response ? this.response.data : null;
  }

  get upload() {
    return this.#upload;
  }

  get download() {
    return this.#download;
  }

  cancel(message) {
    this.cancelToken?.cancel(message);
  }

  reset() {
    if (this.status === 'loading') {
      this.cancel('ignore');
    }
    this.#error = null;
    this.#response = null;
    this.#status = 'pending';
    this.#upload = new ApiRequestProgress();
    this.#download = new ApiRequestProgress();
  }

  async process(callback) {
    if (this.status !== 'pending') {
      return this.result;
    }

    const startTime = Date.now();

    const cb = isFunction(callback) ? callback : () => {};
    this.#status = 'loading';
    cb();

    if (!this.cancelToken) {
      this.cancelToken = axios.CancelToken.source();
    }
    this.#request.cancelToken = this.cancelToken.token;

    this.#request.onUploadProgress = (event) => {
      //console.debug('upload', event.loaded, event.total);
      this.#upload = new ApiRequestProgress(event.loaded, event.total, startTime);
      cb();
    };

    this.#request.onDownloadProgress = (event) => {
      //console.debug('download', event.loaded, event.total);
      this.#download = new ApiRequestProgress(event.loaded, event.total, startTime);
      cb();
    };

    try {
      this.#response = await axios.request(this.#request);
      this.#status = 'success';
      cb();
    } catch (err) {
      if (err instanceof axios.Cancel && err.message === 'ignore') {
        // ignore this error
      } else {
        this.#status = 'failure';
        this.#response = err.response;
        this.#error = new Error(
          this.result ? this.result.message || this.result.status : err.message
        );
        cb();
        throw this.#error;
      }
    }

    return this.result;
  }
}

/**
 * @typedef LoadingState
 * @type {'pending'|'loading'|'success'|'failure'}
 */
