export default function ModifiedDonut({ legends }) {
  return (
    <div className="custom-legend-streams">
      <ul>
        {legends.map((legend) => (
          <li key={legend.name}>
            <span className={`dot ${legend.className}`}></span>
            <p className="legend-secondary-title">{legend.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}
