const uniq = require('lodash/uniq');

/** @param {{title: string, message: string, children: any, borderless: boolean}} props */
export function PageLoadingFailure(props) {
  const { title, message, children, borderless, ...extraProps } = props;

  const classNames = uniq([
    'rounded-1',
    'bg-white',
    'text-center',
    'p-2',
    'py-5',
    borderless ? null : 'border',
    ...(extraProps.className || '')?.split(' '),
  ]).filter((x) => Boolean(x));

  return (
    <div {...extraProps} className={classNames.join(' ')}>
      <p className="h5">{title || 'Sorry!'}</p>
      {message && <p className="text-muted">{message + ''}</p>}
      {children && <div className="small">{children}</div>}
    </div>
  );
}
