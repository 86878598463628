import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Onboarding } from './@components/Onboarding';
import { Parking } from './@components/Parking';
import { Profiling } from './@components/Profiling';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { displayError, displaySuccess } from '@/utils/toaster';
import { Vision } from './@components/Vision';
import { Sensor } from './@components/Sensor';
import { Recording } from './@components/Recording';
import { Streaming } from './@components/Streaming';
import { Device } from './@components/Device';
import { FirmwareUpdate } from './@components/FirmwareUpdate';

export const SettingsContext = createContext();

export function SettingsPage() {
  const { groupId } = useParams();
  const bearerToken = useSelector(getBearerToken);
  const [id, setId] = useState();
  const [loadingOnUpdate, setLoadingOnUpdate] = useState();

  const updateSettings = async (data) => {
    try {
      if (loading) return;
      setLoadingOnUpdate(true);
      const request = api.sai.v1['deployment-group'].$groupId(groupId).settings.$patch({
        data,
      });
      await request.process();
      setLoadingOnUpdate(false);
      displaySuccess('Saved all changes');
      updateGroup();
    } catch (err) {
      setLoadingOnUpdate(false);
      displayError(err);
    }
    return false;
  };

  const updateGroup = () => {
    setId(Date.now());
  };

  let [settingsObj, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['deployment-group'].$groupId(groupId).settings.$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, groupId]);

  if (error) {
    return (
      <PageLoadingFailure message={'Failed to load group settings'}>
        {error + ''}
      </PageLoadingFailure>
    );
  }

  return (
    <SettingsContext.Provider value={{ settingsObj, updateSettings }}>
      <TabsNavigation
        tabs={[
          {
            key: 'recording',
            title: 'Recording',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Recording
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'streaming',
            title: 'Streaming',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Streaming
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'onboarding',
            title: 'Onboarding',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Onboarding
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'firmware-update',
            title: 'Firmware Update',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <FirmwareUpdate
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'device-settings',
            title: 'Device Settings',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Device
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'parking',
            title: 'Parking',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Parking
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'vision',
            title: 'Vision',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Vision
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'profiling',
            title: 'Profiling',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Profiling
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
          {
            key: 'sensor',
            title: 'Sensor',
            content: (
              <>
                {loading ? (
                  <PageLoadingSpinner status={'Loading group settings...'} />
                ) : (
                  <Sensor
                    loadingOnUpdate={loadingOnUpdate}
                    settings={settingsObj?.groupSettings}
                    defaultSettings={settingsObj?.defaultSettings}
                  />
                )}
              </>
            ),
          },
        ]}
      />
    </SettingsContext.Provider>
  );
}
