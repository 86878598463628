import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { AiFillApi } from 'react-icons/ai';
import { BsInfoCircleFill } from 'react-icons/bs';
import { FaEye, FaList } from 'react-icons/fa';
import { GrConnectivity } from 'react-icons/gr';
import { MdCastConnected } from 'react-icons/md';
import { DeviceDiagnosticsListView } from './@components/DeviceDiagnosticsListView';

export function DeviceDiagnosticsListViewPage() {
  return (
    <>
      <BreadcrumbLayout.Header title="Device Diagnostics" icon={<BsInfoCircleFill />} />
      <BreadcrumbLayout.Body>
        <TabsNavigation
          retain
          tabKey="module"
          tabs={[
            {
              key: 'ALL',
              title: (
                <div>
                  <FaList /> All
                </div>
              ),
              content: <DeviceDiagnosticsListView />,
            },
            {
              key: 'ACCESS',
              title: (
                <div>
                  <AiFillApi /> Access
                </div>
              ),
              content: <DeviceDiagnosticsListView module="ACCESS" />,
            },
            {
              key: 'STREAM',
              title: (
                <div>
                  <MdCastConnected /> Stream
                </div>
              ),
              content: <DeviceDiagnosticsListView module="STREAM" />,
            },
            {
              key: 'CONNECT',
              title: (
                <div>
                  <GrConnectivity /> Connect
                </div>
              ),
              content: <DeviceDiagnosticsListView module="CONNECT" />,
            },
            {
              key: 'VISION',
              title: (
                <div>
                  <FaEye /> Vision
                </div>
              ),
              content: <DeviceDiagnosticsListView module="VISION" />,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
