import { Button, Form, Modal } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa';

/**
 * @typedef {object} ChooseFileModalInputProps
 * @property {(file: File) => Promise<bool>} onInput
 */

/** @param {ChooseFileModalInputProps} props */
export function ChooseFileModalInput(props) {
  const { onInput } = props;

  /** @param {import('react').FormEvent<HTMLInputElement>} e */
  const handleInput = async (e) => {
    try {
      const selected = e.target.files[0];
      const keep = await onInput(selected);
      if (!keep) {
        e.target.value = '';
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label>Choose a file to upload.</Form.Label>
          <Form.Control type="file" onInput={handleInput} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled variant="primary" style={{ width: 125 }}>
          <FaUpload /> Upload
        </Button>
      </Modal.Footer>
    </>
  );
}
