import api from '@/api';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBearerToken } from '@/state/auth';
import { fetchAllAiAccelerators, fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';

export const ProductViewContext = createContext();

export function ProductViewLayout(props) {
  const { productId } = useParams();

  const [id, setId] = useState();
  const updateProduct = () => {
    setId(Date.now());
  };

  useDispatchLoader(fetchAllTenants);
  useDispatchLoader(fetchAllAiAccelerators);

  const bearerToken = useSelector(getBearerToken);
  const [product, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.product.$productId(productId).$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, productId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading product...'} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    return (
      <BreadcrumbLayout>
        <PageLoadingFailure message={'Failed to load product'}>{error + ''}</PageLoadingFailure>
      </BreadcrumbLayout>
    );
  }

  return (
    <BreadcrumbLayout title={product.name} subtitle={product.description}>
      <ProductViewContext.Provider value={{ product, updateProduct }}>
        {props.children}
      </ProductViewContext.Provider>
    </BreadcrumbLayout>
  );
}
