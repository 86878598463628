import { recordingCodecs, recordingVideoResolutions } from '@/assets/ai-models/autocomplete';
import { CheckBox } from '@/web/common/forms/CheckBox';
import { InputBox } from '@/web/common/forms/InputBox';
import { InputSlider } from '@/web/common/forms/InputSlider';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Recording(props) {
  const { updateSettings } = useContext(SettingsContext);
  const { settings, defaultSettings, loadingOnUpdate } = props;

  const [continuousRecording, setContinuousRecording] = useState(
    settings.continuousRecording ?? defaultSettings.continuousRecording
  );
  const [continuousRecordingUnitLength, setContinuousRecordingUnitLength] = useState(
    settings.continuousRecordingUnitLength ?? defaultSettings.continuousRecordingUnitLength
  );
  const [limitUploadsOverLTE, setLimitUploadsOverLTE] = useState(
    settings.limitUploadsOverLTE ?? defaultSettings.limitUploadsOverLTE
  );
  const [eventRecording, setEventRecording] = useState(
    settings.eventRecording ?? defaultSettings.eventRecording
  );
  const [preBufferEvent, setPreBufferEvent] = useState(
    settings.preBufferEvent ?? defaultSettings.preBufferEvent
  );
  const [postBufferEvent, setPostBufferEvent] = useState(
    settings.postBufferEvent ?? defaultSettings.postBufferEvent
  );
  const [shouldTakeRecordingSettingsFromModel, setShouldTakeRecordingSettingsFromModel] = useState(
    settings.shouldTakeRecordingSettingsFromModel ??
      defaultSettings.shouldTakeRecordingSettingsFromModel
  );
  const [recordingResolution, setRecordingResolution] = useState(
    settings.recordingResolution ?? defaultSettings.recordingResolution
  );
  const [recordingVideoCodec, setRecordingVideoCodec] = useState(
    settings.recordingVideoCodec ?? defaultSettings.recordingVideoCodec
  );
  const [recordingFrameRateValue, setRecordingFrameRateValue] = useState(
    settings.recordingFrameRateValue ?? defaultSettings.recordingFrameRateValue
  );
  const [recordingBitrateValue, setRecordingBitrateValue] = useState(
    settings.recordingBitrateValue ?? defaultSettings.recordingBitrateValue
  );

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = {
      continuousRecording,
      continuousRecordingUnitLength,
      limitUploadsOverLTE,
      eventRecording,
      preBufferEvent,
      postBufferEvent,
      shouldTakeRecordingSettingsFromModel,
      recordingResolution,
      recordingVideoCodec,
      recordingFrameRateValue,
      recordingBitrateValue,
    };
    updateSettings(data);
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col lg={4} className="my-auto">
            <CheckBox
              type="switch"
              label="Continuous Recording"
              checked={continuousRecording}
              onChange={setContinuousRecording}
            />
          </Col>
          <Col lg={4} className="my-auto">
            <InputSlider
              min={1}
              max={300}
              label="Continuous Recording Unit Length"
              unit={'sec'}
              value={continuousRecordingUnitLength}
              onChange={setContinuousRecordingUnitLength}
            />
          </Col>
          <Col lg={4} className="my-auto">
            <CheckBox
              type="checkbox"
              label="Limit upload over LTE"
              checked={limitUploadsOverLTE}
              onChange={setLimitUploadsOverLTE}
            />
          </Col>
          <Col lg={4} className="my-auto">
            <CheckBox
              type="switch"
              label="Event Recording"
              checked={eventRecording}
              onChange={setEventRecording}
              className="mb-2 py-2"
            />
          </Col>
          <Col lg={4}>
            <InputBox
              label="Pre Buffer (Sec)"
              type="number"
              value={preBufferEvent}
              required
              onChange={setPreBufferEvent}
            />
          </Col>
          <Col lg={4}>
            <InputBox
              label="Pre Buffer (Sec)"
              type="number"
              value={postBufferEvent}
              required
              onChange={setPostBufferEvent}
            />
          </Col>
          <h6 className="my-4">
            <u>Video Quality</u>
          </h6>
          <Row>
            <Col lg={4} className="my-auto">
              <CheckBox
                type="switch"
                label="Override config from deployed AI Models"
                checked={shouldTakeRecordingSettingsFromModel}
                onChange={setShouldTakeRecordingSettingsFromModel}
                className="mb-2 py-2"
              />
            </Col>
            <Col lg={4}>
              <SelectBox
                label="Resolution"
                value={recordingResolution}
                onChange={setRecordingResolution}
                options={Object.entries(recordingVideoResolutions || {})}
                itemValue={0}
                itemText={1}
                required
              />
            </Col>
            <Col lg={4}>
              <SelectBox
                label="Video Codec"
                value={recordingVideoCodec}
                onChange={setRecordingVideoCodec}
                options={Object.entries(recordingCodecs || {})}
                itemValue={0}
                itemText={1}
                required
              />
            </Col>
            <Col lg={4}>
              <InputBox
                label="Frame Rate (Fps)"
                type="number"
                value={recordingFrameRateValue}
                required
                onChange={setRecordingFrameRateValue}
              />
            </Col>
            <Col lg={4}>
              <InputBox
                label="Bitrate (Kbps)"
                type="number"
                value={recordingBitrateValue}
                required
                onChange={setRecordingBitrateValue}
              />
            </Col>
          </Row>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
