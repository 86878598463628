import { nanoid } from '@reduxjs/toolkit';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';
import { useMemo } from 'react';
import Table from 'react-bootstrap/Table';

const idkey = Symbol('id');

/**
 * @template T
 * @typedef {object} DataListTableHeader<T>
 * @property {string} key ID of the table header and data key for the column.
 * @property {string} [title] Title of the table header.
 * @property {number} [width] Width of the table header
 * @property {number} [minWidth] Minimum width of the table header.
 * @property {number} [maxWidth] Minimum width of the table header.
 * @property {(item: T) => import('react').Component} [builder] Optional builder function to build the column.
 * @property {import('csstype').Property.TextAlign} [align]
 * @property {boolean} [disableAction] Disable item row action
 * @property {boolean} [hidden] Whether to hide this column
 */

/**
 * @template T
 * @typedef {object} DataListTableProps<T>
 * @property {Array.<T>} data Data to display into the table.
 * @property {Array.<string | DataListTableHeader.<T>>} [headers] Headers of the table.
 * @property {(item: T, row: number, column: number, e: Event) => any} [onClick] Optional callback function to handle column click.
 * @property {'sm' | 'md' | 'lg' | 'xl'} [responsive]
 * @property {boolean} [small]
 * @property {boolean} [borderless]
 * @property {import('react').Component} [children] Extra children to append at the end of the table body
 */

/**
 * @template T
 * @param {DataListTableProps.<T> & import('react-bootstrap').TableProps} props
 */
export function DataListTable(props) {
  const {
    data: rawData,
    headers: rawHeaders,
    onClick,
    borderless,
    responsive,
    small,
    ...extraProps
  } = props;

  const data = useMemo(() => {
    return (rawData || []).map((item) => {
      if (!item[idkey]) {
        item[idkey] = nanoid();
      }
      return item;
    });
  }, [rawData]);

  const headers = useMemo(() => {
    return (rawHeaders || Object.keys(data[0] || {}))
      .map((header) => {
        if (header === null || header === undefined || header === false) {
          return null;
        }
        if (!isObject(header)) {
          header = { key: header + '' };
        }
        if (header.hidden) {
          return null;
        }
        if (!header[idkey]) {
          header[idkey] = nanoid();
        }
        if (!header.title) {
          header.title = startCase(header.key);
        }
        if (!isFunction(header.builder)) {
          header.builder = (item) => '' + (item[header.key] || '');
        }
        return header;
      })
      .filter(Boolean);
  }, [rawHeaders, data]);

  /** @param {TableHeader<T>} header */
  const getMinWidth = (header) => {
    if (header.minWidth || header.maxWidth) {
      return header.minWidth;
    }
    return header.width;
  };

  /** @param {TableHeader<T>} header */
  const getMaxWidth = (header) => {
    if (header.minWidth || header.maxWidth) {
      return header.maxWidth;
    }
    return header.width;
  };

  const hover = Boolean(data?.length && onClick);
  const itemOnClick = onClick || (() => {});

  return (
    <div
      className="data-list-table"
      style={{
        background: 'white',
        height: 'calc(100vh - 225px)',
        overflowY: 'auto',
        ...extraProps.style,
      }}
    >
      <Table
        {...extraProps}
        borderless={true}
        bordered={!borderless}
        size={small ? 'sm' : null}
        responsive={responsive ?? true}
        hover={hover}
      >
        <thead>
          <tr>
            {headers.map((header) => (
              <th
                key={header[idkey]}
                style={{
                  cursor: 'default',
                  width: header.width,
                  minWidth: getMinWidth(header),
                  maxWidth: getMaxWidth(header),
                  textAlign: header.align,
                }}
              >
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, row) => (
            <tr key={item[idkey]}>
              {headers.map((header, col) => {
                let body = header.builder(item);
                if (typeof body === 'string' && !body) body = null;
                if (typeof body === 'boolean' && !body) body = null;
                if (props.dense && (body === null || body === undefined)) return null;
                return (
                  <td
                    key={header[idkey]}
                    onClick={(e) => (header.disableAction ? null : itemOnClick(item, row, col, e))}
                    style={{
                      cursor: hover ? 'default' : null,
                      textAlign: header.align,
                      verticalAlign: 'middle',
                    }}
                  >
                    {body ?? <code>N/A</code>}
                  </td>
                );
              })}
            </tr>
          ))}
          {props.children}
        </tbody>
      </Table>
    </div>
  );
}
