export const isDev = process.env.NODE_ENV === 'development';
export const isProduction = process.env.NODE_ENV === 'production';

export const SAI_SERVICE_BASE_URL = process.env.REACT_APP_SAI_SERVICE_BASE_URL;

export const AC_SERVICE_BASE_URL = process.env.REACT_APP_AC_SERVICE_BASE_URL;
export const AC_SERVICE_DOCS_USERNAME = process.env.REACT_APP_AC_SERVICE_DOCS_USERNAME;
export const AC_SERVICE_DOCS_PASSWORD = process.env.REACT_APP_AC_SERVICE_DOCS_PASSWORD;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
