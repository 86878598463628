import { MainPageLayout } from '@/web/layouts/MainPageLayout';
import { Row } from 'react-bootstrap';
import { InternalDonutGroup } from './@components/InternalDonutGroup';

export function InternalPage() {
  return (
    <MainPageLayout>
      <div className="dashboard-donut-container">
        <Row className="mt-sm-2">
          <InternalDonutGroup />
        </Row>
      </div>
    </MainPageLayout>
  );
}
