import { Actions } from '@/web/common/AccessRights';
import { TenantSelect } from '@/web/common/TenantSelect';
import { EventsPage } from '.';
import { LabelCategoryListViewPage } from './label-category';
import { CreateLabelCategory } from './label-category/create';
import { ViewLabelCategoryPage } from './label-category/_id';
import { EditLabelCategory } from './label-category/_id/edit';
import { LabelListViewWithTenant } from './labels/_tenantId';
import { CreateLabelWithTenant } from './labels/_tenantId/create';
import { ViewLabelWithTenant } from './labels/_tenantId/_labelId';
import { EditLabelWithTenant } from './labels/_tenantId/_labelId/edit';
import { SharedTriggerListViewWithTenant } from './shared-triggers/_tenantId';
import { TriggerListViewWithTenant } from './triggers/_tenantId';
import { CreateTriggerWithTenant } from './triggers/_tenantId/create';
import { ViewTriggerWithTenant } from './triggers/_tenantId/_triggerId';

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const labelRoutes = [
  {
    path: '/events/labels',
    element: (
      <TenantSelect title="Choose Tenant" subtitle="Select a tenant to manage their labels" />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.LABEL_LIST_READ],
  },
  {
    path: '/events/labels/:tenantId',
    element: <LabelListViewWithTenant />,
    breadcrumb: 'Labels',
    rights: [Actions.LABEL_LIST_READ],
  },
  {
    path: '/events/labels/:tenantId/create',
    element: <CreateLabelWithTenant />,
    breadcrumb: 'Create',
    rights: [Actions.LABEL_CREATE],
  },
  {
    path: '/events/labels/:tenantId/:labelId/edit',
    element: <EditLabelWithTenant />,
    breadcrumb: 'Edit',
    rights: [Actions.LABEL_UPDATE],
  },
  {
    path: '/events/labels/:tenantId/:labelId',
    element: <ViewLabelWithTenant />,
    breadcrumb: 'View',
    rights: [Actions.LABEL_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const labelCategoryRoutes = [
  {
    path: '/events/label-categories',
    element: <LabelCategoryListViewPage />,
    breadcrumb: 'Label Categories',
    rights: [Actions.LABEL_CATEGORY_LIST_READ],
  },
  {
    path: '/events/label-categories/create',
    element: <CreateLabelCategory />,
    breadcrumb: 'Create',
    rights: [Actions.LABEL_CATEGORY_CREATE],
  },
  {
    path: '/events/label-categories/:id/edit',
    element: <EditLabelCategory />,
    breadcrumb: 'Edit',
    rights: [Actions.LABEL_CATEGORY_UPDATE],
  },
  {
    path: '/events/label-categories/:id',
    element: <ViewLabelCategoryPage />,
    breadcrumb: 'View',
    rights: [Actions.LABEL_CATEGORY_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const triggerRoutes = [
  {
    path: '/events/triggers',
    element: (
      <TenantSelect title="Choose Tenant" subtitle="Select a tenant to manage their triggers" />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.TRIGGER_LIST_READ],
  },
  {
    path: '/events/triggers/:tenantId',
    element: <TriggerListViewWithTenant />,
    caseSensitive: true,
    breadcrumb: 'Triggers',
    rights: [Actions.TRIGGER_LIST_READ],
  },
  {
    path: '/events/triggers/:tenantId/create',
    element: <CreateTriggerWithTenant />,
    caseSensitive: true,
    breadcrumb: 'Create',
    rights: [Actions.TRIGGER_CREATE],
  },
  {
    path: '/events/triggers/:tenantId/:triggerId',
    element: <ViewTriggerWithTenant />,
    breadcrumb: 'View',
    rights: [Actions.TRIGGER_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const sharedTriggerRoutes = [
  {
    path: '/events/shared-triggers',
    element: (
      <TenantSelect
        title="Choose Tenant"
        subtitle="Select a tenant to manage their shared Triggers"
      />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.TRIGGER_SHARE_LIST_READ],
  },
  {
    path: '/events/shared-triggers/:tenantId',
    element: <SharedTriggerListViewWithTenant />,
    breadcrumb: 'Shared Triggers',
    rights: [Actions.TRIGGER_SHARE_LIST_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/events',
    element: <EventsPage />,
    breadcrumb: 'Events',
    rights: [
      Actions.EVENT_LIST_READ_BY_TENANT,
      Actions.TRIGGER_LIST_READ,
      Actions.LABEL_LIST_READ,
      Actions.TRIGGER_SHARE_LIST_READ,
    ],
  },
  ...labelRoutes,
  ...labelCategoryRoutes,
  ...triggerRoutes,
  ...sharedTriggerRoutes,
];
