import BreadcrumbLayout from '../BreadcrumbLayout';
import './index.scss';

export function SidebarPlotLayout(props) {
  const { sidebar, children } = props;

  return (
    <BreadcrumbLayout fluid className="sidebar-plot-layout">
      <div className="d-lg-flex sidebar-plot-body">
        <div className="sidebar-donut-container d-none d-lg-block">{sidebar}</div>
        <div className="flex-fill sidebar-plot-content">{children}</div>
      </div>
    </BreadcrumbLayout>
  );
}
