import { useNavigate } from 'react-router';
import { DataListTable } from '@/web/common/DataListTable';
import { useSelector } from 'react-redux';
import { getProductMap } from '@/state/cache';
import { Link } from 'react-router-dom';

/** @param {{groups: Array.<DeploymentGroup>}} props */
export function GroupTableView(props) {
  const groups = props.groups;
  const navigate = useNavigate();
  const productMap = useSelector(getProductMap);

  return (
    <DataListTable
      data={groups}
      onClick={(item) => navigate(`./${item.groupId}`)}
      headers={[
        {
          key: 'name',
          title: 'Name',
          width: 120,
        },
        {
          title: 'Product',
          width: 150,
          align: 'center',
          disableAction: true,
          builder: ({ productId }) =>
            productMap.hasOwnProperty(productId) && (
              <Link to={`/super-admin/products/${productId}`}>{productMap[productId].name}</Link>
            ),
        },
        {
          key: 'description',
          title: 'Description',
          width: 200,
        },
      ]}
    />
  );
}
