import { getBearerToken } from '@/state/auth';
import { useApiRequest } from '@/utils/register-api';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import qs from 'query-string';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

/**
 * @template T
 * @typedef {import('@/utils/register-api').ApiRequest<T>} ApiRequest<T>
 */

/**
 * @template T
 * @typedef {object} TokenBasedPaginationProps<T>
 * @property {number} [defaultLimit=10] Items per page
 * @property {boolean} [disablePagination=false] Disables the pagination
 * @property {(limit: number, token: string) => ApiRequest.<T>} request
 * @property {(request: ApiRequest.<T>, limit: number) => string} nextToken
 * @property {(request: ApiRequest.<T>) => any} success
 * @property {(request: ApiRequest.<T>) => any} [loading]
 * @property {(request: ApiRequest.<T>) => any} [failure]
 * @property {string} [loadingText] Message to display while loading
 */

/**
 * @template T
 * @param {TokenBasedPaginationProps.<T>} props
 */
export function TokenBasedPagination(props) {
  const location = useLocation();
  const query = qs.parse(location.search);
  const token = query.token || null;
  const pageSize = Number(query.limit) || Number(props.defaultLimit) || 10;
  const prevTokens = query.prev ? (query.prev + '').split(',') : [];

  const bearerToken = useSelector(getBearerToken);
  const request = useApiRequest(
    () => props.request(pageSize, token),
    [bearerToken, pageSize, token]
  );

  useEffect(() => {
    request.reset();
    request.process();
  }, [request]);

  switch (request.status) {
    case 'pending':
    case 'loading':
      return props.loading ? (
        props.loading(request)
      ) : (
        <PageLoadingSpinner status={props.loadingText ?? 'Loading items...'} />
      );
    case 'failure':
      return props.failure ? (
        props.failure(request)
      ) : (
        <PageLoadingFailure message={'Failed to fetch list'}>
          {request.error + ''}
        </PageLoadingFailure>
      );
    case 'success':
      const nextToken = props.nextToken?.(request, pageSize);

      /** @type {import('history').Location} */
      const prevLink = token && {
        pathname: location.pathname,
        search: qs.stringify(
          {
            ...query,
            limit: pageSize,
            token: prevTokens[0] || null,
            prev: prevTokens.slice(1) + '',
          },
          { skipNull: true, skipEmptyString: true }
        ),
      };

      /** @type {import('history').Location} */
      const nextLink = nextToken && {
        pathname: location.pathname,
        search: qs.stringify(
          {
            ...query,
            limit: pageSize,
            token: nextToken || '',
            prev: [token, ...prevTokens].filter((x) => x) + '',
          },
          { skipNull: true, skipEmptyString: true }
        ),
      };

      return (
        <>
          {props.success(request)}
          {!props.disablePagination && (
            <div className="d-flex pt-3 justify-content-end">
              <PaginationButton text="Previous" link={prevLink} />
              <div className="px-1" />
              <PaginationButton text="Next" link={nextLink} />
            </div>
          )}
        </>
      );
    default:
      return null;
  }
}

/**
 * @param {{link: object|string, text: string}} props
 */
function PaginationButton(props) {
  const link = props.link;
  const text = props.text;
  return (
    <Button
      size="sm"
      variant="outline-dark"
      disabled={!link}
      as={link ? Link : null}
      to={link || null}
      style={{ width: 100 }}
    >
      {text}
    </Button>
  );
}
