import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaDesktop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProduct, getTenant } from '@/state/cache';
import { timeFromNow } from '@/utils/formatting';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { GeneralEditForm } from '../GeneralEditForm';
import { USER_ACCESS_DEPLOYMENT_GROUP_UPDATE } from '@/web/common/AccessRights';

/** @param {{group: DeploymentGroup}} props */
export function GeneralView(props) {
  const { group } = props;
  const owner = useSelector(getTenant(group.tenantId));
  const product = useSelector(getProduct(group.productId));
  const [showEdit, setShowEdit] = useState(false);

  return (
    <>
      <BreadcrumbLayout.Header icon={<FaDesktop />} title={<span>General Information</span>}>
        <USER_ACCESS_DEPLOYMENT_GROUP_UPDATE>
          <Button onClick={() => setShowEdit(true)} variant="primary" style={{ width: 132 }}>
            Edit
          </Button>
        </USER_ACCESS_DEPLOYMENT_GROUP_UPDATE>
        {showEdit && <GeneralEditForm group={group} onDone={() => setShowEdit(false)} />}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={group}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'name',
              title: 'Name',
            },
            {
              key: 'description',
              title: 'Description',
            },
            {
              key: 'productId',
              title: 'Product',
              builder: ({ productId }) =>
                product && <Link to={`/super-admin/products/${productId}`}>{product.name}</Link>,
            },
            {
              key: 'tenantId',
              title: 'Owner Tenant',
              builder: ({ tenantId }) =>
                owner && <Link to={`/settings/tenants/${tenantId}`}>{owner.name}</Link>,
            },
            {
              key: 'createdAt',
              title: 'Created',
              builder: ({ createdAt }) => timeFromNow(createdAt),
            },
            {
              key: 'updatedAt',
              title: 'Last Updated',
              builder: ({ updatedAt }) => timeFromNow(updatedAt) || 'never',
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
