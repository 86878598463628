import { useVisibleRoutes } from '@/web/pages/routes';
import { useMemo } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { FaArrowLeft, FaHome } from 'react-icons/fa';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import './index.scss';

/**
 * @typedef {object} BreadcrumbItem
 * @property {string} name The name of the breadcrumb
 * @property {string} pathname The pathname to forward to
 * @property {boolean} active Whether this is the current page
 */

/**
 * Get a list of breadcrumb items to display.
 * To recognize a path as a breadcrumb, it must contain breadcrumb field.
 *
 * @param {Array.<import('@/web/pages/routes').ExtendedRoute>} routes
 * @param {string} currentPathname
 * @returns {Array.<BreadcrumbItem>}
 */
function getBreadcrumbs(routes, currentPathname) {
  let results = [];
  if (!currentPathname || currentPathname === '/') {
    return results;
  }

  let path = '';
  for (const part of currentPathname.substring(1).split('/')) {
    path += '/' + part;
    const matches = matchRoutes(routes, path);
    if (!matches?.length) continue;
    const match = matches[matches.length - 1];

    /** @type {import('@/web/pages/routes').ExtendedRoute} */
    const route = match.route;

    let name = '';
    if (typeof route.breadcrumb === 'function') {
      name = route.breadcrumb();
    } else if (route.breadcrumb) {
      name = '' + route.breadcrumb;
    }
    if (!name) continue;

    /** @type {BreadcrumbItem} */
    const breadcrumb = {
      name,
      pathname: match.pathname,
      active: match.pathname === currentPathname,
    };
    results.push(breadcrumb);
  }
  return results;
}

export function PageBreadcrumb(props) {
  const { activeTitle, ...otherProps } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useVisibleRoutes();

  /** @type {Array.<BreadcrumbItem>} */
  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs(routes, location.pathname);
  }, [location.pathname, routes]);

  /** @param {Event} e */
  const handleGoBack = (e) => {
    navigate(-1);
  };

  return (
    <Breadcrumb {...otherProps} className={`page-breadcrumb ${otherProps.className || ''}`}>
      <Breadcrumb.Item onClick={handleGoBack} title="Back">
        <FaArrowLeft />
      </Breadcrumb.Item>
      <Breadcrumb.Item
        title="Home"
        linkAs={Link}
        linkProps={{ to: '/', state: { breadcrumb: true } }}
      >
        <FaHome />
      </Breadcrumb.Item>
      {breadcrumbs.map(({ name, pathname, active }) => (
        <Breadcrumb.Item
          key={pathname}
          active={active}
          linkAs={Link}
          linkProps={{ to: pathname, state: { breadcrumb: true } }}
        >
          {(active && activeTitle) || name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
