import { fetchAllLabelsByTenant } from '@/state/cache';
import { fetchAllLabelCategories } from '@/state/cache/fetchers/label-categories';
import { fetchAllTriggersByTenant } from '@/state/cache/fetchers/triggers';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { displayError } from '@/utils/toaster';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Navigate, useParams } from 'react-router';
import { CreateTriggerForm } from './@components/CreateTriggerForm';

export function CreateTriggerWithTenant() {
  const { tenantId } = useParams();

  useDispatchLoader(fetchAllLabelCategories);
  useDispatchLoader(() => fetchAllLabelsByTenant(tenantId), [tenantId]);
  useDispatchLoader(() => fetchAllTriggersByTenant(tenantId), [tenantId]);

  const [loading, error] = useDispatchLoader(fetchAllLabelCategories);

  if (loading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <PageLoadingSpinner status={'Loading labels...'} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <CreateTriggerForm />
    </BreadcrumbLayout>
  );
}
