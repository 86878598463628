import api from '@/api';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { getBearerToken } from '@/state/auth';
import { useApiRequest } from '@/utils/register-api';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { EndUserView } from './@components/EndUserView';
import { EndUserViewPlaceholder } from './@components/EndUserViewPlaceholder';

export function ViewEndUser() {
  const { tenantId, userId } = useParams();

  const bearerToken = useSelector(getBearerToken);
  const request = useApiRequest(
    () => api.sai.v1.tenant.$tenantId(tenantId)['end-user'].$userId(userId).$get(),
    [bearerToken, tenantId, userId]
  );

  useEffect(() => {
    request.reset();
    request.process().catch(() => {});
  }, [request]);

  switch (request.status) {
    case 'loading':
    case 'pending':
      return (
        <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
          <EndUserViewPlaceholder />
        </BreadcrumbLayout>
      );
    case 'failure':
      return <Navigate replace to="./../.." />;
    case 'success':
      const user = request.result.result;
      return (
        <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
          <EndUserView user={user} />
        </BreadcrumbLayout>
      );
    default:
      return <div className="text-center">Unknown request status</div>;
  }
}
