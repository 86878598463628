import api from '@/api';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getBearerToken } from '@/state/auth';
import { fetchAllAiAccelerators, fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';

export const AiModelContext = createContext();

export function AiModelViewLayout(props) {
  const { modelId } = useParams();

  const [id, setId] = useState(null);
  const updateItem = () => {
    setId(Date.now());
  };

  useDispatchLoader(fetchAllTenants);
  useDispatchLoader(fetchAllAiAccelerators);

  const bearerToken = useSelector(getBearerToken);
  const [item, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['ai-model'].$modelId(modelId).$get();
    await request.process();
    return request.result.result;
  }, [id, modelId, bearerToken]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading AI Model...'} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    return (
      <BreadcrumbLayout>
        <PageLoadingFailure message={'Failed to load AI Model'}>{error + ''}</PageLoadingFailure>
      </BreadcrumbLayout>
    );
  }

  return (
    <BreadcrumbLayout title={item.name} subtitle={item.description}>
      <AiModelContext.Provider value={{ item, updateItem }}>
        {props.children}
      </AiModelContext.Provider>
    </BreadcrumbLayout>
  );
}
