import { AuthSlice } from './auth-slice';

export const { setAvatar, setLoginData, setUser, setTenantId } = AuthSlice.actions;

export * from './auth-slice';
export * from './do-login';
export * from './do-logout';
export * from './fetch-user-data';
export * from './persistor';
export * from './selectors';

/**
 * @typedef {import('./auth-slice').InitialState & import('redux-persist').PersistedState} AuthState
 */
