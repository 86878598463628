import storage from 'redux-persist/lib/storage';

/** @type {import('redux-persist').PersistConfig} */
export const chartPersistConfig = {
  key: 'chart',
  version: 1,
  storage,
  whitelist: [
    // add state items you want to persist in local storage
    'data',
    // 'expiry',
  ],
  // migrate: async (state, version) => {
  //   switch (version) {
  //     default:
  //       return state;
  //   }
  // },
};
