import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';

/** @param {{item: Product, disabled: boolean}} props */
export function DeleteProductButton(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const item = props.item;

  const deleteProduct = async () => {
    const request = api.sai.v1['product'].$productId(item.productId).$delete();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.info({
        title: 'Success',
        message: `Deleted Product <b>${item.name}</b>`,
      });
      navigate({ pathname: './../..' });
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      variant="danger"
      style={{ width: 132 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: deleteProduct,
          title: `Delete ${item.name}?`,
          message: 'It will affect all resources using this Product',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
