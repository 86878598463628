import api from '@/api';
import { getLabelCategoryMap, getLabelsByTenant } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

/** @param {{status: string?}} props */
export function LabelListView(props) {
  const { status } = props;
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const labelMap = useSelector(getLabelsByTenant(tenantId));
  const labelCategoryMap = useSelector(getLabelCategoryMap);

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (pageSize, continuationToken) => {
        const request = api.sai.v1['label'].$get({
          params: {
            pageSize,
            continuationToken,
            tenantId,
            status,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={(item) => navigate(`./${item.labelId}`)}
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'code',
          minWidth: 150,
        },
        {
          key: 'labelType',
          minWidth: 150,
        },
        {
          key: 'aliasedLabelId',
          title: 'Alias Label',
          minWidth: 150,
          disableAction: true,
          builder: ({ aliasedLabelId }) =>
            labelMap &&
            labelMap[aliasedLabelId] && (
              <Link to={`/events/labels/${tenantId}/${aliasedLabelId}`}>
                {labelMap[aliasedLabelId].name}
              </Link>
            ),
        },
        {
          key: 'status',
          width: 125,
          title: 'Status',
          align: 'center',
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          key: 'source',
          minWidth: 150,
          disableAction: true,
          title: 'Label Category',
          builder: ({ source }) =>
            labelCategoryMap &&
            labelCategoryMap[source] && (
              <Link to={`/events/label-categories/${source}`}>
                {' '}
                {labelCategoryMap[source].name}
              </Link>
            ),
        },
        {
          key: 'minValue',
          minWidth: 150,
        },
        {
          key: 'maxValue',
          minWidth: 150,
        },
        {
          key: 'unit',
          minWidth: 150,
        },
        {
          key: 'description',
          minWidth: 200,
        },
      ]}
    />
  );
}
