import { fetchAllProducts } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { USER_ACCESS_DEVICE_POOL_CREATE } from '@/web/common/AccessRights';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AdminDonutGroup } from '../../@components/AdminDonutGroup';
import { DevicePoolListView } from './@components/DevicePoolListView';

export function DevicePoolListViewWithTenant() {
  useDispatchLoader(fetchAllProducts);

  return (
    <SidebarPlotLayout sidebar={<AdminDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Device Pools">
        <USER_ACCESS_DEVICE_POOL_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> Device Pool
          </Button>
        </USER_ACCESS_DEVICE_POOL_CREATE>
      </BreadcrumbLayout.Header>
      <DevicePoolListView />
    </SidebarPlotLayout>
  );
}
