import { InputSlider } from '@/web/common/forms/InputSlider';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';
import { parkingModes } from '@/assets/group-settings/autocomplete';
import { SelectBox } from '@/web/common/forms/SelectBox';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Parking(props) {
  const { loadingOnUpdate, settings, defaultSettings } = props;
  const { updateSettings } = useContext(SettingsContext);

  const [parkingMode, setParkingMode] = useState(
    settings.parkingMode ?? defaultSettings.parkingMode
  );
  const [parkingDelay, setParkingDelay] = useState(
    settings.parkingDelay ?? defaultSettings.parkingDelay
  );
  const [wakeUpRecordingLength, setWakeupRecordingLength] = useState(
    settings.wakeUpRecordingLength ?? defaultSettings.wakeUpRecordingLength
  );
  const [wakeUpThroughAccelerometer, setWakeUpThroughAccelerometer] = useState(
    settings.wakeUpThroughAccelerometer ?? defaultSettings.wakeUpThroughAccelerometer
  );

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = {
      parkingMode,
      parkingDelay,
      wakeUpRecordingLength,
      wakeUpThroughAccelerometer,
    };
    updateSettings(data);
  };

  return (
    <>
      <Card.Body className="mt-2">
        <Row>
          <Col lg={4}>
            <SelectBox
              label="Parking mode"
              value={parkingMode}
              onChange={setParkingMode}
              options={Object.entries(parkingModes || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={0}
              max={30}
              label="Delay"
              unit={'min'}
              value={parkingDelay}
              onChange={setParkingDelay}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={0}
              max={100}
              label="Wake up recording length"
              unit={'sec'}
              value={wakeUpRecordingLength}
              onChange={setWakeupRecordingLength}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={0}
              max={3}
              step={0.1}
              label="Wake up through accelerometer"
              unit={'G'}
              value={wakeUpThroughAccelerometer}
              onChange={setWakeUpThroughAccelerometer}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
