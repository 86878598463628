import api from '@/api';
import { getProductMap } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { Button } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { DeployFirmwareButton } from '../../../../@components/DeployFirmwareButton';

export function FirmwareListView(props) {
  const { tenantId } = useParams();
  const { status } = props;
  const [searchParams] = useSearchParams();
  const productId = searchParams?.get('productId');
  const navigate = useNavigate();
  const productMap = useSelector(getProductMap);

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (pageSize, continuationToken) => {
        const request = api.sai.v1['firmware'].$get({
          params: {
            pageSize,
            continuationToken,
            tenantId,
            productId,
            status,
          },
        });
        await request.process();
        return {
          results: request.result.result.results.map((item) => ({
            ...item,
            productId: item.productIds?.[0] || '',
          })),
          token: request.result.result.continuationToken,
        };
      }}
      onClick={(item) => navigate(`./${item.firmwareId}`)}
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'version',
          align: 'center',
          width: 100,
        },
        {
          key: 'type',
          minWidth: 100,
          align: 'center',
        },
        {
          key: 'status',
          width: 125,
          title: 'Status',
          align: 'center',
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          key: 'productId',
          title: 'Product',
          minWidth: 200,
          disableAction: true,
          builder: ({ productId }) =>
            productMap &&
            productMap[productId] && (
              <Link to={`/super-admin/products/${productId}`}>{productMap[productId].name}</Link>
            ),
        },
        {
          key: 'description',
          minWidth: 200,
        },
        {
          title: '',
          disableAction: true,
          width: 130,
          builder: (item) =>
            item.packageDetails && (
              <Button
                size="sm"
                type="button"
                style={{ width: 120 }}
                variant="outline-primary"
                as="a"
                target="_blank"
                rel="noreferrer"
                href={item.packageDetails.downloadUrl}
              >
                <FaDownload /> Download
              </Button>
            ),
        },
        {
          title: '',
          disableAction: true,
          width: 130,
          builder: (item) =>
            item.status === 'DISCONTINUED' ? null : <DeployFirmwareButton item={item} />,
        },
      ]}
    />
  );
}
