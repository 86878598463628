import {
  USER_ACCESS_LABEL_CATEGORY_LIST_READ,
  USER_ACCESS_LABEL_LIST_READ,
  USER_ACCESS_TRIGGER_LIST_READ,
  USER_ACCESS_TRIGGER_SHARE_LIST_READ,
} from '@/web/common/AccessRights';
import { LabelCategoriesDonut } from '../LabelCategoriesDonut';
import { LabelsDonut } from '../LabelsDonut';
import { SharedTriggersDonut } from '../SharedTriggersDonut';
import { TriggersDonut } from '../TriggersDonut';

export function EventDonutGroup(props) {
  return (
    <>
      <USER_ACCESS_LABEL_LIST_READ>
        <LabelsDonut {...props} />
      </USER_ACCESS_LABEL_LIST_READ>
      <USER_ACCESS_TRIGGER_LIST_READ>
        <TriggersDonut {...props} />
      </USER_ACCESS_TRIGGER_LIST_READ>
      <USER_ACCESS_TRIGGER_SHARE_LIST_READ>
        <SharedTriggersDonut {...props} />
      </USER_ACCESS_TRIGGER_SHARE_LIST_READ>
      <USER_ACCESS_LABEL_CATEGORY_LIST_READ>
        <LabelCategoriesDonut {...props} />
      </USER_ACCESS_LABEL_CATEGORY_LIST_READ>
    </>
  );
}
