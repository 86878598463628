import { getBearerToken } from '@/state/auth';
import { useApiRequest } from '@/utils/register-api';
import { displayError } from '@/utils/toaster';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import qs from 'query-string';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

/**
 * @template T
 * @typedef {object} OffsetBasedPaginationProps<T>
 * @property {number} [defaultLimit=10] Items per page
 * @property {boolean} [disablePagination=false] Disables the pagination
 * @property {(limit, offset, bearerToken) => ApiRequest<T>} request
 * @property {(request: ApiRequest<T>) => number} resultLength
 * @property {(request: ApiRequest<T>) => import('react').Component} success
 * @property {(request: ApiRequest<T>) => import('react').Component} [loading]
 * @property {(request: ApiRequest<T>) => import('react').Component} [failure]
 * @property {string} [loadingText] Message to display while loading
 * @property {string} [emptyListMessage] Messsage to display when result is empty
 */

/**
 * @template T
 * @param {OffsetBasedPaginationProps<T>} props
 */
export function OffsetBasedPagination(props) {
  const location = useLocation();
  const query = qs.parse(location.search);
  const limit = Number(query.limit) || Number(props.defaultLimit) || 10;
  const offset = Number(query.offset) || 0;

  const bearerToken = useSelector(getBearerToken);
  const request = useApiRequest(
    () => props.request(limit, offset, bearerToken),
    [bearerToken, offset, limit]
  );

  useEffect(() => {
    request.process().catch(displayError);
  }, [request]);

  switch (request.status) {
    case 'pending':
    case 'loading':
      return props.loading ? (
        props.loading(request)
      ) : (
        <PageLoadingSpinner status={props.loadingText ?? 'Loading items...'} />
      );
    case 'failure':
      return props.failure ? (
        props.failure(request)
      ) : (
        <PageLoadingFailure message="Failed to fetch list">{request.error + ''}</PageLoadingFailure>
      );
    case 'success':
      const resultLength = props.resultLength(request);

      /** @type {import('history').Location} */
      const prevLink = offset - limit >= 0 && {
        pathname: location.pathname,
        search: qs.stringify({
          ...query,
          limit,
          offset: offset - limit,
        }),
      };

      /** @type {import('history').Location} */
      const nextLink = resultLength >= limit && {
        pathname: location.pathname,
        search: qs.stringify({
          ...query,
          limit,
          offset: offset + limit,
        }),
      };

      return (
        <>
          {props.success(request)}
          {!props.disablePagination && (
            <div className="d-flex pt-3 justify-content-end">
              <PaginationButton text="Previous" link={prevLink} />
              <div className="px-1" />
              <PaginationButton text="Next" link={nextLink} />
            </div>
          )}
        </>
      );
    default:
      return null;
  }
}

/**
 * @param {{link: object|string, text: string}} props
 */
function PaginationButton(props) {
  const link = props.link;
  const text = props.text;
  return (
    <Button
      size="sm"
      variant="outline-dark"
      disabled={!link}
      as={link ? Link : null}
      to={link || null}
      style={{ width: 100 }}
    >
      {text}
    </Button>
  );
}
