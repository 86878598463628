import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FirmwareContext } from './../../';

/** @param {{item: Firmware, disabled: boolean}} props */
export function PublishFirmwareButton(props) {
  const { item } = props;

  const [loading, setLoading] = useState(false);
  const updateParent = useContext(FirmwareContext);

  const publishFirmware = async () => {
    const request = api.sai.v1.firmware.$firmwareId(item.firmwareId)[':publish'].$post();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Published Firmware <b>${item.name}</b>`,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      size={'sm'}
      variant="outline-primary"
      style={{ width: 125 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: publishFirmware,
          title: `Publish ${item.name} (${item.version})?`,
          message: 'It will affect all resources using this Firmware',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Publish'}
    </Button>
  );
}
