import { Link } from 'react-router-dom';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { USER_ACCESS_END_USER_UPDATE } from '@/web/common/AccessRights';

/** @param {{user: EndUser }} props */
export function EndUserView(props) {
  const { user } = props;
  const { username, name, picture } = user;

  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">
          <b>{user.email}</b> / <code>{user.username}</code>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <ListGroup>
          <ListGroup.Item>
            <b>Username:</b> {username}
          </ListGroup.Item>
          <ListGroup.Item>
            <b>Full Name:</b> {name}
          </ListGroup.Item>
          <ListGroup.Item>
            <b>Picture URL:</b> {picture}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
      <Card.Footer className="d-flex align-items-center justify-content-end">
        <USER_ACCESS_END_USER_UPDATE>
          <Button as={Link} to="./edit" variant="primary" style={{ width: 132 }}>
            Edit
          </Button>
        </USER_ACCESS_END_USER_UPDATE>
      </Card.Footer>
    </Card>
  );
}
