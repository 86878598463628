import { getBearerToken } from '@/state/auth';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { DataListTable } from '../../DataListTable';
import './index.scss';

/**
 * @template T
 * @typedef {object} ApiResult<T>
 * @property {string} token
 * @property {Array<T>} results
 */

/**
 * @template T
 * @typedef {object} TokenBasedInfiniteScrollTableBaseProps<T>
 * @property {number} [defaultLimit] Items per page
 * @property {string} [loadingText] Message to display while loading
 * @property {(limit: number, token: string) => Promise<ApiResult<T>>} fetcher
 */

/**
 * @template T
 * @typedef {TokenBasedInfiniteScrollTableBaseProps<T> & import('../../DataListTable').DataListTableProps.<T> & import('react-bootstrap').TableProps } TokenBasedInfiniteScrollTableProps<T>
 */

/**
 * @template T
 * @param {TokenBasedInfiniteScrollTableProps.<T>} props
 */
export function TokenBasedInfiniteScrollTable(props) {
  const { defaultLimit, fetcher, loadingText, children, ...tableProps } = props;
  const pageSize = Number(props.defaultLimit) || 10;
  const bearerToken = useSelector(getBearerToken);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [token, setToken] = useState();
  const [hasNext, setHasNext] = useState(true);
  /** @type {[Array<T>, (v: Array<T>) => any]} */
  const [results, setResults] = useState([]);

  const makeRequest = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await fetcher(pageSize, token);
      setLoading(false);
      setResults([...results, ...data.results]);
      setHasNext(Boolean(data.token));
      setToken(data.token);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError(err);
    }
  };

  const { ref, inView } = useInView({
    delay: 100,
    threshold: 0.2,
    initialInView: true,
  });

  if (error) {
    return (
      <PageLoadingFailure message={'Failed to fetch list'}>
        <pre className="p-2">{error + ''}</pre>
        <Button variant="outline-secondary" onClick={makeRequest}>
          Retry
        </Button>
      </PageLoadingFailure>
    );
  }

  return (
    <DataListTable {...tableProps} data={results} key={bearerToken}>
      {children}
      <tr ref={ref} className="token-based-infinite-scroll-status-row">
        {loading ? (
          <td colSpan={100}>
            <PageLoadingSpinner borderless status={loadingText ?? 'Loading items...'} />
          </td>
        ) : inView && hasNext ? (
          <td colSpan={100} style={{ opacity: 0 }}>
            <img src="#" onError={makeRequest} alt="for next data" />
          </td>
        ) : error ? (
          <td colSpan={100}>
            <PageLoadingFailure borderless message="Failed to load items">
              {error}
            </PageLoadingFailure>
          </td>
        ) : !results?.length ? (
          <td colSpan={100}>
            <PageLoadingFailure borderless message="No items" />
          </td>
        ) : (
          <td style={{ padding: 0, opacity: 0 }} />
        )}
      </tr>
    </DataListTable>
  );
}
