import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { fetchAllDevicePoolsByTenant, getDevicePoolsByTenantAndProduct } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { displayError } from '@/utils/toaster';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

/** @param {{item: Firmware, disabled: boolean}} props */
export function DeployFirmwareDevicePoolForm(props) {
  const { item, isPoolFixed } = props;
  const { tenantId } = useParams();

  const [loading, setLoading] = useState(false);
  const [poolId, setPoolId] = useState(props.poolId || '');

  const bearerToken = useSelector(getBearerToken);

  const [devicePoolsLoading, devicePoolsError] = useDispatchLoader(
    () => fetchAllDevicePoolsByTenant(tenantId),
    [tenantId, bearerToken]
  );
  const devicePools = useSelector(getDevicePoolsByTenantAndProduct(tenantId, item.productIds?.[0]));

  const deployFirmware = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const request = api.sai.v1['device-pool'].$poolId(poolId)['firmware-deployment'].$post({
      data: {
        firmwareId: item.firmwareId,
        firmwareType: item.type,
      },
    });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Deployed Firmware <b>${item.name}</b>`,
      });
      props.onClose();
    } catch (err) {
      setLoading(false);
      displayError(request?.error || err);
    }
  };

  return (
    <Form onSubmit={deployFirmware}>
      <Modal.Body>
        {devicePoolsLoading ? (
          <PageLoadingSpinner status="Loading device pools..." className="py-2" />
        ) : devicePoolsError ? (
          <PageLoadingFailure message="Failed to load device pools" className="py-2">
            {devicePoolsError + ''}
          </PageLoadingFailure>
        ) : (
          <SelectBox
            label="Device Pool"
            placeholder="Select Device Pool"
            value={poolId}
            disabled={isPoolFixed}
            onChange={setPoolId}
            options={devicePools}
            itemValue="poolId"
            itemText="name"
            required
          />
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          type="submit"
          variant="primary"
          disabled={loading || !devicePools}
          style={{ width: 100 }}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Deploy'}
        </Button>
      </Modal.Footer>
    </Form>
  );
}
