import api from '@/api';
import iziToast from 'izitoast';
import difference from 'lodash/difference';
import { useContext, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { EditTenantContext } from '../..';
import { EditDomainList } from '../EditDomainList';

/** @param {{tenant: Tenant}} props */
export function EditTenantFormRegistrationSetting(props) {
  const [tenant, setTenant] = useState(props.tenant);
  const updateParent = useContext(EditTenantContext);
  const [loading, setLoading] = useState(false);
  const [allowedEmailDomains, setAllowedEmailDomains] = useState(tenant.allowedEmailDomains);
  const [allowOpenRegistration, setAllowOpenRegistration] = useState(tenant.allowOpenRegistration);

  /**
   * The domains that exists in `allowedEmailDomains`,
   * but not in `tenant.allowedEmailDomains`
   *
   * @type {Array.<string> }
   */
  const addedAllowedEmails = difference(allowedEmailDomains, tenant.allowedEmailDomains);

  /**
   * The domains that exists in `tenant.allowedEmailDomains`,
   * but not in `allowedEmailDomains`
   *
   * @type {Array.<string> }
   */
  const removedAllowedEmails = difference(tenant.allowedEmailDomains, allowedEmailDomains);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const request = api.sai.v1.tenant.$tenantId(tenant.tenantId)['registration-setting'].$patch({
        data: {
          addedAllowedEmails,
          removedAllowedEmails,
          allowOpenRegistration,
        },
      });
      await request.process();
      iziToast.info({ title: 'Tenant updated successfully' });
      setLoading(false);
      setTenant({
        ...tenant,
        addedAllowedEmails,
        allowedEmailDomains,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      console.error(err);
      iziToast.error({ title: 'Error', message: 'Failed to update tenant' });
    }
  };

  return (
    <>
      <Card.Body>
        <Form.Check
          type="switch"
          label="Allow anyone to register"
          checked={allowOpenRegistration}
          onChange={(e) => setAllowOpenRegistration(e.currentTarget.checked)}
          className="mb-2 py-2"
        />

        <EditDomainList
          adding={addedAllowedEmails}
          removing={removedAllowedEmails}
          domains={allowedEmailDomains}
          setDomains={setAllowedEmailDomains}
        />
      </Card.Body>

      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button variant="primary" style={{ width: 225 }} disabled={loading} onClick={handleSubmit}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Save Registration Setting'}
        </Button>
      </Card.Footer>
    </>
  );
}
