import { Fragment } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';

/**
 * @param {string} currentPath
 * @param {string} menuPath
 */
function isLinkActive(currentPath, menuPath) {
  return currentPath.startsWith(menuPath);
}

/**
 * @param {{menu: import('../menus').DropdownMenuItem}} props
 */
export function DropdownMenuItem(props) {
  const location = useLocation();

  const menu = props.menu;
  switch (menu.type) {
    case 'link':
      return (
        <menu.access>
          <NavDropdown.Item
            as={Link}
            to={menu.to}
            active={isLinkActive(location.pathname, menu.to)}
          >
            {menu.text}
          </NavDropdown.Item>
        </menu.access>
      );
    case 'divider':
      return <NavDropdown.Divider />;
    default:
      return <Fragment />;
  }
}

/**
 * @param {{menu: import('../menus').MainMenuItem}} props
 */
export function MainMenuItem(props) {
  const location = useLocation();

  const menu = props.menu;
  switch (menu.type) {
    case 'link':
      return (
        <menu.access>
          <Nav.Item>
            <Nav.Link to={menu.to} as={Link} active={isLinkActive(location.pathname, menu.to)}>
              {menu.text}
            </Nav.Link>
          </Nav.Item>
        </menu.access>
      );
    case 'dropdown':
      const active = menu.children.find(
        (item) => item.type === 'link' && isLinkActive(location.pathname, item.to)
      );
      return (
        <menu.access>
          <NavDropdown title={menu.text} active={active}>
            {menu.children.map((item, index) => (
              <DropdownMenuItem key={props.index + '.' + index} menu={item} />
            ))}
          </NavDropdown>
        </menu.access>
      );
    default:
      return <Fragment />;
  }
}
