import { streamingCodecs, streamingVideoResolutions } from '@/assets/ai-models/autocomplete';
import { CheckBox } from '@/web/common/forms/CheckBox';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Streaming(props) {
  const { updateSettings } = useContext(SettingsContext);
  const { settings, defaultSettings, loadingOnUpdate } = props;

  const [shouldTakeStreamingSettingsFromModel, setShouldTakeStreamingSettingsFromModel] = useState(
    settings.shouldTakeStreamingSettingsFromModel ??
      defaultSettings.shouldTakeStreamingSettingsFromModel
  );
  const [endToEndEncryption, setEndToEndEncryption] = useState(
    settings.endToEndEncryption ?? defaultSettings.endToEndEncryption
  );
  const [streamingQualityProfile, setStreamingQualityProfile] = useState(
    settings.streamingQualityProfile ?? defaultSettings.streamingQualityProfile
  );
  const [maximumStreamingDuration, setMaximumStreamingDuration] = useState(
    settings.maximumStreamingDuration ?? defaultSettings.maximumStreamingDuration
  );
  const [streamingResolution, setStreamingResolution] = useState(
    settings.streamingResolution ?? defaultSettings.streamingResolution
  );
  const [streamingVideoCodec, setStreamingVideoCodec] = useState(
    settings.streamingVideoCodec ?? defaultSettings.streamingVideoCodec
  );
  const [streamingFrameRateMax, setStreamingFrameRateMax] = useState(
    settings.streamingFrameRateMax ?? defaultSettings.streamingFrameRateMax
  );
  const [streamingFrameRateMin, setStreamingFrameRateMin] = useState(
    settings.streamingFrameRateMin ?? defaultSettings.streamingFrameRateMin
  );
  const [streamingFrameRateValue, setStreamingFrameRateValue] = useState(
    settings.streamingFrameRateValue ?? defaultSettings.streamingFrameRateValue
  );
  const [streamingBitrateMax, setStreamingBitrateMax] = useState(
    settings.streamingBitrateMax ?? defaultSettings.streamingBitrateMax
  );
  const [streamingBitrateMin, setStreamingBitrateMin] = useState(
    settings.streamingBitrateMin ?? defaultSettings.streamingBitrateMin
  );
  const [streamingBitrateValue, setStreamingBitrateValue] = useState(
    settings.streamingBitrateValue ?? defaultSettings.streamingBitrateValue
  );
  const [abtOnFramerateEnabled, setabtOnFramerateEnabled] = useState(
    settings.abtOnFramerateEnabled ?? defaultSettings.abtOnFramerateEnabled
  );
  const [abtOnBitrateEnabled, setAbtOnBitrateEnabled] = useState(
    settings.abtOnBitrateEnabled ?? defaultSettings.abtOnBitrateEnabled
  );

  const showAbtBitrate = !!streamingQualityProfile;
  const showAbtFramerate = !!streamingQualityProfile;

  const isDynamicFramerateEnabled = streamingQualityProfile && abtOnFramerateEnabled;
  const isDynamicBitrateEnabled = streamingQualityProfile && abtOnBitrateEnabled;

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = {
      shouldTakeStreamingSettingsFromModel,
      endToEndEncryption,
      streamingQualityProfile,
      maximumStreamingDuration,
      streamingResolution,
      streamingVideoCodec,
      streamingFrameRateMax,
      streamingFrameRateMin,
      streamingFrameRateValue,
      streamingBitrateMax,
      streamingBitrateMin,
      streamingBitrateValue,
      abtOnFramerateEnabled,
      abtOnBitrateEnabled,
    };
    updateSettings(data);
  };

  const renderFramerateForm = (isDynamicFramerateEnabled) => {
    return (
      <>
        {isDynamicFramerateEnabled ? (
          <Row>
            <Col lg={4}>
              <InputBox
                label="Min Frame Rate (Fps)"
                type="number"
                value={streamingFrameRateMin}
                required
                onChange={setStreamingFrameRateMin}
              />
            </Col>
            <Col lg={4}>
              <InputBox
                label=" Max Frame Rate (Fps)"
                type="number"
                value={streamingFrameRateMax}
                required
                onChange={setStreamingFrameRateMax}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={4}>
              <InputBox
                label="Frame Rate (Fps)"
                type="number"
                value={streamingFrameRateValue}
                required
                onChange={setStreamingFrameRateValue}
              />
            </Col>
          </Row>
        )}
      </>
    );
  };

  const renderBitrateForm = (isDynamicBitrateEnabled) => {
    return (
      <>
        {isDynamicBitrateEnabled ? (
          <Row>
            <Col lg={4}>
              <InputBox
                label="Min Bitrate (Kbps)"
                type="number"
                value={streamingBitrateMin}
                required
                onChange={setStreamingBitrateMin}
              />
            </Col>
            <Col lg={4}>
              <InputBox
                label=" Max Bitrate (Kbps)"
                type="number"
                value={streamingBitrateMax}
                required
                onChange={setStreamingBitrateMax}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={4}>
              <InputBox
                label="Bitrate (Kbps)"
                type="number"
                value={streamingBitrateValue}
                required
                onChange={setStreamingBitrateValue}
              />
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col lg={4} className="my-auto">
            <CheckBox
              type="switch"
              label="Override config from deployed AI Models"
              checked={shouldTakeStreamingSettingsFromModel}
              onChange={setShouldTakeStreamingSettingsFromModel}
            />
          </Col>
          <Col lg={4} className="my-auto">
            <CheckBox
              type="switch"
              label="End-to-end encryption"
              checked={endToEndEncryption}
              onChange={setEndToEndEncryption}
            />
          </Col>
          <Col lg={4} className="my-auto">
            <CheckBox
              type="switch"
              label="Adaptive Streaming Quality"
              checked={streamingQualityProfile}
              onChange={setStreamingQualityProfile}
            />
          </Col>
          <Col lg={4}>
            <InputBox
              label="Maximum streaming duration (Sec)"
              type="number"
              value={maximumStreamingDuration}
              required
              onChange={setMaximumStreamingDuration}
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Resolution"
              value={streamingResolution}
              onChange={setStreamingResolution}
              options={Object.entries(streamingVideoResolutions || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Video Codec"
              value={streamingVideoCodec}
              onChange={setStreamingVideoCodec}
              options={Object.entries(streamingCodecs || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
        </Row>
        {showAbtFramerate && (
          <Row>
            <Col lg={4}>
              <CheckBox
                type="checkbox"
                label="Frame Rate"
                checked={abtOnFramerateEnabled}
                onChange={setabtOnFramerateEnabled}
              />
            </Col>
          </Row>
        )}
        {renderFramerateForm(isDynamicFramerateEnabled)}
        {showAbtBitrate && (
          <Row>
            <Col lg={4}>
              <CheckBox
                type="checkbox"
                label="Bitrate"
                checked={abtOnBitrateEnabled}
                onChange={setAbtOnBitrateEnabled}
              />
            </Col>
          </Row>
        )}
        {renderBitrateForm(isDynamicBitrateEnabled)}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
