import { BrowserRouter } from 'react-router-dom';
import { Pages } from './pages';
import './App.scss';

export function App() {
  return (
    <BrowserRouter>
      <Pages />
    </BrowserRouter>
  );
}
