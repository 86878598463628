import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaCogs, FaPlus } from 'react-icons/fa';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { ContainerComponentForm } from '../ContainerComponentForm';
import { ComponentItem } from './ComponentItem';
import { ComponentItemDetails } from './ComponentItemDetails';
import './index.scss';
import { USER_ACCESS_AI_CONTAINER_UPDATE } from '@/web/common/AccessRights';

/** @param {{item: AiContainer}} props */
export function ContainerComponentListView(props) {
  const { item } = props;
  const [add, setAdd] = useState(false);
  const canAddComponent = item.status === 'DRAFT';

  return (
    <>
      <BreadcrumbLayout.Header icon={<FaCogs />} title={<span className="fs-4">Components</span>}>
        {canAddComponent && (
          <USER_ACCESS_AI_CONTAINER_UPDATE>
            <Button onClick={() => setAdd(true)} variant="outline-primary">
              <FaPlus /> New Component
            </Button>
          </USER_ACCESS_AI_CONTAINER_UPDATE>
        )}
        {add && <ContainerComponentForm item={item} onDone={() => setAdd(false)} />}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        {!item.components || !item.components.length ? (
          <PageLoadingFailure message="No components is added to this AI container" />
        ) : (
          <div className="container-component-list-view border rounded-3 p-lg-3">
            <TabsNavigation
              tabs={item.components.map((el, index) => ({
                key: index,
                title: <ComponentItem component={el} />,
                content: <ComponentItemDetails item={item} index={index} />,
              }))}
            />
          </div>
        )}
      </BreadcrumbLayout.Body>
    </>
  );
}
