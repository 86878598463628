import { CacheSlice } from './cache-slice';

export const {
  // update this entries after you have added new actions
  setAccelerators,
  setAccessRights,
  setAiModels,
  setProducts,
  setRoles,
  setTenant,
  setTenants,
  setGroups,
  setDevicePools,
  setLabels,
  setLabelCategories,
  setTriggers,
} = CacheSlice.actions;

export * from './cache-slice';
export * from './selectors';
export * from './persistor';
export * from './fetchers/access-rights';
export * from './fetchers/ai-accelerators';
export * from './fetchers/ai-models';
export * from './fetchers/products';
export * from './fetchers/roles';
export * from './fetchers/tenants';
export * from './fetchers/groups';
export * from './fetchers/device-pools';
export * from './fetchers/labels';
export * from './fetchers/label-categories';

/**
 * @typedef {import('./cache-slice').InitialState & import('redux-persist').PersistedState} CacheState
 */
