import api from '@/api';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBearerToken } from '@/state/auth';
import { fetchAllProducts, fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';

export const DevicePoolViewContext = createContext();

export function DevicePoolViewLayout(props) {
  const { poolId } = useParams();

  const [id, setId] = useState();
  const updateDevicePool = () => {
    setId(Date.now());
  };

  useDispatchLoader(fetchAllTenants);
  useDispatchLoader(fetchAllProducts);

  const bearerToken = useSelector(getBearerToken);
  const [devicePool, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['device-pool'].$poolId(poolId).$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, poolId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading device pool...'} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    return (
      <BreadcrumbLayout>
        <PageLoadingFailure message={'Failed to load device pool'}>{error + ''}</PageLoadingFailure>
      </BreadcrumbLayout>
    );
  }

  return (
    <BreadcrumbLayout title={devicePool.name} subtitle={devicePool.description}>
      <DevicePoolViewContext.Provider value={{ devicePool, updateDevicePool }}>
        {props.children}
      </DevicePoolViewContext.Provider>
    </BreadcrumbLayout>
  );
}
