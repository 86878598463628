import { Actions } from '@/web/common/AccessRights';
import { TenantSelect } from '@/web/common/TenantSelect';
import { BsInfoCircleFill } from 'react-icons/bs';
import {
  FaCar,
  FaChartArea,
  FaDesktop,
  FaNetworkWired,
  FaStream,
  FaUser,
  FaVideo,
  FaWifi,
} from 'react-icons/fa';
import { GiArtificialHive, GiArtificialIntelligence } from 'react-icons/gi';
import { MdSensors } from 'react-icons/md';
import { Dashboard } from '.';
import { DeviceListViewWithTenant } from './devices';
import { CreateDeviceWithTenant } from './devices/create';
import { ViewDeviceWithTenant } from './devices/_deviceId';
import { ViewDevicePageAiContainer } from './devices/_deviceId/ai-container';
import { DeviceDiagnosticsListViewPage } from './devices/_deviceId/diagnostics';
import { EventListViewWithDevice } from './devices/_deviceId/events';
import { ViewDevicePageGeneral } from './devices/_deviceId/general';
import { ViewDeviceNetworkPage } from './devices/_deviceId/network';
import { DeviceRecordListView } from './devices/_deviceId/records';
import { DeviceSensorsView } from './devices/_deviceId/sensors';
import { DeviceServerConfigView } from './devices/_deviceId/server-config';
import { ViewDevicePageStreams } from './devices/_deviceId/streams';
import { ViewDevicePageTrigger } from './devices/_deviceId/triggers';
import { UserListViewWithDevice } from './devices/_deviceId/users';
import { DeviceVehicleListView } from './devices/_deviceId/vehicles';
import { EndUserManagement } from './end-user/_tenantId';
import { CreateEndUser } from './end-user/_tenantId/create';
import { ViewEndUser } from './end-user/_tenantId/_userId';
import { EditEndUser } from './end-user/_tenantId/_userId/edit';
import { EventListViewWithTenant } from './events';
import { ViewEventWithTenant } from './events/_eventId';
import { StreamListViewWithTenant } from './streams/_tenantId';

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const deviceRoutes = [
  {
    path: '/dashboard/devices',
    element: <DeviceListViewWithTenant />,
    breadcrumb: 'Devices',
    rights: [Actions.DEVICE_LIST_READ],
  },
  {
    path: '/dashboard/devices/create',
    element: <CreateDeviceWithTenant />,
    breadcrumb: 'Create',
    rights: [Actions.DEVICE_ADD_USER_ACCESS],
  },
  {
    path: '/dashboard/devices/:deviceId',
    element: <ViewDeviceWithTenant />,
    breadcrumb: 'View',
    rights: [Actions.DEVICE_READ],
    children: [
      {
        path: 'general',
        element: <ViewDevicePageGeneral />,
        breadcrumb: 'General',
        icon: <FaDesktop />,
        description: 'View general details',
        rights: [Actions.DEVICE_READ],
      },
      {
        path: 'network',
        element: <ViewDeviceNetworkPage />,
        breadcrumb: 'Network',
        icon: <FaWifi />,
        description: 'View network information',
      },
      {
        path: 'users',
        element: <UserListViewWithDevice />,
        breadcrumb: 'Users',
        icon: <FaUser />,
        description: 'View users',
        rights: [Actions.DEVICE_LIST_READ_BY_USER_ID],
      },
      {
        path: 'streams',
        element: <ViewDevicePageStreams />,
        breadcrumb: 'Streams',
        icon: <FaStream />,
        description: 'View streams',
        rights: [Actions.DEVICE_STREAM],
      },
      {
        path: 'events',
        element: <EventListViewWithDevice />,
        breadcrumb: 'Events',
        icon: <FaVideo />,
        description: 'View events',
        rights: [Actions.EVENT_LIST_READ_BY_DEVICE],
      },
      {
        path: 'diagnostics',
        element: <DeviceDiagnosticsListViewPage />,
        breadcrumb: 'Diagnostics',
        icon: <BsInfoCircleFill />,
        description: 'View diagnostics',
      },
      {
        path: 'sensors',
        element: <DeviceSensorsView />,
        breadcrumb: 'Sensors',
        icon: <MdSensors />,
        description: 'View sensors',
      },
      {
        path: 'vehicles',
        element: <DeviceVehicleListView />,
        breadcrumb: 'Vehicle',
        icon: <FaCar />,
        description: 'View vehicle information',
      },
      {
        path: 'ai-container',
        element: <ViewDevicePageAiContainer />,
        breadcrumb: 'AI Container',
        icon: <GiArtificialHive />,
        description: 'View AI Container deployed to this device',
      },
      {
        path: 'triggers',
        element: <ViewDevicePageTrigger />,
        breadcrumb: 'Triggers',
        icon: <GiArtificialIntelligence />,
        description: 'View triggers running on this device',
      },
      {
        path: 'records',
        element: <DeviceRecordListView />,
        breadcrumb: 'Device Records',
        icon: <FaChartArea />,
        description: 'View device records',
      },
      {
        path: 'server-config',
        element: <DeviceServerConfigView />,
        breadcrumb: 'Server Config',
        icon: <FaNetworkWired />,
        description: 'View server configuration',
      },
    ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const endUserRoutes = [
  {
    path: '/dashboard/end-user',
    element: (
      <TenantSelect
        title={'Choose Tenant'}
        subtitle={'Select a tenant to manage their end users'}
      />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.END_USER_LIST_READ],
  },
  {
    path: '/dashboard/end-user/:tenantId',
    element: <EndUserManagement />,
    caseSensitive: true,
    breadcrumb: 'End Users',
    rights: [Actions.END_USER_READ],
  },
  {
    path: '/dashboard/end-user/:tenantId/create',
    element: <CreateEndUser />,
    caseSensitive: true,
    breadcrumb: 'Create',
    rights: [Actions.END_USER_CREATE],
  },
  {
    path: '/dashboard/end-user/:tenantId/:userId',
    element: <ViewEndUser />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.END_USER_READ],
  },
  {
    path: '/dashboard/end-user/:tenantId/:userId/edit',
    element: <EditEndUser />,
    caseSensitive: true,
    breadcrumb: 'Edit',
    rights: [Actions.END_USER_UPDATE],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const eventRoutes = [
  {
    path: '/dashboard/events',
    element: <EventListViewWithTenant />,
    breadcrumb: 'Events',
    rights: [Actions.EVENT_LIST_READ_BY_TENANT],
  },
  {
    path: '/dashboard/events/:eventId',
    element: <ViewEventWithTenant />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.EVENT_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const streamRoutes = [
  {
    path: '/dashboard/streams',
    element: (
      <TenantSelect title={'Choose Tenant'} subtitle={'Select a tenant to manage their streams'} />
    ),
    breadcrumb: 'Tenants',
    rights: [Actions.STREAM_LIST_READ],
  },

  {
    path: '/dashboard/streams/:tenantId',
    element: <StreamListViewWithTenant />,
    caseSensitive: true,
    breadcrumb: 'Events',
    rights: [Actions.STREAM_LIST_READ],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    breadcrumb: 'Dashboard',
    rights: [
      Actions.DEVICE_LIST_READ,
      Actions.END_USER_LIST_READ,
      Actions.EVENT_LIST_READ_BY_TENANT,
      Actions.STREAM_LIST_READ,
    ],
  },
  ...deviceRoutes,
  ...endUserRoutes,
  ...eventRoutes,
  ...streamRoutes,
];
