import {
  USER_ACCESS_DEPLOYMENT_GROUP_LIST_READ,
  USER_ACCESS_DEVICE_POOL_LIST_READ,
  USER_ACCESS_FIRMWARE_LIST_READ,
  USER_ACCESS_FIRMWARE_SHARE_LIST_READ,
} from '@/web/common/AccessRights';
import { DeviceFirmwareDonut } from '../DeviceFirmwareDonut';
import { DeviceGroupsDonut } from '../DeviceGroupsDonut';
import { DevicePoolDonut } from '../DevicePoolDonut';
import { SharedDeviceFirmwareDonut } from '../SharedDeviceFirmwareDonut';

export function AdminDonutGroup(props) {
  return (
    <>
      <USER_ACCESS_FIRMWARE_LIST_READ>
        <DeviceFirmwareDonut {...props} />
      </USER_ACCESS_FIRMWARE_LIST_READ>
      <USER_ACCESS_DEPLOYMENT_GROUP_LIST_READ>
        <DeviceGroupsDonut {...props} />
      </USER_ACCESS_DEPLOYMENT_GROUP_LIST_READ>
      <USER_ACCESS_DEVICE_POOL_LIST_READ>
        <DevicePoolDonut {...props} />
      </USER_ACCESS_DEVICE_POOL_LIST_READ>
      <USER_ACCESS_FIRMWARE_SHARE_LIST_READ>
        <SharedDeviceFirmwareDonut {...props} />
      </USER_ACCESS_FIRMWARE_SHARE_LIST_READ>
    </>
  );
}
