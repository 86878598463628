import { useNavigate } from 'react-router';
import { DataListTable } from '@/web/common/DataListTable';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProductMap } from '@/state/cache';

/** @param {{devicePools: Array.<DevicePool>}} props */
export function DevicePoolTableView(props) {
  const devicePools = props.devicePools;
  const navigate = useNavigate();
  const productMap = useSelector(getProductMap);

  return (
    <DataListTable
      data={devicePools}
      onClick={(item) => navigate(`./${item.poolId}`)}
      headers={[
        {
          key: 'name',
          title: 'Name',
          width: 150,
        },
        {
          title: 'Product',
          width: 125,
          disableAction: true,
          builder: ({ productId }) =>
            productMap.hasOwnProperty(productId) && (
              <Link to={`/super-admin/products/${productId}`}>{productMap[productId].name}</Link>
            ),
        },
        {
          key: 'description',
          title: 'Description',
          width: 200,
        },
      ]}
    />
  );
}
