import api from '@/api';
import { confirmDialog, displayError, displaySuccess } from '@/utils/toaster';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { AiContainerContext } from '../../..';

/** @param {{item: AiContainer, index: number }} props */
export function DeleteComponentButton(props) {
  const { item, index } = props;
  const updateParent = useContext(AiContainerContext);

  const [loading, setLoading] = useState(false);

  const deleteComponent = async () => {
    try {
      setLoading(true);

      const request = api.sai.v1['ai-container'].$containerId(item.containerId).$put({
        data: {
          components: item.components.filter((el, i) => i !== index),
        },
      });

      await request.process();
      displaySuccess('Success', `Deleted component of <b>${item.name}</b>`);
      setLoading(false);

      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
  };

  return (
    <Button
      type="button"
      variant="danger"
      style={{ width: 120 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: deleteComponent,
          title: `Delete this component of ${item.name}?`,
          message: 'It will affect all resources using this component',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
