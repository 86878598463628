import { createSlice } from '@reduxjs/toolkit';

const DATA_EXPIRY_TIME_MS = 15 * 60 * 1000;

/**
 * The chart data properties.
 * A property name must be declared in {@link ChartDataExpiry} with the same name.
 *
 * @typedef {object} ChartData
 * @property {{total: number, tenantId: string}} [aiAccelerators]
 * @property {{total: number, tenantId: string}} [aiContainers]
 * @property {{total: number, tenantId: string}} [aiModels]
 * @property {{total: number, tenantId: string}} [devicePools]
 * @property {{total: number, tenantId: string}} [devices]
 * @property {{total: number, tenantId: string}} [events]
 * @property {{total: number, tenantId: string}} [firmwares]
 * @property {{total: number, tenantId: string}} [sharedFirmwares]
 * @property {{total: number, tenantId: string}} [groups]
 * @property {{total: number, tenantId: string}} [labels]
 * @property {{total: number}} [labelCategories]
 * @property {{total: number}} [platformAdmins]
 * @property {{total: number, tenantId: string}} [products]
 * @property {{total: number}} [roles]
 * @property {{total: number, tenantId: string}} [sharedAiContainers]
 * @property {{total: number, tenantId: string}} [sharedAiModels]
 * @property {{total: number, tenantId: string}} [sharedProducts]
 * @property {{total: number, tenantId: string}} [sharedTriggers]
 * @property {{total: number, tenantId: string}} [streams]
 * @property {{total: number, tenantId: string}} [tenantAdmins]
 * @property {{total: number}} [tenants]
 * @property {{total: number, tenantId: string}} [triggers]
 * @property {{total: number, tenantId: string}} [users]
 */

/**
 * The expiry time of a chart data.
 * Property name must be same with the property name declared in {@link ChartData}
 *
 * @typedef {object} ChartDataExpiry
 * @property {number} [aiAccelerators]
 * @property {number} [aiContainers]
 * @property {number} [aiModels]
 * @property {number} [devicePools]
 * @property {number} [devices]
 * @property {number} [events]
 * @property {number} [firmwares]
 * @property {number} [sharedFirmwares]
 * @property {number} [groups]
 * @property {number} [labels]
 * @property {number} [platformAdmins]
 * @property {number} [products]
 * @property {number} [roles]
 * @property {number} [labelCategories]
 * @property {number} [sharedAiContainers]
 * @property {number} [sharedAiModels]
 * @property {number} [sharedProducts]
 * @property {number} [sharedTriggers]
 * @property {number} [streams]
 * @property {number} [tenantAdmins]
 * @property {number} [tenants]
 * @property {number} [triggers]
 * @property {number} [users]
 */

/**
 * @typedef {object} InitialState
 * @property {ChartData} data
 * @property {ChartDataExpiry} expiry
 */

/** @type {InitialState} */
const initialState = {
  data: {},
  expiry: {},
};

const reducers = {
  /**
   * Update the cached data
   *
   * @param {import('.').ChartState} state
   * @param {{type: string, payload: ChartData}} action
   */
  updateData(state, action) {
    Object.keys(action.payload).forEach((key) => {
      state.data[key] = action.payload[key];
      if (action.payload[key]) {
        state.expiry[key] = Date.now() + DATA_EXPIRY_TIME_MS;
      } else {
        state.expiry[key] = 0;
      }
    });
  },
};

export const ChartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers,
});
