import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useContext } from 'react';
import { GiArtificialIntelligence } from 'react-icons/gi';
import { DeviceViewContext } from '../@components/DeviceViewLayout';
import { DeviceTriggerListView } from './@components/DeployedListView';

export function ViewDevicePageTrigger() {
  /** @type {{device: DeviceDetail}} props */
  const { device } = useContext(DeviceViewContext);
  const groupId =
    device?.onboardedDevice?.deploymentGroupIds[
      device?.onboardedDevice?.deploymentGroupIds?.length - 1
    ];

  return (
    <>
      <BreadcrumbLayout.Header title="Device Triggers" icon={<GiArtificialIntelligence />} />
      <BreadcrumbLayout.Body>
        <DeviceTriggerListView groupId={groupId} />
      </BreadcrumbLayout.Body>
    </>
  );
}
