import { Actions } from '@/web/common/AccessRights/rights';
import { InternalPage } from '.';
import { PlatformAdminManagement } from './platform-admin';
import { CreatePlatformAdmin } from './platform-admin/create';
import { ViewPlatformAdmin } from './platform-admin/_userId';
import { EditPlatformAdmin } from './platform-admin/_userId/edit';
import { TenantAdminManagement } from './tenant-admin';
import { CreateTenantAdmin } from './tenant-admin/create';
import { ViewTenantAdmin } from './tenant-admin/_userId';
import { EditTenantAdmin } from './tenant-admin/_userId/edit';

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const platformAdminRoutes = [
  {
    path: '/internal/platform-admin',
    element: <PlatformAdminManagement />,
    breadcrumb: 'Platform Admins',
    rights: [Actions.PLATFORM_ADMIN_LIST_READ],
  },
  {
    path: '/internal/platform-admin/create',
    element: <CreatePlatformAdmin />,
    breadcrumb: 'Create',
    rights: [Actions.PLATFORM_ADMIN_CREATE],
  },
  {
    path: '/internal/platform-admin/:userId',
    element: <ViewPlatformAdmin />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.PLATFORM_ADMIN_READ],
  },
  {
    path: '/internal/platform-admin/:userId/edit',
    element: <EditPlatformAdmin />,
    caseSensitive: true,
    breadcrumb: 'Edit',
    rights: [Actions.PLATFORM_ADMIN_UPDATE],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
const tenantAdminRoutes = [
  {
    path: '/internal/tenant-admin',
    element: <TenantAdminManagement />,
    breadcrumb: 'Tenant Admins',
    rights: [Actions.TENANT_ADMIN_LIST_READ],
  },
  {
    path: '/internal/tenant-admin/create',
    element: <CreateTenantAdmin />,
    caseSensitive: true,
    breadcrumb: 'Create',
    rights: [Actions.TENANT_ADMIN_CREATE],
  },
  {
    path: '/internal/tenant-admin/:userId',
    element: <ViewTenantAdmin />,
    caseSensitive: true,
    breadcrumb: 'View',
    rights: [Actions.TENANT_ADMIN_READ],
  },
  {
    path: '/internal/tenant-admin/:userId/edit',
    element: <EditTenantAdmin />,
    caseSensitive: true,
    breadcrumb: 'Edit',
    rights: [Actions.TENANT_ADMIN_UPDATE],
  },
];

/** @type {Array.<import('@/web/pages/routes').ExtendedRoute>} */
export const routes = [
  {
    path: '/internal',
    element: <InternalPage />,
    breadcrumb: 'Internals',
    rights: [Actions.TENANT_ADMIN_LIST_READ, Actions.PLATFORM_ADMIN_LIST_READ],
  },
  ...platformAdminRoutes,
  ...tenantAdminRoutes,
];
