import { Col, Container, Row } from 'react-bootstrap';
import './index.scss';

const { version } = require('@/assets/version.json');

function AnyConnectLogo() {
  return (
    <div className="text-center anyconnect-logo">
      <img src="/images/logo.svg" alt="logo" />
      <span>Console</span>
    </div>
  );
}

/**
 * @param {{children: import('react').Component}} props
 */
export function LoginPageLayout(props) {
  return (
    <Container fluid id="login-page">
      <Row style={{ height: '100vh' }}>
        <Col xs={12} lg={6} className="page-highlight">
          <div className="flex-container">
            <AnyConnectLogo />
            <div className="version-text">v{version}</div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="flex-container">{props.children}</div>
        </Col>
      </Row>
    </Container>
  );
}
