import { getBearerToken } from '@/state/auth';
import { fetchAllTenants, getTenantList } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { TenantGridItem } from './TenantGridItem';

/**
 * @typedef {object} TenantSelectProps
 * @property {string} title
 * @property {string} subtitle
 */

/** @param {TenantSelectProps} props */
export function TenantSelectionPage(props) {
  const { title, subtitle, ...extraProps } = props;

  const brearerToken = useSelector(getBearerToken);
  const [loading, error] = useDispatchLoader(fetchAllTenants, [brearerToken]);
  const tenants = useSelector(getTenantList);

  return (
    <BreadcrumbLayout {...extraProps}>
      <BreadcrumbLayout.Header
        title={title || 'Choose Tenant'}
        subtitle={subtitle || 'Select a tenant to manage this resource'}
      />
      <BreadcrumbLayout.Body>
        {loading ? (
          <PageLoadingSpinner status={'Loading tenant list...'} />
        ) : error ? (
          <PageLoadingFailure message={'Failed to fetch list of tenants'} />
        ) : (
          <Row className="g-2">
            {tenants.map((tenant) => (
              <Col xs="12" sm="6" lg="3" xl="2" key={tenant.tenantId}>
                <TenantGridItem tenant={tenant} />
              </Col>
            ))}
          </Row>
        )}
      </BreadcrumbLayout.Body>
    </BreadcrumbLayout>
  );
}
