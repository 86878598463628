import api from '@/api';
import { useSelector } from 'react-redux';
import { getBearerToken } from '@/state/auth';
import { Card } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { EditLabelCategoryForm } from './@components/EditLabelCategoryForm';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';

export function EditLabelCategory() {
  const { id } = useParams();

  const bearerToken = useSelector(getBearerToken);

  const [labelCategory, labelCategoryLoading, labelCategoryError] = useFutureLoader(async () => {
    const request = api.sai.v1['label-category'].$id(id).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, id]);

  if (labelCategoryLoading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <PageLoadingSpinner status={'Loading label category...'} />
      </BreadcrumbLayout>
    );
  }

  if (labelCategoryError) {
    displayError(labelCategoryError);
    return <Navigate replace to="./../.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title className="my-1">
            Edit <b>{labelCategory.name}</b>
          </Card.Title>
        </Card.Header>
        <EditLabelCategoryForm item={labelCategory} />
      </Card>
    </BreadcrumbLayout>
  );
}
