import {
  audioCodecs,
  audioTypes,
  channels,
  quantizations,
  sampleRates,
} from '@/assets/ai-models/autocomplete';
import { Col, Row } from 'react-bootstrap';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';

/**
 * @param {{item: AudioInput, onChange: () => {}, editing: boolean}} props
 */
export function AudioInputFields(props) {
  const { item, onChange, editing } = props;

  /**
   * @param {string} fieldName
   * @returns {[string, (value: string) => any]}
   */
  const changeObserver = (fieldName) => {
    const value = (item && item[fieldName]) || '';
    const setValue = (v) => onChange({ ...item, [fieldName]: v });
    return [value, setValue];
  };

  const [name, setName] = changeObserver('name');
  const [description, setDescription] = changeObserver('description');
  const [sampleRate, setSampleRate] = changeObserver('sampleRate');
  const [quantization, setQuantization] = changeObserver('quantization');
  const [code, setCode] = changeObserver('code');
  const [codec, setCodec] = changeObserver('codec');
  const [type, setType] = changeObserver('type');
  const [channel, setChannel] = changeObserver('channel');

  return (
    <Row className="gx-2">
      <Col lg={6}>
        <InputBox disabled={editing} label="Code" value={code} onChange={setCode} required />
      </Col>
      <Col lg={6}>
        <InputBox label="Name" value={name} onChange={setName} required />
      </Col>
      <Col lg={12}>
        <InputBox label="Description" value={description} onChange={setDescription} />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Sample Rate"
          value={sampleRate}
          onChange={setSampleRate}
          options={Object.entries(sampleRates || {})}
          itemValue={0}
          itemText={1}
        />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Quantization"
          value={quantization}
          onChange={setQuantization}
          options={Object.entries(quantizations || {})}
          itemValue={0}
          itemText={1}
        />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Channel"
          value={channel}
          onChange={setChannel}
          options={Object.entries(channels || {})}
          itemValue={0}
          itemText={1}
        />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Codec"
          value={codec}
          onChange={setCodec}
          options={Object.entries(audioCodecs || {})}
          itemValue={0}
          itemText={1}
        />
      </Col>
      <Col lg={4}>
        <SelectBox
          label="Type"
          value={type}
          onChange={setType}
          options={Object.entries(audioTypes || {})}
          itemValue={0}
          itemText={1}
        />
      </Col>
    </Row>
  );
}
