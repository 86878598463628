import api from '@/api';
import { checkIsCacheValid, updateData } from '..';

/**
 * Fetch and cache Shared AI Model donut chart data
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchSharedAiModelDonutData = (refresh) => async (dispatch, getState) => {
  const state = getState();

  const { login, tenantId } = state.auth;
  if (!login?.accessToken) return;

  if (!refresh && checkIsCacheValid('sharedAiModels')(state)) return;

  const request = api.sai.v1['ai-model-share'][':count'].$get({
    params: {
      tenantId,
    },
  });
  await request.process();

  /** @type {import('..').ChartData} */
  const chartData = {
    sharedAiModels: {
      tenantId,
      total: request.result.result.count,
    },
  };

  await dispatch(updateData(chartData));
};
