import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';
import {
  hertzOptions,
  hertzOptionsRecording,
  accelerometerTypesVision,
} from '@/assets/group-settings/autocomplete';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Sensor(props) {
  const { updateSettings } = useContext(SettingsContext);
  const { settings, defaultSettings, loadingOnUpdate } = props;

  const [accelerometerSamplingRateRecording, setAccelerometerSamplingRateRecording] = useState(
    settings.accelerometerSamplingRateRecording ??
      defaultSettings.accelerometerSamplingRateRecording
  );
  const [accelerometerSamplingRateTrigger, setAccelerometerSamplingRateTrigger] = useState(
    settings.accelerometerSamplingRateTrigger ?? defaultSettings.accelerometerSamplingRateTrigger
  );
  const [accelerometerSamplingRateVision, setAccelerometerSamplingRateVision] = useState(
    settings.accelerometerSamplingRateVision ?? defaultSettings.accelerometerSamplingRateVision
  );
  const [gyroscopeSamplingRateRecording, setGyroscopeSamplingRateRecording] = useState(
    settings.gyroscopeSamplingRateRecording ?? defaultSettings.gyroscopeSamplingRateRecording
  );
  const [gyroscopeSamplingRateTrigger, setGyroscopeSamplingRateTrigger] = useState(
    settings.gyroscopeSamplingRateTrigger ?? defaultSettings.gyroscopeSamplingRateTrigger
  );
  const [gyroscopeSamplingRateVision, setGyroscopeSamplingRateVision] = useState(
    settings.gyroscopeSamplingRateVision ?? defaultSettings.gyroscopeSamplingRateVision
  );
  const [accelerometerTypeVision, setAccelerometerTypeVision] = useState(
    settings.accelerometerTypeVision ?? defaultSettings.accelerometerTypeVision
  );

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = {
      accelerometerSamplingRateRecording,
      accelerometerSamplingRateTrigger,
      accelerometerSamplingRateVision,
      gyroscopeSamplingRateRecording,
      gyroscopeSamplingRateTrigger,
      gyroscopeSamplingRateVision,
      accelerometerTypeVision,
    };
    updateSettings(data);
  };

  return (
    <>
      <Card.Body>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="Accelerometer Sampling Rate (Vision)"
              value={accelerometerSamplingRateVision}
              onChange={setAccelerometerSamplingRateVision}
              options={Object.entries(hertzOptions || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Accelerometer Sampling Rate (Recording)"
              value={accelerometerSamplingRateRecording}
              onChange={setAccelerometerSamplingRateRecording}
              options={Object.entries(hertzOptionsRecording || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Accelerometer Sampling Rate (Trigger)"
              value={accelerometerSamplingRateTrigger}
              onChange={setAccelerometerSamplingRateTrigger}
              options={Object.entries(hertzOptions || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Gyroscope Sampling Rate (Vision)"
              value={gyroscopeSamplingRateVision}
              onChange={setGyroscopeSamplingRateVision}
              options={Object.entries(hertzOptions || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Gyroscope Sampling Rate (Recording)"
              value={gyroscopeSamplingRateRecording}
              onChange={setGyroscopeSamplingRateRecording}
              options={Object.entries(hertzOptionsRecording || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Gyroscope Sampling Rate (Trigger)"
              value={gyroscopeSamplingRateTrigger}
              onChange={setGyroscopeSamplingRateTrigger}
              options={Object.entries(hertzOptions || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Accelerometer Type"
              value={accelerometerTypeVision}
              onChange={setAccelerometerTypeVision}
              options={Object.entries(accelerometerTypesVision || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
