import api from '@/api';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBearerToken } from '@/state/auth';
import { fetchAllProducts, fetchAllTenants } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';

export const GroupViewContext = createContext();

export function GroupViewLayout(props) {
  const { groupId } = useParams();

  const [id, setId] = useState();
  const updateGroup = () => {
    setId(Date.now());
  };

  useDispatchLoader(fetchAllTenants);
  useDispatchLoader(fetchAllProducts);

  const bearerToken = useSelector(getBearerToken);
  const [group, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['deployment-group'].$groupId(groupId).$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, groupId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading device group...'} />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    return (
      <BreadcrumbLayout>
        <PageLoadingFailure message={'Failed to load device group'}>
          {error + ''}
        </PageLoadingFailure>
      </BreadcrumbLayout>
    );
  }

  return (
    <BreadcrumbLayout title={group.name} subtitle={group.description}>
      <GroupViewContext.Provider value={{ group, updateGroup }}>
        {props.children}
      </GroupViewContext.Provider>
    </BreadcrumbLayout>
  );
}
