import { Card, FloatingLabel, Form, Placeholder } from 'react-bootstrap';

export function CreateEndUserPlaceholder() {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">Create End User</Card.Title>
      </Card.Header>
      <Placeholder as={Card.Body} animation="glow">
        <FloatingLabel label="Role" className="mb-2">
          <Placeholder as={Form.Select} />
        </FloatingLabel>
        <FloatingLabel label="Email Address" className="mb-2">
          <Placeholder as={Form.Control} />
        </FloatingLabel>
        <FloatingLabel label="Username" className="mb-2">
          <Placeholder as={Form.Control} />
        </FloatingLabel>
        <FloatingLabel label="Password" className="mb-2">
          <Placeholder as={Form.Control} />
        </FloatingLabel>
        <FloatingLabel label="Full Name" className="mb-2">
          <Placeholder as={Form.Control} />
        </FloatingLabel>
        <FloatingLabel label="Picture" className="mb-2">
          <Placeholder as={Form.Control} />
        </FloatingLabel>
      </Placeholder>
      <Placeholder
        as={Card.Footer}
        animation="glow"
        className="d-flex align-items-center justify-content-between"
      >
        <div className="text-muted">Loading...</div>
        <Placeholder.Button variant="primary" xs={2} />
      </Placeholder>
    </Card>
  );
}
