import {
  startPolicies,
  networksToDownload,
  deploymentPolicies,
  triggerPolicies,
  retryPolicies,
  periodicCheckPolicies,
  days,
  datesInAMonth,
} from '@/assets/ai-models/autocomplete';
import { InputBox } from '@/web/common/forms/InputBox';
import { InputSlider } from '@/web/common/forms/InputSlider';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState, useEffect } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function FirmwareUpdate(props) {
  const { updateSettings } = useContext(SettingsContext);
  const { settings, defaultSettings, loadingOnUpdate } = props;

  const [downloadStartPolicy, setDownloadStartPolicy] = useState(
    settings.downloadStartPolicy ?? defaultSettings.downloadStartPolicy
  );
  const [downloadStartSchedule, setDownloadStartSchedule] = useState(
    settings.downloadStartSchedule ?? defaultSettings.downloadStartSchedule
  );
  /**
   * @type {{geoFenceDownloadStart: String, setGeoFenceDownloadStart: () => any}}
   * format : "latitude:${latitude} longitude:${longitude} radius:${radius}"
   */
  const [geoFenceDownloadStart] = useState(
    settings.geoFenceDownloadStart ?? defaultSettings.geoFenceDownloadStart
  );
  const [downloadRetryPolicy, setDownloadRetryPolicy] = useState(
    settings.downloadRetryPolicy ?? defaultSettings.downloadRetryPolicy
  );
  const [downloadRetrySchedule, setDownloadRetrySchedule] = useState(
    settings.downloadRetrySchedule ?? defaultSettings.downloadRetrySchedule
  );
  const [maxRetryOnDownloadFailure, setMaxRetryOnDownloadFailure] = useState(
    settings.maxRetryOnDownloadFailure ?? defaultSettings.maxRetryOnDownloadFailure
  );
  const [networkToDownload, setNetworkToDownload] = useState(
    settings.networkToDownload ?? defaultSettings.networkToDownload
  );
  const [maxPackageSizeForLTEDownload, setMaxPackageSizeForLTEDownload] = useState(
    settings.maxPackageSizeForLTEDownload ?? defaultSettings.maxPackageSizeForLTEDownload
  );
  const [installStartPolicy, setInstallStartPolicy] = useState(
    settings.installStartPolicy ?? defaultSettings.installStartPolicy
  );
  const [installationStartSchedule, setInstallationStartSchedule] = useState(
    settings.installationStartSchedule ?? defaultSettings.installationStartSchedule
  );
  /**
   * @type {{geoFenceInstallationStart: String, setGeoFenceInstallationStart: () => any}}
   * format : "latitude:${latitude} longitude:${longitude} radius:${radius}"
   */
  const [geoFenceInstallationStart] = useState(
    settings.geoFenceInstallationStart ?? defaultSettings.geoFenceInstallationStart
  );
  const [installationRetryPolicy, setInstallationRetryPolicy] = useState(
    settings.installationRetryPolicy ?? defaultSettings.installationRetryPolicy
  );
  const [installationRetrySchedule, setInstallationRetrySchedule] = useState(
    settings.installationRetrySchedule ?? defaultSettings.installationRetrySchedule
  );
  const [maxRetryOnInstallationFailure, setMaxRetryOnInstallationFailure] = useState(
    settings.maxRetryOnInstallationFailure ?? defaultSettings.maxRetryOnInstallationFailure
  );
  const [periodicCheckPolicy, setPeriodicCheckPolicy] = useState(
    settings.periodicCheckPolicy ?? defaultSettings.periodicCheckPolicy
  );
  const [periodicCheckTime] = useState(
    settings.periodicCheckTime ?? defaultSettings.periodicCheckTime
  );
  const [periodicCheckInterval, setPeriodicCheckInterval] = useState(
    settings.periodicCheckInterval ?? defaultSettings.periodicCheckInterval
  );
  const [onDeploymentPolicy, setOnDeploymentPolicy] = useState(
    settings.onDeploymentPolicy ?? defaultSettings.onDeploymentPolicy
  );
  const [onUserTriggerPolicy, setOnUserTriggerPolicy] = useState(
    settings.onUserTriggerPolicy ?? defaultSettings.onUserTriggerPolicy
  );
  const [downloadLatitude, setDownloadLatitude] = useState('');
  const [downloadLongitude, setDownloadLongitude] = useState('');
  const [downloadRadius, setDownloadRadius] = useState('');
  const [installLatitude, setInstallLatitude] = useState('');
  const [installLongitude, setInstallLongitude] = useState('');
  const [installRadius, setInstallRadius] = useState('');
  const [day, setDay] = useState('');
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    if (geoFenceInstallationStart != null) {
      try {
        setInstallLatitude(geoFenceInstallationStart.split(' ')[0].split(':')[1]);
        setInstallLongitude(geoFenceInstallationStart.split(' ')[1].split(':')[1]);
        setInstallRadius(geoFenceInstallationStart.split(' ')[2].split(':')[1]);
      } catch (e) {}
    }
  }, [geoFenceInstallationStart]);

  useEffect(() => {
    if (geoFenceDownloadStart != null) {
      try {
        setDownloadLatitude(geoFenceDownloadStart.split(' ')[0].split(':')[1]);
        setDownloadLongitude(geoFenceDownloadStart.split(' ')[1].split(':')[1]);
        setDownloadRadius(geoFenceDownloadStart.split(' ')[2].split(':')[1]);
      } catch (e) {}
    }
  }, [geoFenceDownloadStart]);

  useEffect(() => {
    try {
      switch (periodicCheckPolicy) {
        case 'WEEKLY':
          setDay(periodicCheckTime.split(' ')[0]);
          setTime(periodicCheckTime.split(' ')[1]);
          break;
        case 'DAILY':
          setTime(periodicCheckTime);
          break;
        case 'MONTHLY':
          setDate(periodicCheckTime.split(' ')[0]);
          setTime(periodicCheckTime.split(' ')[1]);
          break;
        default:
      }
    } catch (e) {}
  }, [periodicCheckPolicy, periodicCheckTime]);

  const generatePeriodicCheckTime = (periodicCheckPolicy) => {
    switch (periodicCheckPolicy) {
      case 'DAILY':
        return `${time}`;
      case 'WEEKLY':
        return `${day} ${time}`;
      case 'MONTHLY':
        return `${date} ${time}`;
      default:
    }
  };

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = {
      downloadStartPolicy,
      downloadStartSchedule,
      geoFenceDownloadStart,
      downloadRetryPolicy,
      downloadRetrySchedule,
      maxRetryOnDownloadFailure,
      networkToDownload,
      maxPackageSizeForLTEDownload,
      installStartPolicy,
      installationStartSchedule,
      geoFenceInstallationStart,
      installationRetryPolicy,
      installationRetrySchedule,
      maxRetryOnInstallationFailure,
      periodicCheckPolicy,
      periodicCheckTime: generatePeriodicCheckTime(periodicCheckPolicy),
      periodicCheckInterval,
      onDeploymentPolicy,
      onUserTriggerPolicy,
    };
    updateSettings(data);
  };

  const showMaxPackageSizeForLTEDownload = networkToDownload === 'ANY';
  const showDownloadStartSchedule = downloadStartPolicy === 'SCHEDULED';
  const showDownloadStartGeoFence = downloadStartPolicy === 'GEO_FENCE';
  const showInstallStartSchedule = installStartPolicy === 'SCHEDULED';
  const showInstallStartGeoFence = installStartPolicy === 'GEO_FENCE';
  const showDownloadRetrySchedule = downloadRetryPolicy === 'SCHEDULED';
  const showInstallRetrySchedule = installationRetryPolicy === 'SCHEDULED';
  const showPeriodicCheckDaily = periodicCheckPolicy === 'DAILY';
  const showPeriodicCheckWeekly = periodicCheckPolicy === 'WEEKLY';
  const showPeriodicCheckMonthly = periodicCheckPolicy === 'MONTHLY';

  return (
    <>
      <Card.Body>
        <h6 className="mb-3">
          <u>Download</u>
        </h6>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="Download start policy"
              value={downloadStartPolicy}
              onChange={setDownloadStartPolicy}
              options={Object.entries(startPolicies || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          {showDownloadStartSchedule && (
            <Col lg={4}>
              <InputBox
                label="Download start schedule"
                value={downloadStartSchedule}
                required
                type="datetime-local"
                onChange={setDownloadStartSchedule}
              />
            </Col>
          )}
          {showDownloadStartGeoFence && (
            <>
              <Col lg={3}>
                <InputBox
                  label="Latitude"
                  type="number"
                  value={downloadLatitude}
                  required
                  onChange={setDownloadLatitude}
                />
              </Col>
              <Col lg={3}>
                <InputBox
                  label="Longitude"
                  type="number"
                  value={downloadLongitude}
                  required
                  onChange={setDownloadLongitude}
                />
              </Col>
              <Col lg={2}>
                <InputBox
                  label="Radius"
                  type="number"
                  value={downloadRadius}
                  required
                  onChange={setDownloadRadius}
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="Download retry policy"
              value={downloadRetryPolicy}
              onChange={setDownloadRetryPolicy}
              options={Object.entries(retryPolicies || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          {showDownloadRetrySchedule && (
            <Col lg={4}>
              <InputBox
                label="Download retry schedule"
                value={downloadRetrySchedule}
                required
                type="datetime-local"
                onChange={setDownloadRetrySchedule}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={4}>
            <InputSlider
              min={0}
              max={10}
              label="Max retry on download failure"
              unit={"time('s)"}
              value={maxRetryOnDownloadFailure}
              onChange={setMaxRetryOnDownloadFailure}
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="Network to download"
              value={networkToDownload}
              onChange={setNetworkToDownload}
              options={Object.entries(networksToDownload || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          {showMaxPackageSizeForLTEDownload && (
            <Col lg={4}>
              <InputBox
                label="Max Package Size For LTE Download (MB)"
                type="number"
                value={maxPackageSizeForLTEDownload}
                required
                onChange={setMaxPackageSizeForLTEDownload}
              />
            </Col>
          )}
        </Row>
        <h6 className="my-4">
          <u>Installation</u>
        </h6>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="Installation start policy"
              value={installStartPolicy}
              onChange={setInstallStartPolicy}
              options={Object.entries(startPolicies || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          {showInstallStartSchedule && (
            <Col lg={4}>
              <InputBox
                label="Installation start schedule"
                value={installationStartSchedule}
                required
                type="datetime-local"
                onChange={setInstallationStartSchedule}
              />
            </Col>
          )}
          {showInstallStartGeoFence && (
            <>
              <Col lg={3}>
                <InputBox
                  label="Latitude"
                  type="number"
                  value={installLatitude}
                  required
                  onChange={setInstallLatitude}
                />
              </Col>
              <Col lg={3}>
                <InputBox
                  label="Longitude"
                  type="number"
                  value={installLongitude}
                  required
                  onChange={setInstallLongitude}
                />
              </Col>
              <Col lg={2}>
                <InputBox
                  label="Radius"
                  type="number"
                  value={installRadius}
                  required
                  onChange={setInstallRadius}
                />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="Installation retry policy"
              value={installationRetryPolicy}
              onChange={setInstallationRetryPolicy}
              options={Object.entries(retryPolicies || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          {showInstallRetrySchedule && (
            <Col lg={4}>
              <InputBox
                label="Installation retry schedule"
                value={installationRetrySchedule}
                required
                type="datetime-local"
                onChange={setInstallationRetrySchedule}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={4}>
            <InputSlider
              min={0}
              max={10}
              label="Max retry on installation failure"
              unit={"time('s)"}
              value={maxRetryOnInstallationFailure}
              onChange={setMaxRetryOnInstallationFailure}
            />
          </Col>
        </Row>
        <h6 className="my-4">
          <u>Periodic</u>
        </h6>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="Periodic check policy"
              value={periodicCheckPolicy}
              onChange={setPeriodicCheckPolicy}
              options={Object.entries(periodicCheckPolicies || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          {showPeriodicCheckDaily && (
            <Col lg={4}>
              <InputBox label="Time" value={time} required type="time" onChange={setTime} />
            </Col>
          )}
          {showPeriodicCheckWeekly && (
            <>
              <Col lg={3}>
                <SelectBox
                  label="Day"
                  value={day}
                  onChange={setDay}
                  options={Object.entries(days || {})}
                  itemValue={0}
                  itemText={1}
                  required
                />
              </Col>
              <Col lg={2}>
                <InputBox label="Time" value={time} required type="time" onChange={setTime} />
              </Col>
            </>
          )}
          {showPeriodicCheckMonthly && (
            <>
              <Col lg={3}>
                <SelectBox
                  label="Month"
                  value={date}
                  onChange={setDate}
                  options={Object.entries(datesInAMonth || {})}
                  itemValue={0}
                  itemText={1}
                  required
                />
              </Col>
              <Col lg={2}>
                <InputBox label="Time" value={time} required type="time" onChange={setTime} />
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col lg={4}>
            <InputSlider
              min={1}
              max={24}
              label="Periodic check interval"
              unit={'hour(s)'}
              value={periodicCheckInterval}
              onChange={setPeriodicCheckInterval}
            />
          </Col>
        </Row>
        <h6 className="my-4">
          <u>OTA Trigger Policy</u>
        </h6>
        <Row>
          <Col lg={4}>
            <SelectBox
              label="OTA on deployment"
              value={onDeploymentPolicy}
              onChange={setOnDeploymentPolicy}
              options={Object.entries(deploymentPolicies || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
          <Col lg={4}>
            <SelectBox
              label="OTA on user action"
              value={onUserTriggerPolicy}
              onChange={setOnUserTriggerPolicy}
              options={Object.entries(triggerPolicies || {})}
              itemValue={0}
              itemText={1}
              required
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
