import { Card } from 'react-bootstrap';
import { MdPages } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useRangedColor } from '@/utils/hooks/RangedColor';

/** @param {{item: import('.').PageItem}} props */
export function PagedGridItemLarge(props) {
  const { item } = props;
  const color = useRangedColor(item.path, props.color, 50, 150);

  return (
    <Card
      as={Link}
      to={item.path}
      className="text-decoration-none list-group-item-action"
      style={{ height: '100%' }}
    >
      <Card.Header className="text-center px-2 py-4">
        <span style={{ color, fontSize: 64 }}>{item.icon ?? <MdPages />}</span>
      </Card.Header>
      <Card.Body>
        <div className="fs-6 fw-bold text-capitalize">{item.title ?? item.path}</div>
        {item.subtitle && <div className="small text-muted">{item.subtitle}</div>}
      </Card.Body>
    </Card>
  );
}
