export const PRODUCTS = {
  SMARTER_AI_GATEWAY: 'eca54d18-370a-4182-a330-09fe8089644b',
  SMARTER_AI_GATEWAY_SOLO: 'nzfj8kal-k9za-y00u-3sj9-upruyor38nvi',
};

export const IMAGER_TYPE = {
  VID_1: 'vid_1',
  VID_2: 'vid_2',
};

export const BOUNDING_BOX_LABEL_COLORS = [
  '#f06292',
  '#ba68c8',
  '#9575cd',
  '#7986cb',
  '#64b5f6',
  '#4fc3f7',
  '#4dd0e1',
  '#4db6ac',
  '#81c784',
  '#aed581',
  '#ff8a65',
  '#d4e157',
  '#ffd54f',
  '#ffb74d',
  '#a1887f',
  '#90a4ae',
];
