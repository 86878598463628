import { setAvatar, setLoginData, setTenantId, setUser } from '.';

/**
 * Logout current session
 *
 * @returns {Promise<void>}
 */
export const doLogout = () => async (dispatch) => {
  dispatch(setTenantId());
  dispatch(setLoginData());
  dispatch(setUser());
  dispatch(setAvatar());
};
