import api from '@/api';
import { getTenantId } from '@/state/auth';
import { getProductMap } from '@/state/cache';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function CreateDeviceForm() {
  const tenantId = useSelector(getTenantId);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [deviceId, setDeviceId] = useState('');
  const [deviceSecret, setDeviceSecret] = useState('');
  const [tag, setTag] = useState('');
  const [productId, setProductId] = useState('');

  const navigate = useNavigate();
  const productMap = useSelector(getProductMap);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.device.$post({
        data: {
          ownerTenantId: tenantId,
          productId,
          deviceId,
          deviceSecret,
          tag,
        },
      });
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Created Device`,
      });
      navigate({ pathname: './..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Create Device</Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <InputBox
            label="Device ID"
            placeholder="Enter Device ID"
            value={deviceId}
            onChange={setDeviceId}
            required
          />
          <InputBox
            label="Device Secret"
            placeholder="Enter Device Secret"
            value={deviceSecret}
            onChange={setDeviceSecret}
            required
          />
          <InputBox label="Tag" placeholder="Enter Tag" value={tag} onChange={setTag} required />
          <SelectBox
            label="Product"
            placeholder="Select Product"
            value={productId}
            onChange={setProductId}
            options={Object.values(productMap || {})}
            itemValue="productId"
            itemText="name"
            required
          />
          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-end">
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
