import api from '@/api';
import { getTenantId } from '@/state/auth';
import { formatDateTime, formatInitiatorType, formatStreamStatus } from '@/utils/formatting';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { FaStream } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export function ViewDevicePageStreams() {
  const { deviceId } = useParams();
  const tenantId = useSelector(getTenantId);

  return (
    <>
      <BreadcrumbLayout.Header icon={<FaStream />} title="Device Streams" />
      <BreadcrumbLayout.Body>
        <TokenBasedInfiniteScrollTable
          fetcher={async (pageSize, continuationToken) => {
            const request = api.sai.v1.stream.$get({
              params: {
                tenantId,
                deviceId,
                pageSize,
                continuationToken,
              },
            });
            await request.process();
            return {
              results: request.result.result.results,
              token: request.result.result.continuationToken,
            };
          }}
          headers={[
            {
              key: 'deviceId',
              minWidth: 150,
            },
            {
              key: 'deviceName',
              minWidth: 150,
            },
            {
              key: 'initiatorType',
              minWidth: 150,
              builder: (item) => formatInitiatorType(item.initiatorType),
            },
            {
              key: 'lastUpdateTime',
              minWidth: 150,
              builder: (item) => formatDateTime(item.lastUpdateTime),
            },
            {
              key: 'endTime',
              minWidth: 150,
              builder: (item) => formatDateTime(item.endTime),
            },
            {
              key: 'streamStatus',
              minWidth: 150,
              builder: (item) => formatStreamStatus(item.streamStatus),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
