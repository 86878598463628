import { Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getProduct, getTenant } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { DeleteAiContainerButton } from '../DeleteAiContainerButton';
import { DiscontinueAiContainerButton } from '../DiscontinueAiContainerButton';
import { EditAiContainerButton } from '../EditAiContainerButton';
import { PublishAiContainerButton } from '../PublishAiContainerButton';
import { ShareAiContainerButton } from '../ShareAiContainerButton';
import { RemoveShareAiContainerButton } from '../RemoveShareAiContainerButton';
import {
  USER_ACCESS_AI_CONTAINER_DELETE,
  USER_ACCESS_AI_CONTAINER_DISCONTINUE,
  USER_ACCESS_AI_CONTAINER_PUBLISH,
  USER_ACCESS_AI_CONTAINER_SHARE_CREATE,
  USER_ACCESS_AI_CONTAINER_SHARE_DELETE,
  USER_ACCESS_AI_CONTAINER_UPDATE,
} from '@/web/common/AccessRights';

/** @param {{item: AiContainer, products: Array<Product> }} props */
export function AiContainerGeneralView(props) {
  const { item } = props;
  const { tenantId } = useParams();
  const owner = useSelector(getTenant(item?.ownerTenantId));
  const product = useSelector(getProduct(item?.productId));

  const canPublish = item.status === 'DRAFT';
  const canDiscontinue = item.status === 'DRAFT' || item.status === 'PUBLISHED';
  const isShared = owner?.tenantId !== tenantId;
  const canShare = item.status !== 'DISCONTINUED';
  return (
    <>
      <BreadcrumbLayout.Header
        title={
          <>
            {item.name}{' '}
            <span style={{ color: '#949', fontFamily: 'Fira Code, monospace' }}>
              {item.version}
            </span>
          </>
        }
        subtitle={item.description}
      >
        {item.status === 'DRAFT' && !isShared && (
          <Stack className="justify-content-end" direction="horizontal" gap={2}>
            <USER_ACCESS_AI_CONTAINER_UPDATE>
              <EditAiContainerButton item={item} />
            </USER_ACCESS_AI_CONTAINER_UPDATE>
            <USER_ACCESS_AI_CONTAINER_DELETE>
              <DeleteAiContainerButton item={item} />
            </USER_ACCESS_AI_CONTAINER_DELETE>
          </Stack>
        )}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          width={150}
          minWidth={80}
          data={item}
          headers={[
            {
              key: 'version',
            },
            {
              key: 'type',
              builder: ({ type }) => <span className="text-uppercase">{type}</span>,
            },
            {
              title: 'Tenant',
              builder: () =>
                owner && (
                  <Stack direction="horizontal" gap="4">
                    <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
                    {!isShared && canShare && (
                      <USER_ACCESS_AI_CONTAINER_SHARE_CREATE>
                        <ShareAiContainerButton item={item} />
                      </USER_ACCESS_AI_CONTAINER_SHARE_CREATE>
                    )}
                    {isShared && (
                      <USER_ACCESS_AI_CONTAINER_SHARE_DELETE>
                        <RemoveShareAiContainerButton item={item} />
                      </USER_ACCESS_AI_CONTAINER_SHARE_DELETE>
                    )}
                  </Stack>
                ),
            },
            {
              title: 'Product',
              builder: () =>
                product && (
                  <Link to={`/super-admin/products/${product.productId}`}>{product.name}</Link>
                ),
            },
            {
              key: 'status',
              builder: ({ status }) => (
                <Stack direction="horizontal" gap="2">
                  <div className="badge me-4" style={{ background: makeColorFrom(status, 73) }}>
                    {status}
                  </div>
                  {canPublish && !isShared && (
                    <USER_ACCESS_AI_CONTAINER_PUBLISH>
                      <PublishAiContainerButton item={item} />
                    </USER_ACCESS_AI_CONTAINER_PUBLISH>
                  )}
                  {canDiscontinue && !isShared && (
                    <USER_ACCESS_AI_CONTAINER_DISCONTINUE>
                      <DiscontinueAiContainerButton item={item} />
                    </USER_ACCESS_AI_CONTAINER_DISCONTINUE>
                  )}
                </Stack>
              ),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
