import { getTenant } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { timeFromNow } from '@/utils/formatting';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { FaDesktop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/** @param {{tenant: Tenant}} props */
export function TenantGeneralDetails(props) {
  const { tenant } = props;
  const parentTenant = useSelector(getTenant(tenant.parentTenantId));

  return (
    <>
      <BreadcrumbLayout.Header
        color="2"
        icon={<FaDesktop size={24} />}
        title={<span className="fs-4">General Details</span>}
      />
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={tenant}
          width={250}
          minWidth={150}
          headers={[
            {
              key: 'name',
            },
            {
              key: 'tenantCode',
              title: 'Code',
              builder: ({ tenantCode }) => <code>{tenantCode}</code>,
            },
            {
              key: 'tenantStatus',
              title: 'Status',
              builder: ({ tenantStatus }) => (
                <div className="badge" style={{ background: makeColorFrom(tenantStatus, 13) }}>
                  {tenantStatus}
                </div>
              ),
            },
            {
              key: 'address',
            },
            {
              key: 'parentTenantId',
              title: 'Parent Tenant',
              builder: ({ parentTenantId }) =>
                parentTenant && <Link to={`./../${parentTenantId}`}>{parentTenant.name}</Link>,
            },
            {
              key: 'createdAt',
              title: 'Created',
              builder: ({ createdAt }) => timeFromNow(createdAt),
            },
            {
              key: 'updatedAt',
              title: 'Last Updated',
              builder: ({ updatedAt }) => timeFromNow(updatedAt) || 'never',
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
