import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} InitialState
 * @property {Object.<string, Role>} roles
 * @property {number} rolesExpiresAt
 * @property {Array.<AccessRight>} accessRights
 * @property {number} accessRightsExpiresAt
 * @property {Object.<string, Tenant>} tenants
 * @property {number} tenantsExpiresAt
 * @property {Object.<string, Object.<string, Product>>} products
 * @property {number} productsExpiresAt
 * @property {Object.<string, AiModel>} aiModels
 * @property {number} aiModelsExpiresAt
 * @property {Object.<string, AiAccelerator>} accelerators
 * @property {number} acceleratorsExpiresAt
 * @property {Object.<string, Array.<DeploymentGroup>>} groups
 * @property {Object.<string, number>} groupsExpiresAt
 * @property {Object.<string, Array.<DevicePool>>} devicePools
 * @property {Object.<string, number>} devicePoolsExpiresAt
 * @property {Object.<string, Object.<string, Label>>} labels
 * @property {Object.<string, number>} labelsExpiresAt
 * @property {Object.<string, LabelCategory>} labelCategories
 * @property {Object.<string, number>} labelCategoriesExpiresAt
 * @property {Object.<string, Object.<string, Trigger>>} triggers
 * @property {Object.<string, number>} triggersExpiresAt
 */

/** @type {InitialState} */
const initialState = {
  roles: {},
  rolesExpiresAt: 0,
  accessRights: [],
  accessRightsExpiresAt: 0,
  tenants: {},
  tenantsExpiresAt: 0,
  products: {},
  productsExpiresAt: {},
  aiModels: {},
  aiModelsExpiresAt: 0,
  accelerators: {},
  acceleratorsExpiresAt: 0,
  groups: {},
  groupsExpiresAt: {},
  devicePools: {},
  devicePoolsExpiresAt: {},
  labels: {},
  labelsExpiresAt: {},
  labelCategories: {},
  labelCategoriesExpiresAt: {},
  triggers: {},
  triggersExpiresAt: {},
};

const reducers = {
  /**
   * Update the roles
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: Array.<Role>}} action
   */
  setRoles(state, action) {
    const roles = {};
    action.payload?.forEach((item) => {
      roles[item.name] = item;
    });
    state.roles = roles;

    if (!action.payload?.length) {
      state.rolesExpiresAt = 0;
    } else {
      state.rolesExpiresAt = Date.now() + 10 * 60 * 1000;
    }
  },

  /**
   * Update the access rights
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: Array.<AccessRight>}} action
   */
  setAccessRights(state, action) {
    state.accessRights = action.payload || [];
    if (!action.payload?.length) {
      state.accessRightsExpiresAt = 0;
    } else {
      state.accessRightsExpiresAt = Date.now() + 6 * 60 * 60 * 1000;
    }
  },

  /**
   * Update a specific tenant
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: Tenant}} action
   */
  setTenant(state, action) {
    if (action.payload) {
      if (!state.tenants) state.tenants = {};
      state.tenants[action.payload.tenantId] = action.payload;
    }
  },

  /**
   * Update the tenants
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: Array.<Tenant>}} action
   */
  setTenants(state, action) {
    const tenants = {};
    action.payload?.forEach((item) => {
      tenants[item.tenantId] = item;
    });
    state.tenants = tenants;

    if (!action.payload?.length) {
      state.tenantsExpiresAt = 0;
    } else {
      state.tenantsExpiresAt = Date.now() + 10 * 60 * 1000;
    }
  },

  /**
   * Update the products
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: {tenantId: string, products: Array.<Product>}}} action
   */
  setProducts(state, action) {
    let { tenantId, products } = action.payload;

    const productMap = {};
    action.payload?.products?.forEach((item) => {
      productMap[item.productId] = item;
    });
    state.products[tenantId] = productMap;

    if (!products?.length) {
      state.productsExpiresAt[tenantId] = 0;
    } else {
      state.productsExpiresAt[tenantId] = Date.now() + 10 * 60 * 1000;
    }
  },

  /**
   * Update the AI models
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: Array.<AiModel>}} action
   */
  setAiModels(state, action) {
    const aiModels = {};
    action.payload?.forEach((item) => {
      aiModels[item.modelId] = item;
    });
    state.aiModels = aiModels;

    if (!action.payload?.length) {
      state.aiModelsExpiresAt = 0;
    } else {
      state.aiModelsExpiresAt = Date.now() + 10 * 60 * 1000;
    }
  },

  /**
   * Update the AI accelerators
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: Array.<AiAccelerator>}} action
   */
  setAccelerators(state, action) {
    const accelerators = {};
    action.payload?.forEach((item) => {
      accelerators[item.codeName] = item;
    });
    state.accelerators = accelerators;

    if (!action.payload?.length) {
      state.acceleratorsExpiresAt = 0;
    } else {
      state.acceleratorsExpiresAt = Date.now() + 10 * 60 * 1000;
    }
  },

  /**
   * Update the groups
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: {tenantId: string, groups: Array.<DeploymentGroup>}}} action
   */
  setGroups(state, action) {
    const { tenantId, groups } = action.payload;
    state.groups[tenantId] = groups || [];

    if (!groups?.length) {
      state.groupsExpiresAt[tenantId] = 0;
    } else {
      state.groupsExpiresAt[tenantId] = Date.now() + 10 * 60 * 1000;
    }
  },

  /**
   * Update the device pools
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: {tenantId: string, devicePools: Array.<DevicePool>}}} action
   */
  setDevicePools(state, action) {
    const { tenantId, devicePools } = action.payload;
    state.devicePools[tenantId] = devicePools || [];

    if (!devicePools?.length) {
      state.devicePoolsExpiresAt[tenantId] = 0;
    } else {
      state.devicePoolsExpiresAt[tenantId] = Date.now() + 10 * 60 * 1000;
    }
  },

  /**
   * Update the labels
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: {tenantId: string, labels: Array.<Label>}}} action
   */
  setLabels(state, action) {
    let { tenantId, labels } = action.payload;

    const labelMap = {};
    action.payload?.labels?.forEach((item) => {
      labelMap[item.labelId] = item;
    });
    state.labels[tenantId] = labelMap;

    if (!labels?.length) {
      state.labelsExpiresAt[tenantId] = 0;
    } else {
      state.labelsExpiresAt[tenantId] = Date.now() + 10 * 60 * 1000;
    }
  },
  /**
   * Update the Label Categories
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: Array.<LabelCategory>}} action
   */
  setLabelCategories(state, action) {
    const labelCategories = {};
    action.payload?.forEach((item) => {
      labelCategories[item.id] = item;
    });
    state.labelCategories = labelCategories;

    if (state.labelCategories.length === 0) {
      state.labelCategoriesExpiresAt = 0;
    } else {
      state.labelCategoriesExpiresAt = Date.now() + 10 * 60 * 1000;
    }
  },
  /**
   * Update the triggers
   *
   * @param {import('.').CacheState} state
   * @param {{type: string, payload: {tenantId: string, triggers: Array.<Trigger>}}} action
   */
  setTriggers(state, action) {
    let { tenantId, triggers } = action.payload;

    const triggerMap = {};
    action.payload?.triggers?.forEach((item) => {
      triggerMap[item.triggerId] = item;
    });
    state.triggers[tenantId] = triggerMap;

    if (!triggers?.length) {
      state.triggersExpiresAt[tenantId] = 0;
    } else {
      state.triggersExpiresAt[tenantId] = Date.now() + 10 * 60 * 1000;
    }
  },
};

export const CacheSlice = createSlice({
  name: 'cache',
  initialState,
  reducers,
});
