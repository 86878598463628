import api from '@/api';
import { doLogout, setLoginData } from '@/state/auth';
import { ApiRequest } from '@/utils/register-api';

/** @param {import('@reduxjs/toolkit').Store<import('..').StoreState>} store */
export function refreshUserTokenPeriodically(store) {
  async function doRefresh() {
    const auth = store.getState().auth;

    if (!auth.login || !auth.login.refreshToken || auth.login.refreshTokenExpiresAt < Date.now()) {
      return store.dispatch(doLogout());
    }

    function buildRequest() {
      switch (auth.tokenData?.subject_type) {
        case 'PLATFORM_ADMIN':
          return api.sai.v1.auth['platform-admin'].refresh.$post({
            // TODO: waiting for backend feature to complete
            // params: {
            //   tenantId: auth.tenantId,
            // },
            data: {
              refreshToken: auth.login.refreshToken,
            },
          });
        case 'TENANT_ADMIN':
          return api.sai.v1.auth['tenant-admin'].refresh.$post({
            data: {
              refreshToken: auth.login.refreshToken,
            },
          });
        default:
          throw new Error(`Invalid subject type: ${auth.tokenData?.subject_type}`);
      }
    }

    try {
      const request = buildRequest();
      await request.process();
      const result = request.result.result;
      store.dispatch(setLoginData(result));
    } catch (err) {
      console.error(err);
      store.dispatch(doLogout());
    }
  }

  let timeoutId;
  store.subscribe(() => {
    const state = store.getState();
    if (!state.auth._persist.rehydrated) {
      return;
    }
    if (state.auth.login?.accessToken) {
      ApiRequest.defaultOptions.headers['Authorization'] = `Bearer ${state.auth.login.accessToken}`;
      clearTimeout(timeoutId);
      const expiry = state.auth.login.accessTokenExpiresAt;
      const time = Math.max(expiry - Date.now() - 60 * 1000, 10);
      timeoutId = setTimeout(doRefresh, time);
    } else {
      ApiRequest.defaultOptions.headers['Authorization'] = null;
      clearTimeout(timeoutId);
    }
  });
}
