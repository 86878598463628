import api from '@/api';
import { displayError } from '@/utils/toaster';
import { CheckBox } from '@/web/common/forms/CheckBox';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useContext, useEffect, useState } from 'react';
import { FaBluetooth, FaEthernet, FaMobileAlt, FaNetworkWired, FaWifi } from 'react-icons/fa';
import { ProductViewContext } from '../@components/ProductViewLayout';

export function ViewProductPageConnectivity() {
  /** @type {{product: Product, updateProduct: () => any}} */
  const { product, updateProduct } = useContext(ProductViewContext);

  const [bluetooth, setBluetooth] = useState(Boolean(product.bluetooth === 'true'));
  const [wifi, setWifi] = useState(Boolean(product.wifi === 'true'));
  const [ethernet, setEthernet] = useState(Boolean(product.ethernet === 'true'));
  const [cellular, setCellular] = useState(Boolean(product.cellular === 'true'));

  const hasNoChange = () => {
    return (
      bluetooth === (product.bluetooth === 'true') &&
      wifi === (product.wifi === 'true') &&
      ethernet === (product.ethernet === 'true') &&
      cellular === (product.cellular === 'true')
    );
  };

  useEffect(
    () => {
      if (hasNoChange()) {
        return;
      }
      const request = api.sai.v1.product.$productId(product.productId).$patch({
        data: {
          bluetooth,
          wifi,
          ethernet,
          cellular,
        },
      });
      request.process().then(updateProduct).catch(displayError);
      return () => request.cancel('ignore');
    },
    // eslint-disable-next-line
    [bluetooth, wifi, ethernet, cellular]
  );

  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaNetworkWired size="24" />}
        subtitle={product.name}
        title="Connectivity Capabilities"
      />
      <BreadcrumbLayout.Body>
        <FilledCheckBoxWithIcon
          icon={<FaEthernet size={24} />}
          label="Ethernet (LAN)"
          value={ethernet}
          onChange={setEthernet}
        />

        <FilledCheckBoxWithIcon //
          icon={<FaWifi size={24} />}
          label="Wi-Fi"
          value={wifi}
          onChange={setWifi}
        />

        <FilledCheckBoxWithIcon
          icon={<FaBluetooth size={24} />}
          label="Bluetooth"
          value={bluetooth}
          onChange={setBluetooth}
        />

        <FilledCheckBoxWithIcon
          icon={<FaMobileAlt size={24} />}
          label="Cellular (LTE / 4G)"
          value={cellular}
          onChange={setCellular}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}

function FilledCheckBoxWithIcon({ value, onChange, icon, label }) {
  return (
    <div
      className="list-group-item-action border rounded-1 mb-2 px-3 py-2 py-lg-3"
      onClick={() => onChange(!value)}
    >
      <CheckBox
        className=""
        value={value}
        onChange={onChange}
        label={
          <>
            <font color={value ? '#4d68fd' : 'grey'}>{icon}</font> {label}
          </>
        }
      />
    </div>
  );
}
