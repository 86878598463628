import { nanoid } from '@reduxjs/toolkit';
import isFunction from 'lodash/isFunction';
import { useEffect, useMemo, useState } from 'react';

const processor = Symbol('process');

/**
 * Returns a memorized ApiRequest which triggers view updates as request status changes.
 *
 * @template T
 * @param {() => T} builder Creates an ApiRequest object. It will be recreated if deps changes.
 * @param {import('react').DependencyList} deps If present, effect will only activate if the values in the list change. If absent, effect will only activate once per component lifetime.
 * @returns {T}
 */
export function useApiRequest(builder, deps) {
  const [id, setId] = useState(null);

  /** @type {import('./apiRequest').ApiRequest}  */
  const request = useMemo(
    () => {
      const req = builder();
      req[processor] = req.process;
      req.process = (callback) => {
        const cb = isFunction(callback) ? callback : () => {};
        return req[processor](() => {
          setId(nanoid());
          cb(id);
        });
      };
      return req;
    },
    // eslint-disable-next-line
    deps || []
  );

  useEffect(() => () => request.cancel('ignore'), [request]);
  return request;
}
