import { getLabelCategory } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { formatBasicOperator } from '@/utils/triggers';
import { DataItemTable } from '@/web/common/DataItemTable';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/** @param {{item: TriggerWithLabels}} props */
export function TriggerJobDetails(props) {
  const { item } = props;

  const root = item.triggerDefinition?.root || {};
  const label = item.labelList?.find((x) => x.labelId === root.variable);
  const source = useSelector(getLabelCategory(label.sourceId));

  if (!label) {
    <PageLoadingFailure message="Label was not found" />;
  }

  return (
    <DataItemTable
      data={root}
      width={300}
      minWidth={175}
      headers={[
        {
          title: 'Source',
          builder: () =>
            source && <Link to={`/events/label-categories/${source.id}`}>{source.name}</Link>,
        },
        {
          title: 'Label',
          builder: () =>
            label && (
              <Link to={`/events/labels/${item.ownerTenantId}/${root.variable}`}>
                {label?.name}
              </Link>
            ),
        },
        {
          title: 'Operator',
          builder: ({ operator }) => formatBasicOperator(operator),
        },
        {
          key: 'intValue',
          title: 'Value (Integer)',
          hidden: typeof root.intValue !== 'number',
        },
        {
          key: 'floatValue',
          title: 'Value (Decimal)',
          hidden: typeof root.floatValue !== 'number',
        },
        {
          title: 'Unit',
          builder: () => label.unit,
        },
        {
          title: 'Status',
          builder: () => (
            <div className="badge" style={{ background: makeColorFrom(label.status, 73) }}>
              {label.status}
            </div>
          ),
        },
      ]}
    />
  );
}
