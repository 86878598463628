import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Badge } from 'react-bootstrap';
import { FaCog } from 'react-icons/fa';

/** @param {{tenant: Tenant}} props */
export function TenantConfig(props) {
  const { tenant } = props;
  return (
    <>
      <BreadcrumbLayout.Header
        color="5"
        icon={<FaCog size={24} />}
        title={<span className="fs-4">Configurations</span>}
      />
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={tenant}
          width={250}
          minWidth={150}
          headers={[
            {
              key: 'logRetentionPeriod',
            },
            {
              key: 'eventRetentionPeriod',
            },
            {
              key: 'allowOpenRegistration',
              title: 'Allow Registrations',
              builder: ({ allowOpenRegistration }) => (allowOpenRegistration ? 'Yes' : 'No'),
            },
            {
              key: 'allowedEmailDomains',
              title: 'Allowed Email Domains',
              builder: ({ allowedEmailDomains }) => (
                <div className="d-flex flex-wrap gap-2">
                  {allowedEmailDomains?.map((item) => (
                    <Badge key={item}>{item}</Badge>
                  ))}
                </div>
              ),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
