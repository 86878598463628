import { useNavigate } from 'react-router';
import { DataListTable } from '@/web/common/DataListTable';

/** @param {{labelCategories: Array.<LabelCategory>}} props */
export function LabelCategoryTableView(props) {
  const labelCategories = props.labelCategories;
  const navigate = useNavigate();

  return (
    <DataListTable
      data={labelCategories}
      onClick={(item) => navigate(`/events/label-categories/${item.id}`)}
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'description',
          minWidth: 200,
        },
      ]}
    />
  );
}
