import { MainNavbar } from '../MainNavbar';
import { MainFooter } from '../MainFooter';
import './index.scss';

/**
 * @param {{children: import('react').Component}} props
 */
export function MainPageLayout(props) {
  return (
    <div id="main-layout">
      <MainNavbar />
      <main>{props.children}</main>
      <MainFooter />
    </div>
  );
}
