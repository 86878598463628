import {
  USER_ACCESS_DEVICE_LIST_READ,
  USER_ACCESS_END_USER_LIST_READ,
  USER_ACCESS_EVENT_LIST_READ_BY_TENANT,
  USER_ACCESS_STREAM_LIST_READ,
} from '@/web/common/AccessRights';
import { DevicesDonut } from '../DevicesDonut';
import { EventsDonut } from '../EventsDonut';
import { StreamsDonut } from '../StreamsDonut';
import { UsersDonut } from '../UsersDonut';

export function DashboardDonutGroup(props) {
  return (
    <>
      <USER_ACCESS_DEVICE_LIST_READ>
        <DevicesDonut {...props} />
      </USER_ACCESS_DEVICE_LIST_READ>
      <USER_ACCESS_END_USER_LIST_READ>
        <UsersDonut {...props} />
      </USER_ACCESS_END_USER_LIST_READ>
      <USER_ACCESS_EVENT_LIST_READ_BY_TENANT>
        <EventsDonut {...props} />
      </USER_ACCESS_EVENT_LIST_READ_BY_TENANT>
      <USER_ACCESS_STREAM_LIST_READ>
        <StreamsDonut {...props} />
      </USER_ACCESS_STREAM_LIST_READ>
    </>
  );
}
