import api from '@/api';
import { confirmDialog, displayError, displaySuccess } from '@/utils/toaster';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { DeviceViewContext } from '../DeviceViewLayout';

export function RebootDeviceButton(props) {
  /** @type {{device: DeviceDetail}} */
  const { device, updateDevice } = useContext(DeviceViewContext);
  const [loading, setLoading] = useState(false);

  const rebootDevice = async () => {
    const request = api.sai.v1.device.$deviceId(device.deviceId)[':reboot'].$post();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      displaySuccess('Success', `Device reboot successful!`);
      updateDevice();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      variant="primary"
      style={{ width: 100 }}
      disabled={loading}
      onClick={() =>
        confirmDialog({
          onConfirm: rebootDevice,
          title: `Reboot ${device.deviceId} (${device.tag})?`,
          message: 'It will affect all resources using this Device',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Reboot'}
    </Button>
  );
}
