import api from '@/api';
import { configParamTypes } from '@/assets/ai-models/autocomplete';
import { displayError, displaySuccess } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { AiModelContext } from '../../../@components/AiModelViewLayout';

/**
 * @param {{item: ConfigurationParameter, onDone: () => any}} props
 */
export function ConfigurationModalForm(props) {
  const { item, onDone } = props;
  const editing = Boolean(item);

  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item: model, updateItem } = useContext(AiModelContext);

  const [loading, setLoading] = useState(null);

  const [name, setName] = useState(item?.name);
  const [type, setType] = useState(item?.type);
  const [configurationValue, setConfigurationValue] = useState(item?.configurationValue);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      /** @type {ConfigurationParameter} */
      const newValue = {
        name,
        type,
        configurationValue,
      };
      const request = api.sai.v1['ai-model'].$modelId(model.modelId).$put({
        data: {
          configurationParameters: editing
            ? model.configurationParameters.map((el) => (el.name === name ? newValue : el))
            : [...model.configurationParameters, newValue],
        },
      });
      await request.process();
      setLoading(false);
      displaySuccess(`Configuration parameter saved`);
      onDone();
      updateItem();
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
    return false;
  };

  return (
    <Modal show={true} onHide={onDone}>
      <Modal.Header closeButton>
        <Modal.Title>Configuration Parameter</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <InputBox
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
            disabled={editing}
            required
          />
          <SelectBox
            label="Type"
            value={type}
            onChange={(value) => {
              setType(value);
              setConfigurationValue(null);
            }}
            options={Object.entries(configParamTypes || {})}
            itemValue={0}
            itemText={1}
          />
          <ConfigurationBox
            type={type}
            label="Configuration value"
            value={configurationValue}
            onChange={setConfigurationValue}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" variant="secondary" style={{ width: 100 }} onClick={onDone}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={loading} style={{ width: 100 }}>
            {loading ? <Spinner animation="border" size="sm" /> : editing ? 'Save' : 'Add'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function ConfigurationBox(props) {
  const { type, ...extraProps } = props;
  switch (type) {
    case 'BOOLEAN':
      return (
        <SelectBox
          {...extraProps}
          itemValue={'value'}
          itemText={'text'}
          options={[
            { value: 'false', text: 'False' },
            { value: 'true', text: 'True' },
          ]}
        />
      );
    case 'NUMBER':
      return <InputBox {...extraProps} type="number" />;
    case 'STRING':
    default:
      return <InputBox {...extraProps} />;
  }
}
