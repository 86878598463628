import api from '@/api';
import { getProductList } from '@/state/cache';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export function CreateAiContainerForm(props) {
  const { tenantId: ownerTenantId } = useParams();

  const navigate = useNavigate();
  const products = useSelector(getProductList);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [version, setVersion] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [productId, setProductId] = useState('');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['ai-container'].$post({
        data: {
          name,
          version,
          type,
          ownerTenantId,
          description,
          productId,
          components: [],
        },
      });
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Created AI Container`,
      });
      const id = request.result.result?.containerId;
      navigate(`./../${id}`);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Create AI Container</Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <FloatingLabel label="Product*" className="mb-2">
            <Form.Select
              value={productId}
              placeholder="Select Product"
              onChange={(e) => setProductId(e.currentTarget.value)}
              required
            >
              <option value={''} disabled>
                Select Product
              </option>
              {products?.map((item) => (
                <option key={item.productId} value={item.productId}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <InputBox label="Name" value={name} onChange={setName} required />
          <InputBox label="Version" value={version} onChange={setVersion} required />
          <InputBox label="Type" value={type} onChange={setType} required />
          <InputBox
            textarea
            label="Description"
            value={description}
            onChange={setDescription}
            style={{ height: '75px' }}
          />

          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-end">
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
