import { makeColorFromRanged } from '@/utils';
import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';

/**
 * @typedef {object} ColorfulBadgeProps
 * @property {any} children
 * @property {number} [from]
 * @property {number} [to]
 * @property {any} [suffix]
 * @property {boolean} light
 */

/** @param {ColorfulBadgeProps & import('react-bootstrap').BadgeProps} props */
export function ColorfulBadge(props) {
  const { children, light, from, to, suffix = 1, style, ...badgeProps } = props;

  const lightColor = '#fff';
  const darkColor = useMemo(() => {
    return makeColorFromRanged(children, suffix, from ?? 25, to ?? 145);
  }, [children, suffix, light, from, to]);

  if (!children) {
    return null;
  }

  return (
    <Badge
      {...badgeProps}
      bg={''}
      style={{
        backgroundColor: light ? lightColor : darkColor,
        color: light ? darkColor : lightColor,
        ...style,
      }}
    >
      {children}
    </Badge>
  );
}
