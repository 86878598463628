import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ShareProductForm } from '../ShareProductForm';

/** @param {{item: Product, disabled: boolean}} props */
export function ShareProductButton(props) {
  const { item, disabled } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        size="sm"
        type="button"
        variant="outline-primary"
        style={{ width: 175 }}
        disabled={disabled}
        onClick={() => setShowModal(true)}
      >
        Share Product
      </Button>
      <Modal size={'md'} show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Share Product</Modal.Title>
        </Modal.Header>
        <ShareProductForm item={item} onClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
}
