import api from '@/api';
import { setGroups } from '..';

/**
 * Fetch and cache all groups
 *
 * @param {string} tenantId Tenant ID to fetch
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchAllGroupsByTenant = (tenantId, refresh) => async (dispatch, getState) => {
  const state = getState();
  if (!state.auth.login?.accessToken) return;

  if (state.cache.groupsExpiresAt[tenantId] > Date.now()) {
    if (!refresh && state.cache.groups[tenantId].length > 0) return;
  }

  const groups = [];
  const pageSize = 50;

  // Get deployment groups of the tenant
  let hasMore = true;
  let continuationToken = null;
  while (hasMore) {
    const request = api.sai.v1['deployment-group'].$get({
      params: {
        pageSize,
        continuationToken,
        tenantId,
      },
      headers: {
        Authorization: `Bearer ${state.auth.login.accessToken}`,
      },
    });
    await request.process();
    const result = request.result.result.results;
    groups.push(...result);
    continuationToken = request.result.result.continuationToken;
    hasMore = Boolean(continuationToken) && result.length >= pageSize;
  }

  await dispatch(setGroups({ tenantId, groups }));
};
