import api from '@/api';
import { useParams } from 'react-router-dom';
import { TokenBasedPagination } from '@/web/common/pagination/TokenBasedPagination';
import { DeploymentHistoryTableView } from '../DeploymentHistoryTableView';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListFirmwareDeploymentActivityModel>} RequestType */

export function DeploymentHistoryListView() {
  const { tenantId, productId } = useParams();
  return (
    <TokenBasedPagination
      request={(limit, token) =>
        api.sai.v1.product.$productId(productId)['firmware-deployment-history'].$get({
          params: {
            tenantId,
            pageSize: limit,
            beforeActivityId: token,
          },
        })
      }
      nextToken={
        /**
         * @param {RequestType} request
         * @param {number} limit
         */
        (request, limit) => {
          const items = request.result?.result || [];
          return items[limit - 1]?.activityId;
        }
      }
      loading={() => {
        return <PageLoadingSpinner status={`Loading deployment history...`} />;
      }}
      success={
        /** @param {RequestType} request */
        (request) => {
          let deployments = request.result.result;
          if (deployments.length === 0) {
            return <PageLoadingFailure message="No more deployment history" />;
          }
          return <DeploymentHistoryTableView deployments={deployments} />;
        }
      }
    />
  );
}
