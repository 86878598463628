import api from '@/api';
import { checkIsCacheValid, updateData } from '..';

/**
 * Fetch and cache Platform Admin chart data
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchPlatformAdminDonutData = (refresh) => async (dispatch, getState) => {
  const state = getState();

  const { login } = state.auth;
  if (!login?.accessToken) return;

  if (!refresh && checkIsCacheValid('platformAdmins')(state)) return;

  const request = api.sai.v1['platform-admin'][':count'].$get();
  await request.process();

  /** @type {import('..').ChartData} */
  const chartData = {
    platformAdmins: {
      total: request.result.result.count,
    },
  };

  await dispatch(updateData(chartData));
};
