import { USER_ACCESS_TENANT_CREATE } from '@/web/common/AccessRights';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { createContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SettingsDonutGroup } from '../@components/SettingsDonutGroup';
import { TenantListView } from './@components/TenantListView';

export const TenantListContext = createContext();

export function TenantManagement() {
  const [id, changeId] = useState(null);
  return (
    <TenantListContext.Provider value={[id, changeId]}>
      <SidebarPlotLayout sidebar={<SettingsDonutGroup sidebar={true} />}>
        <BreadcrumbLayout.Header title="Manage Tenants">
          <USER_ACCESS_TENANT_CREATE>
            <Button as={Link} variant="outline-primary" to="./create">
              <FaPlus /> New Tenant
            </Button>
          </USER_ACCESS_TENANT_CREATE>
        </BreadcrumbLayout.Header>
        <TenantListView />
      </SidebarPlotLayout>
    </TenantListContext.Provider>
  );
}
