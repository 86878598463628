import iziToast from 'izitoast';
import { useContext, useEffect, useRef, useState } from 'react';
import { isFQDN } from 'validator';
import { Button, Form, Stack } from 'react-bootstrap';
import { DomainListContext } from '../EditDomainList';

/**
 * @typedef {object} DomainInputFormProps
 * @property {string} [value] The domain name. Pass something to activate edit mode.
 * @property {() => void} [onCancel] The cancel button is visible when in edit mode.
 * @property {(value: string, previous: string) => void} onSubmit
 */

/** @param {{ }} props  */
export function DomainInputForm(props) {
  const { value, onSubmit, onCancel } = props;

  const inputRef = useRef(null);
  const domains = useContext(DomainListContext);
  const [inputText, setInputText] = useState(value || '');

  useEffect(() => {
    inputRef.current.focus();
  }, [value]);

  /** @param {Event} e */
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const text = inputText.trim();
    if (!text) {
      setInputText('');
    } else if (value === text) {
      onCancel();
    } else if (domains.indexOf(text) >= 0) {
      iziToast.destroy();
      iziToast.error({
        title: 'Error',
        message: `<b>${text}</b> already exists`,
      });
    } else if (!isFQDN(text)) {
      iziToast.destroy();
      iziToast.error({
        title: 'Error',
        message: `<b>${text}</b> is not a valid domain name`,
      });
    } else {
      onSubmit(text, value);
      setInputText('');
    }
  };

  return (
    <Form onSubmit={handleSubmit} onBlur={onCancel}>
      <Stack direction="horizontal" gap={2}>
        <Form.Control
          ref={inputRef}
          type="text"
          value={inputText}
          placeholder="Enter new domain name"
          style={{ height: 40, width: '100%' }}
          onChange={(e) => setInputText(e.currentTarget.value)}
        />
        <Button type="submit" variant={value ? 'outline-success' : 'success'}>
          {value ? 'OK' : 'Add'}
        </Button>
        {value && (
          <Button type="button" variant="outline-warning" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Stack>
    </Form>
  );
}
