import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { FaNetworkWired } from 'react-icons/fa';

export function DeviceServerConfigView() {
  return (
    <>
      <BreadcrumbLayout.Header title="Server Configuration" icon={<FaNetworkWired />} />
      <BreadcrumbLayout.Body>
        <div>View server configuration of the device</div>
      </BreadcrumbLayout.Body>
    </>
  );
}
