import React from 'react';
import { Card } from 'react-bootstrap';

// TODO: add jsdoc comment
export function BoundingBox(props) {
  const { boundingBoxLabels, isFullscreen } = props;
  const labelsGraterThanZero = boundingBoxLabels?.length > 0;
  return (
    <div
      style={{
        height: '150px',
        border: labelsGraterThanZero ? '1px solid #ccc' : 'transparent',
        overflow: 'auto',
        width: '100%',
      }}
      className="boundingBox"
    >
      {isFullscreen && <Card.Header>Bounding Box Labels</Card.Header>}
      {labelsGraterThanZero && (
        <div style={{ paddingTop: '12px' }}>
          {boundingBoxLabels.map((item, index) => {
            return (
              <h6
                key={index}
                style={{
                  color: item.color,
                  fontSize: '10px',
                  paddingLeft: '10px',
                  overflow: 'hidden',
                }}
              >
                {`${item.label} &#10144; ${item.confidence}`}
              </h6>
            );
          })}
        </div>
      )}
    </div>
  );
}
