import isFunction from 'lodash/isFunction';
import startCase from 'lodash/startCase';
import qs from 'query-string';
import { createRef } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {object} TabItem
 * @property {string} key
 * @property {any} [title]
 * @property {any} [content]
 */

/**
 * @typedef {object} TabsNavigationProps
 * @property {Array.<TabItem>} tabs
 * @property {string} [tabKey] Query param name [Default: 'tab']
 * @property {string} [defaultTab] The default selected tab
 * @property {boolean} [defaultView] Add nice-tabs class if defaultView is false
 * @property {boolean} [replace] Replace current route [Default: false]
 * @property {boolean} [retain] Whether to retain query and state [Default: false]
 * @property {boolean} [disableNavigation] Do not change route on tab change [Default: false]
 * @property {(tab: string) => any} [onTabChange]
 */

/** @param {TabsNavigationProps} props */
export function TabsNavigation(props) {
  const tabsRef = createRef();
  const navigate = useNavigate();
  const location = useLocation();
  const query = qs.parse(location.search);

  const tabKey = props.tabKey || 'tab';
  const defaultKey = props.defaultTab || props.tabs[0].key;
  const activeKey = query[tabKey] || defaultKey;

  /** @param {string} tab */
  const handleTab = (tab) => {
    /**@type {HTMLDivElement} */
    const div = tabsRef.current.children[1];
    if (div.offsetTop > window.scrollY + window.innerHeight * 0.5) {
      window.scrollTo(0, div.offsetTop - 150);
    }

    if (tab !== activeKey) {
      if (isFunction(props.onTabChange)) {
        props.onTabChange(tab);
      }

      if (props.disableNavigation) {
        return;
      }
      navigate(
        {
          ...location,
          search: qs.stringify({
            ...(props.retain ? query : []),
            [tabKey]: tab,
          }),
        },
        {
          replace: props.replace,
          state: props.retain ? null : location.state,
        }
      );
    }
  };

  return (
    <div ref={tabsRef}>
      <Tabs
        mountOnEnter
        unmountOnExit
        onSelect={handleTab}
        activeKey={activeKey}
        defaultActiveKey={defaultKey}
        className={props.defaultView ? null : 'nice-tabs'}
      >
        {props.tabs.map((tab) => (
          <Tab key={tab.key} eventKey={tab.key} title={tab.title || startCase(tab.key)}>
            {isFunction(tab.content) ? tab.content() : tab.content}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
