import api from '@/api';
import { chimes, mediaTypes, triggerTypes } from '@/assets/triggers/autocomplete';
import { displayError, displaySuccess } from '@/utils/toaster';
import { CheckBox } from '@/web/common/forms/CheckBox';
import { InputBox } from '@/web/common/forms/InputBox';
import { InputSlider } from '@/web/common/forms/InputSlider';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicTypeComponentEdit } from '../../../@components/BasicTypeComponentEdit';
import { ComplexTypeComponentEdit } from '../../../@components/ComplexTypeComponentEdit';

export function CreateTriggerForm() {
  const navigate = useNavigate();
  const { tenantId } = useParams();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState();
  const [tts, setTts] = useState();
  const [chime, setChime] = useState();
  const [critical, setCritical] = useState();
  const [mediaType, setMediaType] = useState();
  const [coolDownTimer, setCoolDownTimer] = useState(1);
  const [triggerDurationThreshold, setTriggerDurationThreshold] = useState(0);

  const [type, setType] = useState('BASIC');
  const [triggerDefinition, setTriggerDefinition] = useState();

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      const request = api.sai.v1['trigger'].$post({
        data: {
          name,
          chime,
          coolDownTimer,
          critical,
          mediaType,
          ownerTenantId: tenantId,
          triggerDefinition,
          triggerDurationThreshold,
          tts,
        },
      });
      await request.process();
      setLoading(false);
      displaySuccess('Success', 'Created a new trigger');
      navigate({ pathname: './..' });
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit}>
        <Card.Header>
          <Card.Title>Create Trigger</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputBox
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
            required
          />
          <InputSlider
            min={1}
            max={3600}
            label="Cooldown Timer"
            unit={'seconds'}
            value={coolDownTimer}
            onChange={setCoolDownTimer}
          />
          <InputSlider
            min={0}
            max={20}
            label="Trigger Duration Threshold"
            unit={'seconds'}
            value={triggerDurationThreshold}
            onChange={setTriggerDurationThreshold}
          />
          <SelectBox
            label="Media Type"
            placeholder="Select Media Type"
            value={mediaType}
            onChange={setMediaType}
            options={Object.entries(mediaTypes || {})}
            itemValue={0}
            itemText={1}
            required
          />
          <InputBox
            label="Text to play in dashcam"
            placeholder="Put a message from your driver"
            value={tts}
            onChange={setTts}
            required
          />
          <SelectBox
            label="Chime"
            placeholder="Select Chime"
            value={chime}
            onChange={setChime}
            options={Object.entries(chimes || {})}
            itemValue={0}
            itemText={1}
            required
          />
          <CheckBox type="checkbox" label="Critical" checked={critical} onChange={setCritical} />
          <hr className="mx-auto w-75" />
          <SelectBox
            label="Type"
            value={type}
            onChange={(type) => {
              setType(type);
              setTriggerDefinition({});
            }}
            options={Object.entries(triggerTypes || {})}
            itemValue={0}
            itemText={1}
            required
          />
          {type === 'BASIC' && (
            <BasicTypeComponentEdit value={triggerDefinition} onChange={setTriggerDefinition} />
          )}
          {type === 'COMPLEX' && (
            <ComplexTypeComponentEdit value={triggerDefinition} onChange={setTriggerDefinition} />
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-end">
          <Button
            type="submit"
            variant="primary"
            style={{ width: 132 }}
            disabled={loading || !triggerDefinition?.root?.type}
          >
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
