import { PageBreadcrumb } from '@/web/common/PageBreadcrumb';
import { createContext } from 'react';
import { Container } from 'react-bootstrap';
import { MainPageLayout } from '../MainPageLayout';
import { PageBody } from './body';
import { PageHeader } from './header';
import './index.scss';

export const TitledPageHeaderContext = createContext();

/**
 * @typedef {object} BreadcrumbLayoutProps
 * @property {boolean} [hideBar]
 * @property {imoprt('react').Component} [children] The children of this component.
 * @property {boolean} [breadcrumb] Whether to display the breadcrumb or not [default: true]
 * @property {string} [color] The display color generator for the header bar. [default: title]
 * @property {imoprt('react').Component} [icon] Icon to display before title.
 * @property {imoprt('react').Component} [title] The title of the page.
 * @property {imoprt('react').Component} [subtitle] The subtitle of the page.
 */

/** @param {BreadcrumbLayoutProps} props */
function BreadcrumbLayout(props) {
  const { children, breadcrumb, color, icon, title, hideBar, subtitle, ...bodyProps } = props;
  const headerProps = { color, icon, title, subtitle, hideBar };

  const showBreadcrumb = breadcrumb === undefined || breadcrumb;

  return (
    <MainPageLayout>
      <Container {...bodyProps} className={`titled-page ${bodyProps.className || ''}`}>
        <TitledPageHeaderContext.Provider value={headerProps}>
          {showBreadcrumb && <PageBreadcrumb />}
          {children}
        </TitledPageHeaderContext.Provider>
      </Container>
    </MainPageLayout>
  );
}

export default Object.assign(BreadcrumbLayout, {
  Breadcrumb: PageBreadcrumb,
  Header: PageHeader,
  Body: PageBody,
});
