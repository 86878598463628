import { calculateMd5sum } from '@/utils/files/file-utils';
import { formatFileSize, formatTimeRfc1123 } from '@/utils/formatting';
import { ApiRequest } from '@/utils/register-api';
import { displayError, displayWarning } from '@/utils/toaster';
import axios from 'axios';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ChooseFileModalInput } from './file-chooser';
import { FileUploadingModalBody } from './file-uploading';
import { FileValidatingModalBody } from './file-validating';
import { UploadPreviewModalBody } from './upload-preview';

const UploaderStateEnum = Object.freeze({
  file_input: 0,
  validating_file: 1,
  file_validated: 2,
  uploading_file: 3,
  file_uploaded: 4,
  cancelled: 5,
});

/**
 * @typedef {object} FileUploaderModalProps
 * @property {string} [title]
 * @property {() => any} onDone
 * @property {FileUploadOptions} options
 * @property {(value: FileModel) => any} onChange
 * @property {(previous: FileUploadCredentialModel?, cancelToken: any) => Promise<FileUploadCredentialModel>} credentialFetcher
 * @property {(data: FileAddRequest, cancelToken: any) => Promise<FileModel>} entryCreator
 */

/** @param {FileUploaderModalProps} props */
export function FileUploadModal(props) {
  const { title, options, onDone, onChange, credentialFetcher, entryCreator } = props;

  /** @type {[number, (v) => any]} */
  const [state, setState] = useState(UploaderStateEnum.file_input);

  /** @type {[FileUploadCredentialModel, (v) => any]} */
  const [credentials, setCredentials] = useState();

  /** @type {[string, (v) => any]} */
  const [md5sum, setMd5sum] = useState();

  /** @type {[File, (v) => any]} */
  const [file, setFile] = useState();

  /** @type {[import('@/utils/register-api').ApiRequestProgress, (v) => any]} */
  const [progress, setProgress] = useState();

  /** @type {[import('axios').CancelTokenSource, (v) => any]} */
  const [canceller, setCanceller] = useState(axios.CancelToken.source());

  const canHide = state !== UploaderStateEnum.uploading_file;

  const handleCancel = () => {
    canceller.cancel('ignore');
    setState(UploaderStateEnum.file_input);
    setCanceller(axios.CancelToken.source());
  };

  /** @param {File} file */
  const validateFile = async (file) => {
    try {
      setState(UploaderStateEnum.validating_file);

      if (options?.validateFileType && !options.validateFileType(file.type)) {
        displayWarning('Invalid file type', 'Please select a valid file with extension');
        setState(UploaderStateEnum.file_input);
        return false;
      }

      if (options?.maxFileSizeBytes && file.size > options.maxFileSizeBytes) {
        displayWarning(
          'File size should not exceed ' + formatFileSize(options.maxFileSizeBytes),
          'Contact with system administrator to change this limit.'
        );
        setState(UploaderStateEnum.file_input);
        return false;
      }

      const [creds, md5] = await Promise.all([
        credentialFetcher(credentials, canceller), // fetch credentials
        calculateMd5sum(file), // calculate file md5
      ]);

      if (!creds?.uploadUrl || creds.urlExpiresAt < Date.now()) {
        displayWarning('Could not obtain upload credentials');
        setState(UploaderStateEnum.file_input);
        return false;
      }

      setCredentials(creds);
      setMd5sum(md5);
      setFile(file);
      setState(UploaderStateEnum.file_validated);
      return true;
    } catch (err) {
      setState(UploaderStateEnum.file_input);
      console.debug(err);
      displayError(err);
      return false;
    }
  };

  const startUploading = async () => {
    try {
      setProgress(null);
      setState(UploaderStateEnum.uploading_file);

      const creds = await credentialFetcher(credentials, canceller);

      const uploader = new ApiRequest({
        method: 'PUT',
        url: creds.uploadUrl,
        data: file,
        headers: {
          'content-type': file.type,
          'x-ms-blob-type': 'BlockBlob',
          'x-ms-date': formatTimeRfc1123(),
        },
      });
      uploader.cancelToken = canceller;
      await uploader.process(() => setProgress(uploader.upload));
      if (uploader.status !== 'success') {
        setState(UploaderStateEnum.file_validated);
        return;
      }

      const result = await entryCreator({
        md5sum,
        actualFileName: file.name,
        contentLength: file.size,
        containerName: credentials.containerName,
        storageProvider: credentials.storageProvider,
        storedFilePath: credentials.filePath,
      });
      if (!result.id) {
        throw new Error('Failed to create entry');
      }

      setState(UploaderStateEnum.file_uploaded);
      onChange(result);
      onDone();
    } catch (err) {
      console.debug(err);
      displayError(err);
      setState(UploaderStateEnum.file_validated);
    }
  };

  return (
    <Modal size="md" show={true} onHide={canHide ? onDone : null}>
      <Modal.Header closeButton={canHide}>
        <Modal.Title>{title || 'Upload File'}</Modal.Title>
      </Modal.Header>
      {state === UploaderStateEnum.file_input ? (
        <ChooseFileModalInput onInput={validateFile} />
      ) : state === UploaderStateEnum.validating_file ? (
        <FileValidatingModalBody onCancel={handleCancel} />
      ) : state === UploaderStateEnum.file_validated ? (
        <UploadPreviewModalBody
          file={file}
          md5sum={md5sum}
          credentials={credentials}
          onCancel={handleCancel}
          onSubmit={startUploading}
        />
      ) : state === UploaderStateEnum.uploading_file ? (
        <FileUploadingModalBody progress={progress} onCancel={handleCancel} />
      ) : null}
    </Modal>
  );
}
