import api from '@/api';
import { getTenantId } from '@/state/auth';
import { getProductMap, getTenantMap } from '@/state/cache';
import { USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_FIRMWARE } from '@/web/common/AccessRights';
import { ColorfulBadge } from '@/web/common/ColorfulBadge';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { DeployFirmwareButton } from '../../../@components/DeployFirmwareButton';

/** @param {{status: "DRAFT" | "PUBLISHED" | "DISCONTINUED"}} props */
export function SharedFirmwareListView(props) {
  const { status } = props;
  const [searchParams] = useSearchParams();
  const tenantId = useSelector(getTenantId);
  const tenantMap = useSelector(getTenantMap);
  const productMap = useSelector(getProductMap);
  const productId = searchParams?.get('productId');
  const navigate = useNavigate();

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (pageSize, continuationToken) => {
        const request = api.sai.v1['firmware-share'].$get({
          params: {
            pageSize,
            continuationToken,
            tenantId,
            productId,
            status,
          },
        });
        await request.process();
        return {
          results: request.result.result.results.map((item) => ({
            ...item,
            ...item.firmware,
          })),
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {SharedFirmwareDetailModel & Firmware} item */
        (item) => navigate(`/admin/firmwares/${tenantId}/${item.firmwareId}`)
      }
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'version',
          align: 'center',
          width: 100,
        },
        {
          key: 'type',
          minWidth: 100,
          align: 'center',
        },
        {
          key: 'status',
          minWidth: 125,
          align: 'center',
          builder: ({ status }) => status && <ColorfulBadge>{status}</ColorfulBadge>,
        },
        {
          key: 'productId',
          title: 'Product',
          minWidth: 150,
          disableAction: true,
          builder: ({ productId }) =>
            productMap &&
            productMap[productId] && (
              <Link to={`/super-admin/products/${productId}`}>{productMap[productId].name}</Link>
            ),
        },
        {
          key: 'ownerTenantId',
          title: 'Owner Tenant',
          minWidth: 150,
          disableAction: true,
          builder: ({ ownerTenantId }) =>
            tenantMap &&
            tenantMap[ownerTenantId] && (
              <Link to={`/settings/tenants/${ownerTenantId}`}>{tenantMap[ownerTenantId].name}</Link>
            ),
        },
        {
          key: 'accessLevel',
          minWidth: 120,
        },
        {
          key: 'description',
          minWidth: 200,
        },
        {
          title: 'Actions',
          disableAction: true,
          width: 150,
          builder: (item) => (
            <USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_FIRMWARE>
              <div className="text-center">
                {item.status === 'DISCONTINUED' ? null : <DeployFirmwareButton item={item} />}
              </div>
            </USER_ACCESS_DEPLOYMENT_GROUP_DEPLOY_FIRMWARE>
          ),
        },
      ]}
    />
  );
}
