import { fetchAllProducts } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { DashboardDonutGroup } from '../../@components/DashboardDonutGroup';
import { StreamListView } from './@components/StreamListView';

export function StreamListViewWithTenant() {
  useDispatchLoader(fetchAllProducts);

  return (
    <SidebarPlotLayout sidebar={<DashboardDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Streams" />
      <StreamListView />
    </SidebarPlotLayout>
  );
}
