import { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { FaDesktop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getAiAccelerator, getTenant } from '@/state/cache';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { ProductViewContext } from '../@components/ProductViewLayout';
import { EditProductButton } from './@components/EditProductButton';
import { ShareProductButton } from '../@components/ShareProductButton';
import { RemoveShareProductButton } from '../@components/RemoveShareProductButton';
import { Stack } from 'react-bootstrap';
import {
  USER_ACCESS_PRODUCT_DELETE,
  USER_ACCESS_PRODUCT_SHARE_CREATE,
  USER_ACCESS_PRODUCT_SHARE_DELETE,
  USER_ACCESS_PRODUCT_UPDATE,
} from '@/web/common/AccessRights';
import { DeleteProductButton } from './@components/DeleteProductButton';

export function ViewProductPageGeneral() {
  /** @type {{product: Product, updateProduct: () => any}} */
  const { product } = useContext(ProductViewContext);
  const { tenantId } = useParams();
  const owner = useSelector(getTenant(product.ownerTenantId));
  const accelerator = useSelector(getAiAccelerator(product.aiAcceleratorIds?.[0]));
  const isShared = owner?.tenantId !== tenantId;
  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaDesktop />}
        title={'General Information'}
        subtitle={
          <>
            Showing general information about <b>{product.name}</b>
          </>
        }
      >
        <USER_ACCESS_PRODUCT_UPDATE>
          <EditProductButton />
        </USER_ACCESS_PRODUCT_UPDATE>
        <USER_ACCESS_PRODUCT_DELETE>
          <DeleteProductButton item={product} />
        </USER_ACCESS_PRODUCT_DELETE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={product}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'name',
              title: 'Name',
            },
            {
              key: 'serialNo',
              title: 'Serial',
            },
            {
              key: 'description',
              title: 'Description',
            },
            // TODO: Uncomment after product release/discontinue is implemented
            // {
            //   key: 'productStatus',
            //   title: 'Status',
            //   align: 'center',
            //   builder: ({ productStatus }) => (
            //     <span className="badge" style={{ background: makeColorFrom(productStatus, 22) }}>
            //       {productStatus}
            //     </span>
            //   ),
            // },
            {
              key: 'productManual',
              title: 'Manual',
              builder: ({ productManual }) =>
                productManual && (
                  <a
                    className="value"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={productManual}
                  >
                    {productManual}
                  </a>
                ),
            },
            {
              key: 'website',
              title: 'Website',
              builder: ({ website }) =>
                website && (
                  <a className="value" target="_blank" rel="noopener noreferrer" href={website}>
                    {website}
                  </a>
                ),
            },
            {
              title: 'Owner Tenant',
              builder: () =>
                owner && (
                  <Stack direction="horizontal" gap="4">
                    <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
                    {!isShared && (
                      <USER_ACCESS_PRODUCT_SHARE_CREATE>
                        <ShareProductButton item={product} />
                      </USER_ACCESS_PRODUCT_SHARE_CREATE>
                    )}
                    {isShared && (
                      <USER_ACCESS_PRODUCT_SHARE_DELETE>
                        <RemoveShareProductButton item={product} />
                      </USER_ACCESS_PRODUCT_SHARE_DELETE>
                    )}
                  </Stack>
                ),
            },
            {
              key: 'aiAcceleratorIds',
              title: 'AI Accelerator',
              builder: () =>
                accelerator && (
                  <Link to={`/super-admin/ai-accelerator/${accelerator.codeName}`}>
                    {accelerator.name}
                  </Link>
                ),
            },
            {
              key: 'firmwareComponents',
              title: 'Firmware Components',
              builder: ({ firmwareComponents }) =>
                firmwareComponents?.map((value) => (
                  <Badge pill key={value} bg="secondary" className="mx-1">
                    {value}
                  </Badge>
                )),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
