/**
 * @param {'APPLICATION_PACKAGE'|'CAN_BUS_FW'|'SYSTEM_IMAGE'|'OEM_IMAGE' } type
 */
export const getFirmwareTypeName = (type) => {
  switch (type) {
    case 'APPLICATION_PACKAGE':
      return 'Application Package';
    case 'CAN_BUS_FW':
      return 'Canbus';
    case 'SYSTEM_IMAGE':
      return 'System Image';
    case 'OEM_IMAGE':
      return 'OEM Image';
    default:
      return type;
  }
};
