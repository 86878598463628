import api from '@/api';
import { getTenantMap } from '@/state/cache';
import { formatDateTime } from '@/utils/formatting';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RemoveGroupButton } from '../RemoveGroupButton';

export function DeviceListView() {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const tenantMap = useSelector(getTenantMap);

  const [id, setId] = useState(Date.now());

  const updateParent = () => {
    setId(Date.now());
  };

  return (
    <TokenBasedInfiniteScrollTable
      key={id}
      fetcher={async (limit, token) => {
        const request = api.sai.v1['deployment-group'].$groupId(groupId).device.$get({
          params: {
            pageSize: limit,
            continuationToken: token,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {OnboardedDeviceModel} [onboardedDevice] */
        (onboardedDevice) => navigate(`/dashboard/devices/${onboardedDevice.deviceId}`)
      }
      headers={[
        {
          key: 'label',
          minWidth: 150,
        },
        {
          key: 'deviceId',
          minWidth: 150,
        },

        {
          key: 'onboardTime',
          builder: ({ onboardTime }) => formatDateTime(onboardTime),
          minWidth: 200,
        },
        {
          key: 'ownerTenantId',
          title: 'Owner Tenant',
          disableAction: true,
          minWidth: 200,
          builder: ({ ownerTenantId }) =>
            tenantMap &&
            tenantMap[ownerTenantId] && (
              <Link to={`/settings/tenants/${ownerTenantId}`}>{tenantMap[ownerTenantId].name}</Link>
            ),
        },
        {
          key: 'actions',
          disableAction: true,
          minWidth: 200,
          builder: (row) => (
            <RemoveGroupButton updateParent={updateParent} groupId={groupId} device={row} />
          ),
        },
      ]}
    />
  );
}
