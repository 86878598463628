import { Badge } from 'react-bootstrap';
import { formatDateTime } from '@/utils/formatting';
import { DataListTable } from '@/web/common/DataListTable';
import { getFirmwareTypeName } from '@/utils/products';

/** @param {{deployments: Array.<FirmwareDeploymentActivityModel>}} props */
export function DeploymentHistoryTableView(props) {
  const deploymentmentHistory = props.deployments;

  return (
    <DataListTable
      data={deploymentmentHistory}
      headers={[
        {
          key: 'activityTime',
          title: 'Time',
          width: 200,
          builder: (item) => formatDateTime(item.activityTime),
        },
        {
          key: 'activityType',
          title: 'Activity',
          width: 100,
          builder: (item) => (
            <Badge bg={{ DEPLOY: 'secondary', UNDEPLOY: 'danger' }[item.activityType]}>
              {item.activityType}
            </Badge>
          ),
        },
        {
          key: 'activityId',
          title: 'Activity ID',
          width: 120,
        },
        {
          key: 'firmwareName',
          title: 'Name',
          width: 275,
        },
        {
          key: 'firmwareType',
          title: 'Type',
          /** @param {FirmwareDeploymentModel} [item]*/
          builder: (item) => getFirmwareTypeName(item.firmwareType),
        },
        {
          key: 'firmwareVersion',
          title: 'Version',
        },
      ]}
    />
  );
}
