import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { FaDesktop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getAiAccelerator, getTenant } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { timeFromNow } from '@/utils/formatting';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { AiModelContext } from '../@components/AiModelViewLayout';
import { DeleteAiModelButton } from './@components/DeleteAiModelButton';
import { DiscontinueAiModelButton } from './@components/DiscontinueAiModelButton';
import { EditAiModelButton } from './@components/EditAiModelButton';
import { PublishAiModelButton } from './@components/PublishAiModelButton';
import { ShareAiModelButton } from './@components/ShareAiModelButton';
import { RemoveShareAiModelButton } from './@components/RemoveShareAiModelButton';
import {
  USER_ACCESS_AI_MODEL_DELETE,
  USER_ACCESS_AI_MODEL_DISCONTINUE,
  USER_ACCESS_AI_MODEL_PUBLISH,
  USER_ACCESS_AI_MODEL_SHARE_CREATE,
  USER_ACCESS_AI_MODEL_SHARE_DELETE,
  USER_ACCESS_AI_MODEL_UPDATE,
} from '@/web/common/AccessRights';

export function AiModelGeneral() {
  const { tenantId } = useParams();

  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item } = useContext(AiModelContext);
  const owner = useSelector(getTenant(item.ownerTenantId));
  const accelerator = useSelector(getAiAccelerator(item.aiAcceleratorIds?.[0]));

  const isShared = tenantId !== item.ownerTenantId;
  const canPublish = item.status === 'DRAFT';
  const canDiscontinue = item.status === 'DRAFT' || item.status === 'PUBLISHED';
  const canEditOrDelete = item.status === 'DRAFT';
  const canShare = item.status !== 'DISCONTINUED';

  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaDesktop />}
        title={'General Information'}
        subtitle={
          <>
            General information about <b>{item.name}</b> AI model
          </>
        }
      >
        {!isShared && (
          <div className="d-flex gap-2 justify-content-end">
            {canEditOrDelete && (
              <>
                <USER_ACCESS_AI_MODEL_DELETE>
                  <DeleteAiModelButton />
                </USER_ACCESS_AI_MODEL_DELETE>
                <USER_ACCESS_AI_MODEL_UPDATE>
                  <EditAiModelButton />
                </USER_ACCESS_AI_MODEL_UPDATE>
              </>
            )}
          </div>
        )}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={item}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'name',
              title: 'Name',
            },
            {
              key: 'version',
            },
            {
              key: 'vendor',
            },
            {
              key: 'description',
            },
            {
              key: 'type',
            },
            {
              key: 'ownerTenantId',
              title: 'Tenant',
              builder: () =>
                owner && (
                  <Stack direction="horizontal" gap="4">
                    <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
                    {!isShared && canShare && (
                      <USER_ACCESS_AI_MODEL_SHARE_CREATE>
                        <ShareAiModelButton item={item} />
                      </USER_ACCESS_AI_MODEL_SHARE_CREATE>
                    )}
                    {isShared && (
                      <USER_ACCESS_AI_MODEL_SHARE_DELETE>
                        <RemoveShareAiModelButton item={item} />
                      </USER_ACCESS_AI_MODEL_SHARE_DELETE>
                    )}
                  </Stack>
                ),
            },
            {
              key: 'aiAcceleratorIds',
              title: 'AI Accelerator',
              builder: () =>
                accelerator && (
                  <Link to={`/super-admin/ai-accelerators/${accelerator.codeName}`}>
                    {accelerator.name}
                  </Link>
                ),
            },
            {
              key: 'status',
              builder: ({ status }) => (
                <div className="d-flex flex-wrap gap-2 align-items-center">
                  <div>
                    <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
                      {status}
                    </span>
                  </div>
                  {canPublish && (
                    <USER_ACCESS_AI_MODEL_PUBLISH>
                      <PublishAiModelButton />
                    </USER_ACCESS_AI_MODEL_PUBLISH>
                  )}
                  {canDiscontinue && (
                    <USER_ACCESS_AI_MODEL_DISCONTINUE>
                      <DiscontinueAiModelButton />
                    </USER_ACCESS_AI_MODEL_DISCONTINUE>
                  )}
                </div>
              ),
            },
            {
              key: 'inputLayer',
            },
            {
              key: 'outputLayer',
            },
            {
              key: 'createdAt',
              builder: ({ createdAt }) => timeFromNow(createdAt),
            },
            {
              key: 'updatedAt',
              builder: ({ updatedAt }) => timeFromNow(updatedAt) || 'never',
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
