import { implementAPI, ApiRequest } from '@/utils/register-api';

const api = {
  // ac: require('./ac-service'),
  sai: require('./sai-service'),
};

for (const item of Object.values(api)) {
  implementAPI(item, (config) => new ApiRequest(config));
}

export default api;
