export const hertzOptions = {
  1: '1 Hz',
  2: '2 Hz',
  4: '4 Hz',
  5: '5 Hz',
  10: '10 Hz',
  20: '20 Hz',
  25: '25 Hz',
  50: '50 Hz',
  100: '100 Hz',
};

export const hertzOptionsRecording = {
  1: '1 Hz',
  2: '2 Hz',
  4: '4 Hz',
  5: '5 Hz',
};

export const accelerometerTypesVision = {
  LINEAR: 'Linear',
  RAW: 'Raw',
};

export const parkingModes = {
  'OFF': 'Off',
  'ON': 'On',
  'POWER SAVE': 'Power Save',
};
