import { useMemo } from 'react';
import { CloseButton, ListGroup } from 'react-bootstrap';
import { makeColorFrom } from '@/utils';

/**
 * @typedef {object} SelectAiModelProps
 * @property {Array.<AiModel>} models
 * @property {string} value
 * @property {(id: string) => any} onSelect
 * @property {Array.<AiModel>} [disabledModels]
 */

/** @param {SelectAiModelProps} props */
export function SelectAiModel(props) {
  const { models, value, onSelect, disabledModels } = props;
  const model = value && models.find((el) => el.modelId === value);
  const disabledIds = new Set(disabledModels?.map((el) => el.modelId));

  if (model) {
    return (
      <ListGroup.Item
        title={model.description}
        className="flex-fill position-relative top-item"
        style={{ background: '#f5f5f5' }}
      >
        <div className="fw-bold text-capitalize">
          {model.name}{' '}
          <sup>
            <TextBadge text={model.status} suffix={73} />
          </sup>
        </div>
        <div className="position-absolute top-0 end-0 mt-2 me-2">
          <CloseButton onClick={() => onSelect(null)} />
        </div>
      </ListGroup.Item>
    );
  }

  return (
    <ListGroup>
      <ListGroup.Item style={{ background: '#00000016' }}>
        Please select an AI Model:
      </ListGroup.Item>
      <div className="fixed-scroll">
        {models.map((el) => (
          <AiModelListItem
            key={el.modelId}
            model={el}
            onSelect={onSelect}
            disabled={disabledIds.has(el.modelId)}
          />
        ))}
      </div>
    </ListGroup>
  );
}

function AiModelListItem(props) {
  const { model, disabled, onSelect } = props;
  return (
    <ListGroup.Item
      disabled={disabled}
      action={!disabled}
      onClick={() => !disabled && onSelect(model.modelId)}
    >
      <div className="fw-bold text-capitalize">
        {model.name}{' '}
        <sup>
          <TextBadge text={model.status} suffix={73} />
        </sup>
      </div>
      <div className="small">{model.description}</div>
    </ListGroup.Item>
  );
}

function TextBadge(props) {
  const { text, suffix } = props;
  const color = useMemo(() => makeColorFrom(text, suffix), [text, suffix]);
  return (
    <div className="badge rounded-pill text-uppercase px-2" style={{ background: color }}>
      {text}
    </div>
  );
}
