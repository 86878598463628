import api from '@/api';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getBearerToken } from '@/state/auth';
import { useApiRequest } from '@/utils/register-api';
import { Navigate, useParams } from 'react-router';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { EditAiAccelaratorPlaceholder } from './@components/EditAiAccelaratorPlaceholder';
import { EditAiAccelaratorForm } from './@components/EditAiAccelaratorForm';

export function EditAiAccelerator() {
  const { codeName } = useParams();
  const bearerToken = useSelector(getBearerToken);

  const request = useApiRequest(
    () => api.sai.v1['ai-accelerator'].$codeName(codeName).$get({}),
    [bearerToken, codeName]
  );

  useEffect(() => {
    request.reset();
    request.process().catch(() => {});
  }, [request]);

  switch (request.status) {
    case 'loading':
    case 'pending':
      return (
        <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
          <EditAiAccelaratorPlaceholder />
        </BreadcrumbLayout>
      );
    case 'failure':
      return <Navigate replace to="./.." />;
    case 'success':
      const item = request.result.result;
      return (
        <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
          <EditAiAccelaratorForm item={item} />
        </BreadcrumbLayout>
      );
    default:
      return <div className="text-center">Unknown request status</div>;
  }
}
