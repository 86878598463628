import api from '@/api';
import { useSelector } from 'react-redux';
import { getBearerToken } from '@/state/auth';
import { Navigate, useParams } from 'react-router';
import { displayError } from '@/utils/toaster';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { fetchAllTenants } from '@/state/cache';
import { EventView } from './@components/EventView';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';

export function ViewEventWithTenant() {
  const { eventId } = useParams();

  const [tenantsLoading, tenantsError] = useDispatchLoader(fetchAllTenants);

  const bearerToken = useSelector(getBearerToken);
  const [event, eventLoading, eventError] = useFutureLoader(async () => {
    const request = api.sai.v1.event.$eventId(eventId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, eventId]);

  let loading = null;
  if (eventLoading) {
    loading = 'Loading event...';
  } else if (tenantsLoading) {
    loading = 'Loading tenants...';
  }

  if (loading) {
    return (
      <BreadcrumbLayout fluid>
        <PageLoadingSpinner status={loading} />
      </BreadcrumbLayout>
    );
  }

  if (eventError || tenantsError) {
    displayError(eventError);
    displayError(tenantsError);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout fluid>
      <EventView item={event} />
    </BreadcrumbLayout>
  );
}
