import { inputOutputSources } from '@/assets/theme/colors';
import { AiModelAudioOutputCard } from './@ai-model/AudioOutputCard';
import { AiModelSensorOutputCard } from './@ai-model/SensorOutputCard';
import { AiModelVideoOutputCard } from './@ai-model/VideoOutputCard';
import { ProductAudioOutputCard } from './@product/AudioOutputCard';
import { ProductSensorOutputCard } from './@product/SensorOutputCard';
import { ProductVideoOutputCard } from './@product/VideoOutputCard';
import { DefaultOutputCard } from './DefaultOutputCard';

/** @param {{mapping: InputMapModel, color: string}} props */
export function OutputCard(props) {
  const { mapping, color, hideLink, ...otherProps } = props;
  const _color = color || inputOutputSources[mapping.inputType];
  const nextProps = { ...otherProps, hideLink, mapping, color: _color };

  if (!mapping?.mappedOutputCode || !mapping?.sourceId) {
    return <DefaultOutputCard {...otherProps} />;
  }
  switch (mapping.inputSource) {
    case 'MODEL':
      switch (mapping.inputType) {
        case 'VIDEO':
          return <AiModelVideoOutputCard {...nextProps} />;
        case 'AUDIO':
          return <AiModelAudioOutputCard {...nextProps} />;
        case 'SENSOR':
          return <AiModelSensorOutputCard {...nextProps} />;
        default:
          return <DefaultOutputCard {...otherProps} />;
      }
    case 'PRODUCT':
      switch (mapping.inputType) {
        case 'VIDEO':
          return <ProductVideoOutputCard {...nextProps} />;
        case 'AUDIO':
          return <ProductAudioOutputCard {...nextProps} />;
        case 'SENSOR':
          return <ProductSensorOutputCard {...nextProps} />;
        default:
          return <DefaultOutputCard {...otherProps} />;
      }
    default:
      return <DefaultOutputCard {...otherProps} />;
  }
}
