import { donutCharColors } from '@/assets/colors/autocomplete';
import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchSharedAiModelDonutData } from '@/state/chart/fetchers/shared-ai-models';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function SharedAiModelsDonut(props) {
  const { sidebar } = props;

  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchSharedAiModelDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.sharedAiModels?.total || 0,
    points: [
      ['data1', 'data2'],
      ['Shared AI Models', chartData.sharedAiModels?.total || 0],
    ],
  };

  return (
    <DashboardItem sidebar={sidebar} header={'Shared AI Models'} url={'/ai/shared-ai-models'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'Shared AI Models' }}
        legends={[{ name: 'AI Models', className: 'dark-blue' }]}
        colors={[donutCharColors.DARK_BLUE]}
        data={data.points}
      />
    </DashboardItem>
  );
}
