export const Actions = {
  AI_ACCELERATOR_CREATE: 'AI_ACCELERATOR_CREATE',
  AI_ACCELERATOR_UPDATE: 'AI_ACCELERATOR_UPDATE',
  AI_ACCELERATOR_READ: 'AI_ACCELERATOR_READ',
  AI_ACCELERATOR_LIST_READ: 'AI_ACCELERATOR_LIST_READ',
  AI_ACCELERATOR_DELETE: 'AI_ACCELERATOR_DELETE',
  AI_CONTAINER_CREATE: 'AI_CONTAINER_CREATE',
  AI_CONTAINER_READ: 'AI_CONTAINER_READ',
  AI_CONTAINER_LIST_READ: 'AI_CONTAINER_LIST_READ',
  AI_CONTAINER_UPDATE: 'AI_CONTAINER_UPDATE',
  AI_CONTAINER_DELETE: 'AI_CONTAINER_DELETE',
  AI_CONTAINER_PUBLISH: 'AI_CONTAINER_PUBLISH',
  AI_CONTAINER_DISCONTINUE: 'AI_CONTAINER_DISCONTINUE',
  AI_CONTAINER_GET_FILE_CREDENTIAL: 'AI_CONTAINER_GET_FILE_CREDENTIAL',
  AI_CONTAINER_DEPLOYMENT_CREATE: 'AI_CONTAINER_DEPLOYMENT_CREATE',
  AI_CONTAINER_DEPLOYMENT_LIST_READ: 'AI_CONTAINER_DEPLOYMENT_LIST_READ',
  AI_CONTAINER_SHARE_CREATE: 'AI_CONTAINER_SHARE_CREATE',
  AI_CONTAINER_SHARE_READ: 'AI_CONTAINER_SHARE_READ',
  AI_CONTAINER_SHARE_LIST_READ: 'AI_CONTAINER_SHARE_LIST_READ',
  AI_CONTAINER_SHARE_UPDATE: 'AI_CONTAINER_SHARE_UPDATE',
  AI_CONTAINER_SHARE_DELETE: 'AI_CONTAINER_SHARE_DELETE',
  AI_MODEL_CREATE: 'AI_MODEL_CREATE',
  AI_MODEL_UPDATE: 'AI_MODEL_UPDATE',
  AI_MODEL_READ: 'AI_MODEL_READ',
  AI_MODEL_LIST_READ: 'AI_MODEL_LIST_READ',
  AI_MODEL_DELETE: 'AI_MODEL_DELETE',
  AI_MODEL_PUBLISH: 'AI_MODEL_PUBLISH',
  AI_MODEL_DISCONTINUE: 'AI_MODEL_DISCONTINUE',
  AI_MODEL_GET_FILE_CREDENTIAL: 'AI_MODEL_GET_FILE_CREDENTIAL',
  AI_MODEL_SHARE_CREATE: 'AI_MODEL_SHARE_CREATE',
  AI_MODEL_SHARE_UPDATE: 'AI_MODEL_SHARE_UPDATE',
  AI_MODEL_SHARE_READ: 'AI_MODEL_SHARE_READ',
  AI_MODEL_SHARE_LIST_READ: 'AI_MODEL_SHARE_LIST_READ',
  AI_MODEL_SHARE_DELETE: 'AI_MODEL_SHARE_DELETE',
  FIRMWARE_CREATE: 'FIRMWARE_CREATE',
  FIRMWARE_READ: 'FIRMWARE_READ',
  FIRMWARE_LIST_READ: 'FIRMWARE_LIST_READ',
  FIRMWARE_UPDATE: 'FIRMWARE_UPDATE',
  FIRMWARE_DELETE: 'FIRMWARE_DELETE',
  FIRMWARE_PUBLISH: 'FIRMWARE_PUBLISH',
  FIRMWARE_DISCONTINUE: 'FIRMWARE_DISCONTINUE',
  FIRMWARE_GET_FILE_CREDENTIAL: 'FIRMWARE_GET_FILE_CREDENTIAL',
  FIRMWARE_DEPLOYMENT_CREATE: 'FIRMWARE_DEPLOYMENT_CREATE',
  FIRMWARE_DEPLOYMENT_LIST_READ: 'FIRMWARE_DEPLOYMENT_LIST_READ',
  FIRMWARE_SHARE_CREATE: 'FIRMWARE_SHARE_CREATE',
  FIRMWARE_SHARE_UPDATE: 'FIRMWARE_SHARE_UPDATE',
  FIRMWARE_SHARE_READ: 'FIRMWARE_SHARE_READ',
  FIRMWARE_SHARE_LIST_READ: 'FIRMWARE_SHARE_LIST_READ',
  FIRMWARE_SHARE_DELETE: 'FIRMWARE_SHARE_DELETE',
  LABEL_CREATE: 'LABEL_CREATE',
  LABEL_UPDATE: 'LABEL_UPDATE',
  LABEL_READ: 'LABEL_READ',
  LABEL_LIST_READ: 'LABEL_LIST_READ',
  LABEL_DELETE: 'LABEL_DELETE',
  LABEL_PUBLISH: 'LABEL_PUBLISH',
  LABEL_DISCONTINUE: 'LABEL_DISCONTINUE',
  LABEL_CATEGORY_CREATE: 'LABEL_CATEGORY_CREATE',
  LABEL_CATEGORY_READ: 'LABEL_CATEGORY_READ',
  LABEL_CATEGORY_LIST_READ: 'LABEL_CATEGORY_LIST_READ',
  LABEL_CATEGORY_UPDATE: 'LABEL_CATEGORY_UPDATE',
  PRODUCT_DEPLOY_FIRMWARE: 'PRODUCT_DEPLOY_FIRMWARE',
  PRODUCT_UNDEPLOY_FIRMWARE: 'PRODUCT_UNDEPLOY_FIRMWARE',
  PRODUCT_CREATE: 'PRODUCT_CREATE',
  PRODUCT_READ: 'PRODUCT_READ',
  PRODUCT_LIST_READ: 'PRODUCT_LIST_READ',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  PRODUCT_DELETE: 'PRODUCT_DELETE',
  PRODUCT_RELEASE: 'PRODUCT_RELEASE',
  PRODUCT_DISCONTINUE: 'PRODUCT_DISCONTINUE',
  PRODUCT_SHARE_CREATE: 'PRODUCT_SHARE_CREATE',
  PRODUCT_SHARE_UPDATE: 'PRODUCT_SHARE_UPDATE',
  PRODUCT_SHARE_READ: 'PRODUCT_SHARE_READ',
  PRODUCT_SHARE_LIST_READ: 'PRODUCT_SHARE_LIST_READ',
  PRODUCT_SHARE_DELETE: 'PRODUCT_SHARE_DELETE',
  TENANT_CREATE: 'TENANT_CREATE',
  TENANT_READ: 'TENANT_READ',
  TENANT_LIST_READ: 'TENANT_LIST_READ',
  TENANT_UPDATE: 'TENANT_UPDATE',
  TENANT_UPDATE_REGISTRATION_SETTING: 'TENANT_UPDATE_REGISTRATION_SETTING',
  TENANT_UPDATE_RETENTION: 'TENANT_UPDATE_RETENTION',
  TRIGGER_CREATE: 'TRIGGER_CREATE',
  TRIGGER_UPDATE: 'TRIGGER_UPDATE',
  TRIGGER_READ: 'TRIGGER_READ',
  TRIGGER_LIST_READ: 'TRIGGER_LIST_READ',
  TRIGGER_DELETE: 'TRIGGER_DELETE',
  TRIGGER_PUBLISH: 'TRIGGER_PUBLISH',
  TRIGGER_DISCONTINUE: 'TRIGGER_DISCONTINUE',
  TRIGGER_DEPLOYMENT_CREATE: 'TRIGGER_DEPLOYMENT_CREATE',
  TRIGGER_DEPLOYMENT_LIST_READ: 'TRIGGER_DEPLOYMENT_LIST_READ',
  TRIGGER_SHARE_CREATE: 'TRIGGER_SHARE_CREATE',
  TRIGGER_SHARE_UPDATE: 'TRIGGER_SHARE_UPDATE',
  TRIGGER_SHARE_READ: 'TRIGGER_SHARE_READ',
  TRIGGER_SHARE_LIST_READ: 'TRIGGER_SHARE_LIST_READ',
  TRIGGER_SHARE_DELETE: 'TRIGGER_SHARE_DELETE',
  STREAM_LIST_READ: 'STREAM_LIST_READ',
  EVENT_LIST_READ_BY_TENANT: 'EVENT_LIST_READ_BY_TENANT',
  EVENT_LIST_READ_BY_DEVICE: 'EVENT_LIST_READ_BY_DEVICE',
  EVENT_LIST_READ_BY_USER: 'EVENT_LIST_READ_BY_USER',
  EVENT_READ: 'EVENT_READ',
  EVENT_UPDATE: 'EVENT_UPDATE',
  DEPLOYMENT_GROUP_CREATE: 'DEPLOYMENT_GROUP_CREATE',
  DEPLOYMENT_GROUP_READ: 'DEPLOYMENT_GROUP_READ',
  DEPLOYMENT_GROUP_LIST_READ: 'DEPLOYMENT_GROUP_LIST_READ',
  DEPLOYMENT_GROUP_UPDATE: 'DEPLOYMENT_GROUP_UPDATE',
  DEPLOYMENT_GROUP_DEPLOY_FIRMWARE: 'DEPLOYMENT_GROUP_DEPLOY_FIRMWARE',
  DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE: 'DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE',
  DEPLOYMENT_GROUP_DEPLOY_TRIGGER: 'DEPLOYMENT_GROUP_DEPLOY_TRIGGER',
  DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER: 'DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER',
  DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER: 'DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER',
  DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER: 'DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER',
  DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ: 'DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ',
  DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ: 'DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ',
  DEVICE_TRIGGER_DEPLOYMENT_LIST_READ: 'DEVICE_TRIGGER_DEPLOYMENT_LIST_READ',
  DEVICE_KEY_CREATE: 'DEVICE_KEY_CREATE',
  DEVICE_KEY_UPDATE: 'DEVICE_KEY_UPDATE',
  DEVICE_READ: 'DEVICE_READ',
  DEVICE_STREAM: 'DEVICE_STREAM',
  DEVICE_GET_MEDIA_CREDENTIAL: 'DEVICE_GET_MEDIA_CREDENTIAL',
  DEVICE_ADD_MEDIA: 'DEVICE_ADD_MEDIA',
  DEVICE_READ_MEDIA_LIST: 'DEVICE_READ_MEDIA_LIST',
  DEVICE_ADD_FIRMWARE_INFO: 'DEVICE_ADD_FIRMWARE_INFO',
  DEVICE_READ_FIRMWARE_INFO: 'DEVICE_READ_FIRMWARE_INFO',
  DEVICE_PRESENCE_READ: 'DEVICE_PRESENCE_READ',
  DEVICE_REBOOT: 'DEVICE_REBOOT',
  DEVICE_AI_CONTAINER_DOWNLOAD: 'DEVICE_AI_CONTAINER_DOWNLOAD',
  DEVICE_LIST_READ: 'DEVICE_LIST_READ',
  DEVICE_UPDATE_ONBOARDED_DEVICE: 'DEVICE_UPDATE_ONBOARDED_DEVICE',
  DEVICE_RESET: 'DEVICE_RESET',
  DEVICE_ADD_TO_DEPLOYMENT_GROUP: 'DEVICE_ADD_TO_DEPLOYMENT_GROUP',
  DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP: 'DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP',
  DEVICE_ADD_USER_ACCESS: 'DEVICE_ADD_USER_ACCESS',
  DEVICE_REMOVE_USER_ACCESS: 'DEVICE_REMOVE_USER_ACCESS',
  DEVICE_ADD_TO_GROUP: 'DEVICE_ADD_TO_GROUP',
  DEVICE_REMOVE_FROM_GROUP: 'DEVICE_REMOVE_FROM_GROUP',
  DEVICE_TRANSFER_DEVICE: 'DEVICE_TRANSFER_DEVICE',
  DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP: 'DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP',
  DEVICE_LIST_READ_BY_USER_ID: 'DEVICE_LIST_READ_BY_USER_ID',
  DEVICE_LIST_READ_IN_GROUP: 'DEVICE_LIST_READ_IN_GROUP',
  DEVICE_LOG_SAVE: 'DEVICE_LOG_SAVE',
  DEVICE_LOG_READ: 'DEVICE_LOG_READ',
  DEVICE_POOL_CREATE: 'DEVICE_POOL_CREATE',
  DEVICE_POOL_UPDATE: 'DEVICE_POOL_UPDATE',
  DEVICE_POOL_READ: 'DEVICE_POOL_READ',
  DEVICE_POOL_LIST_READ: 'DEVICE_POOL_LIST_READ',
  DEVICE_POOL_DEPLOY_FIRMWARE: 'DEVICE_POOL_DEPLOY_FIRMWARE',
  DEVICE_POOL_UNDEPLOY_FIRMWARE: 'DEVICE_POOL_UNDEPLOY_FIRMWARE',
  ACCESS_RIGHT_LIST_READ: 'ACCESS_RIGHT_LIST_READ',
  END_USER_CREATE: 'END_USER_CREATE',
  END_USER_READ: 'END_USER_READ',
  END_USER_UPDATE: 'END_USER_UPDATE',
  END_USER_LIST_READ: 'END_USER_LIST_READ',
  END_USER_GET_FILE_CREDENTIAL: 'END_USER_GET_FILE_CREDENTIAL',
  END_USER_LOG_SAVE: 'END_USER_LOG_SAVE',
  END_USER_LOG_READ: 'END_USER_LOG_READ',
  PLATFORM_ADMIN_CREATE: 'PLATFORM_ADMIN_CREATE',
  PLATFORM_ADMIN_READ: 'PLATFORM_ADMIN_READ',
  PLATFORM_ADMIN_UPDATE: 'PLATFORM_ADMIN_UPDATE',
  PLATFORM_ADMIN_TENANT_ACCESS_UPDATE: 'PLATFORM_ADMIN_TENANT_ACCESS_UPDATE',
  PLATFORM_ADMIN_DELETE: 'PLATFORM_ADMIN_DELETE',
  PLATFORM_ADMIN_LIST_READ: 'PLATFORM_ADMIN_LIST_READ',
  PLATFORM_ADMIN_GET_FILE_CREDENTIAL: 'PLATFORM_ADMIN_GET_FILE_CREDENTIAL',
  ROLE_CREATE: 'ROLE_CREATE',
  ROLE_READ: 'ROLE_READ',
  ROLE_LIST_READ: 'ROLE_LIST_READ',
  ROLE_UPDATE: 'ROLE_UPDATE',
  ROLE_DELETE: 'ROLE_DELETE',
  TENANT_ADMIN_CREATE: 'TENANT_ADMIN_CREATE',
  TENANT_ADMIN_READ: 'TENANT_ADMIN_READ',
  TENANT_ADMIN_LIST_READ: 'TENANT_ADMIN_LIST_READ',
  TENANT_ADMIN_UPDATE: 'TENANT_ADMIN_UPDATE',
  TENANT_ADMIN_DELETE: 'TENANT_ADMIN_DELETE',
  TENANT_ADMIN_GET_FILE_CREDENTIAL: 'TENANT_ADMIN_GET_FILE_CREDENTIAL',
};

export const Rights = {
  AI_ACCELERATOR_CREATE: ['AI_ACCELERATOR_ALL', 'AI_ACCELERATOR_CREATE'],
  AI_ACCELERATOR_UPDATE: ['AI_ACCELERATOR_ALL', 'AI_ACCELERATOR_UPDATE'],
  AI_ACCELERATOR_READ: ['AI_ACCELERATOR_ALL', 'AI_ACCELERATOR_READ'],
  AI_ACCELERATOR_LIST_READ: ['AI_ACCELERATOR_ALL', 'AI_ACCELERATOR_LIST_READ'],
  AI_ACCELERATOR_DELETE: ['AI_ACCELERATOR_ALL', 'AI_ACCELERATOR_DELETE'],
  AI_CONTAINER_CREATE: ['AI_CONTAINER_ALL', 'AI_CONTAINER_CREATE'],
  AI_CONTAINER_READ: ['AI_CONTAINER_ALL', 'AI_CONTAINER_READ'],
  AI_CONTAINER_LIST_READ: ['AI_CONTAINER_ALL', 'AI_CONTAINER_LIST_READ'],
  AI_CONTAINER_UPDATE: ['AI_CONTAINER_ALL', 'AI_CONTAINER_UPDATE'],
  AI_CONTAINER_DELETE: ['AI_CONTAINER_ALL', 'AI_CONTAINER_DELETE'],
  AI_CONTAINER_PUBLISH: ['AI_CONTAINER_ALL', 'AI_CONTAINER_PUBLISH'],
  AI_CONTAINER_DISCONTINUE: ['AI_CONTAINER_ALL', 'AI_CONTAINER_DISCONTINUE'],
  AI_CONTAINER_GET_FILE_CREDENTIAL: ['AI_CONTAINER_ALL', 'AI_CONTAINER_GET_FILE_CREDENTIAL'],
  AI_CONTAINER_DEPLOYMENT_CREATE: ['AI_CONTAINER_DEPLOYMENT_ALL', 'AI_CONTAINER_DEPLOYMENT_CREATE'],
  AI_CONTAINER_DEPLOYMENT_LIST_READ: [
    'AI_CONTAINER_DEPLOYMENT_ALL',
    'AI_CONTAINER_DEPLOYMENT_LIST_READ',
  ],
  AI_CONTAINER_SHARE_CREATE: ['AI_CONTAINER_SHARE_ALL', 'AI_CONTAINER_SHARE_CREATE'],
  AI_CONTAINER_SHARE_READ: ['AI_CONTAINER_SHARE_ALL', 'AI_CONTAINER_SHARE_READ'],
  AI_CONTAINER_SHARE_LIST_READ: ['AI_CONTAINER_SHARE_ALL', 'AI_CONTAINER_SHARE_LIST_READ'],
  AI_CONTAINER_SHARE_UPDATE: ['AI_CONTAINER_SHARE_ALL', 'AI_CONTAINER_SHARE_UPDATE'],
  AI_CONTAINER_SHARE_DELETE: ['AI_CONTAINER_SHARE_ALL', 'AI_CONTAINER_SHARE_DELETE'],
  AI_MODEL_CREATE: ['AI_MODEL_ALL', 'AI_MODEL_CREATE'],
  AI_MODEL_UPDATE: ['AI_MODEL_ALL', 'AI_MODEL_UPDATE'],
  AI_MODEL_READ: ['AI_MODEL_ALL', 'AI_MODEL_READ'],
  AI_MODEL_LIST_READ: ['AI_MODEL_ALL', 'AI_MODEL_LIST_READ'],
  AI_MODEL_DELETE: ['AI_MODEL_ALL', 'AI_MODEL_DELETE'],
  AI_MODEL_PUBLISH: ['AI_MODEL_ALL', 'AI_MODEL_PUBLISH'],
  AI_MODEL_DISCONTINUE: ['AI_MODEL_ALL', 'AI_MODEL_DISCONTINUE'],
  AI_MODEL_GET_FILE_CREDENTIAL: ['AI_MODEL_ALL', 'AI_MODEL_GET_FILE_CREDENTIAL'],
  AI_MODEL_SHARE_CREATE: ['AI_MODEL_SHARE_ALL', 'AI_MODEL_SHARE_CREATE'],
  AI_MODEL_SHARE_UPDATE: ['AI_MODEL_SHARE_ALL', 'AI_MODEL_SHARE_UPDATE'],
  AI_MODEL_SHARE_READ: ['AI_MODEL_SHARE_ALL', 'AI_MODEL_SHARE_READ'],
  AI_MODEL_SHARE_LIST_READ: ['AI_MODEL_SHARE_ALL', 'AI_MODEL_SHARE_LIST_READ'],
  AI_MODEL_SHARE_DELETE: ['AI_MODEL_SHARE_ALL', 'AI_MODEL_SHARE_DELETE'],
  FIRMWARE_CREATE: ['FIRMWARE_ALL', 'FIRMWARE_CREATE'],
  FIRMWARE_READ: ['FIRMWARE_ALL', 'FIRMWARE_READ'],
  FIRMWARE_LIST_READ: ['FIRMWARE_ALL', 'FIRMWARE_LIST_READ'],
  FIRMWARE_UPDATE: ['FIRMWARE_ALL', 'FIRMWARE_UPDATE'],
  FIRMWARE_DELETE: ['FIRMWARE_ALL', 'FIRMWARE_DELETE'],
  FIRMWARE_PUBLISH: ['FIRMWARE_ALL', 'FIRMWARE_PUBLISH'],
  FIRMWARE_DISCONTINUE: ['FIRMWARE_ALL', 'FIRMWARE_DISCONTINUE'],
  FIRMWARE_GET_FILE_CREDENTIAL: ['FIRMWARE_ALL', 'FIRMWARE_GET_FILE_CREDENTIAL'],
  FIRMWARE_DEPLOYMENT_CREATE: ['FIRMWARE_DEPLOYMENT_ALL', 'FIRMWARE_DEPLOYMENT_CREATE'],
  FIRMWARE_DEPLOYMENT_LIST_READ: ['FIRMWARE_DEPLOYMENT_ALL', 'FIRMWARE_DEPLOYMENT_LIST_READ'],
  FIRMWARE_SHARE_CREATE: ['FIRMWARE_SHARE_ALL', 'FIRMWARE_SHARE_CREATE'],
  FIRMWARE_SHARE_UPDATE: ['FIRMWARE_SHARE_ALL', 'FIRMWARE_SHARE_UPDATE'],
  FIRMWARE_SHARE_READ: ['FIRMWARE_SHARE_ALL', 'FIRMWARE_SHARE_READ'],
  FIRMWARE_SHARE_LIST_READ: ['FIRMWARE_SHARE_ALL', 'FIRMWARE_SHARE_LIST_READ'],
  FIRMWARE_SHARE_DELETE: ['FIRMWARE_SHARE_ALL', 'FIRMWARE_SHARE_DELETE'],
  LABEL_CREATE: ['LABEL_ALL', 'LABEL_CREATE'],
  LABEL_UPDATE: ['LABEL_ALL', 'LABEL_UPDATE'],
  LABEL_READ: ['LABEL_ALL', 'LABEL_READ'],
  LABEL_LIST_READ: ['LABEL_ALL', 'LABEL_LIST_READ'],
  LABEL_DELETE: ['LABEL_ALL', 'LABEL_DELETE'],
  LABEL_PUBLISH: ['LABEL_ALL', 'LABEL_PUBLISH'],
  LABEL_DISCONTINUE: ['LABEL_ALL', 'LABEL_DISCONTINUE'],
  LABEL_CATEGORY_CREATE: ['LABEL_CATEGORY_ALL', 'LABEL_CATEGORY_CREATE'],
  LABEL_CATEGORY_READ: ['LABEL_CATEGORY_ALL', 'LABEL_CATEGORY_READ'],
  LABEL_CATEGORY_LIST_READ: ['LABEL_CATEGORY_ALL', 'LABEL_CATEGORY_LIST_READ'],
  LABEL_CATEGORY_UPDATE: ['LABEL_CATEGORY_ALL', 'LABEL_CATEGORY_UPDATE'],
  PRODUCT_DEPLOY_FIRMWARE: ['PRODUCT_DEPLOYMENT_ALL', 'PRODUCT_DEPLOY_FIRMWARE', 'PRODUCT_ALL'],
  PRODUCT_UNDEPLOY_FIRMWARE: ['PRODUCT_DEPLOYMENT_ALL', 'PRODUCT_UNDEPLOY_FIRMWARE', 'PRODUCT_ALL'],
  PRODUCT_CREATE: ['PRODUCT_ALL', 'PRODUCT_CREATE'],
  PRODUCT_READ: ['PRODUCT_ALL', 'PRODUCT_READ'],
  PRODUCT_LIST_READ: ['PRODUCT_ALL', 'PRODUCT_LIST_READ'],
  PRODUCT_UPDATE: ['PRODUCT_ALL', 'PRODUCT_UPDATE'],
  PRODUCT_DELETE: ['PRODUCT_ALL', 'PRODUCT_DELETE'],
  PRODUCT_RELEASE: ['PRODUCT_ALL', 'PRODUCT_RELEASE'],
  PRODUCT_DISCONTINUE: ['PRODUCT_ALL', 'PRODUCT_DISCONTINUE'],
  PRODUCT_SHARE_CREATE: ['PRODUCT_SHARE_ALL', 'PRODUCT_SHARE_CREATE'],
  PRODUCT_SHARE_UPDATE: ['PRODUCT_SHARE_ALL', 'PRODUCT_SHARE_UPDATE'],
  PRODUCT_SHARE_READ: ['PRODUCT_SHARE_ALL', 'PRODUCT_SHARE_READ'],
  PRODUCT_SHARE_LIST_READ: ['PRODUCT_SHARE_ALL', 'PRODUCT_SHARE_LIST_READ'],
  PRODUCT_SHARE_DELETE: ['PRODUCT_SHARE_ALL', 'PRODUCT_SHARE_DELETE'],
  TENANT_CREATE: ['TENANT_ALL', 'TENANT_CREATE'],
  TENANT_READ: ['TENANT_ALL', 'TENANT_READ'],
  TENANT_LIST_READ: ['TENANT_ALL', 'TENANT_LIST_READ'],
  TENANT_UPDATE: ['TENANT_ALL', 'TENANT_UPDATE'],
  TENANT_UPDATE_REGISTRATION_SETTING: ['TENANT_ALL', 'TENANT_UPDATE_REGISTRATION_SETTING'],
  TENANT_UPDATE_RETENTION: ['TENANT_ALL', 'TENANT_UPDATE_RETENTION'],
  TRIGGER_CREATE: ['TRIGGER_ALL', 'TRIGGER_CREATE'],
  TRIGGER_UPDATE: ['TRIGGER_ALL', 'TRIGGER_UPDATE'],
  TRIGGER_READ: ['TRIGGER_ALL', 'TRIGGER_READ'],
  TRIGGER_LIST_READ: ['TRIGGER_ALL', 'TRIGGER_LIST_READ'],
  TRIGGER_DELETE: ['TRIGGER_ALL', 'TRIGGER_DELETE'],
  TRIGGER_PUBLISH: ['TRIGGER_ALL', 'TRIGGER_PUBLISH'],
  TRIGGER_DISCONTINUE: ['TRIGGER_ALL', 'TRIGGER_DISCONTINUE'],
  TRIGGER_DEPLOYMENT_CREATE: ['TRIGGER_DEPLOYMENT_ALL', 'TRIGGER_DEPLOYMENT_CREATE'],
  TRIGGER_DEPLOYMENT_LIST_READ: ['TRIGGER_DEPLOYMENT_ALL', 'TRIGGER_DEPLOYMENT_LIST_READ'],
  TRIGGER_SHARE_CREATE: ['TRIGGER_SHARE_ALL', 'TRIGGER_SHARE_CREATE'],
  TRIGGER_SHARE_UPDATE: ['TRIGGER_SHARE_ALL', 'TRIGGER_SHARE_UPDATE'],
  TRIGGER_SHARE_READ: ['TRIGGER_SHARE_ALL', 'TRIGGER_SHARE_READ'],
  TRIGGER_SHARE_LIST_READ: ['TRIGGER_SHARE_ALL', 'TRIGGER_SHARE_LIST_READ'],
  TRIGGER_SHARE_DELETE: ['TRIGGER_SHARE_ALL', 'TRIGGER_SHARE_DELETE'],
  STREAM_LIST_READ: ['STREAM_ALL', 'STREAM_LIST_READ'],
  EVENT_LIST_READ_BY_TENANT: ['EVENT_LIST_READ', 'EVENT_LIST_READ_BY_TENANT', 'EVENT_ALL'],
  EVENT_LIST_READ_BY_DEVICE: ['EVENT_LIST_READ', 'EVENT_LIST_READ_BY_DEVICE', 'EVENT_ALL'],
  EVENT_LIST_READ_BY_USER: ['EVENT_LIST_READ', 'EVENT_LIST_READ_BY_USER', 'EVENT_ALL'],
  EVENT_READ: ['EVENT_ALL', 'EVENT_READ'],
  EVENT_UPDATE: ['EVENT_ALL', 'EVENT_UPDATE'],
  DEPLOYMENT_GROUP_CREATE: ['DEPLOYMENT_GROUP_ALL', 'DEPLOYMENT_GROUP_CREATE'],
  DEPLOYMENT_GROUP_READ: ['DEPLOYMENT_GROUP_ALL', 'DEPLOYMENT_GROUP_READ'],
  DEPLOYMENT_GROUP_LIST_READ: ['DEPLOYMENT_GROUP_ALL', 'DEPLOYMENT_GROUP_LIST_READ'],
  DEPLOYMENT_GROUP_UPDATE: ['DEPLOYMENT_GROUP_ALL', 'DEPLOYMENT_GROUP_UPDATE'],
  DEPLOYMENT_GROUP_DEPLOY_FIRMWARE: [
    'DEPLOYMENT_GROUP_ALL',
    'DEPLOYMENT_GROUP_DEPLOY_FIRMWARE',
    'DEPLOYMENT_GROUP_DEPLOYMENT_ALL',
  ],
  DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE: [
    'DEPLOYMENT_GROUP_ALL',
    'DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE',
    'DEPLOYMENT_GROUP_DEPLOYMENT_ALL',
  ],
  DEPLOYMENT_GROUP_DEPLOY_TRIGGER: [
    'DEPLOYMENT_GROUP_ALL',
    'DEPLOYMENT_GROUP_DEPLOY_TRIGGER',
    'DEPLOYMENT_GROUP_DEPLOYMENT_ALL',
  ],
  DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER: [
    'DEPLOYMENT_GROUP_ALL',
    'DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER',
    'DEPLOYMENT_GROUP_DEPLOYMENT_ALL',
  ],
  DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER: [
    'DEPLOYMENT_GROUP_ALL',
    'DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER',
    'DEPLOYMENT_GROUP_DEPLOYMENT_ALL',
  ],
  DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER: [
    'DEPLOYMENT_GROUP_ALL',
    'DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER',
    'DEPLOYMENT_GROUP_DEPLOYMENT_ALL',
  ],
  DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ: [
    'DEVICE_DEPLOYMENT_ALL',
    'DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ',
  ],
  DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ: [
    'DEVICE_DEPLOYMENT_ALL',
    'DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ',
  ],
  DEVICE_TRIGGER_DEPLOYMENT_LIST_READ: [
    'DEVICE_DEPLOYMENT_ALL',
    'DEVICE_TRIGGER_DEPLOYMENT_LIST_READ',
  ],
  DEVICE_KEY_CREATE: ['DEVICE_ALL', 'DEVICE_KEY_CREATE'],
  DEVICE_KEY_UPDATE: ['DEVICE_ALL', 'DEVICE_KEY_UPDATE'],
  DEVICE_READ: ['DEVICE_ALL', 'DEVICE_READ'],
  DEVICE_STREAM: ['DEVICE_ALL', 'DEVICE_STREAM'],
  DEVICE_GET_MEDIA_CREDENTIAL: ['DEVICE_ALL', 'DEVICE_GET_MEDIA_CREDENTIAL'],
  DEVICE_ADD_MEDIA: ['DEVICE_ALL', 'DEVICE_ADD_MEDIA'],
  DEVICE_READ_MEDIA_LIST: ['DEVICE_ALL', 'DEVICE_READ_MEDIA_LIST'],
  DEVICE_ADD_FIRMWARE_INFO: ['DEVICE_ALL', 'DEVICE_ADD_FIRMWARE_INFO'],
  DEVICE_READ_FIRMWARE_INFO: ['DEVICE_ALL', 'DEVICE_READ_FIRMWARE_INFO'],
  DEVICE_PRESENCE_READ: ['DEVICE_ALL', 'DEVICE_PRESENCE_READ'],
  DEVICE_REBOOT: ['DEVICE_ALL', 'DEVICE_REBOOT'],
  DEVICE_AI_CONTAINER_DOWNLOAD: ['DEVICE_ALL', 'DEVICE_AI_CONTAINER_DOWNLOAD'],
  DEVICE_LIST_READ: ['DEVICE_ALL', 'DEVICE_LIST_READ'],
  DEVICE_UPDATE_ONBOARDED_DEVICE: ['DEVICE_ALL', 'DEVICE_UPDATE_ONBOARDED_DEVICE'],
  DEVICE_RESET: ['DEVICE_ALL', 'DEVICE_RESET'],
  DEVICE_ADD_TO_DEPLOYMENT_GROUP: ['DEVICE_ALL', 'DEVICE_ADD_TO_DEPLOYMENT_GROUP'],
  DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP: ['DEVICE_ALL', 'DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP'],
  DEVICE_ADD_USER_ACCESS: ['DEVICE_ALL', 'DEVICE_ADD_USER_ACCESS'],
  DEVICE_REMOVE_USER_ACCESS: ['DEVICE_ALL', 'DEVICE_REMOVE_USER_ACCESS'],
  DEVICE_ADD_TO_GROUP: ['DEVICE_ALL', 'DEVICE_ADD_TO_GROUP'],
  DEVICE_REMOVE_FROM_GROUP: ['DEVICE_ALL', 'DEVICE_REMOVE_FROM_GROUP'],
  DEVICE_TRANSFER_DEVICE: ['DEVICE_ALL', 'DEVICE_TRANSFER_DEVICE'],
  DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP: ['DEVICE_ALL', 'DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP'],
  DEVICE_LIST_READ_BY_USER_ID: ['DEVICE_ALL', 'DEVICE_LIST_READ_BY_USER_ID'],
  DEVICE_LIST_READ_IN_GROUP: ['DEVICE_ALL', 'DEVICE_LIST_READ_IN_GROUP'],
  DEVICE_LOG_SAVE: ['DEVICE_LOG_ALL', 'DEVICE_LOG_SAVE'],
  DEVICE_LOG_READ: ['DEVICE_LOG_ALL', 'DEVICE_LOG_READ'],
  DEVICE_POOL_CREATE: ['DEVICE_POOL_ALL', 'DEVICE_POOL_CREATE'],
  DEVICE_POOL_UPDATE: ['DEVICE_POOL_ALL', 'DEVICE_POOL_UPDATE'],
  DEVICE_POOL_READ: ['DEVICE_POOL_ALL', 'DEVICE_POOL_READ'],
  DEVICE_POOL_LIST_READ: ['DEVICE_POOL_ALL', 'DEVICE_POOL_LIST_READ'],
  DEVICE_POOL_DEPLOY_FIRMWARE: ['DEVICE_POOL_ALL', 'DEVICE_POOL_DEPLOY_FIRMWARE'],
  DEVICE_POOL_UNDEPLOY_FIRMWARE: ['DEVICE_POOL_ALL', 'DEVICE_POOL_UNDEPLOY_FIRMWARE'],
  ACCESS_RIGHT_LIST_READ: ['ACCESS_RIGHT_ALL', 'ACCESS_RIGHT_LIST_READ'],
  END_USER_CREATE: ['END_USER_ALL', 'END_USER_CREATE'],
  END_USER_READ: ['END_USER_ALL', 'END_USER_READ'],
  END_USER_UPDATE: ['END_USER_ALL', 'END_USER_UPDATE'],
  END_USER_LIST_READ: ['END_USER_ALL', 'END_USER_LIST_READ'],
  END_USER_GET_FILE_CREDENTIAL: ['END_USER_ALL', 'END_USER_GET_FILE_CREDENTIAL'],
  END_USER_LOG_SAVE: ['END_USER_LOG_ALL', 'END_USER_LOG_SAVE'],
  END_USER_LOG_READ: ['END_USER_LOG_ALL', 'END_USER_LOG_READ'],
  PLATFORM_ADMIN_CREATE: ['PLATFORM_ADMIN_ALL', 'PLATFORM_ADMIN_CREATE'],
  PLATFORM_ADMIN_READ: ['PLATFORM_ADMIN_ALL', 'PLATFORM_ADMIN_READ'],
  PLATFORM_ADMIN_UPDATE: ['PLATFORM_ADMIN_ALL', 'PLATFORM_ADMIN_UPDATE'],
  PLATFORM_ADMIN_TENANT_ACCESS_UPDATE: [
    'PLATFORM_ADMIN_ALL',
    'PLATFORM_ADMIN_TENANT_ACCESS_UPDATE',
  ],
  PLATFORM_ADMIN_DELETE: ['PLATFORM_ADMIN_ALL', 'PLATFORM_ADMIN_DELETE'],
  PLATFORM_ADMIN_LIST_READ: ['PLATFORM_ADMIN_ALL', 'PLATFORM_ADMIN_LIST_READ'],
  PLATFORM_ADMIN_GET_FILE_CREDENTIAL: ['PLATFORM_ADMIN_ALL', 'PLATFORM_ADMIN_GET_FILE_CREDENTIAL'],
  ROLE_CREATE: ['ROLE_ALL', 'ROLE_CREATE'],
  ROLE_READ: ['ROLE_ALL', 'ROLE_READ'],
  ROLE_LIST_READ: ['ROLE_ALL', 'ROLE_LIST_READ'],
  ROLE_UPDATE: ['ROLE_ALL', 'ROLE_UPDATE'],
  ROLE_DELETE: ['ROLE_ALL', 'ROLE_DELETE'],
  TENANT_ADMIN_CREATE: ['TENANT_ADMIN_ALL', 'TENANT_ADMIN_CREATE'],
  TENANT_ADMIN_READ: ['TENANT_ADMIN_ALL', 'TENANT_ADMIN_READ'],
  TENANT_ADMIN_LIST_READ: ['TENANT_ADMIN_ALL', 'TENANT_ADMIN_LIST_READ'],
  TENANT_ADMIN_UPDATE: ['TENANT_ADMIN_ALL', 'TENANT_ADMIN_UPDATE'],
  TENANT_ADMIN_DELETE: ['TENANT_ADMIN_ALL', 'TENANT_ADMIN_DELETE'],
  TENANT_ADMIN_GET_FILE_CREDENTIAL: ['TENANT_ADMIN_ALL', 'TENANT_ADMIN_GET_FILE_CREDENTIAL'],
};

export const ActionScopes = {
  TENANT: 'PLATFORM',
  AI_ACCELERATOR_CREATE: 'PLATFORM',
  AI_ACCELERATOR_UPDATE: 'PLATFORM',
  AI_ACCELERATOR_READ: 'TENANT',
  AI_ACCELERATOR_LIST_READ: 'TENANT',
  AI_ACCELERATOR_DELETE: 'PLATFORM',
  AI_CONTAINER_CREATE: 'TENANT',
  AI_CONTAINER_UPDATE: 'TENANT',
  AI_CONTAINER_READ: 'TENANT',
  AI_CONTAINER_LIST_READ: 'TENANT',
  AI_CONTAINER_DELETE: 'TENANT',
  AI_CONTAINER_PUBLISH: 'TENANT',
  AI_CONTAINER_DISCONTINUE: 'TENANT',
  AI_CONTAINER_GET_FILE_CREDENTIAL: 'TENANT',
  AI_CONTAINER_SHARE_CREATE: 'TENANT',
  AI_CONTAINER_SHARE_UPDATE: 'TENANT',
  AI_CONTAINER_SHARE_READ: 'TENANT',
  AI_CONTAINER_SHARE_LIST_READ: 'TENANT',
  AI_CONTAINER_SHARE_DELETE: 'TENANT',
  AI_MODEL_CREATE: 'TENANT',
  AI_MODEL_UPDATE: 'TENANT',
  AI_MODEL_READ: 'TENANT',
  AI_MODEL_LIST_READ: 'TENANT',
  AI_MODEL_DELETE: 'TENANT',
  AI_MODEL_PUBLISH: 'TENANT',
  AI_MODEL_DISCONTINUE: 'TENANT',
  AI_MODEL_GET_FILE_CREDENTIAL: 'TENANT',
  AI_MODEL_SHARE_CREATE: 'TENANT',
  AI_MODEL_SHARE_UPDATE: 'TENANT',
  AI_MODEL_SHARE_READ: 'TENANT',
  AI_MODEL_SHARE_LIST_READ: 'TENANT',
  AI_MODEL_SHARE_DELETE: 'TENANT',
  FIRMWARE_CREATE: 'TENANT',
  FIRMWARE_UPDATE: 'TENANT',
  FIRMWARE_READ: 'TENANT',
  FIRMWARE_LIST_READ: 'TENANT',
  FIRMWARE_DELETE: 'TENANT',
  FIRMWARE_PUBLISH: 'TENANT',
  FIRMWARE_DISCONTINUE: 'TENANT',
  FIRMWARE_GET_FILE_CREDENTIAL: 'TENANT',
  FIRMWARE_SHARE_CREATE: 'TENANT',
  FIRMWARE_SHARE_UPDATE: 'TENANT',
  FIRMWARE_SHARE_READ: 'TENANT',
  FIRMWARE_SHARE_LIST_READ: 'TENANT',
  FIRMWARE_SHARE_DELETE: 'TENANT',
  LABEL_CREATE: 'TENANT',
  LABEL_UPDATE: 'TENANT',
  LABEL_READ: 'TENANT',
  LABEL_LIST_READ: 'TENANT',
  LABEL_DELETE: 'TENANT',
  LABEL_PUBLISH: 'TENANT',
  LABEL_DISCONTINUE: 'TENANT',
  LABEL_CATEGORY_CREATE: 'PLATFORM',
  LABEL_CATEGORY_UPDATE: 'PLATFORM',
  LABEL_CATEGORY_READ: 'TENANT',
  LABEL_CATEGORY_LIST_READ: 'TENANT',
  PRODUCT_CREATE: 'PLATFORM',
  PRODUCT_UPDATE: 'PLATFORM',
  PRODUCT_READ: 'TENANT',
  PRODUCT_LIST_READ: 'TENANT',
  PRODUCT_DELETE: 'PLATFORM',
  PRODUCT_RELEASE: 'PLATFORM',
  PRODUCT_DISCONTINUE: 'PLATFORM',
  PRODUCT_DEPLOY_FIRMWARE: 'PLATFORM',
  PRODUCT_UNDEPLOY_FIRMWARE: 'PLATFORM',
  PRODUCT_SHARE_CREATE: 'PLATFORM',
  PRODUCT_SHARE_UPDATE: 'PLATFORM',
  PRODUCT_SHARE_READ: 'TENANT',
  PRODUCT_SHARE_LIST_READ: 'TENANT',
  PRODUCT_SHARE_DELETE: 'PLATFORM',
  TENANT_CREATE: 'PLATFORM',
  TENANT_READ: 'TENANT',
  TENANT_LIST_READ: 'PLATFORM',
  TENANT_UPDATE: 'TENANT',
  TENANT_UPDATE_REGISTRATION_SETTING: 'TENANT',
  TENANT_UPDATE_RETENTION: 'PLATFORM',
  TRIGGER_CREATE: 'TENANT',
  TRIGGER_UPDATE: 'TENANT',
  TRIGGER_READ: 'TENANT',
  TRIGGER_LIST_READ: 'TENANT',
  TRIGGER_DELETE: 'TENANT',
  TRIGGER_PUBLISH: 'TENANT',
  TRIGGER_DISCONTINUE: 'TENANT',
  TRIGGER_SHARE_CREATE: 'TENANT',
  TRIGGER_SHARE_UPDATE: 'TENANT',
  TRIGGER_SHARE_READ: 'TENANT',
  TRIGGER_SHARE_LIST_READ: 'TENANT',
  TRIGGER_SHARE_DELETE: 'TENANT',
  STREAM_LIST_READ: 'TENANT',
  EVENT_READ: 'TENANT',
  EVENT_LIST_READ_BY_TENANT: 'TENANT',
  EVENT_LIST_READ_BY_DEVICE: 'TENANT',
  EVENT_LIST_READ_BY_USER: 'TENANT',
  DEPLOYMENT_GROUP_CREATE: 'TENANT',
  DEPLOYMENT_GROUP_READ: 'TENANT',
  DEPLOYMENT_GROUP_LIST_READ: 'TENANT',
  DEPLOYMENT_GROUP_UPDATE: 'TENANT',
  DEPLOYMENT_GROUP_DEPLOY_FIRMWARE: 'TENANT',
  DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE: 'TENANT',
  DEPLOYMENT_GROUP_DEPLOY_TRIGGER: 'TENANT',
  DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER: 'TENANT',
  DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER: 'TENANT',
  DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER: 'TENANT',
  DEVICE_KEY_CREATE: 'PLATFORM',
  DEVICE_READ: 'TENANT',
  DEVICE_LIST_READ: 'TENANT',
  DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP: 'TENANT',
  DEVICE_LIST_READ_BY_USER_ID: 'TENANT',
  DEVICE_KEY_UPDATE: 'TENANT',
  DEVICE_RESET: 'TENANT',
  DEVICE_UPDATE_ONBOARDED_DEVICE: 'TENANT',
  DEVICE_ADD_TO_DEPLOYMENT_GROUP: 'TENANT',
  DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP: 'TENANT',
  DEVICE_ADD_USER_ACCESS: 'TENANT',
  DEVICE_REMOVE_USER_ACCESS: 'TENANT',
  DEVICE_TRANSFER_DEVICE: 'TENANT',
  DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ: 'TENANT',
  DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ: 'TENANT',
  DEVICE_TRIGGER_DEPLOYMENT_LIST_READ: 'TENANT',
  DEVICE_STREAM: 'TENANT',
  DEVICE_GET_MEDIA_CREDENTIAL: 'TENANT',
  DEVICE_ADD_MEDIA: 'TENANT',
  DEVICE_READ_MEDIA_LIST: 'TENANT',
  DEVICE_ADD_FIRMWARE_INFO: 'TENANT',
  DEVICE_READ_FIRMWARE_INFO: 'TENANT',
  DEVICE_PRESENCE_READ: 'TENANT',
  DEVICE_REBOOT: 'TENANT',
  DEVICE_LOG_READ: 'TENANT',
  DEVICE_POOL_CREATE: 'TENANT',
  DEVICE_POOL_UPDATE: 'TENANT',
  DEVICE_POOL_READ: 'TENANT',
  DEVICE_POOL_LIST_READ: 'TENANT',
  DEVICE_POOL_DEPLOY_FIRMWARE: 'TENANT',
  DEVICE_POOL_UNDEPLOY_FIRMWARE: 'TENANT',
  ACCESS_RIGHT_LIST_READ: 'TENANT',
  END_USER_CREATE: 'TENANT',
  END_USER_READ: 'TENANT',
  END_USER_UPDATE: 'TENANT',
  END_USER_LIST_READ: 'TENANT',
  END_USER_LOG_READ: 'TENANT',
  END_USER_GET_FILE_CREDENTIAL: 'TENANT',
  PLATFORM_ADMIN_CREATE: 'PLATFORM',
  PLATFORM_ADMIN_READ: 'PLATFORM',
  PLATFORM_ADMIN_UPDATE: 'PLATFORM',
  PLATFORM_ADMIN_TENANT_ACCESS_UPDATE: 'PLATFORM',
  PLATFORM_ADMIN_DELETE: 'PLATFORM',
  PLATFORM_ADMIN_LIST_READ: 'PLATFORM',
  PLATFORM_ADMIN_GET_FILE_CREDENTIAL: 'PLATFORM',
  ROLE_CREATE: 'PLATFORM',
  ROLE_READ: 'TENANT',
  ROLE_LIST_READ: 'TENANT',
  ROLE_UPDATE: 'PLATFORM',
  ROLE_DELETE: 'PLATFORM',
  TENANT_ADMIN_CREATE: 'TENANT',
  TENANT_ADMIN_READ: 'TENANT',
  TENANT_ADMIN_LIST_READ: 'TENANT',
  TENANT_ADMIN_UPDATE: 'TENANT',
  TENANT_ADMIN_DELETE: 'TENANT',
  TENANT_ADMIN_GET_FILE_CREDENTIAL: 'TENANT',
  AI_ACCELERATOR_ALL: 'PLATFORM',
  AI_CONTAINER_ALL: 'TENANT',
  AI_CONTAINER_DEPLOYMENT_ALL: 'TENANT',
  AI_CONTAINER_DEPLOYMENT_CREATE: 'TENANT',
  AI_CONTAINER_DEPLOYMENT_LIST_READ: 'TENANT',
  AI_CONTAINER_SHARE_ALL: 'TENANT',
  AI_MODEL_ALL: 'TENANT',
  AI_MODEL_SHARE_ALL: 'TENANT',
  FIRMWARE_ALL: 'TENANT',
  FIRMWARE_DEPLOYMENT_ALL: 'TENANT',
  FIRMWARE_DEPLOYMENT_CREATE: 'TENANT',
  FIRMWARE_DEPLOYMENT_LIST_READ: 'TENANT',
  FIRMWARE_SHARE_ALL: 'TENANT',
  LABEL_ALL: 'TENANT',
  LABEL_CATEGORY_ALL: 'PLATFORM',
  PRODUCT_DEPLOYMENT_ALL: 'PLATFORM',
  PRODUCT_ALL: 'PLATFORM',
  PRODUCT_SHARE_ALL: 'PLATFORM',
  TENANT_ALL: 'PLATFORM',
  TRIGGER_ALL: 'TENANT',
  TRIGGER_DEPLOYMENT_ALL: 'TENANT',
  TRIGGER_DEPLOYMENT_CREATE: 'TENANT',
  TRIGGER_DEPLOYMENT_LIST_READ: 'TENANT',
  TRIGGER_SHARE_ALL: 'TENANT',
  STREAM_ALL: 'TENANT',
  EVENT_LIST_READ: 'TENANT',
  EVENT_ALL: 'TENANT',
  EVENT_UPDATE: 'TENANT',
  DEPLOYMENT_GROUP_ALL: 'TENANT',
  DEPLOYMENT_GROUP_DEPLOYMENT_ALL: 'TENANT',
  DEVICE_DEPLOYMENT_ALL: 'TENANT',
  DEVICE_ALL: 'PLATFORM',
  DEVICE_AI_CONTAINER_DOWNLOAD: 'TENANT',
  DEVICE_ADD_TO_GROUP: 'TENANT',
  DEVICE_REMOVE_FROM_GROUP: 'TENANT',
  DEVICE_LIST_READ_IN_GROUP: 'TENANT',
  DEVICE_LOG_ALL: 'TENANT',
  DEVICE_LOG_SAVE: 'TENANT',
  DEVICE_POOL_ALL: 'TENANT',
  ACCESS_RIGHT_ALL: 'TENANT',
  END_USER_ALL: 'TENANT',
  END_USER_LOG_ALL: 'TENANT',
  END_USER_LOG_SAVE: 'TENANT',
  PLATFORM_ADMIN_ALL: 'PLATFORM',
  ROLE_ALL: 'PLATFORM',
  TENANT_ADMIN_ALL: 'TENANT',
};
