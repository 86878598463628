import { ChartSlice } from './chart-slice';

export const {
  // update this entries after you have added new actions
  updateData,
} = ChartSlice.actions;

export * from './chart-slice';
export * from './persistor';
export * from './selectors';

/**
 * @typedef {import('./chart-slice').InitialState & import('redux-persist').PersistedState} ChartState
 */
