import api from '@/api';
import { getAiAcceleratorMap, getTenantMap } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenProduct>} RequestType */

/** @param {{status: string?}} props */
export function ProductListView(props) {
  const { status } = props;
  const { tenantId } = useParams();

  const navigate = useNavigate();
  const tenantMap = useSelector(getTenantMap);
  const acceleratorMap = useSelector(getAiAcceleratorMap);

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (limit, token) => {
        const request = api.sai.v1.product.$get({
          params: {
            pageSize: limit,
            continuationToken: token,
            tenantId,
            status,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={(item) => navigate(`./${item.productId}`)}
      headers={[
        {
          key: 'name',
          title: 'Name',
          minWidth: 150,
        },
        {
          key: 'serialNo',
          title: 'Serial',
          align: 'center',
          minWidth: 100,
        },
        {
          key: 'productStatus',
          width: 125,
          title: 'Status',
          align: 'center',
          builder: ({ productStatus }) => (
            <span className="badge" style={{ background: makeColorFrom(productStatus, 22) }}>
              {productStatus}
            </span>
          ),
        },
        {
          key: 'description',
          title: 'Description',
          minWidth: 200,
        },
        {
          key: 'aiAcceleratorIds',
          title: 'AI Accelarator',
          minWidth: 150,
          disableAction: true,
          builder: ({ aiAcceleratorIds }) =>
            acceleratorMap &&
            acceleratorMap[aiAcceleratorIds[0]] && (
              <Link to={`/super-admin/ai-accelerator/${aiAcceleratorIds[0]}`}>
                {acceleratorMap[aiAcceleratorIds[0]].name}
              </Link>
            ),
        },
        {
          key: 'ownerTenantId',
          title: 'Owner Tenant',
          minWidth: 150,
          disableAction: true,
          builder: ({ ownerTenantId }) =>
            tenantMap &&
            tenantMap[ownerTenantId] && (
              <Link to={`/settings/tenants/${ownerTenantId}`}>{tenantMap[ownerTenantId].name}</Link>
            ),
        },
      ]}
    />
  );
}
