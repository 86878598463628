import api from '@/api';
import { useParams } from 'react-router';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { formatDateTime, formatInitiatorType, formatStreamStatus } from '@/utils/formatting';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenStream>} RequestType */

export function StreamListView() {
  const { tenantId } = useParams();
  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (limit, token) => {
        const request = api.sai.v1.stream.$get({
          params: {
            tenantId,
            pageSize: limit,
            continuationToken: token,
          },
        });
        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      headers={[
        {
          key: 'deviceId',
          minWidth: 150,
        },
        {
          key: 'deviceName',
          minWidth: 150,
        },
        {
          key: 'initiatorType',
          minWidth: 150,
          /** @param {Stream} [item]*/
          builder: (item) => formatInitiatorType(item.initiatorType),
        },
        {
          key: 'lastUpdateTime',
          minWidth: 150,
          /** @param {Stream} [item]*/
          builder: (item) => formatDateTime(item.lastUpdateTime),
        },
        {
          key: 'endTime',
          minWidth: 150,
          /** @param {Stream} [item]*/
          builder: (item) => formatDateTime(item.endTime),
        },
        {
          key: 'streamStatus',
          minWidth: 150,
          /** @param {Stream} [item]*/
          builder: (item) => formatStreamStatus(item.streamStatus),
        },
      ]}
    />
  );
}
