import api from '@/api';
import { TokenBasedPagination } from '@/web/common/pagination/TokenBasedPagination';
import { GroupTableView } from '../GroupTableView';
import { useParams } from 'react-router';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenDeploymentGroup>} RequestType */

export function GroupListView() {
  const { tenantId } = useParams();
  return (
    <TokenBasedPagination
      request={(limit, token) =>
        api.sai.v1['deployment-group'].$get({
          params: {
            tenantId,
            pageSize: limit,
            continuationToken: token,
          },
        })
      }
      nextToken={
        /** @param {RequestType} request */
        (request) => request.result.result.continuationToken
      }
      success={
        /** @param {RequestType} request */
        (request) => {
          let groups = request.result.result.results;
          if (groups.length === 0) {
            return <div className="text-center text-muted py-3">No more groups</div>;
          }
          return <GroupTableView groups={groups} />;
        }
      }
    />
  );
}
