import {
  resolutions,
  videoCodecs,
  colorTypes,
  bitDepths,
  sampleRates,
  quantizations,
  channels,
  audioCodecs,
  audioTypes,
  sensorTypes,
  sensorUnits,
  paramNames,
  configParamTypes,
} from '@/assets/ai-models/autocomplete';

export const getDepthSensor = (value) => {
  return value ? 'Available' : 'Unavailable';
};

export const getAudioCodec = (value) => {
  return audioCodecs[value] || value;
};

export const getVideoCodec = (value) => {
  return videoCodecs[value] || value;
};

export const getAudioTypes = (value) => {
  return audioTypes[value] || value;
};

export const getResolution = (value) => {
  return resolutions[value] || value;
};

export const getColorType = (value) => {
  return colorTypes[value] || value;
};

export const getBitDepth = (value) => {
  return bitDepths[value] || value;
};

export const getSampleRate = (value) => {
  return sampleRates[value] || value;
};

export const getQuantization = (value) => {
  return quantizations[value] || value;
};

export const getChannel = (value) => {
  return channels[value] || value;
};

export const getSensorType = (value) => {
  return sensorTypes[value] || value;
};

export const getSensorUnit = (value) => {
  return sensorUnits[value] || value;
};

export const getParamName = (value) => {
  return paramNames[value] || value;
};

export const getConfigParamType = (value) => {
  return configParamTypes[value] || value;
};

export const getAiAcceleratorName = (value, list) => {
  return list[value] || value;
};
