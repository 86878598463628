import { createContext, useState } from 'react';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { CreateRoleModal } from './@components/CreateRoleModal';
import { RoleListView } from './@components/RoleListView';
import { SettingsDonutGroup } from '../@components/SettingsDonutGroup';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { USER_ACCESS_ROLE_CREATE } from '@/web/common/AccessRights';

export const RoleListContext = createContext();

export function RolesManagement() {
  const [id, changeId] = useState(null);
  return (
    <RoleListContext.Provider value={[id, changeId]}>
      <SidebarPlotLayout sidebar={<SettingsDonutGroup sidebar={true} />}>
        <BreadcrumbLayout.Header title="Manage Roles">
          <USER_ACCESS_ROLE_CREATE>
            <CreateRoleModal key={id} />
          </USER_ACCESS_ROLE_CREATE>
        </BreadcrumbLayout.Header>
        <RoleListView />
      </SidebarPlotLayout>
    </RoleListContext.Provider>
  );
}
