import {
  USER_ACCESS_ADMIN_PAGE,
  USER_ACCESS_AI_PAGE,
  USER_ACCESS_DASHBOARD_PAGE,
  USER_ACCESS_EVENTS_PAGE,
  USER_ACCESS_INTERNAL_PAGE,
  USER_ACCESS_SETTINGS_PAGE,
  USER_ACCESS_SUPER_ADMIN_PAGE,
} from '@/web/common/AccessRights';
import { Nav, Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { MainMenuItem } from './@components/MenuItems';
import { createLink } from './menus';
import { UserAvatar } from './UserAvatar';
import './index.scss';

/** @type {import('./menus').MenuItems} */
const menus = [
  createLink('Dashboard', '/dashboard', USER_ACCESS_DASHBOARD_PAGE), //
  createLink('AI', '/ai', USER_ACCESS_AI_PAGE), //
  createLink('Events', '/events', USER_ACCESS_EVENTS_PAGE), //
  createLink('Admin', '/admin', USER_ACCESS_ADMIN_PAGE), //
  createLink('Super-Admin', '/super-admin', USER_ACCESS_SUPER_ADMIN_PAGE), //
  createLink('Settings', '/settings', USER_ACCESS_SETTINGS_PAGE), //
  createLink('Internal', '/internal', USER_ACCESS_INTERNAL_PAGE), //

  // createDropDown('Dashboard', [
  //   createLink('Devices', '/dashboard/devices'), //
  // ]),
  // createDropDown('AI', [
  //   createLink('AI Models', '/ai/ai-models'),
  //   createLink('Shared AI Models', '/ai/shared-ai-models'),
  //   createDivider(),
  //   createLink('AI Containers', '/ai/ai-containers'),
  //   createLink('Shared AI Containers', '/ai/shared-ai-containers'),
  // ]),
  // createDropDown('Events', [
  //   createLink('Events', '/events/events'),
  //   createDivider(),
  //   createLink('Labels', '/events/labels'),
  //   createLink('Label Categories', '/events/label-categories'),
  //   createDivider(),
  //   createLink('Triggers', '/events/triggers'),
  //   createLink('Shared Triggers', '/ai/shared-triggers'),
  // ]),
  // createDropDown('Admin', [
  //   createLink('Device Firmwares', '/admin/firmwares'),
  //   createLink('Shared Firmwares', '/admin/shared-firmwares'),
  //   createDivider(),
  //   createLink('Device Pools', '/admin/device-pools'),
  //   createLink('Device Groups', '/admin/groups'),
  // ]),
  // createDropDown('Super-Admin', [
  //   createLink('Products', '/super-admin/products'),
  //   createLink('Shared Products', '/super-admin/shared-products'),
  //   createDivider(),
  //   createLink('AI Accelarators', '/super-admin/ai-accelerator'),
  // ]),
  // createDropDown('Management', [
  //   createLink('Roles', '/settings/roles'),
  //   createLink('Tenants', '/settings/tenants'),
  //   createDivider(),
  //   createLink('Platform Admins', '/internal/platform-admin'),
  //   createLink('Tenant Admins', '/internal/tenant-admin'),
  //   createLink('End Users', '/dashboard/end-user'),
  // ]),
];

export function MainNavbar() {
  const location = useLocation();

  return (
    <Navbar id="main-navbar" bg="primary" variant="dark" expand="lg" collapseOnSelect>
      <Navbar.Brand as={Link} to="/">
        <div className="text-center anyconnect-logo">
          <img src="/images/logo.svg" alt="logo" />
          <span>Console</span>
        </div>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="main-navbar" />
      <Navbar.Collapse className="justify-content-end">
        <hr className="mt-1 mb-2 mx-3 d-lg-none" />
        <Nav activeKey={location.pathname}>
          {menus.map((menu, index) => (
            <MainMenuItem key={index} menu={menu} index={index} />
          ))}
        </Nav>
        <UserAvatar />
      </Navbar.Collapse>
    </Navbar>
  );
}
