import api from '@/api';
import { confirmDialog, displayError, displaySuccess } from '@/utils/toaster';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { DeviceViewContext } from '../DeviceViewLayout';

export function RemoveGroupButton(props) {
  /** @type {{device: DeviceDetail}} */
  const { device, updateDevice } = useContext(DeviceViewContext);
  const { groupId } = props;
  const [loading, setLoading] = useState(false);

  const removeGroup = async () => {
    const request = api.sai.v1['deployment-group']
      .$groupId(groupId)
      .device.$deviceId(device.deviceId)
      .$delete({});
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      displaySuccess('Success', `Device removed from group!`);
      updateDevice();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      variant="warning"
      style={{ width: 150 }}
      disabled={loading}
      onClick={() =>
        confirmDialog({
          onConfirm: removeGroup,
          title: `Remove ${device.deviceId} (${device.tag}) from group?`,
          message: 'It will affect all resources using this Device',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Remove Group'}
    </Button>
  );
}
