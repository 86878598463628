import api from '@/api';
import { displayError, displaySuccess } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { ChangePlatformAdminPasswordModal } from '../ChangePlatformAdminPasswordModal';

/** @param {{user: PlatformAdmin }} props */
export function EditPlatformAdminForm(props) {
  const { user } = props;

  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState(user.address || '');
  const [company, setCompany] = useState(user.company || '');
  const [description, setDescription] = useState(user.description || '');
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [phone, setPhone] = useState(user.phone || '');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      const request = api.sai.v1['platform-admin'].$id(user.id).$patch({
        data: {
          address: address !== user.address ? address : null,
          company: company !== user.company ? company : null,
          description: description !== user.description ? description : null,
          displayName: displayName !== user.displayName ? displayName : null,
          firstName: firstName !== user.firstName ? firstName : null,
          lastName: lastName !== user.lastName ? lastName : null,
          phone: phone !== user.phone ? phone : null,
          // pictureFileId, TODO
        },
      });
      await request.process();
      setLoading(false);
      displaySuccess('Success', 'Updated platform admin');
    } catch (err) {
      displayError(err);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} id="platform-admin-edit-form">
      <Card.Body>
        <div className="d-lg-flex">
          <div className="mb-2 me-lg-2" style={{ flex: '1 1' }}>
            <InputBox label="First Name" value={firstName} onChange={setFirstName} />
          </div>
          <div className="mb-2" style={{ flex: '1 1' }}>
            <InputBox label="Last Name" value={lastName} onChange={setLastName} />
          </div>
        </div>
        <InputBox label="Display Name" value={displayName} onChange={setDisplayName} />
        <InputBox
          label="Description"
          textarea
          value={description}
          onChange={setDescription}
          style={{ height: 100 }}
        />
        <InputBox label="Company name" value={company} onChange={setCompany} />
        <InputBox label="Phone number" value={phone} onChange={setPhone} />
        <InputBox
          label="Address"
          textarea
          value={address}
          onChange={setAddress}
          style={{ height: 100 }}
        />
      </Card.Body>
      <Card.Footer className="d-flex align-items-center justify-content-between">
        <ChangePlatformAdminPasswordModal user={user} />
        {/* <DeletePlatformAdminButton disabled={loading} user={user} /> */}
        <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
          {loading && <Spinner animation="border" size="sm" />} Save
        </Button>
      </Card.Footer>
    </Form>
  );
}
