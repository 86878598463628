/**
 * @template T
 * @param {(auth: import(".").CacheState) => T} callback
 * @returns {(state: import("..").StoreState) => T}
 */
const withCacheState = (callback) => (state) => callback(state.cache);

export const getRolesMap = withCacheState((cache) => cache.roles || {});

export const getRoles = (state) => Object.values(getRolesMap(state));

export const getAccessRights = withCacheState((cache) => cache.accessRights || []);

export const getTenantMap = withCacheState((cache) => cache.tenants || {});

export const getTenant = (id) => withCacheState((cache) => cache.tenants[id]);

export const getTenantList = (state) => Object.values(getTenantMap(state));

/** @param {import("..").StoreState} state */
export const getProductMap = (state) => state.cache.products[state.auth.tenantId] || {};

/**
 * @param {string} id product id
 * @returns {(state: import("..").StoreState) => Product}
 */
export const getProduct = (id) => (state) => getProductMap(state)[id];

/** @param {import("..").StoreState} state */
export const getProductList = (state) => Object.values(getProductMap(state));

export const getAiAcceleratorMap = withCacheState((cache) => cache.accelerators || {});

/** @param {string} id AI Accelerator id */
export const getAiAccelerator = (id) => withCacheState((cache) => cache.accelerators[id]);

export const getAiAcceleratorList = (state) => Object.values(getAiAcceleratorMap(state));

export const getAiModelMap = withCacheState((cache) => cache.aiModels || {});

export const getAiModel = (id) => withCacheState((cache) => cache.aiModels[id]);

export const getAiModelList = (state) => Object.values(getAiModelMap(state));

export const getGroupsByTenant = (tenantId) =>
  withCacheState((cache) => cache.groups[tenantId] || []);

export const getGroupsByTenantAndProduct = (tenantId, productId) =>
  withCacheState(
    (cache) => cache.groups[tenantId]?.filter((item) => item?.productId === productId) || []
  );

export const getGroup = (groupId, tenantId) =>
  withCacheState(
    (cache) => cache.groups?.[tenantId]?.find((group) => group.groupId === groupId) || {}
  );

export const getDevicePoolsByTenant = (tenantId) =>
  withCacheState((cache) => cache.devicePools?.[tenantId] || []);

export const getDevicePoolsByTenantAndProduct = (tenantId, productId) =>
  withCacheState(
    (cache) => cache.devicePools[tenantId]?.filter((item) => item?.productId === productId) || []
  );

export const getLabelsByTenant = (tenantId) =>
  withCacheState((cache) => cache.labels?.[tenantId] || {});

export const getLabel = (labelId, tenantId) =>
  withCacheState((cache) => cache.labels?.[tenantId]?.[labelId] || {});

export const getLabelCategoryMap = withCacheState((cache) => cache.labelCategories || {});

export const getLabelCategory = (id) =>
  withCacheState((cache) => cache.labelCategories?.[id] || {});

export const getTriggersByTenant = (tenantId) =>
  withCacheState((cache) => cache.triggers?.[tenantId] || {});

export const getTrigger = (triggerId, tenantId) =>
  withCacheState((cache) => cache.triggers?.[tenantId]?.[triggerId] || {});
