export const labelTypes = {
  PRIMARY: 'Primary',
  ALIAS: 'Alias',
};

export const triggerThresholdInputTypes = {
  INPUT: 'Input',
  SLIDER: 'Slider',
};

export const labelUnits = {
  'years': 'years',
  '%': '%',
  'seconds': 'seconds',
  '°C': '°C',
  'rpm': 'rpm',
  'kPa': 'kPa',
  'degrees': 'degrees',
  'grams/sec': 'grams/sec',
  'm/s': 'm/s',
  'km': 'km',
  '°before TDC': '°before TDC',
  'Volt': 'Volt',
  'Boolean (ON/OFF)': 'Boolean (ON/OFF)',
  'm/s²': 'm/s^2',
  'Meter': 'Meter',
  'Milliseconds': 'Milliseconds',
  'angle': 'angle',
};
