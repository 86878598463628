import { formatDateTime } from '@/utils/formatting';
import { formatTriggerType } from '@/utils/triggers';
import { DataListTable } from '@/web/common/DataListTable';

/** @param {{deployments: Array.<TriggerDeploymentModel>}} props */
export function DeployedTableView(props) {
  const deployments = props.deployments;
  return (
    <DataListTable
      data={deployments}
      headers={[
        {
          key: 'deploymentTime',
          title: 'Deployment Time',
          width: 225,
          /** @param {TriggerDeploymentModel} [item]*/
          builder: (item) => formatDateTime(item.deploymentTime),
        },
        {
          key: 'triggerName',
          title: 'Name',
        },
        {
          key: 'triggerType',
          title: 'Type',
          /** @param {TriggerDeploymentModel} [item]*/
          builder: (item) => formatTriggerType(item.triggerType),
        },
      ]}
    />
  );
}
