import { GiMagicBroom } from 'react-icons/gi';
import { DroppableInputOutputMapping } from '../DroppableInputOutputMapping';

/**
 * @typedef {object} DroppableInputSourcesProps
 * @property {AiModel} source
 * @property {Object.<string, InputMapModel>} mappings
 * @property {(mappings: Object.<string, InputMapModel>) => any} onChange
 */

/** @param {DroppableInputSourcesProps} props */
export function DroppableInputSources(props) {
  const { source, mappings, onChange } = props;

  /**
   * @param {VideoInput & AudioInput & SensorInput} input
   * @returns {(output: InputMapModel) => any}
   */
  const handleDrop = (input) => (output) => {
    onChange(Object.assign({}, mappings, { [input.code]: output }));
  };

  /**
   * @param {VideoInput & AudioInput & SensorInput} input
   * @returns {(e: Event) => any}
   */
  const handleClear = (input) => (e) => {
    e.stopPropagation();
    delete mappings[input.code];
    onChange(mappings);
  };

  const noItems = !(
    source.videoInputs.length ||
    source.audioInputs.length ||
    source.sensorInputs.length
  );

  return (
    <div className="d-grid gap-2 align-items-center justify-content-xl-center fixed-scroll input-sources">
      {source.videoInputs.map((input) => (
        <DroppableInputOutputMapping
          key={input.code}
          inputType="VIDEO"
          input={input}
          source={source}
          mapping={mappings[input.code]}
          onDrop={handleDrop(input)}
          onClear={handleClear(input)}
        />
      ))}

      {source.audioInputs.map((input) => (
        <DroppableInputOutputMapping
          key={input.code}
          inputType="AUDIO"
          input={input}
          source={source}
          mapping={mappings[input.code]}
          onDrop={handleDrop(input)}
          onClear={handleClear(input)}
        />
      ))}

      {source.sensorInputs.map((input) => (
        <DroppableInputOutputMapping
          key={input.code}
          inputType="SENSOR"
          input={input}
          source={source}
          mapping={mappings[input.code]}
          onDrop={handleDrop(input)}
          onClear={handleClear(input)}
        />
      ))}

      {noItems && (
        <div className="text-center text-muted">
          <GiMagicBroom size={64} color="teal" />
          <div className="py-2 small">No input sources available</div>
        </div>
      )}
    </div>
  );
}
