import { MdMovie } from 'react-icons/md';
import { getBitDepth, getColorType, getResolution, getVideoCodec } from '@/utils/ai-models';
import { DataItemTable } from '@/web/common/DataItemTable';
import { InputCardLayout } from '../InputCardLayout';

/** @param {{input: VideoInput, source: AiModel }} props */
export function VideoInputCard(props) {
  const { input } = props;
  return (
    <InputCardLayout {...props} icon={<MdMovie />}>
      <DataItemTable
        dense
        border={false}
        data={input}
        className="small"
        minWidth={100}
        headers={[
          {
            key: 'colorType',
            builder: ({ colorType }) => getColorType(colorType),
          },
          {
            key: 'resolution',
            builder: ({ resolution }) => getResolution(resolution),
          },
          {
            key: 'codec',
            builder: ({ codec }) => getVideoCodec(codec),
          },
          {
            key: 'fps',
            title: 'FPS',
          },
          {
            key: 'bitDepth',
            builder: ({ bitDepth }) => getBitDepth(bitDepth),
          },
          {
            key: 'bitrate',
          },
          {
            key: 'depthSensorAvailable',
            title: 'Depth Sensor',
            builder: ({ depthSensorAvailable }) => depthSensorAvailable && 'Available',
          },
          {
            title: 'Field of View',
            builder: () =>
              input.fovX &&
              input.fovY && (
                <>
                  {input.fovX}&deg; &times; {input.fovY}&deg;
                </>
              ),
          },
          {
            key: 'grayScaleAmount',
            title: 'Grayscale amount',
          },
        ]}
      />
    </InputCardLayout>
  );
}
