/**
 * @typedef {object} Divider
 * @property {'divider'} type
 */

import { Fragment } from 'react';

/**
 * @typedef {object} MenuLink
 * @property {'link'} type
 * @property {string} to
 * @property {string} text
 * @property {any} access
 */

/**
 * @typedef {MenuLink | Divider} DropdownMenuItem
 */

/**
 * @typedef {object} DropdownMenu
 * @property {'dropdown'} type
 * @property {string} text
 * @property {Array.<DropdownMenuItem>} children
 * @property {any} access
 */

/**
 * @typedef {DropdownMenu | MenuLink} MainMenuItem
 */

/**
 * @typedef {Array.<MainMenuItem>} MenuItems
 */

/**
 * Create a link menu item
 *
 * @param {string} text
 * @param {string} to
 * @param {any} access
 * @returns {DropdownMenu}
 */
export function createLink(text, to, access) {
  access ||= Fragment;
  return {
    type: 'link',
    text,
    to,
    access,
  };
}

/**
 * Create a dropdown menu item
 *
 * @param {string} text
 * @param {Array.<DropdownMenuItem>} children
 * @param {any} access
 * @returns {DropdownMenu}
 */
export function createDropDown(text, children, access) {
  access ||= Fragment;
  return {
    type: 'dropdown',
    text,
    children,
    access,
  };
}

/**
 * Create a divider
 *
 * @returns {Divider}
 */
export function createDivider() {
  return {
    type: 'divider',
  };
}
