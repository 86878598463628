import { Button } from 'react-bootstrap';
import { BsGoogle } from 'react-icons/bs';
import './index.scss';

/**
 * @typedef {object} GoogleButtonProps
 * @property {boolean} [disabled]
 * @property {(e: Event) => any} [onClick]
 */

/**
 * @param {GoogleButtonProps} props
 */
export function GoogleButton(props) {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      className="social-button google-login-button"
      size="lg"
    >
      <BsGoogle /> Google
    </Button>
  );
}
