import { sensorTypes, sensorUnits } from '@/assets/ai-models/autocomplete';
import { Col, Row } from 'react-bootstrap';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';

/**
 * @param {{item: SensorInput, onChange: () => {}, editing: boolean}} props
 */
export function SensorInputFields(props) {
  const { item, onChange, editing } = props;

  /**
   * @param {string} fieldName
   * @returns {[string, (value: string) => any]}
   */
  const changeObserver = (fieldName) => {
    const value = (item && item[fieldName]) || '';
    const setValue = (v) => onChange({ ...item, [fieldName]: v });
    return [value, setValue];
  };

  const [code, setCode] = changeObserver('code');
  const [name, setName] = changeObserver('name');
  const [description, setDescription] = changeObserver('description');
  const [sensorType, setSensorType] = changeObserver('sensorType');
  const [sensorUnit, setSensorUnit] = changeObserver('sensorUnit');

  const showUnitDropdown = !!sensorUnits[sensorType];

  return (
    <Row className="gx-2">
      <Col lg={6}>
        <InputBox disabled={editing} label="Code" value={code} onChange={setCode} required />
      </Col>
      <Col lg={6}>
        <InputBox label="Name" value={name} onChange={setName} required />
      </Col>
      <Col lg={12}>
        <InputBox label="Description" value={description} onChange={setDescription} />
      </Col>
      <Col lg={6}>
        <SelectBox
          label="Sensor Type"
          value={sensorType}
          onChange={setSensorType}
          options={Object.entries(sensorTypes || {})}
          itemValue={0}
          itemText={1}
        />
      </Col>
      {showUnitDropdown && (
        <Col lg={6}>
          <SelectBox
            label="Sensor Unit"
            value={sensorUnit}
            onChange={setSensorUnit}
            options={Object.entries(sensorUnits[sensorType] || {})}
            itemValue={0}
            itemText={1}
          />
        </Col>
      )}
    </Row>
  );
}
