import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import { nanoid } from '@reduxjs/toolkit';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { RoleListContext } from '../..';

export function CreateRoleModal() {
  const [componentId, changeId] = useContext(RoleListContext);

  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.role.$post({
        data: {
          name,
          description,
          type: 'DEFAULT',
          rights: [],
        },
      });
      await request.process();
      setLoading(false);
      setShow(false);
      changeId(nanoid());
      iziToast.info({
        title: `Role ${name} has been created`,
      });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Button key={componentId} variant="outline-primary" onClick={() => setShow(true)}>
        <FaPlus /> New Role
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton className="px-4">
          <Modal.Title>Create New Role</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <InputBox
              label="Name"
              className="mb-3"
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={setName}
              required
            />
            <InputBox
              label="Description"
              className="mb-3"
              textarea
              placeholder="Enter description"
              value={description}
              onChange={setDescription}
            />
            {error && <Alert variant="danger"> {error + ''}</Alert>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setShow(false)}
              style={{ width: 100 }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" style={{ width: 100 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
