import { InputSlider } from '@/web/common/forms/InputSlider';
import { useContext, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { SettingsContext } from '../..';

/** @param {{settings: GroupSettingsModel, defaultSettings: GroupSettingsModel}} props */
export function Profiling(props) {
  const { loadingOnUpdate, settings, defaultSettings } = props;
  const { updateSettings } = useContext(SettingsContext);
  const [cpuProfilingFrequency, setCpuProfilingFrequency] = useState(
    settings.cpuProfilingFrequency ?? defaultSettings.cpuProfilingFrequency
  );
  const [gpuProfilingFrequency, setGpuProfilingFrequency] = useState(
    settings.gpuProfilingFrequency ?? defaultSettings.gpuProfilingFrequency
  );
  const [memoryProfilingFrequency, setMemoryProfilingFrequency] = useState(
    settings.memoryProfilingFrequency ?? defaultSettings.memoryProfilingFrequency
  );
  const [networkProfilingFrequency, setNetworkProfilingFrequency] = useState(
    settings.networkProfilingFrequency ?? defaultSettings.networkProfilingFrequency
  );
  const [diskProfilingFrequency, setDiskProfilingFrequency] = useState(
    settings.diskProfilingFrequency ?? defaultSettings.diskProfilingFrequency
  );
  const [canbusProfilingFrequency, setCanbusProfilingFrequency] = useState(
    settings.canbusProfilingFrequency ?? defaultSettings.canbusProfilingFrequency
  );
  const [sensorProfilingFrequency, setsensorProfilingFrequency] = useState(
    settings.sensorProfilingFrequency ?? defaultSettings.sensorProfilingFrequency
  );
  const [profileNetworkOnActiveAdapterChange, setProfileNetworkOnActiveAdapterChange] = useState(
    settings.profileNetworkOnActiveAdapterChange ??
      defaultSettings.profileNetworkOnActiveAdapterChange
  );

  const handleSubmit = () => {
    /** @type {GroupSettingsModel} */
    const data = {
      cpuProfilingFrequency,
      gpuProfilingFrequency,
      memoryProfilingFrequency,
      networkProfilingFrequency,
      diskProfilingFrequency,
      canbusProfilingFrequency,
      sensorProfilingFrequency,
      profileNetworkOnActiveAdapterChange,
    };
    updateSettings(data);
  };

  return (
    <>
      <Card.Body className="mt-2">
        <Row>
          <Col lg={4}>
            <InputSlider
              min={30}
              max={600}
              label="CPU Profiling Frequency"
              unit={'sec'}
              value={cpuProfilingFrequency}
              onChange={setCpuProfilingFrequency}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={30}
              max={600}
              label="GPU Profiling Frequency"
              unit={'sec'}
              value={gpuProfilingFrequency}
              onChange={setGpuProfilingFrequency}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={30}
              max={600}
              label="Memory Profiling Frequency"
              unit={'sec'}
              value={memoryProfilingFrequency}
              onChange={setMemoryProfilingFrequency}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={30}
              max={360}
              label="Network Profiling Frequency"
              unit={'min'}
              value={networkProfilingFrequency}
              onChange={setNetworkProfilingFrequency}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={1}
              max={60}
              label="Disk Profiling Frequency"
              unit={'min'}
              value={diskProfilingFrequency}
              onChange={setDiskProfilingFrequency}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={30}
              max={600}
              label="Canbus Profiling Frequency"
              unit={'sec'}
              value={canbusProfilingFrequency}
              onChange={setCanbusProfilingFrequency}
            />
          </Col>
          <Col lg={4}>
            <InputSlider
              min={1}
              max={600}
              label="Sensor Profiling Frequency"
              unit={'sec'}
              value={sensorProfilingFrequency}
              onChange={setsensorProfilingFrequency}
            />
          </Col>
          <Col lg={4} className="my-auto">
            <Form.Check
              type="switch"
              label="Profile Network on Active Adapter Change"
              checked={profileNetworkOnActiveAdapterChange}
              onChange={(e) => setProfileNetworkOnActiveAdapterChange(e.currentTarget.checked)}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          style={{ width: 225 }}
          disabled={loadingOnUpdate}
          onClick={handleSubmit}
        >
          {loadingOnUpdate ? <Spinner animation="border" size="sm" /> : 'Save Settings'}
        </Button>
      </Card.Footer>
    </>
  );
}
