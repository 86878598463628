import { ModelInputCard } from './ModelInputCard';

const { AudioInputCard } = require('./AudioInputCard');
const { DefaultInputCard } = require('./DefaultInputCard');
const { SensorInputCard } = require('./SensorInputCard');
const { VideoInputCard } = require('./VideoInputCard');

/**
 * @typedef {object} InputCardProps
 * @property {'VIDEO'|'AUDIO'|'SENSOR'|'MODEL'} inputType
 * @property {VideoInput & AudioInput & SensorInput & ModelInput} input
 * @property {AiModel} source
 * @property {string} color
 */

/** @param {InputCardProps} props */
export function InputCard(props) {
  const { inputType, ...otherProps } = props;
  if (!props.input || !props.source) {
    return <DefaultInputCard {...otherProps} />;
  }
  switch (inputType) {
    case 'VIDEO':
      return <VideoInputCard {...props} />;
    case 'AUDIO':
      return <AudioInputCard {...props} />;
    case 'SENSOR':
      return <SensorInputCard {...props} />;
    case 'MODEL':
      return <ModelInputCard {...props} />;
    default:
      return <DefaultInputCard {...otherProps} />;
  }
}
