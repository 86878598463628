import api from '@/api';
import { getTenantMap } from '@/state/cache';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

export function SharedProductListView(props) {
  const navigate = useNavigate();
  const { tenantId } = useParams();
  const { status } = props;
  const tenantMap = useSelector(getTenantMap);
  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (limit, token) => {
        const request = api.sai.v1['product-share'].$get({
          params: {
            pageSize: limit,
            continuationToken: token,
            tenantId,
            status,
          },
        });

        await request.process();
        return {
          results: request.result.result.results,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {SharedProductDetailModel} [productDetail] */
        (productDetail) =>
          navigate(`/super-admin/products/${tenantId}/${productDetail.product.productId}`)
      }
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'description',
          minWidth: 200,
          builder: ({ product }) => product?.description,
        },
        {
          key: 'accessLevel',
        },
        {
          key: 'ownerTenantId',
          title: 'Owner Tenant',
          disableAction: true,
          minWidth: 200,
          builder: ({ product }) =>
            tenantMap &&
            tenantMap[product?.ownerTenantId] && (
              <Link to={`/settings/tenants/${product?.ownerTenantId}`}>
                {tenantMap[product?.ownerTenantId].name}
              </Link>
            ),
        },
      ]}
    />
  );
}
