import api from '@/api';
import { confirmDialog, displayError, displaySuccess } from '@/utils/toaster';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { AiModelContext } from '../../../@components/AiModelViewLayout';

/** @param {{value: 'videoInputs'|'audioInputs'|'sensorInputs'|'modelInputs', item: VideoInput & AudioInput & SensorInput & ModelInput}} props */
export function DeleteInputSourceButton(props) {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item: model, updateItem } = useContext(AiModelContext);
  const { value, item } = props;

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const request = api.sai.v1['ai-model'].$modelId(model.modelId).$put({
        data: {
          [value]: model[value].filter((el) => el.code !== item.code),
        },
      });
      await request.process();
      setLoading(false);
      displaySuccess(`Input source removed`);
      updateItem();
    } catch (err) {
      console.error(err);
      setLoading(false);
      displayError(err);
    }
    return false;
  };

  return (
    <Button
      className="rounded-0"
      variant="outline-danger"
      disabled={loading}
      style={{ width: '50%' }}
      onClick={() =>
        confirmDialog({
          onConfirm: handleDelete,
          title: `Delete ${item.name} (${item.code})?`,
          message: 'This operation can not be reversed',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
