import { useEffect, useState } from 'react';
import './index.scss';

const dataId = Symbol('draggable data');

/** @param {DragEvent} e */
const handleOnClick = (e) => {
  e.stopPropagation();
  window[dataId] = null;
};

export function DraggableDiv(props) {
  const { data, onDrag, onSelect, ...otherProps } = props;

  const [touch, setTouch] = useState(null);
  const [selected, setSelected] = useState(false);

  /** @param {DragEvent} e */
  const handleDragStart = (e) => {
    onDrag && onDrag();
    setSelected(true);
    window[dataId] = data;
    e.dataTransfer.effectAllowed = 'link';
  };

  /** @param {DragEvent} e */
  const handleDragEnd = (e) => {
    setSelected(false);
    window[dataId] = null;
  };

  /** @param {TouchEvent} e */
  const handleTouchStart = (e) => {
    e.stopPropagation();
    clearTimeout(touch);
    const timeoutId = setTimeout(() => {
      onDrag && onDrag();
      window[dataId] = data;
      setSelected(true);
      onSelect && onSelect();
    }, 800);
    setTouch(timeoutId);
  };

  /** @param {TouchEvent} e */
  const handleTouchEnd = (e) => {
    e.stopPropagation();
    clearTimeout(touch);
  };

  useEffect(
    () => {
      if (!selected) return;
      const intervalId = setInterval(() => {
        if (window[dataId] !== data) {
          clearInterval(intervalId);
          setSelected(false);
        }
      }, 100);
      return () => clearInterval(intervalId);
    },
    // eslint-disable-next-line
    [selected]
  );

  useEffect(() => {
    document.addEventListener('click', handleOnClick);
    return () => document.removeEventListener('click', handleOnClick);
  }, []);

  return (
    <div
      {...otherProps}
      draggable
      onContextMenu={(e) => e.preventDefault()}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{
        cursor: 'grab',
        userSelect: 'none',
        animation: selected ? 'shaking 0.5s infinite linear' : null,
        //...otherProps.style,
      }}
    />
  );
}

export function DroppableDiv(props) {
  const { canDrop, onDrop, ...otherProps } = props;
  const color = props.color || 'teal';

  const [selected, setSelected] = useState(false);

  const isAcceptable = () => window[dataId] && canDrop && canDrop(window[dataId]);

  /** @param {TouchEvent} e  */
  const handleClick = (e) => {
    e.preventDefault();
    if (isAcceptable()) {
      onDrop && onDrop(window[dataId]);
      window[dataId] = null;
    }
  };

  /** @param {DragEvent} e  */
  const handleDrop = (e) => {
    e.preventDefault();
    onDrop && onDrop(window[dataId]);
    window[dataId] = null;
  };

  /** @param {DragEvent} e  */
  const handleDragOver = (e) => {
    if (isAcceptable()) {
      e.preventDefault();
    }
  };

  useEffect(
    () => {
      const intervalId = setInterval(() => {
        if (!window[dataId]) {
          if (selected) setSelected(false);
        } else if (isAcceptable()) {
          if (!selected) setSelected(true);
        }
      }, 100);
      return () => clearInterval(intervalId);
    },
    // eslint-disable-next-line
    [selected]
  );

  return (
    <div
      {...otherProps}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
      style={{
        userSelect: 'none',
        borderRadius: '5px',
        //border: '2px dashed teal',
        padding: 2,
        backgroundImage: `linear-gradient(90deg, ${color} 50%, transparent 50%),
          linear-gradient(0deg, ${color} 50%, transparent 50%),
          linear-gradient(90deg, ${color} 50%, transparent 50%),
          linear-gradient(0deg, ${color} 50%, transparent 50%)`,
        backgroundRepeat: 'repeat-x, repeat-y, repeat-x, repeat-y',
        backgroundSize: '10px 2px, 2px 10px, 10px 2px, 2px 10px',
        backgroundPosition: 'left top, right top, left bottom, left top',
        animation: selected ? 'border-dance 15s infinite linear' : null,
        ...otherProps.style,
      }}
    />
  );
}
