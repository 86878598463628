import api from '@/api';
import { checkIsCacheValid, updateData } from '..';

/**
 * Fetch and cache Tenant Admin chart data
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchTenantAdminDonutData = (refresh) => async (dispatch, getState) => {
  const state = getState();

  const { login, tenantId } = state.auth;
  if (!login?.accessToken) return;

  if (!refresh && checkIsCacheValid('tenantAdmins')(state)) return;

  const request = api.sai.v1.tenant.$tenantId(tenantId).admin[':count'].$get();
  await request.process();

  /** @type {import('..').ChartData} */
  const chartData = {
    tenantAdmins: {
      tenantId,
      total: request.result.result.count,
    },
  };

  await dispatch(updateData(chartData));
};
