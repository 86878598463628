import iziToast from 'izitoast';

export function setupToaster() {
  iziToast.settings({
    zindex: 9999,
    position: 'topCenter',
  });
}

/**
 * @typedef {object} ConfirmDialogParams
 * @property {string} [title='Are you sure?'] The dialog title
 * @property {string} [message = 'Please confirm.'] The dialog message
 * @property {false | number} [timeout = 10000] Timeout is milliseconds, or false to show forever.
 * @property {() => {}} onConfirm
 */

/**
 * Display a confirm dialog.
 *
 * @param {ConfirmDialogParams} params
 * @returns {Promise<void>}
 */
export async function confirmDialog(params = {}) {
  return new Promise((resolve, reject) => {
    iziToast.question({
      overlay: true,
      position: 'center',
      maxWidth: '500px',
      timeout: params.timeout === undefined ? 10000 : params.timeout,
      title: params.title === undefined ? 'Are you sure?' : params.title,
      message: params.message === undefined ? 'Please confirm.' : params.message,
      buttons: [
        [
          '<button style="width: 100px"><b>YES</b></button>',
          (instance, toast) => instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes'),
        ],
        [
          '<button style="width: 100px"><b>NO</b></button>',
          (instance, toast) => instance.hide({ transitionOut: 'fadeOut' }, toast, 'no'),
          true,
        ],
      ],
      onClosing: (settings, toast, closedBy) => {
        if (closedBy === 'yes') {
          resolve();
          params.onConfirm && params.onConfirm();
        } else {
          reject();
        }
      },
    });
  });
}

/**
 * Displays an error toast.
 *
 * @param {Error} error
 */
export function displayError(error) {
  if (!error) return;
  if (error.message === 'invalid token') return;
  console.error(error);
  iziToast.error({
    title: error.name,
    message: error.message,
  });
}

/**
 * Display a success toast.
 *
 * @param {string} title
 * @param {string} message
 */
export function displaySuccess(title, message) {
  iziToast.success({
    displayMode: 'replace',
    title: title || 'Success',
    message: message || '',
  });
}

/**
 * Display a success toast.
 *
 * @param {string} title
 * @param {string} message
 */
export function displayWarning(title, message) {
  iziToast.warning({
    displayMode: 'replace',
    title: title || 'Warning',
    message: message || '',
  });
}
