import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/**
 * A hook to dispatch an action inside `useEffect` hook.
 *
 * Returns a stateful loading and error.
 *
 * @param {() => any} [action] A builder for action to dispatch.
 * @param {import('react').DependencyList} [deps] If present, effect will only activate if the values in the list change. If absent, effect will only activate once per component lifetime.
 * @param {number} [interval] Interval period in milliseconds.
 * @returns {[boolean, Error?]} [loading, error]
 */
export function usePeriodicDispatcher(action, deps, interval) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      let mounted = true;
      function update() {
        dispatch(action())
          .catch((err) => mounted && setError(err))
          .finally(() => mounted && setLoading(false));
      }

      update();
      const pin = setInterval(update, interval);

      return () => {
        mounted = false;
        clearInterval(pin);
      };
    },
    // eslint-disable-next-line
    [...(deps ?? [action]), dispatch]
  );

  return [loading, error];
}
