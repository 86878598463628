import { MdSurroundSound } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAiModel } from '@/state/cache';
import { getParamName } from '@/utils/ai-models';
import { OutputCardLayout } from '../../OutputCardLayout';

/** @param {{mapping: InputMapModel}} props */
export function AiModelAudioOutputCard(props) {
  const { mapping } = props;
  const model = useSelector(getAiModel(mapping.sourceId));
  const output = model?.audioOutputs?.find((item) => item.code === mapping?.mappedOutputCode);

  return (
    <OutputCardLayout {...props} icon={<MdSurroundSound />} title={getParamName(output?.name)}>
      {model && (
        <Link
          title={model.description}
          to={`/ai/ai-models/${model.ownerTenantId}/${model.modelId}`}
        >
          {model.name}
        </Link>
      )}
    </OutputCardLayout>
  );
}
