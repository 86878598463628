import { Card, Tabs, Tab } from 'react-bootstrap';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';

export function EditTenantFormPlaceholder() {
  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">Edit Tenant</Card.Title>
      </Card.Header>
      <Tabs className="nice-tabs">
        <Tab eventKey="general" title="General">
          <PageLoadingSpinner status="Loadinig tenant..." />
        </Tab>
        <Tab eventKey="retention" title="Retention Period" />
        <Tab eventKey="registration" title="Registration Setting" />
      </Tabs>
    </Card>
  );
}
