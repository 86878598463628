import { useState, useEffect } from 'react';
import { UserAgentApplication } from 'msal';
import { Button, Spinner } from 'react-bootstrap';
import { MICROSOFT_CLIENT_ID } from '../../../../../config';
import { BsMicrosoft } from 'react-icons/bs';
import './index.scss';

/**
 * @typedef {object} MicrosoftLoginButtonProps
 * @property {boolean} [disabled]
 * @property {(email: string, idToken: string) => void} [onLogin]
 * @property {(error: string) => any} [onError]
 */

/**
 * @param {MicrosoftLoginButtonProps} props
 */
export function MicrosoftLoginButton(props) {
  const [loading, setLoading] = useState(false);
  const [msal, setMSAL] = useState(null);

  useEffect(() => {
    if (msal == null) {
      const instance = new UserAgentApplication({
        auth: { clientId: MICROSOFT_CLIENT_ID },
      });
      setMSAL(instance);
    }
  }, [msal]);

  /**
   * @param {Event} e
   */
  async function handleLogin(e) {
    try {
      setLoading(true);
      const response = await msal.loginPopup({ scopes: ['user.read'] });
      const email = response.account.userName;
      const idToken = response.idToken.rawIdToken;
      props.onLogin && props.onLogin(email, idToken);
    } catch (err) {
      props.onError && props.onError(err.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Button
      disabled={loading || props.disabled}
      onClick={handleLogin}
      className="social-button microsoft-login-button"
      size="lg"
    >
      {loading ? (
        <Spinner animation="border" style={{ verticalAlign: 'middle' }} />
      ) : (
        <BsMicrosoft />
      )}
      <span>Microsoft</span>
    </Button>
  );
}
