import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { AiContainerContext } from '../../';

/** @param {{item: AiContainer, disabled: boolean}} props */
export function PublishAiContainerButton(props) {
  const [loading, setLoading] = useState(false);
  const updateParent = useContext(AiContainerContext);

  const item = props.item;

  const publishAiContainer = async () => {
    const request = api.sai.v1['ai-container'].$containerId(item.containerId)[':publish'].$post();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Published AI Container <b>${item.name}</b>`,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      size="sm"
      variant="outline-primary"
      style={{ width: 120 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: publishAiContainer,
          title: `Publish ${item.name} (${item.version})?`,
          message: 'It will affect all resources using this AI Container',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Publish'}
    </Button>
  );
}
