import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { FaUser } from 'react-icons/fa';
import { UserListView } from './@components/UserListView';

export function UserListViewWithDevice() {
  return (
    <>
      <BreadcrumbLayout.Header title="Device Users" icon={<FaUser />} />
      <BreadcrumbLayout.Body>
        <UserListView />
      </BreadcrumbLayout.Body>
    </>
  );
}
