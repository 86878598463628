import { fetchAllLabelsByTenant } from '@/state/cache';
import { fetchAllLabelCategories } from '@/state/cache/fetchers/label-categories';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { displayError } from '@/utils/toaster';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Navigate, useParams } from 'react-router';
import { CreateLabelForm } from './@components/CreateLabelForm';

export function CreateLabelWithTenant() {
  const { tenantId } = useParams();
  const [labelsLoading, labelsError] = useDispatchLoader(
    () => fetchAllLabelsByTenant(tenantId),
    [tenantId]
  );

  const [labelCategoriesLoading, labelCategoriesError] = useDispatchLoader(fetchAllLabelCategories);

  if (labelsLoading || labelCategoriesLoading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <PageLoadingSpinner status={'Loading labels...'} />
      </BreadcrumbLayout>
    );
  }

  if (labelsError || labelCategoriesError) {
    displayError(labelsError);
    displayError(labelCategoriesError);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <CreateLabelForm />
    </BreadcrumbLayout>
  );
}
