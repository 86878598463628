import moment from 'moment';

/**
 * @param {string} text
 * @param {number} length
 * @param {string} spread
 */
export function takeTextPortion(text, length, spread = '...') {
  if (text.length < length) {
    return text;
  }
  return text.substring(0, length) + spread;
}

/**
 * @param {string} time
 */
export function timeFromNow(time) {
  if (!time) return '';
  const date = new Date(time);
  if (!date) return '';
  // return moment(date).format('YYYY-MM-DD hh:mm:ss A');
  return moment(date).fromNow();
}

/**
 * @param {number} duration Duration in milliseconds
 */
export function formatDuration(duration) {
  if (!duration || isNaN(duration)) return 'invalid';
  return moment(Date.now() - duration).toNow(true);
}

/**
 * @param {string} time
 */
export function formatDate(time) {
  if (!time) return '';
  const date = new Date(time);
  if (!date) return '';
  return moment(date).format('YYYY-MM-DD');
}

/**
 * @param {string} time
 */
export function formatDateTime(time) {
  if (!time) return '';
  const date = new Date(time);
  if (!date) return '';
  return moment(date).format('YYYY-MM-DD hh:mm:ss A');
}

/**
 * Get time in RFC 1123 format.
 *
 * @param {Date} [time]
 */
export function formatTimeRfc1123(time) {
  return moment(time).utc().format('ddd, DD MMM YYYY HH:mm:ss GMT');
}

/**
 * Formats size of files into human readable format
 *
 * @param {number} size
 * @param {{decimal: number, short: bool}} options
 */
export function formatFileSize(size, options = { decimal: 2, short: true }) {
  const shortSuffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const longSuffixes = ['bytes', 'kilobytes', 'megabytes', 'gigabytes', 'terabytes', 'petabytes'];
  const suffixes = options.short ? shortSuffixes : longSuffixes;

  let sign = false;
  if (size < 0) {
    sign = true;
    size = -size;
  }

  let index = 0;
  while (size > 1024) {
    size /= 1024;
    index++;
  }

  return `${sign ? '-' : ''}${size.toFixed(options.decimal)} ${suffixes[index]}`;
}

/**
 * Formats access level into human readable format
 *
 * @param {'VIEWER' | 'OWNER' | 'ADMIN'} accessLevel
 */
export const formatAccessLevel = (accessLevel) => {
  switch (accessLevel) {
    case 'VIEWER':
      return 'Viewer';
    case 'OWNER':
      return 'Owner';
    case 'ADMIN':
      return 'Admin';
    default:
      return accessLevel;
  }
};

/**
 * Formats initiator type into human readable format
 *
 * @param {'END_USER'|'TENANT_ADMIN'|'PLATFORM_ADMIN'} initiatorType
 */
export const formatInitiatorType = (initiatorType) => {
  switch (initiatorType) {
    case 'END_USER':
      return 'End User';
    case 'TENANT_ADMIN':
      return 'Tenant Admin';
    case 'PLATFORM_ADMIN':
      return 'Platform Admin';
    default:
      return initiatorType;
  }
};

/**
 * Formats stream status into human readable format
 *
 * @param {'CREATED'|'ONGOING'|'ENDED'|'LOST_TRACK'} streamStatus
 */
export const formatStreamStatus = (streamStatus) => {
  switch (streamStatus) {
    case 'CREATED':
      return 'Created';
    case 'ONGOING':
      return 'Ongoing';
    case 'ENDED':
      return 'Ended';
    case 'LOST_TRACK':
      return 'Lost Track';
    default:
      return streamStatus;
  }
};
