export const AccessRightGroups = {
  AI_ACCELERATOR_ALL: {
    AI_ACCELERATOR_CREATE: {},
    AI_ACCELERATOR_UPDATE: {},
    AI_ACCELERATOR_READ: {},
    AI_ACCELERATOR_LIST_READ: {},
    AI_ACCELERATOR_DELETE: {},
  },
  AI_CONTAINER_ALL: {
    AI_CONTAINER_CREATE: {},
    AI_CONTAINER_READ: {},
    AI_CONTAINER_LIST_READ: {},
    AI_CONTAINER_UPDATE: {},
    AI_CONTAINER_DELETE: {},
    AI_CONTAINER_PUBLISH: {},
    AI_CONTAINER_DISCONTINUE: {},
    AI_CONTAINER_GET_FILE_CREDENTIAL: {},
  },
  AI_CONTAINER_DEPLOYMENT_ALL: {
    AI_CONTAINER_DEPLOYMENT_CREATE: {},
    AI_CONTAINER_DEPLOYMENT_LIST_READ: {},
  },
  AI_CONTAINER_SHARE_ALL: {
    AI_CONTAINER_SHARE_CREATE: {},
    AI_CONTAINER_SHARE_READ: {},
    AI_CONTAINER_SHARE_LIST_READ: {},
    AI_CONTAINER_SHARE_UPDATE: {},
    AI_CONTAINER_SHARE_DELETE: {},
  },
  AI_MODEL_ALL: {
    AI_MODEL_CREATE: {},
    AI_MODEL_UPDATE: {},
    AI_MODEL_READ: {},
    AI_MODEL_LIST_READ: {},
    AI_MODEL_DELETE: {},
    AI_MODEL_PUBLISH: {},
    AI_MODEL_DISCONTINUE: {},
    AI_MODEL_GET_FILE_CREDENTIAL: {},
  },
  AI_MODEL_SHARE_ALL: {
    AI_MODEL_SHARE_CREATE: {},
    AI_MODEL_SHARE_UPDATE: {},
    AI_MODEL_SHARE_READ: {},
    AI_MODEL_SHARE_LIST_READ: {},
    AI_MODEL_SHARE_DELETE: {},
  },
  FIRMWARE_ALL: {
    FIRMWARE_CREATE: {},
    FIRMWARE_READ: {},
    FIRMWARE_LIST_READ: {},
    FIRMWARE_UPDATE: {},
    FIRMWARE_DELETE: {},
    FIRMWARE_PUBLISH: {},
    FIRMWARE_DISCONTINUE: {},
    FIRMWARE_GET_FILE_CREDENTIAL: {},
  },
  FIRMWARE_DEPLOYMENT_ALL: {
    FIRMWARE_DEPLOYMENT_CREATE: {},
    FIRMWARE_DEPLOYMENT_LIST_READ: {},
  },
  FIRMWARE_SHARE_ALL: {
    FIRMWARE_SHARE_CREATE: {},
    FIRMWARE_SHARE_UPDATE: {},
    FIRMWARE_SHARE_READ: {},
    FIRMWARE_SHARE_LIST_READ: {},
    FIRMWARE_SHARE_DELETE: {},
  },
  LABEL_ALL: {
    LABEL_CREATE: {},
    LABEL_UPDATE: {},
    LABEL_READ: {},
    LABEL_LIST_READ: {},
    LABEL_DELETE: {},
    LABEL_PUBLISH: {},
    LABEL_DISCONTINUE: {},
  },
  LABEL_CATEGORY_ALL: {
    LABEL_CATEGORY_CREATE: {},
    LABEL_CATEGORY_READ: {},
    LABEL_CATEGORY_LIST_READ: {},
    LABEL_CATEGORY_UPDATE: {},
  },
  PRODUCT_DEPLOYMENT_ALL: {
    PRODUCT_DEPLOY_FIRMWARE: {},
    PRODUCT_UNDEPLOY_FIRMWARE: {},
  },
  PRODUCT_ALL: {
    PRODUCT_CREATE: {},
    PRODUCT_READ: {},
    PRODUCT_LIST_READ: {},
    PRODUCT_UPDATE: {},
    PRODUCT_DELETE: {},
    PRODUCT_RELEASE: {},
    PRODUCT_DISCONTINUE: {},
    PRODUCT_DEPLOY_FIRMWARE: {},
    PRODUCT_UNDEPLOY_FIRMWARE: {},
  },
  PRODUCT_SHARE_ALL: {
    PRODUCT_SHARE_CREATE: {},
    PRODUCT_SHARE_UPDATE: {},
    PRODUCT_SHARE_READ: {},
    PRODUCT_SHARE_LIST_READ: {},
    PRODUCT_SHARE_DELETE: {},
  },
  TENANT_ALL: {
    TENANT_CREATE: {},
    TENANT_READ: {},
    TENANT_LIST_READ: {},
    TENANT_UPDATE: {},
    TENANT_UPDATE_REGISTRATION_SETTING: {},
    TENANT_UPDATE_RETENTION: {},
  },
  TRIGGER_ALL: {
    TRIGGER_CREATE: {},
    TRIGGER_UPDATE: {},
    TRIGGER_READ: {},
    TRIGGER_LIST_READ: {},
    TRIGGER_DELETE: {},
    TRIGGER_PUBLISH: {},
    TRIGGER_DISCONTINUE: {},
  },
  TRIGGER_DEPLOYMENT_ALL: {
    TRIGGER_DEPLOYMENT_CREATE: {},
    TRIGGER_DEPLOYMENT_LIST_READ: {},
  },
  TRIGGER_SHARE_ALL: {
    TRIGGER_SHARE_CREATE: {},
    TRIGGER_SHARE_UPDATE: {},
    TRIGGER_SHARE_READ: {},
    TRIGGER_SHARE_LIST_READ: {},
    TRIGGER_SHARE_DELETE: {},
  },
  STREAM_ALL: {
    STREAM_LIST_READ: {},
  },
  EVENT_LIST_READ: {
    EVENT_LIST_READ_BY_TENANT: {},
    EVENT_LIST_READ_BY_DEVICE: {},
    EVENT_LIST_READ_BY_USER: {},
  },
  EVENT_ALL: {
    EVENT_READ: {},
    EVENT_LIST_READ_BY_TENANT: {},
    EVENT_LIST_READ_BY_DEVICE: {},
    EVENT_LIST_READ_BY_USER: {},
    EVENT_UPDATE: {},
  },
  DEPLOYMENT_GROUP_ALL: {
    DEPLOYMENT_GROUP_CREATE: {},
    DEPLOYMENT_GROUP_READ: {},
    DEPLOYMENT_GROUP_LIST_READ: {},
    DEPLOYMENT_GROUP_UPDATE: {},
    DEPLOYMENT_GROUP_DEPLOY_FIRMWARE: {},
    DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE: {},
    DEPLOYMENT_GROUP_DEPLOY_TRIGGER: {},
    DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER: {},
    DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER: {},
    DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER: {},
  },
  DEPLOYMENT_GROUP_DEPLOYMENT_ALL: {
    DEPLOYMENT_GROUP_UNDEPLOY_FIRMWARE: {},
    DEPLOYMENT_GROUP_DEPLOY_FIRMWARE: {},
    DEPLOYMENT_GROUP_DEPLOY_TRIGGER: {},
    DEPLOYMENT_GROUP_UNDEPLOY_TRIGGER: {},
    DEPLOYMENT_GROUP_DEPLOY_AI_CONTAINER: {},
    DEPLOYMENT_GROUP_UNDEPLOY_AI_CONTAINER: {},
  },
  DEVICE_DEPLOYMENT_ALL: {
    DEVICE_AI_CONTAINER_DEPLOYMENT_LIST_READ: {},
    DEVICE_FIRMWARE_DEPLOYMENT_LIST_READ: {},
    DEVICE_TRIGGER_DEPLOYMENT_LIST_READ: {},
  },
  DEVICE_ALL: {
    DEVICE_KEY_CREATE: {},
    DEVICE_KEY_UPDATE: {},
    DEVICE_READ: {},
    DEVICE_STREAM: {},
    DEVICE_GET_MEDIA_CREDENTIAL: {},
    DEVICE_ADD_MEDIA: {},
    DEVICE_READ_MEDIA_LIST: {},
    DEVICE_ADD_FIRMWARE_INFO: {},
    DEVICE_READ_FIRMWARE_INFO: {},
    DEVICE_PRESENCE_READ: {},
    DEVICE_REBOOT: {},
    DEVICE_AI_CONTAINER_DOWNLOAD: {},
    DEVICE_LIST_READ: {},
    DEVICE_UPDATE_ONBOARDED_DEVICE: {},
    DEVICE_RESET: {},
    DEVICE_ADD_TO_DEPLOYMENT_GROUP: {},
    DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP: {},
    DEVICE_ADD_USER_ACCESS: {},
    DEVICE_REMOVE_USER_ACCESS: {},
    DEVICE_ADD_TO_GROUP: {},
    DEVICE_REMOVE_FROM_GROUP: {},
    DEVICE_TRANSFER_DEVICE: {},
    DEVICE_LIST_READ_IN_DEPLOYMENT_GROUP: {},
    DEVICE_LIST_READ_BY_USER_ID: {},
    DEVICE_LIST_READ_IN_GROUP: {},
  },
  DEVICE_LOG_ALL: {
    DEVICE_LOG_SAVE: {},
    DEVICE_LOG_READ: {},
  },
  DEVICE_POOL_ALL: {
    DEVICE_POOL_CREATE: {},
    DEVICE_POOL_UPDATE: {},
    DEVICE_POOL_READ: {},
    DEVICE_POOL_LIST_READ: {},
    DEVICE_POOL_DEPLOY_FIRMWARE: {},
    DEVICE_POOL_UNDEPLOY_FIRMWARE: {},
  },
  ACCESS_RIGHT_ALL: {
    ACCESS_RIGHT_LIST_READ: {},
  },
  END_USER_ALL: {
    END_USER_CREATE: {},
    END_USER_READ: {},
    END_USER_UPDATE: {},
    END_USER_LIST_READ: {},
    END_USER_GET_FILE_CREDENTIAL: {},
  },
  END_USER_LOG_ALL: {
    END_USER_LOG_SAVE: {},
    END_USER_LOG_READ: {},
  },
  PLATFORM_ADMIN_ALL: {
    PLATFORM_ADMIN_CREATE: {},
    PLATFORM_ADMIN_READ: {},
    PLATFORM_ADMIN_UPDATE: {},
    PLATFORM_ADMIN_TENANT_ACCESS_UPDATE: {},
    PLATFORM_ADMIN_DELETE: {},
    PLATFORM_ADMIN_LIST_READ: {},
    PLATFORM_ADMIN_GET_FILE_CREDENTIAL: {},
  },
  ROLE_ALL: {
    ROLE_CREATE: {},
    ROLE_READ: {},
    ROLE_LIST_READ: {},
    ROLE_UPDATE: {},
    ROLE_DELETE: {},
  },
  TENANT_ADMIN_ALL: {
    TENANT_ADMIN_CREATE: {},
    TENANT_ADMIN_READ: {},
    TENANT_ADMIN_LIST_READ: {},
    TENANT_ADMIN_UPDATE: {},
    TENANT_ADMIN_DELETE: {},
    TENANT_ADMIN_GET_FILE_CREDENTIAL: {},
  },
};
