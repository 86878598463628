import { fetchAllTenants } from '@/state/cache';
import { USER_ACCESS_END_USER_CREATE } from '@/web/common/AccessRights';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DashboardDonutGroup } from '../../@components/DashboardDonutGroup';
import { EndUserListView } from './@components/EndUserListView';

export function EndUserManagement() {
  useSelector(fetchAllTenants);

  return (
    <SidebarPlotLayout sidebar={<DashboardDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="End Users">
        <USER_ACCESS_END_USER_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> New End User
          </Button>
        </USER_ACCESS_END_USER_CREATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <EndUserListView />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
