import { getAiAccelerator, getAiModel, getTenant } from '@/state/cache';
import { USER_ACCESS_AI_CONTAINER_UPDATE } from '@/web/common/AccessRights';
import { Card } from 'react-bootstrap';
import { FaCog } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InputOutputMapping } from '../../InputOutputMapping';
import { DeleteComponentButton } from '../DeleteComponentButton';
import { EditComponentButton } from '../EditComponentButton';

/** @param {{item: AiContainer, index: number }} props */
export function ComponentItemDetails(props) {
  const { item, index } = props;
  const component = item.components[index];
  const model = useSelector(getAiModel(component.modelId));
  const canEditOrDelete = item.status === 'DRAFT';

  return (
    <Card>
      <ComponentItemDetailsHeader model={model} />
      <Card.Body className="d-grid gap-3 justify-content-md-center overflow-auto">
        {model.videoInputs.map((input) => (
          <InputOutputMapping
            inputType="VIDEO"
            key={input.code}
            input={input}
            source={model}
            mapping={component.inputMapping[input.code]}
          />
        ))}

        {model.audioInputs.map((input) => (
          <InputOutputMapping
            inputType="AUDIO"
            key={input.code}
            input={input}
            source={model}
            mapping={component.inputMapping[input.code]}
          />
        ))}

        {model.sensorInputs.map((input) => (
          <InputOutputMapping
            inputType="SENSOR"
            key={input.code}
            input={input}
            source={model}
            mapping={component.inputMapping[input.code]}
          />
        ))}
      </Card.Body>
      <Card.Footer className="d-flex gap-2 justify-content-end">
        {canEditOrDelete && (
          <USER_ACCESS_AI_CONTAINER_UPDATE>
            <DeleteComponentButton {...props} />
            <EditComponentButton {...props} />
          </USER_ACCESS_AI_CONTAINER_UPDATE>
        )}
      </Card.Footer>
    </Card>
  );
}

/** @param {{model: AiModel}} props */
function ComponentItemDetailsHeader(props) {
  const { model } = props;
  const owner = useSelector(getTenant(model?.ownerTenantId));
  const accelerator = useSelector(getAiAccelerator(model?.aiAcceleratorIds[0]));

  return (
    <Card.Header className="pe-4 d-flex align-items-center gap-3">
      <FaCog size={48} color="#979781" />
      <div>
        <Card.Title className="roboto-slab m-0">{model.name}</Card.Title>
        <div className="text-muted small">{model.description}</div>
        <div className="text-muted small">
          <span className="d-inline-block">
            Version: <b>{model.version}</b>
          </span>
          <span className="mx-1">&bull;</span>
          <span className="d-inline-block">
            Type: <b className="text-uppercase">{model.type}</b>
          </span>
          <span className="mx-1">&bull;</span>
          {owner && (
            <span className="d-inline-block">
              Owner: <Link to={`/settings/tenants/${owner.tenantId}`}>{owner.name}</Link>
            </span>
          )}
          <span className="mx-1">&bull;</span>
          {accelerator && (
            <span className="d-inline-block">
              AI Accelerator:{' '}
              <Link to={`/super-admin/ai-accelerator/${accelerator.codeName}`}>
                {accelerator.name}
              </Link>
            </span>
          )}
        </div>
      </div>
    </Card.Header>
  );
}
