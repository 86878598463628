import { IMAGER_TYPE } from '@/assets/events';
import { sortListByDate } from '@/utils/events';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import React, { createRef, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FcRemoveImage } from 'react-icons/fc';
import { CommonPlayerControls } from '../player/commonPlayerControls';
import { CustomVideoPlayer } from '../player/customVideoPlayer';

/**
 * @typedef {object} VideoContainerProps
 * @property {boolean} loading
 * @property {any} list
 * @property {any} error
 * @property {any} eventObj
 */

/**
 * @param {VideoContainerProps} props
 */
export function VideoContainer(props) {
  const { loading, list, error, eventObj } = props;

  const [filteredVid_1, setFilteredVidOne] = useState([]);
  const [filteredVid_2, setFilteredVidTwo] = useState([]);
  const [bBoxes_1, setBoundingBoxOne] = useState(null);
  const [bBoxes_2, setBoundingBoxTwo] = useState(null);
  const [totalPlayableTime, setTotalPlayableTime] = useState(null);
  const [commonVolume, setCommonVolume] = useState(1);
  const [commonSeek, setCommonSeek] = useState(0);
  const [currentPlayingTime, setCurrentPlayingTime] = useState(null);
  const [currentPlayingStatus, setCurrentPlayingStatus] = useState(false);
  const [imagerOneAcknowledgementPermission, setImagerOneAcknowledgementPermission] =
    useState(false);
  const [imagerTwoAcknowledgementPermission, setImagerTwoAcknowledgementPermission] =
    useState(false);
  const [priorityImager, setPriorityImager] = useState(false);
  const playOne_ref = createRef();
  const playTwo_ref = createRef();

  useEffect(() => {
    let mediaList = list?.results;
    let vidOne = mediaList?.filter(
      (obj) => obj.mediaType === 'VIDEO' && obj.mediaSource === IMAGER_TYPE.VID_1
    );
    let vidTwo = mediaList?.filter(
      (obj) => obj.mediaType === 'VIDEO' && obj.mediaSource === IMAGER_TYPE.VID_2
    );
    let bBoxOne = mediaList?.filter(
      (obj) => obj.mediaType === 'B_BOX' && obj.mediaSource === IMAGER_TYPE.VID_1
    );
    let bBoxTwo = mediaList?.filter(
      (obj) => obj.mediaType === 'B_BOX' && obj.mediaSource === IMAGER_TYPE.VID_2
    );
    setFilteredVidOne(sortListByDate(vidOne, 'fromTimestamp'));
    setFilteredVidTwo(sortListByDate(vidTwo, 'fromTimestamp'));
    setBoundingBoxOne(sortListByDate(bBoxOne, 'fromTimestamp'));
    setBoundingBoxTwo(sortListByDate(bBoxTwo, 'fromTimestamp'));
  }, [list]);

  /**
   * Value came from customVideoPlayer
   *
   * @param {any} data
   */
  const getTotalRecordingTime = (data) => {
    const max = data.totalPlayableTime > totalPlayableTime;
    const priorityImage = max ? data.priorityImager : priorityImager;
    setTotalPlayableTime(Math.max(data.totalPlayableTime, totalPlayableTime));
    setPriorityImager(priorityImage);
    setImagerOneAcknowledgementPermission(priorityImage === 1);
    setImagerTwoAcknowledgementPermission(priorityImage === 2);
  };

  /**
   * Value came from customVideoPlayer
   *
   * @param {number} timeInSec
   */
  const getCurrentTime = (timeInSec) => {
    setCurrentPlayingTime(timeInSec);
  };

  /**
   *
   * @param {boolean} status
   */
  const getCurrentPlayingStatus = (status) => {
    setCurrentPlayingStatus(status);
  };

  /**
   *
   * @param {number} [imagerId]
   */
  const setFullscreenImager = (imagerId) => {
    if (imagerId === null) {
      setCommonSeek(currentPlayingTime);
      setImagerOneAcknowledgementPermission(priorityImager === 1);
      setImagerTwoAcknowledgementPermission(priorityImager === 2);
    } else {
      setImagerOneAcknowledgementPermission(imagerId === 1);
      setImagerTwoAcknowledgementPermission(imagerId === 2);
    }
  };

  /**
   * Value came from VideoControls
   *
   * @param {number} vol
   */
  const setCommonVolumeFromCommonControls = (vol) => {
    setCommonVolume(vol);
  };

  /**
   * Value came from VideoControls
   *
   * @param {number} position
   */
  const setCommonSeekFromCommonControls = (position) => {
    setCommonSeek(position);
  };

  if (loading) {
    return <PageLoadingSpinner status={'Loading videos'} />;
  }
  if (error) {
    return (
      <PageLoadingFailure message="Failed to load device videos" className="py-2">
        {error + ''}
      </PageLoadingFailure>
    );
  }

  const {
    recordingStartTimestamp,
    recordingEndTimestamp,
    timestamp: eventReportedTime,
    deviceName,
  } = eventObj;

  return (
    <div
      className="d-flex"
      style={{ columnGap: '2rem', flexWrap: 'wrap', justifyContent: 'center' }}
    >
      <Row style={{ width: '100%' }}>
        <br />
        {filteredVid_1 && filteredVid_1.length > 0 && (
          <Col md={6}>
            <div>
              <Card.Header>Forward Facing Camera</Card.Header>
              <CustomVideoPlayer
                key={1}
                id={1}
                tenantName={deviceName}
                eventReportedTime={eventReportedTime}
                eventStartTimestamp={recordingStartTimestamp}
                eventEndTimestamp={recordingEndTimestamp}
                boundingBoxData={bBoxes_1}
                videoData={filteredVid_1}
                setTotalRecordingTime={getTotalRecordingTime}
                setCurrentPlayingTime={getCurrentTime}
                setCurrentPlayingStatus={getCurrentPlayingStatus}
                setCurrentFullscreenImager={setFullscreenImager}
                acknowledgementPermission={imagerOneAcknowledgementPermission}
                commonVolume={commonVolume}
                commonSeek={commonSeek}
                playOne_ref={playOne_ref}
              />
            </div>
          </Col>
        )}

        {filteredVid_2 && filteredVid_2.length > 0 && (
          <Col md={6}>
            <div>
              <Card.Header>In Cabin Camera</Card.Header>
              <CustomVideoPlayer
                key={2}
                id={2}
                tenantName={deviceName}
                eventReportedTime={eventReportedTime}
                eventStartTimestamp={recordingStartTimestamp}
                eventEndTimestamp={recordingEndTimestamp}
                boundingBoxData={bBoxes_2}
                videoData={filteredVid_2}
                setTotalRecordingTime={getTotalRecordingTime}
                setCurrentPlayingTime={getCurrentTime}
                setCurrentPlayingStatus={getCurrentPlayingStatus}
                setCurrentFullscreenImager={setFullscreenImager}
                acknowledgementPermission={imagerTwoAcknowledgementPermission}
                commonVolume={commonVolume}
                commonSeek={commonSeek}
                playTwo_ref={playTwo_ref}
              />
            </div>
          </Col>
        )}

        {((!filteredVid_1 && !filteredVid_2) ||
          (filteredVid_1?.length < 1 && filteredVid_2?.length < 1)) && (
          <>
            <FcRemoveImage size={140} />
            <p>No Videos Found</p>
          </>
        )}

        {totalPlayableTime && (
          <CommonPlayerControls
            totalEventTimeInSec={totalPlayableTime}
            currentPlayingTime={currentPlayingTime}
            currentPlayingStatus={currentPlayingStatus}
            volume={setCommonVolumeFromCommonControls}
            seek={setCommonSeekFromCommonControls}
            playOne_ref={playOne_ref}
            playTwo_ref={playTwo_ref}
          />
        )}
      </Row>
    </div>
  );
}
