// eslint-disable-next-line no-unused-vars
const { ApiRequest } = require('@/utils/register-api/apiRequest');
require('./index.types.js');

module.exports = {
  $baseURL: 'https://sai-api.bitanon.xyz:9043',
  v1: {
    'tenant': {
      /** @param {!string} tenantId */
      '$tenantId': (tenantId) => ({
        'event-webhook': {
          /**
           * Gets a specific tenant event webhook detail by tenant ID
           *
           * @summary Get TenantEventWebhook
           * @param {{ headers: V1TenantTenantIdEventWebhookGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTenantEventWebhook>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Adds a new tenant event webhook
           *
           * @summary Create TenantEventWebhook
           * @param {{ data: TenantEventWebhookUpdateRequest, headers: V1TenantTenantIdEventWebhookPutHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTenantEventWebhook>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $put: (config) => null,
          /**
           * Deletes a specific tenant event webhook by tenant ID
           *
           * @summary Delete TenantEventWebhook
           * @param {{ headers: V1TenantTenantIdEventWebhookDeleteHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTenantEventWebhook>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $delete: (config) => null,
        },
        'end-user': {
          /**
           * Retrieves a list of End users in a tenant in ascending order of email
           *
           * @summary Get End User List
           * @param {{ params: V1TenantTenantIdEndUserGetParams, headers: V1TenantTenantIdEndUserGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithContinuationTokenEndUser>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$get': (config) => null,
          /**
           * Creates a new End user in the system
           *
           * @summary Create End User
           * @param {{ data: EndUserCreateRequest, headers: V1TenantTenantIdEndUserPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEndUser>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$post': (config) => null,
          /** @param {!string} userId User Id */
          '$userId': (userId) => ({
            session: {
              /** @param {!string} sessionId Session Id */
              $sessionId: (sessionId) => ({
                'session-data': {
                  /**
                   * Update an existing End User session data
                   *
                   * @summary Update End User Session Data
                   * @param {{ data: EndUserSessionDataUpdateRequest }} config Axios Request config
                   * @returns {ApiRequest<SuccessResponseEndUserSessionModel>} An ApiRequest; starts after you call 'response' or 'result'.
                   */
                  $post: (config) => null,
                },
              }),
              /**
               * Retrieves a list of sessions of an end user
               *
               * @summary Get End User Session By User Id
               * @param {{ params: V1TenantTenantIdEndUserUserIdSessionGetParams }} config Axios Request config
               * @returns {ApiRequest<SuccessResponseListWithContinuationTokenEndUserSessionModel>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              $get: (config) => null,
            },
            log: {
              /**
               * Retrieves a list of logs of an end user
               *
               * @summary Find End User Log
               * @param {{ params: V1TenantTenantIdEndUserUserIdLogGetParams, headers: V1TenantTenantIdEndUserUserIdLogGetHeaders }} config Axios Request config
               * @returns {ApiRequest<SuccessResponseListWithContinuationTokenEndUserLog>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              $get: (config) => null,
              /**
               * Post a new end user log
               *
               * @summary Create End User Log
               * @param {{ data: EndUserLogCreateRequest, headers: V1TenantTenantIdEndUserUserIdLogPostHeaders }} config Axios Request config
               * @returns {ApiRequest<SuccessResponseEndUserLog>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              $post: (config) => null,
            },
            /**
             * Retrieves an End user by id
             *
             * @summary Get End User by ID
             * @param {{ headers: V1TenantTenantIdEndUserUserIdGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseEndUser>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
            /**
             * Updates an End user by user id
             *
             * @summary Update End User
             * @param {{ data: EndUserUpdateRequest, headers: V1TenantTenantIdEndUserUserIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseEndUser>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
          '*': {
            'file-credentials': {
              ':refresh': {
                /**
                 * Gets end user picture and package file upload credentials
                 *
                 * @summary Get end user file upload credential
                 * @param {{ data: EndUserFilesCredentialsModel, headers: V1TenantTenantIdEndUserFileCredentialsRefreshPostHeaders }} config Axios Request config
                 * @returns {ApiRequest<SuccessResponseEndUserFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
                 */
                $post: (config) => null,
              },
              /**
               * Gets end user picture and package file upload credentials
               *
               * @summary Get end user file upload credential
               * @param {{ params: V1TenantTenantIdEndUserFileCredentialsGetParams, headers: V1TenantTenantIdEndUserFileCredentialsGetHeaders }} config Axios Request config
               * @returns {ApiRequest<SuccessResponseEndUserFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              '$get': (config) => null,
            },
          },
        },
        'admin': {
          /**
           * Get existing tenant admin list sorted in ascending order of email
           *
           * @summary Get tenant admin list
           * @param {{ params: V1TenantTenantIdAdminGetParams, headers: V1TenantTenantIdAdminGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithPaginationMetadataTenantAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$get': (config) => null,
          /**
           * Creates a new Tenant admin in the system
           *
           * @summary Create Tenant Admin
           * @param {{ data: TenantAdminCreateRequest, headers: V1TenantTenantIdAdminPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTenantAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$post': (config) => null,
          /** @param {!string} adminId Admin id */
          '$adminId': (adminId) => ({
            session: {
              /** @param {!string} sessionId Session id */
              $sessionId: (sessionId) => ({
                'session-data': {
                  /**
                   * Update an existing Tenant admin session data
                   *
                   * @summary Update Tenant Admin Session Data
                   * @param {{ data: TenantAdminSessionDataUpdateRequest }} config Axios Request config
                   * @returns {ApiRequest<SuccessResponseTenantAdminSessionModel>} An ApiRequest; starts after you call 'response' or 'result'.
                   */
                  $post: (config) => null,
                },
              }),
              /**
               * Retrieves a list of all Tenant admin sessions of an Admin
               *
               * @summary Get Tenant Admin Session By Admin Id
               * @param {{ params: V1TenantTenantIdAdminAdminIdSessionGetParams }} config Axios Request config
               * @returns {ApiRequest<SuccessResponseListWithContinuationTokenTenantAdminSessionModel>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              $get: (config) => null,
            },
            /**
             * Retrieves a Tenant Admin by tenant id and admin id
             *
             * @summary Get Tenant Admin
             * @param {{ headers: V1TenantTenantIdAdminAdminIdGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseTenantAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
            /**
             * Deletes a Tenant admin by tenant id and admin id
             *
             * @summary Delete Tenant Admin
             * @param {{ headers: V1TenantTenantIdAdminAdminIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseTenantAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
            /**
             * Updates an existing Tenant admin in the system
             *
             * @summary Update Tenant admin
             * @param {{ data: TenantAdminUpdateRequest, headers: V1TenantTenantIdAdminAdminIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseTenantAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
          '*': {
            'file-credentials': {
              ':refresh': {
                /**
                 * Gets tenant admin picture and package file upload credentials
                 *
                 * @summary Get tenant admin file upload credential
                 * @param {{ data: TenantAdminFilesCredentialsModel, headers: V1TenantTenantIdAdminFileCredentialsRefreshPostHeaders }} config Axios Request config
                 * @returns {ApiRequest<SuccessResponseTenantAdminFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
                 */
                $post: (config) => null,
              },
              /**
               * Gets tenant admin picture and package file upload credentials
               *
               * @summary Get tenant admin file upload credential
               * @param {{ params: V1TenantTenantIdAdminFileCredentialsGetParams, headers: V1TenantTenantIdAdminFileCredentialsGetHeaders }} config Axios Request config
               * @returns {ApiRequest<SuccessResponseTenantAdminFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              '$get': (config) => null,
            },
          },
          ':count': {
            /**
             * Get total number of tenant admin of a tenant
             *
             * @summary Count tenant admin of a tenant
             * @param {{ headers: V1TenantTenantIdAdminCountGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
        },
        /**
         * Retrieves a Tenant by tenant id
         *
         * @summary Get Tenant
         * @param {{ headers: V1TenantTenantIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseTenant>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Updates an existing Tenant
         *
         * @summary Update Tenant
         * @param {{ data: TenantUpdateRequest, headers: V1TenantTenantIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseTenant>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
        'retention-period': {
          /**
           * Updates tenant retention period by tenant id
           *
           * @summary Update retention period of tenant
           * @param {{ data: TenantRetentionPeriodUpdateRequest, headers: V1TenantTenantIdRetentionPeriodPatchHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTenant>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $patch: (config) => null,
        },
        'registration-setting': {
          /**
           * Updates tenant registration setting by tenant id
           *
           * @summary Update Tenant Registration Setting
           * @param {{ data: TenantRegistrationSettingUpdateRequest, headers: V1TenantTenantIdRegistrationSettingPatchHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTenant>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $patch: (config) => null,
        },
      }),
      /**
       * Retrieves a list of all Tenants exist in the system
       *
       * @summary Get Tenant List
       * @param {{ params: V1TenantGetParams, headers: V1TenantGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenTenant>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Creates a new Tenant in the system
       *
       * @summary Create Tenant
       * @param {{ data: TenantCreateRequest, headers: V1TenantPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseTenant>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      ':count': {
        /**
         * Get total number of Tenant
         *
         * @summary Count Tenant
         * @param {{ headers: V1TenantCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
      'endUser': {
        ':count': {
          /**
           * Get total number of end users within a tenant
           *
           * @summary Count End User within a tenant
           * @param {{ params: V1TenantEndUserCountGetParams, headers: V1TenantEndUserCountGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
      },
    },
    'ai-model': {
      /** @param {!string} modelId A unique ai-model ID */
      '$modelId': (modelId) => ({
        /**
         * Get ai-model details by modelId
         *
         * @summary Get ai-model
         * @param {{ headers: V1AiModelModelIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiModelDetailsInfo>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Update an existing ai-model information
         *
         * @summary Update ai-model
         * @param {{ data: AiModelUpdateRequest, headers: V1AiModelModelIdPutHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$put': (config) => null,
        /**
         * Delete an existing ai-model
         *
         * @summary Delete ai-model
         * @param {{ headers: V1AiModelModelIdDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$delete': (config) => null,
        ':publish': {
          /**
           * Publish an ai-model
           *
           * @summary Publish ai-model
           * @param {{ headers: V1AiModelModelIdPublishPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        ':discontinue': {
          /**
           * Discontinue an ai-model
           *
           * @summary Discontinue ai-model
           * @param {{ headers: V1AiModelModelIdDiscontinuePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'share': {
          /**
           * Share ai-model with tenants
           *
           * @summary Create ai-model share
           * @param {{ data: AiModelShareCreateRequest, headers: V1AiModelModelIdSharePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiModelShare>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} tenantId A unique Tenant ID */
          $tenantId: (tenantId) => ({
            /**
             * Delete an existing ai-model share
             *
             * @summary Delete ai-model share
             * @param {{ headers: V1AiModelModelIdShareTenantIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseAiModelShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
            /**
             * Update existing ai-model share
             *
             * @summary Update ai-model share
             * @param {{ data: AiModelShareUpdateRequest, headers: V1AiModelModelIdShareTenantIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseAiModelShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
        },
      }),
      /**
       * Get list of ai-model of a tenant
       *
       * @summary Get ai-model list
       * @param {{ params: V1AiModelGetParams, headers: V1AiModelGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenAiModelDetailsInfo>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new ai-model
       *
       * @summary Create ai-model
       * @param {{ data: AiModelCreateRequest, headers: V1AiModelPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseAiModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      '*': {
        'file-credentials': {
          ':refresh': {
            /**
             * Gets ai-model picture and package file upload credentials
             *
             * @summary Get ai-model file upload credential
             * @param {{ data: AiModelFilesCredentialsModel, headers: V1AiModelFileCredentialsRefreshPostHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseAiModelFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $post: (config) => null,
          },
          /**
           * Gets ai-model picture and package file upload credentials
           *
           * @summary Get ai-model file upload credential
           * @param {{ params: V1AiModelFileCredentialsGetParams, headers: V1AiModelFileCredentialsGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiModelFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$get': (config) => null,
        },
      },
      ':count': {
        /**
         * Get total number of aiModels
         *
         * @summary Count AiModel
         * @param {{ params: V1AiModelCountGetParams, headers: V1AiModelCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'ai-container': {
      /** @param {!string} containerId A unique ai-container ID */
      '$containerId': (containerId) => ({
        /**
         * Get ai-container details information
         *
         * @summary Get ai-container
         * @param {{ headers: V1AiContainerContainerIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiContainer>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Update an existing ai-container information
         *
         * @summary Update ai-container
         * @param {{ data: AiContainerUpdateRequest, headers: V1AiContainerContainerIdPutHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiContainer>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$put': (config) => null,
        /**
         * Delete an existing ai-container
         *
         * @summary Delete ai-container
         * @param {{ headers: V1AiContainerContainerIdDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiContainer>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$delete': (config) => null,
        ':publish': {
          /**
           * Publish an ai-container
           *
           * @summary Publish ai-container
           * @param {{ headers: V1AiContainerContainerIdPublishPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiContainer>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        ':discontinue': {
          /**
           * Discontinue an ai-container
           *
           * @summary Discontinue ai-container
           * @param {{ headers: V1AiContainerContainerIdDiscontinuePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiContainer>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'share': {
          /**
           * Share ai-container with tenants
           *
           * @summary Create ai-container share
           * @param {{ data: AiContainerShareCreateRequest, headers: V1AiContainerContainerIdSharePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiContainerShare>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} tenantId A unique Tenant ID */
          $tenantId: (tenantId) => ({
            /**
             * Delete shared ai-container of a tenant
             *
             * @summary Delete ai-container share
             * @param {{ headers: V1AiContainerContainerIdShareTenantIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseAiContainerShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
            /**
             * Update existing ai-container share information
             *
             * @summary Update ai-container share
             * @param {{ data: AiContainerShareUpdateRequest, headers: V1AiContainerContainerIdShareTenantIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseAiContainerShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
        },
      }),
      /**
       * Get ai-container list of a tenant
       *
       * @summary Get ai-container list
       * @param {{ params: V1AiContainerGetParams, headers: V1AiContainerGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenAiContainer>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create new ai-container
       *
       * @summary Create ai-container
       * @param {{ data: AiContainerCreateRequest, headers: V1AiContainerPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseAiContainer>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      '*': {
        'file-credentials': {
          ':refresh': {
            /**
             * Gets ai-container picture and package file upload credentials
             *
             * @summary Get ai-container file upload credential
             * @param {{ data: AiContainerFilesCredentialsModel, headers: V1AiContainerFileCredentialsRefreshPostHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseAiContainerFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $post: (config) => null,
          },
          /**
           * Gets ai-container picture and package file upload credentials
           *
           * @summary Get ai-container file upload credential
           * @param {{ params: V1AiContainerFileCredentialsGetParams, headers: V1AiContainerFileCredentialsGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiContainerFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$get': (config) => null,
        },
      },
      ':count': {
        /**
         * Get total number of aiContainers
         *
         * @summary Count AiContainer
         * @param {{ params: V1AiContainerCountGetParams, headers: V1AiContainerCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'trigger': {
      /**
       * Find a list of triggers in a tenant
       *
       * @summary Get trigger list
       * @param {{ params: V1TriggerGetParams, headers: V1TriggerGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenTriggerWithLabels>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new Trigger in the system
       *
       * @summary Create Trigger
       * @param {{ data: TriggerCreateRequest, headers: V1TriggerPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseTrigger>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} triggerId Trigger id */
      '$triggerId': (triggerId) => ({
        ':publish': {
          /**
           * Publish a trigger
           *
           * @summary Publish trigger
           * @param {{ headers: V1TriggerTriggerIdPublishPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTrigger>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        ':discontinue': {
          /**
           * Discontinue a trigger
           *
           * @summary Discontinue trigger
           * @param {{ headers: V1TriggerTriggerIdDiscontinuePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTrigger>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'share': {
          /**
           * Share trigger with tenants
           *
           * @summary Create trigger share
           * @param {{ data: TriggerShareCreateRequest, headers: V1TriggerTriggerIdSharePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTriggerShare>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} tenantId A unique Tenant ID */
          $tenantId: (tenantId) => ({
            /**
             * Delete shared trigger of a tenant
             *
             * @summary Delete trigger share
             * @param {{ headers: V1TriggerTriggerIdShareTenantIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseTriggerShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
            /**
             * Update existing trigger share information
             *
             * @summary Update trigger share
             * @param {{ data: TriggerShareUpdateRequest, headers: V1TriggerTriggerIdShareTenantIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseTriggerShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
        },
        /**
         * Find an existing Trigger by trigger id
         *
         * @summary Get Trigger
         * @param {{ headers: V1TriggerTriggerIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseTriggerWithLabels>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Delete an existing trigger by trigger id
         *
         * @summary Delete trigger
         * @param {{ headers: V1TriggerTriggerIdDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseTrigger>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$delete': (config) => null,
        /**
         * Update an existing trigger
         *
         * @summary Update trigger
         * @param {{ data: TriggerUpdateRequest, headers: V1TriggerTriggerIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseTrigger>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
      }),
      ':count': {
        /**
         * Get total number of triggers
         *
         * @summary Count Trigger
         * @param {{ params: V1TriggerCountGetParams, headers: V1TriggerCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'stream': {
      /**
       * Retrieve a list of streams
       *
       * @summary Get stream
       * @param {{ params: V1StreamGetParams, headers: V1StreamGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenStream>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new stream
       *
       * @summary Create stream
       * @param {{ data: StreamCreateRequest, headers: V1StreamPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseStream>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} streamId A unique stream ID */
      '$streamId': (streamId) => ({
        /**
         * Update an existing stream
         *
         * @summary Update stream
         * @param {{ data: StreamUpdateRequest, headers: V1StreamStreamIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $patch: (config) => null,
      }),
      ':count': {
        /**
         * Get total number of streams
         *
         * @summary Count Stream
         * @param {{ params: V1StreamCountGetParams, headers: V1StreamCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'role': {
      /**
       * Get existing role list sorted in ascending order of name
       *
       * @summary Get role list
       * @param {{ params: V1RoleGetParams, headers: V1RoleGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithPaginationMetadataRole>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new role
       *
       * @summary Create role
       * @param {{ data: RoleCreateRequest, headers: V1RolePostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseRole>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} name Name of the role */
      '$name': (name) => ({
        /**
         * Get an existing role by name
         *
         * @summary Get role by name
         * @param {{ headers: V1RoleNameGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseRole>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
        /**
         * Delete an existing role
         *
         * @summary Delete role
         * @param {{ headers: V1RoleNameDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseRole>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $delete: (config) => null,
        /**
         * Update an existing role
         *
         * @summary Update role
         * @param {{ data: RoleUpdateRequest, headers: V1RoleNamePatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseRole>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $patch: (config) => null,
      }),
      ':count': {
        /**
         * Get total number of roles
         *
         * @summary Count Role
         * @param {{ headers: V1RoleCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'product': {
      /**
       * Gets the list of products with pagination
       *
       * @summary Get Product List
       * @param {{ params: V1ProductGetParams, headers: V1ProductGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenProduct>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Creates a new product
       *
       * @summary Create Product
       * @param {{ data: ProductCreateRequest, headers: V1ProductPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseProduct>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} productId */
      '$productId': (productId) => ({
        ':release': {
          /**
           * Release an existing unreleased product
           *
           * @summary Release Product
           * @param {{ headers: V1ProductProductIdReleasePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseProduct>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        ':discontinue': {
          /**
           * Discontinue a released product
           *
           * @summary Discontinue Product
           * @param {{ headers: V1ProductProductIdDiscontinuePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseProduct>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'share': {
          /**
           * Shares a product with a tenant for a specific access level
           *
           * @summary Create Product Share
           * @param {{ data: ProductShareCreateRequest, headers: V1ProductProductIdSharePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseProductShare>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} tenantId */
          $tenantId: (tenantId) => ({
            /**
             * Deletes access of a product from a tenant
             *
             * @summary Delete Product Share
             * @param {{ headers: V1ProductProductIdShareTenantIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseProductShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
            /**
             * Updates access level of a tenant for a product
             *
             * @summary Update Product Share
             * @param {{ data: ProductShareUpdateRequest, headers: V1ProductProductIdShareTenantIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseProductShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
        },
        'firmware-deployment': {
          /**
           * Get list of firmwares deployed for each type to a product
           *
           * @summary Get firmware deployment list of each type by product
           * @param {{ params: V1ProductProductIdFirmwareDeploymentGetParams, headers: V1ProductProductIdFirmwareDeploymentGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListFirmwareDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Deploy firmware to an existing product
           *
           * @summary Create firmware deployment
           * @param {{ data: FirmwareDeploymentCreateRequest, headers: V1ProductProductIdFirmwareDeploymentPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseFirmwareDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} firmwareType firmware type */
          $firmwareType: (firmwareType) => ({
            /**
             * remove a firmware deployment
             *
             * @summary Remove firmware deployment
             * @param {{ data: FirmwareDeploymentRemoveRequest, headers: V1ProductProductIdFirmwareDeploymentFirmwareTypeDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
          }),
        },
        /**
         * Gets the product details of a specific product
         *
         * @summary Get Product
         * @param {{ headers: V1ProductProductIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseProduct>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Deletes a specific product
         *
         * @summary Delete Product
         * @param {{ headers: V1ProductProductIdDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseProduct>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$delete': (config) => null,
        /**
         * Updates a specific product
         *
         * @summary Update Product
         * @param {{ data: ProductUpdateRequest, headers: V1ProductProductIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseProduct>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
        'firmware-deployment-history': {
          /**
           * Get firmware deployment history of product in descending order of activity id
           *
           * @summary Get firmware deployment history of product in descending order of activity id
           * @param {{ params: V1ProductProductIdFirmwareDeploymentHistoryGetParams, headers: V1ProductProductIdFirmwareDeploymentHistoryGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListFirmwareDeploymentActivityModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
      }),
      ':count': {
        /**
         * Get total number of products
         *
         * @summary Count Product
         * @param {{ params: V1ProductCountGetParams, headers: V1ProductCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'presence': {
      device: {
        query: {
          /**
           * Create a device presence query
           *
           * @summary Create a device presence query
           * @param {{ data: DevicePresenceQueryRequest, headers: V1PresenceDeviceQueryPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListDevicePresenceData>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
      },
    },
    'platform-admin': {
      /**
       * Get existing platform admin list sorted in ascending order of email
       *
       * @summary Get platform admin list
       * @param {{ params: V1PlatformAdminGetParams, headers: V1PlatformAdminGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithPaginationMetadataPlatformAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Creates a new Platform admin in the system
       *
       * @summary Create Platform admin
       * @param {{ data: PlatformAdminCreateRequest, headers: V1PlatformAdminPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponsePlatformAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} adminId Platform admin id */
      '$adminId': (adminId) => ({
        session: {
          /** @param {!string} sessionId Platform admin session id */
          $sessionId: (sessionId) => ({
            'session-data': {
              /**
               * Updates Platform admin session data by session id and admin id
               *
               * @summary Update Platform admin session data
               * @param {{ data: PlatformAdminSessionDataUpdateRequest }} config Axios Request config
               * @returns {ApiRequest<SuccessResponsePlatformAdminSessionModel>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              $post: (config) => null,
            },
          }),
          /**
           * Retrieves the list of Platform admin session by ID
           *
           * @summary Get Platform admin session
           * @param {{ params: V1PlatformAdminAdminIdSessionGetParams }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithContinuationTokenPlatformAdminSessionModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
      }),
      '*': {
        'file-credentials': {
          ':refresh': {
            /**
             * Gets platform admin picture and package file upload credentials
             *
             * @summary Get platform admin file upload credential
             * @param {{ data: PlatformAdminFilesCredentialsModel, headers: V1PlatformAdminFileCredentialsRefreshPostHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponsePlatformAdminFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $post: (config) => null,
          },
          /**
           * Gets platform admin picture and package file upload credentials
           *
           * @summary Get platform admin file upload credential
           * @param {{ params: V1PlatformAdminFileCredentialsGetParams, headers: V1PlatformAdminFileCredentialsGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponsePlatformAdminFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$get': (config) => null,
        },
      },
      /** @param {!string} id Platform admin id */
      '$id': (id) => ({
        /**
         * Retrieve a Platform admin by id
         *
         * @summary Get Platform admin
         * @param {{ headers: V1PlatformAdminIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponsePlatformAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Deletes a Platform admin by Id
         *
         * @summary Delete Platform admin
         * @param {{ headers: V1PlatformAdminIdDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponsePlatformAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$delete': (config) => null,
        /**
         * Updates a Platform admin data
         *
         * @summary Update Platform admin
         * @param {{ data: PlatformAdminUpdateRequest, headers: V1PlatformAdminIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponsePlatformAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
        'tenant-access': {
          /**
           * Get Platform admin tenant access list with other details
           *
           * @summary Get Platform admin tenant access list
           * @param {{ headers: V1PlatformAdminIdTenantAccessGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTenantAccessDetailsList>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Updates Platform admin tenant access by platform admin id
           *
           * @summary Update Platform admin tenant access
           * @param {{ data: PlatformAdminTenantAccessUpdateRequest, headers: V1PlatformAdminIdTenantAccessPatchHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponsePlatformAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $patch: (config) => null,
        },
      }),
      ':count': {
        /**
         * Get total number of PlatformAdmin
         *
         * @summary Count PlatformAdmin
         * @param {{ headers: V1PlatformAdminCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
      'username': {
        /** @param {!string} username Platform admin username */
        $username: (username) => ({
          /**
           * Retrieve a Platform admin by username
           *
           * @summary Get Platform admin by username
           * @param {{ headers: V1PlatformAdminUsernameUsernameGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponsePlatformAdmin>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        }),
      },
    },
    'label': {
      /**
       * Get list of label of a tenant
       *
       * @summary Get label list
       * @param {{ params: V1LabelGetParams, headers: V1LabelGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenLabelWithAliasDetails>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new label
       *
       * @summary Create label
       * @param {{ data: LabelCreateRequest, headers: V1LabelPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseLabel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} labelId A unique label ID */
      '$labelId': (labelId) => ({
        ':publish': {
          /**
           * Publish an label
           *
           * @summary Publish label
           * @param {{ headers: V1LabelLabelIdPublishPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLabel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        ':discontinue': {
          /**
           * Discontinue an label
           *
           * @summary Discontinue label
           * @param {{ headers: V1LabelLabelIdDiscontinuePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLabel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        /**
         * Get label details by labelId
         *
         * @summary Get label
         * @param {{ headers: V1LabelLabelIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseLabelWithAliasDetails>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Delete an existing label
         *
         * @summary Delete label
         * @param {{ headers: V1LabelLabelIdDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseLabel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$delete': (config) => null,
        /**
         * Update an existing label information
         *
         * @summary Update label
         * @param {{ data: LabelUpdateRequest, headers: V1LabelLabelIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseLabel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
      }),
      ':count': {
        /**
         * Get total number of labels
         *
         * @summary Count Label
         * @param {{ params: V1LabelCountGetParams, headers: V1LabelCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'label-category': {
      /**
       * Gets the list of label categories order by name
       *
       * @summary Get Label Category List
       * @param {{ params: V1LabelCategoryGetParams, headers: V1LabelCategoryGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenLabelCategory>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Adds a new label category
       *
       * @summary Create Label Category
       * @param {{ data: LabelCategoryCreateRequest, headers: V1LabelCategoryPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseLabelCategory>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} id */
      '$id': (id) => ({
        /**
         * Gets a specific label category detail by name
         *
         * @summary Get Label Category
         * @param {{ headers: V1LabelCategoryIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseLabelCategory>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
        /**
         * Updates a specific label category details by name
         *
         * @summary Update Label Category
         * @param {{ data: LabelCategoryUpdateRequest, headers: V1LabelCategoryIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseLabelCategory>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $patch: (config) => null,
      }),
      ':count': {
        /**
         * Get total number of label categories
         *
         * @summary Count Label Category
         * @param {{ headers: V1LabelCategoryCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'firmware': {
      /**
       * Gets the list of firmwares for a tenant ID
       *
       * @summary Get Firmware List
       * @param {{ params: V1FirmwareGetParams, headers: V1FirmwareGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenFirmwareDetailsInfo>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Adds a new firmware
       *
       * @summary Create Firmware
       * @param {{ data: FirmwareCreateRequest, headers: V1FirmwarePostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseFirmware>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} firmwareId */
      '$firmwareId': (firmwareId) => ({
        ':publish': {
          /**
           * Publish a firmware
           *
           * @summary Publish Firmware
           * @param {{ headers: V1FirmwareFirmwareIdPublishPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseFirmware>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        ':discontinue': {
          /**
           * Discontinue a firmware
           *
           * @summary Discontinue Firmware
           * @param {{ headers: V1FirmwareFirmwareIdDiscontinuePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseFirmware>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'share': {
          /**
           * Shares a firmware with a tenant for a specific access level
           *
           * @summary Create Firmware Share
           * @param {{ data: FirmwareShareCreateRequest, headers: V1FirmwareFirmwareIdSharePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseFirmwareShare>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} tenantId */
          $tenantId: (tenantId) => ({
            /**
             * Deletes access of a firmware from a tenant
             *
             * @summary Delete Firmware Share
             * @param {{ headers: V1FirmwareFirmwareIdShareTenantIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseFirmwareShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
            /**
             * Updates access level of a tenant for a firmware
             *
             * @summary Update Firmware Share
             * @param {{ data: FirmwareShareUpdateRequest, headers: V1FirmwareFirmwareIdShareTenantIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseFirmwareShare>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
        },
        /**
         * Gets a specific firmware detail by firmware ID
         *
         * @summary Get Firmware
         * @param {{ headers: V1FirmwareFirmwareIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseFirmwareDetailsInfo>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Deletes a specific firmware by firmware ID
         *
         * @summary Delete Firmware
         * @param {{ headers: V1FirmwareFirmwareIdDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseFirmware>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$delete': (config) => null,
        /**
         * Updates a specific firmware details by firmware ID
         *
         * @summary Update Firmware
         * @param {{ data: FirmwareUpdateRequest, headers: V1FirmwareFirmwareIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseFirmware>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
        'download-link': {
          /**
           * Generates firmware download link
           *
           * @summary Generate Download Link
           * @param {{ headers: V1FirmwareFirmwareIdDownloadLinkGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseString>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
      }),
      '*': {
        'file-credentials': {
          ':refresh': {
            /**
             * Gets firmware picture and package file upload credentials
             *
             * @summary Get Firmware file upload credential
             * @param {{ data: FirmwareFilesCredentialsModel, headers: V1FirmwareFileCredentialsRefreshPostHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseFirmwareFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $post: (config) => null,
          },
          /**
           * Gets firmware picture and package file upload credentials
           *
           * @summary Get Firmware file upload credential
           * @param {{ params: V1FirmwareFileCredentialsGetParams, headers: V1FirmwareFileCredentialsGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseFirmwareFilesCredentialsModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$get': (config) => null,
        },
      },
      ':count': {
        /**
         * Get total number of firmwares
         *
         * @summary Count Firmwares
         * @param {{ params: V1FirmwareCountGetParams, headers: V1FirmwareCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'file': {
      /**
       * @summary Create a file information entry
       * @param {{ data: FileAddRequest, headers: V1FilePostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseFileModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      $post: (config) => null,
    },
    'event': {
      /**
       * Get list of events
       *
       * @summary Get events
       * @param {{ params: V1EventGetParams, headers: V1EventGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenEventModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new event
       *
       * @summary Create event
       * @param {{ data: EventCreateRequest, headers: V1EventPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseEventModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} eventId */
      '$eventId': (eventId) => ({
        snapshot: {
          /**
           * Add a snapshot to an event
           *
           * @summary Add Snapshot To Event
           * @param {{ data: AddSnapshotToEventRequest, headers: V1EventEventIdSnapshotPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEventModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        /**
         * Get an existing event
         *
         * @summary Get event
         * @param {{ headers: V1EventEventIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseEventModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
        /**
         * Update recordingEndTimestamp of an existing event
         *
         * @summary Update event
         * @param {{ data: EventUpdateRequest, headers: V1EventEventIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseEventModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $patch: (config) => null,
      }),
      ':count': {
        /**
         * Get total number of events
         *
         * @summary Count Event
         * @param {{ params: V1EventCountGetParams, headers: V1EventCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'device': {
      /**
       * Get list of devices details of a tenant
       *
       * @summary Get Device List By Tenant
       * @param {{ params: V1DeviceGetParams, headers: V1DeviceGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Creates a new device
       *
       * @summary Create Device
       * @param {{ data: DeviceCreateRequest, headers: V1DevicePostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} deviceId */
      '$deviceId': (deviceId) => ({
        ':reboot': {
          /**
           * Reboot a specific device by device ID
           *
           * @summary Reboot a device
           * @param {{ headers: V1DeviceDeviceIdRebootPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'user-access': {
          /**
           * Creates access for an user to a specific device
           *
           * @summary Create User Access
           * @param {{ data: UserAccessCreateRequest, headers: V1DeviceDeviceIdUserAccessPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} userId */
          $userId: (userId) => ({
            /**
             * Deletes access for an user to a specific device
             *
             * @summary Delete User Access
             * @param {{ headers: V1DeviceDeviceIdUserAccessUserIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
          }),
        },
        'transfer': {
          /**
           * Transfers a device from one tenant to another
           *
           * @summary Transfer Device
           * @param {{ data: DeviceTransferRequest, headers: V1DeviceDeviceIdTransferPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'reset': {
          /**
           * Resets a device to non onboarded state
           *
           * @summary Reset Device
           * @param {{ headers: V1DeviceDeviceIdResetPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'refresh': {
          /**
           * Refreshes session for an onboarded device
           *
           * @summary Refresh Token
           * @param {{ data: DeviceSessionRefreshRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceOnboardResult>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'recording-request': {
          /**
           * Finds the list of recording requests by device id
           *
           * @summary Get recording request list by device
           * @param {{ params: V1DeviceDeviceIdRecordingRequestGetParams, headers: V1DeviceDeviceIdRecordingRequestGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListRecordingRequest>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Create a new recording request in the system
           *
           * @summary Create recording request
           * @param {{ data: RecordingRequestCreateRequest, headers: V1DeviceDeviceIdRecordingRequestPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseRecordingRequest>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} requestId Request id */
          $requestId: (requestId) => ({
            /**
             * Finds an existing recording request by request id
             *
             * @summary Get recording request
             * @param {{ headers: V1DeviceDeviceIdRecordingRequestRequestIdGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseRecordingRequest>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
            /**
             * Updates an existing recording request in the system
             *
             * @summary Update recording request
             * @param {{ data: RecordingRequestUpdateRequest, headers: V1DeviceDeviceIdRecordingRequestRequestIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseRecordingRequest>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
        },
        'onboard': {
          /**
           * Onboards a device
           *
           * @summary Onboard Device
           * @param {{ data: DeviceOnboardRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceOnboardResult>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'onboard-token': {
          /**
           * Generates owner token for a device
           *
           * @summary Generate Onboarded Token
           * @param {{ data: OwnerTokenRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseOwnerTokenModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'metric': {
          /**
           * Post metric data for a device
           *
           * @summary Post device metric data
           * @param {{ data: Array.<MetricDataCreateRequest>, headers: V1DeviceDeviceIdMetricPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$post': (config) => null,
          'wifi-usage': {
            /**
             * Finds the list of wifi usage by device id
             *
             * @summary Get wifi usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricWifiUsageGetParams, headers: V1DeviceDeviceIdMetricWifiUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceWifiUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'swap-usage': {
            /**
             * Finds the list of swap usage by device id
             *
             * @summary Get swap usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricSwapUsageGetParams, headers: V1DeviceDeviceIdMetricSwapUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceSwapUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'storage-usage': {
            /**
             * Finds the list of storage usage by device id
             *
             * @summary Get storage usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricStorageUsageGetParams, headers: V1DeviceDeviceIdMetricStorageUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceStorageUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'memory-usage': {
            /**
             * Finds the list of memory usage by device id
             *
             * @summary Get memory usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricMemoryUsageGetParams, headers: V1DeviceDeviceIdMetricMemoryUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceMemoryUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'gpu-usage': {
            /**
             * Finds the list of gpu usage by device id
             *
             * @summary Get gpu usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricGpuUsageGetParams, headers: V1DeviceDeviceIdMetricGpuUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceGpuUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'gpu-status': {
            /**
             * Finds the list of gpu status by device id
             *
             * @summary Get gpu status list by device
             * @param {{ params: V1DeviceDeviceIdMetricGpuStatusGetParams, headers: V1DeviceDeviceIdMetricGpuStatusGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceGpuStatusModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'ethernet-usage': {
            /**
             * Finds the list of ethernet usage by device id
             *
             * @summary Get ethernet usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricEthernetUsageGetParams, headers: V1DeviceDeviceIdMetricEthernetUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceEthernetUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'cpu-status': {
            /**
             * Finds the list of cpu status by device id
             *
             * @summary Get cpu status list by device
             * @param {{ params: V1DeviceDeviceIdMetricCpuStatusGetParams, headers: V1DeviceDeviceIdMetricCpuStatusGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceCpuStatusModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'cpu-core-usage': {
            /**
             * Finds the list of cpu core usage by device id
             *
             * @summary Get cpu core usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricCpuCoreUsageGetParams, headers: V1DeviceDeviceIdMetricCpuCoreUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceCpuCoreUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'cellular-usage': {
            /**
             * Finds the list of cellular usage by device id
             *
             * @summary Get cellular usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricCellularUsageGetParams, headers: V1DeviceDeviceIdMetricCellularUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceCellularUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'battery-status': {
            /**
             * Finds the list of battery status by device id
             *
             * @summary Get battery status list by device
             * @param {{ params: V1DeviceDeviceIdMetricBatteryStatusGetParams, headers: V1DeviceDeviceIdMetricBatteryStatusGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceBatteryStatusModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          'average-cpu-usage': {
            /**
             * Finds the list of average cpu usage by device id
             *
             * @summary Get average cpu usage list by device
             * @param {{ params: V1DeviceDeviceIdMetricAverageCpuUsageGetParams, headers: V1DeviceDeviceIdMetricAverageCpuUsageGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListDeviceAverageCpuUsageModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
        },
        'media': {
          /**
           * Get List of uploaded media for a device
           *
           * @summary Get Media List
           * @param {{ params: V1DeviceDeviceIdMediaGetParams, headers: V1DeviceDeviceIdMediaGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithContinuationTokenMediaInfo>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Create an entry for an uploaded media
           *
           * @summary Report a media upload
           * @param {{ data: ReportMediaUploadRequest, headers: V1DeviceDeviceIdMediaPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseMediaInfo>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'log': {
          /**
           * Gets log list of a specific device with pagination
           *
           * @summary Find Device Log
           * @param {{ params: V1DeviceDeviceIdLogGetParams, headers: V1DeviceDeviceIdLogGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithContinuationTokenDeviceLog>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Posts log of a specific device
           *
           * @summary Create Device Log
           * @param {{ data: DeviceLogCreateRequest, headers: V1DeviceDeviceIdLogPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceLog>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'log-request': {
          /**
           * Finds the list of log requests by device id
           *
           * @summary Get log request list by device
           * @param {{ params: V1DeviceDeviceIdLogRequestGetParams, headers: V1DeviceDeviceIdLogRequestGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListLogRequest>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Create a new log request in the system
           *
           * @summary Create log request
           * @param {{ data: LogRequestCreateRequest, headers: V1DeviceDeviceIdLogRequestPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLogRequest>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} requestId Request id */
          $requestId: (requestId) => ({
            /**
             * Finds an existing log request by request id
             *
             * @summary Get log request
             * @param {{ headers: V1DeviceDeviceIdLogRequestRequestIdGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseLogRequest>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
            /**
             * Updates an existing log request in the system
             *
             * @summary Update log request
             * @param {{ data: LogRequestUpdateRequest, headers: V1DeviceDeviceIdLogRequestRequestIdPatchHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseLogRequest>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $patch: (config) => null,
          }),
        },
        'firmware-info': {
          /**
           * Get firmware info list of a device
           *
           * @summary Get device firmware info
           * @param {{ headers: V1DeviceDeviceIdFirmwareInfoGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListDeviceFirmwareDetailInfo>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Post firmware info for a device
           *
           * @summary Post device firmware info
           * @param {{ data: DeviceFirmwareInfo, headers: V1DeviceDeviceIdFirmwareInfoPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceFirmwareInfo>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'onboarded-device': {
          /**
           * Updates an onboarded device
           *
           * @summary Update Onboarded Device
           * @param {{ data: OnboardedDeviceUpdateRequest, headers: V1DeviceDeviceIdOnboardedDevicePatchHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $patch: (config) => null,
        },
        'device-key': {
          /**
           * Updates the device key information of a device
           *
           * @summary Update Device Detail
           * @param {{ data: DeviceKeyUpdateRequest, headers: V1DeviceDeviceIdDeviceKeyPatchHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $patch: (config) => null,
        },
        /**
         * Gets the device info for a specific device ID
         *
         * @summary Get Device
         * @param {{ headers: V1DeviceDeviceIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseDeviceDetail>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        'user': {
          /**
           * Get list of end users with access to the device
           *
           * @summary Get device user list
           * @param {{ headers: V1DeviceDeviceIdUserGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListDeviceUserModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'stream-tokens': {
          /**
           * Get tokens for streaming from a device
           *
           * @summary Get tokens for streaming from a device
           * @param {{ headers: V1DeviceDeviceIdStreamTokensGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseStreamTokens>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'sensor-data': {
          /**
           * Finds the list of sensor report by device id
           *
           * @summary Get Sensor Report List by Device
           * @param {{ params: V1DeviceDeviceIdSensorDataGetParams, headers: V1DeviceDeviceIdSensorDataGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListSensorDataModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'reply-token': {
          /**
           * Get stream message reply token
           *
           * @summary Get stream message reply token
           * @param {{ headers: V1DeviceDeviceIdReplyTokenGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTokenWithExpirationModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'presence-token': {
          /**
           * Get tokens for device presence
           *
           * @summary Get tokens for device presence
           * @param {{ headers: V1DeviceDeviceIdPresenceTokenGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTokenWithExpirationModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'presence-history': {
          /**
           * Finds the history of device presence by device id
           *
           * @summary Get presence history by device
           * @param {{ params: V1DeviceDeviceIdPresenceHistoryGetParams, headers: V1DeviceDeviceIdPresenceHistoryGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithContinuationTokenDevicePresenceHistoryModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'onboard-log': {
          /**
           * Retrieve list of onboard log of a device
           *
           * @summary Get device onboard log
           * @param {{ params: V1DeviceDeviceIdOnboardLogGetParams, headers: V1DeviceDeviceIdOnboardLogGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithContinuationTokenDeviceOnboardLog>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'media-credential': {
          /**
           * Return azure sas url to allow upload a file
           *
           * @summary Request for upload credentials
           * @param {{ headers: V1DeviceDeviceIdMediaCredentialGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseMediaUploadCredentialResponse>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          '$get': (config) => null,
          ':refresh': {
            /**
             * Returns a updated upload url
             *
             * @summary Request to update upload url for a file
             * @param {{ params: V1DeviceDeviceIdMediaCredentialRefreshGetParams, headers: V1DeviceDeviceIdMediaCredentialRefreshGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseMediaUploadCredentialResponse>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
          ':batchGet': {
            /**
             * Return a list of upload url to allow upload files
             *
             * @summary Request for upload credentials for a list of files
             * @param {{ params: V1DeviceDeviceIdMediaCredentialBatchGetGetParams, headers: V1DeviceDeviceIdMediaCredentialBatchGetGetHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseListMediaUploadCredentialResponse>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $get: (config) => null,
          },
        },
        'group': {
          /** @param {!string} groupId */
          $groupId: (groupId) => ({
            'trigger-deployment': {
              /**
               * Gets the trigger deployment list of a device for a group
               *
               * @summary Get Device Trigger Deployment List
               * @param {{ params: V1DeviceDeviceIdGroupGroupIdTriggerDeploymentGetParams, headers: V1DeviceDeviceIdGroupGroupIdTriggerDeploymentGetHeaders }} config Axios Request config
               * @returns {ApiRequest<SuccessResponseListWithContinuationTokenTriggerDeployment>} An ApiRequest; starts after you call 'response' or 'result'.
               */
              $get: (config) => null,
            },
          }),
        },
        'ai-container-deployment': {
          /**
           * Get Deployed AiContainer DownloadUrl of a Group Deployment
           *
           * @summary Get AiContainer Deployment Download Url
           * @param {{ headers: V1DeviceDeviceIdAiContainerDeploymentGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiContainerWithDonloadInfoModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
      }),
      '.': {
        ':bulk-add': {
          /**
           * Creates device in bulk
           *
           * @summary Device Bulk Add
           * @param {{ data: BulkAddRequest, headers: V1DeviceBulkAddPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListDeviceBulkAddResponse>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
      },
      ':count': {
        /**
         * Get total number of devices within a tenant
         *
         * @summary Count Device within a tenant
         * @param {{ params: V1DeviceCountGetParams, headers: V1DeviceCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'device-pool': {
      /**
       * Get device pool list by tenant
       *
       * @summary Get Device Pool List
       * @param {{ params: V1DevicePoolGetParams, headers: V1DevicePoolGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenDevicePool>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Creates a new device pool
       *
       * @summary Create Device Pool
       * @param {{ data: DevicePoolCreateRequest, headers: V1DevicePoolPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseDevicePool>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} poolId Device Pool Id */
      '$poolId': (poolId) => ({
        'firmware-deployment': {
          /**
           * Get list of firmwares deployed for each type to a pool
           *
           * @summary Get firmware deployment list of each type by pool
           * @param {{ params: V1DevicePoolPoolIdFirmwareDeploymentGetParams, headers: V1DevicePoolPoolIdFirmwareDeploymentGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListFirmwareDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Deploy firmware to an existing pool
           *
           * @summary Create firmware deployment
           * @param {{ data: FirmwareDeploymentCreateRequest, headers: V1DevicePoolPoolIdFirmwareDeploymentPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseFirmwareDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} firmwareType firmware type */
          $firmwareType: (firmwareType) => ({
            /**
             * remove a firmware deployment
             *
             * @summary Remove firmware deployment
             * @param {{ data: FirmwareDeploymentRemoveRequest, headers: V1DevicePoolPoolIdFirmwareDeploymentFirmwareTypeDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
          }),
        },
        /**
         * Get an existing device pool
         *
         * @summary Get Device Pool
         * @param {{ headers: V1DevicePoolPoolIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseDevicePool>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Update and existing device pool
         *
         * @summary Update Device Pool
         * @param {{ data: DevicePoolUpdateRequest, headers: V1DevicePoolPoolIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseDevicePool>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
        'firmware-deployment-history': {
          /**
           * Get firmware deployment history of pool in descending order of activity id
           *
           * @summary Get firmware deployment history of pool in descending order of activity id
           * @param {{ params: V1DevicePoolPoolIdFirmwareDeploymentHistoryGetParams, headers: V1DevicePoolPoolIdFirmwareDeploymentHistoryGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListFirmwareDeploymentActivityModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
      }),
      ':count': {
        /**
         * Get total number of Device Pools
         *
         * @summary Count DevicePool
         * @param {{ params: V1DevicePoolCountGetParams, headers: V1DevicePoolCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'device-batch': {
      /**
       * Get device batch list
       *
       * @summary Get Device Batch List
       * @param {{ params: V1DeviceBatchGetParams, headers: V1DeviceBatchGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListDeviceBatch>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      $get: (config) => null,
      /**
       * Creates a new device batch
       *
       * @summary Create Device Batch
       * @param {{ data: DeviceBatchCreateRequest, headers: V1DeviceBatchPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseDeviceBatch>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      $post: (config) => null,
      /** @param {!string} batchId */
      $batchId: (batchId) => ({
        /**
         * Get an existing device batch
         *
         * @summary Get Device Batch
         * @param {{ headers: V1DeviceBatchBatchIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseDeviceBatch>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
        /**
         * Update and existing device batch
         *
         * @summary Update Device Batch
         * @param {{ data: DeviceBatchUpdateRequest, headers: V1DeviceBatchBatchIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseDeviceBatch>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $patch: (config) => null,
      }),
    },
    'deployment-group': {
      /**
       * Get deployment group list for a tenant
       *
       * @summary Get deployment group list by tenant
       * @param {{ params: V1DeploymentGroupGetParams, headers: V1DeploymentGroupGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenDeploymentGroup>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new deployment group
       *
       * @summary Create deployment group
       * @param {{ data: DeploymentGroupCreateRequest, headers: V1DeploymentGroupPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseDeploymentGroup>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} groupId A unique Group ID */
      '$groupId': (groupId) => ({
        'trigger-deployment': {
          /**
           * Get list of trigger deployed for each type to a group
           *
           * @summary Get trigger deployment list of each type by group
           * @param {{ params: V1DeploymentGroupGroupIdTriggerDeploymentGetParams, headers: V1DeploymentGroupGroupIdTriggerDeploymentGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListTriggerDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Deploy a trigger to an existing group
           *
           * @summary Create trigger deployment
           * @param {{ data: TriggerDeploymentCreateRequest, headers: V1DeploymentGroupGroupIdTriggerDeploymentPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseTriggerDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /**
           * remove a trigger deployment
           *
           * @summary Remove trigger deployment
           * @param {{ data: TriggerDeploymentRemoveRequest, headers: V1DeploymentGroupGroupIdTriggerDeploymentDeleteHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $delete: (config) => null,
        },
        'firmware-deployment': {
          /**
           * Get list of firmwares deployed for each type to a group
           *
           * @summary Get firmware deployment list of each type by group
           * @param {{ params: V1DeploymentGroupGroupIdFirmwareDeploymentGetParams, headers: V1DeploymentGroupGroupIdFirmwareDeploymentGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListFirmwareDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Deploy firmware to an existing group
           *
           * @summary Create firmware deployment
           * @param {{ data: FirmwareDeploymentCreateRequest, headers: V1DeploymentGroupGroupIdFirmwareDeploymentPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseFirmwareDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} firmwareType firmware type */
          $firmwareType: (firmwareType) => ({
            /**
             * remove a firmware deployment
             *
             * @summary Remove firmware deployment
             * @param {{ data: FirmwareDeploymentRemoveRequest, headers: V1DeploymentGroupGroupIdFirmwareDeploymentFirmwareTypeDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
          }),
        },
        'device': {
          /**
           * Get list of onboarded devices in a deployment group
           *
           * @summary Get onboarded device in deployment group
           * @param {{ params: V1DeploymentGroupGroupIdDeviceGetParams, headers: V1DeploymentGroupGroupIdDeviceGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListWithContinuationTokenOnboardedDeviceModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Deploy an existing device to a group
           *
           * @summary Add device to group
           * @param {{ data: GroupDeviceAddRequest, headers: V1DeploymentGroupGroupIdDevicePostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /** @param {!string} deviceId A unique device Id */
          $deviceId: (deviceId) => ({
            /**
             * Delete a device from an existing deployment group
             *
             * @summary Delete deployment group in device
             * @param {{ headers: V1DeploymentGroupGroupIdDeviceDeviceIdDeleteHeaders }} config Axios Request config
             * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
             */
            $delete: (config) => null,
          }),
        },
        'ai-container-deployment': {
          /**
           * Get ai containers deployment details of a group
           *
           * @summary Get ai container deployment of a group
           * @param {{ params: V1DeploymentGroupGroupIdAiContainerDeploymentGetParams, headers: V1DeploymentGroupGroupIdAiContainerDeploymentGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiContainerDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Deploy ai container to an existing group
           *
           * @summary Create ai container deployment
           * @param {{ data: AiContainerDeploymentCreateRequest, headers: V1DeploymentGroupGroupIdAiContainerDeploymentPostHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseAiContainerDeploymentModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
          /**
           * remove an ai container deployment
           *
           * @summary Remove ai container deployment
           * @param {{ data: AiContainerDeploymentRemoveRequest, headers: V1DeploymentGroupGroupIdAiContainerDeploymentDeleteHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $delete: (config) => null,
        },
        /**
         * Get deployment group by group Id
         *
         * @summary Get deployment group by Id
         * @param {{ headers: V1DeploymentGroupGroupIdGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseDeploymentGroup>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$get': (config) => null,
        /**
         * Update an existing deployment group information
         *
         * @summary Update deployment group
         * @param {{ data: DeploymentGroupUpdateRequest, headers: V1DeploymentGroupGroupIdPatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseDeploymentGroup>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        '$patch': (config) => null,
        'settings': {
          /**
           * Get group settings and default group settings
           *
           * @summary Get group settings
           * @param {{ headers: V1DeploymentGroupGroupIdSettingsGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseGroupSettingsResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
          /**
           * Update an group settings of a deployment group
           *
           * @summary Update group settings
           * @param {{ data: GroupSettingsUpdateRequest, headers: V1DeploymentGroupGroupIdSettingsPatchHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseGroupSettingsModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $patch: (config) => null,
        },
        'trigger-deployment-history': {
          /**
           * Get trigger deployment history of group in descending order of activity id
           *
           * @summary Get trigger deployment history of group in descending order of activity id
           * @param {{ params: V1DeploymentGroupGroupIdTriggerDeploymentHistoryGetParams, headers: V1DeploymentGroupGroupIdTriggerDeploymentHistoryGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListTriggerDeploymentActivityModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'firmware-deployment-history': {
          /**
           * Get firmware deployment history of group in descending order of activity id
           *
           * @summary Get firmware deployment history of group in descending order of activity id
           * @param {{ params: V1DeploymentGroupGroupIdFirmwareDeploymentHistoryGetParams, headers: V1DeploymentGroupGroupIdFirmwareDeploymentHistoryGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListFirmwareDeploymentActivityModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
        'ai-container-deployment-history': {
          /**
           * Get ai-container deployment history of group in descending order of activity id
           *
           * @summary Get ai-container deployment history of group in descending order of activity id
           * @param {{ params: V1DeploymentGroupGroupIdAiContainerDeploymentHistoryGetParams, headers: V1DeploymentGroupGroupIdAiContainerDeploymentHistoryGetHeaders }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseListAiContainerDeploymentActivityModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $get: (config) => null,
        },
      }),
      ':count': {
        /**
         * Get total number of deployment group
         *
         * @summary Count Deployment group
         * @param {{ params: V1DeploymentGroupCountGetParams, headers: V1DeploymentGroupCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'auth': {
      'tenant-admin': {
        'send-code': {
          /**
           * Sends secret code to Tenant admin email
           *
           * @summary Tenant Admin Send New Code
           * @param {{ data: TenantAdminCodeSendRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'reset-password': {
          /**
           * Sends request to reset Tenant admin password
           *
           * @summary Tenant Admin Reset Password
           * @param {{ data: TenantAdminPasswordResetRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'refresh': {
          /**
           * Renew Tenant admin authentication token
           *
           * @summary Refresh tenant admin token
           * @param {{ data: RefreshToken }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLoginResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'logout': {
          /**
           * Sign-out Tenant admin from the system
           *
           * @summary Logout Tenant Admin
           * @param {{ data: RefreshToken }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'login': {
          /**
           * Sign-in Tenant admin in the system
           *
           * @summary Login Tenant admin
           * @param {{ data: TenantAdminLoginRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLoginResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
      },
      'platform-admin': {
        'send-code': {
          /**
           * Sends secret code to Platform admin email
           *
           * @summary Platform Admin Send New Code
           * @param {{ data: PlatformAdminCodeSendRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'reset-password': {
          /**
           * Sends request to reset Platfrom admin password
           *
           * @summary Platform Admin Reset Password
           * @param {{ data: PlatformAdminPasswordResetRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'refresh': {
          /**
           * Renew Platform admin authentication token
           *
           * @summary Refresh Platform admin token
           * @param {{ data: RefreshToken, params: V1AuthPlatformAdminRefreshPostParams }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLoginResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'logout': {
          /**
           * Sign-out Platform admin from the system
           *
           * @summary Log Out Platform Admin
           * @param {{ data: RefreshToken }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'login': {
          /**
           * Sign-in to Smarter-Ai system as a PlatformAdmin
           *
           * @summary Login Platform Admin
           * @param {{ data: PlatformAdminLoginRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLoginResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
      },
      'end-user': {
        'verify-email': {
          /**
           * Verifies End user email
           *
           * @summary Verify End User Account
           * @param {{ data: EndUserVerifyRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'send-code': {
          /**
           * Sends secret code to End user email
           *
           * @summary End User Send New Code
           * @param {{ data: EndUserCodeSendRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'reset-password': {
          /**
           * Sends request to reset End user password
           *
           * @summary End User Reset Password
           * @param {{ data: EndUserPasswordResetRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'register': {
          /**
           * Register in the system as an End user
           *
           * @summary Register End User
           * @param {{ data: EndUserRegisterRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'refresh': {
          /**
           * Renew End user authentication token
           *
           * @summary Refresh end user token
           * @param {{ data: RefreshToken }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLoginResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'logout': {
          /**
           * Sign-out Enduser from the system
           *
           * @summary Logout End User
           * @param {{ data: RefreshToken }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseEmptyModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
        'login': {
          /**
           * Sign-in to the system as an End user
           *
           * @summary Login End user
           * @param {{ data: EndUserLoginRequest }} config Axios Request config
           * @returns {ApiRequest<SuccessResponseLoginResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
           */
          $post: (config) => null,
        },
      },
    },
    'ai-accelerator': {
      /**
       * Get existing Ai-Accelerator list
       *
       * @summary Get Ai-Accelerator list
       * @param {{ params: V1AiAcceleratorGetParams, headers: V1AiAcceleratorGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenAiAccelerator>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      /**
       * Create a new Ai-Accelerator
       *
       * @summary Create Ai-Accelerator
       * @param {{ data: AiAcceleratorCreateRequest, headers: V1AiAcceleratorPostHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseAiAccelerator>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$post': (config) => null,
      /** @param {!string} codeName A unique codeName for Ai-Accelerator */
      '$codeName': (codeName) => ({
        /**
         * Get Ai-Accelerator details information by code-name
         *
         * @summary Get Ai-Accelerator by code-name
         * @param {{ headers: V1AiAcceleratorCodeNameGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiAccelerator>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
        /**
         * Delete an existing Ai-Accelerator
         *
         * @summary Delete Ai-Accelerator
         * @param {{ headers: V1AiAcceleratorCodeNameDeleteHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiAccelerator>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $delete: (config) => null,
        /**
         * Update an existing Ai-Accelerator information
         *
         * @summary Update Ai-Accelerator
         * @param {{ data: AiAcceleratorUpdateRequest, headers: V1AiAcceleratorCodeNamePatchHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseAiAccelerator>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $patch: (config) => null,
      }),
      ':count': {
        /**
         * Get total number of Ai-Accelerators
         *
         * @summary Count Ai-Accelerator
         * @param {{ headers: V1AiAcceleratorCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'trigger-share': {
      /**
       * Retrieves a list of shared triggers in a tenant
       *
       * @summary Get trigger share
       * @param {{ params: V1TriggerShareGetParams, headers: V1TriggerShareGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenSharedTriggerDetailModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      ':count': {
        /**
         * Get total number of Trigger Share
         *
         * @summary Count TriggerShare
         * @param {{ params: V1TriggerShareCountGetParams, headers: V1TriggerShareCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'stream-credential': {
      /**
       * Get server credentials for stream initiation
       *
       * @summary Get server credentials for stream initiation
       * @param {{ headers: V1StreamCredentialGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseServerCredentialModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      $get: (config) => null,
    },
    'product-share': {
      /**
       * Retrieves a list of Shared Products in a tenant
       *
       * @summary Get Product Share
       * @param {{ params: V1ProductShareGetParams, headers: V1ProductShareGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenSharedProductDetailModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      ':count': {
        /**
         * Get total number of Product Share
         *
         * @summary Count ProductShare
         * @param {{ params: V1ProductShareCountGetParams, headers: V1ProductShareCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'ip-utils': {
      'my-ip': {
        /**
         * Returns the client's public IP address from the request headers.
         *
         * @summary Get your public IP address
         * @param {{ headers: V1IpUtilsMyIpGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseIpAddressResult>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
      'lookup-ip': {
        /**
         * Lookup geographic coordinates and location data from an IPv4 address.
         *
         * @summary Get location information of an IP address
         * @param {{ params: V1IpUtilsLookupIpGetParams, headers: V1IpUtilsLookupIpGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseIpLookupResult>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
      'geocoding': {
        /**
         * Find out the address from geographic coordinates
         *
         * @summary Get address from coordinates
         * @param {{ params: V1IpUtilsGeocodingGetParams, headers: V1IpUtilsGeocodingGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseGeocodingResult>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'firmware-share': {
      /**
       * Retrieves a list of Shared Firmwares in a tenant
       *
       * @summary Get Firmware Share
       * @param {{ params: V1FirmwareShareGetParams, headers: V1FirmwareShareGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenSharedFirmwareDetailModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      ':count': {
        /**
         * Get total number of Firmware Share
         *
         * @summary Count FirmwareShare
         * @param {{ params: V1FirmwareShareCountGetParams, headers: V1FirmwareShareCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'device-access': {
      /**
       * get list of device along with access level for an end user
       *
       * @summary Get device access of a user
       * @param {{ params: V1DeviceAccessGetParams, headers: V1DeviceAccessGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenDeviceAccessModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      $get: (config) => null,
    },
    'ai-model-share': {
      /**
       * Retrieves a list of Shared AiModels in a Tenant
       *
       * @summary Get AiModel Share
       * @param {{ params: V1AiModelShareGetParams, headers: V1AiModelShareGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenSharedAiModelDetailModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      ':count': {
        /**
         * Get total number of Ai-Model Share
         *
         * @summary Count AiModelShare
         * @param {{ params: V1AiModelShareCountGetParams, headers: V1AiModelShareCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'ai-container-share': {
      /**
       * Retrieves a list of shared ai-containers in a tenant
       *
       * @summary Get ai-container share
       * @param {{ params: V1AiContainerShareGetParams, headers: V1AiContainerShareGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithContinuationTokenSharedAiContainerDetailModel>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      '$get': (config) => null,
      ':count': {
        /**
         * Get total number of AiContainer Share
         *
         * @summary Count Ai Container Share
         * @param {{ params: V1AiContainerShareCountGetParams, headers: V1AiContainerShareCountGetHeaders }} config Axios Request config
         * @returns {ApiRequest<SuccessResponseCountResponseModel>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: (config) => null,
      },
    },
    'access-right': {
      /**
       * Get existing access-right list sorted in ascending order of name
       *
       * @summary Get access-right list
       * @param {{ params: V1AccessRightGetParams, headers: V1AccessRightGetHeaders }} config Axios Request config
       * @returns {ApiRequest<SuccessResponseListWithPaginationMetadataAccessRight>} An ApiRequest; starts after you call 'response' or 'result'.
       */
      $get: (config) => null,
      /** @param {!string} name Name of access-right */
      $name: (name) => ({
        /**
         * Get existing access-right details information by name
         *
         * @summary Get access-right by name
         * @returns {ApiRequest<SuccessResponseAccessRight>} An ApiRequest; starts after you call 'response' or 'result'.
         */
        $get: () => null,
      }),
    },
  },
};
