import { useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { makeColorFrom } from '@/utils';
import { InputCard } from '@/web/common/input-output-sources/InputCard';
import { AiModelContext } from '../../../@components/AiModelViewLayout';
import { AddInputSourceButton } from '../AddInputSourceButton';
import { DeleteInputSourceButton } from '../DeleteInputSourceButton';
import { EditInputSourceButton } from '../EditInputSourceButton';
import './index.scss';

/** @param {{label: string, type: 'VIDEO'|'AUDIO'|'SENSOR'|'MODEL', value: 'videoInputs'|'audioInputs'|'sensorInputs'|'modelInputs'}} props */
export function InputSourcesView(props) {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item: model } = useContext(AiModelContext);

  const { label, type, value } = props;
  const color = makeColorFrom(label, 1);
  const inputs = model[value];

  return (
    <div key={label} className="model-input">
      <div className="title mb-2 text-center text-lg-start">
        {label} ({inputs.length} item{inputs.length > 1 && 's'})
      </div>

      <div className="row g-2 row-cols-sm-auto">
        {inputs.map((item) => (
          <div key={item.code} className="col-12">
            <InputCard
              inputType={type}
              input={item}
              source={model}
              color={color}
              footer={
                <ButtonGroup size="sm" className="w-100">
                  <EditInputSourceButton item={item} value={value} label={label} />
                  <DeleteInputSourceButton item={item} value={value} label={label} />
                </ButtonGroup>
              }
            />
          </div>
        ))}
        <div className="col-12 d-flex justify-content-center align-items-center">
          <div style={{ height: 150, width: 180 }}>
            <AddInputSourceButton value={value} label={label} />
          </div>
        </div>
      </div>
    </div>
  );
}
