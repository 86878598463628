import { MdGraphicEq } from 'react-icons/md';
import { getSensorType, getSensorUnit } from '@/utils/ai-models';
import { DataItemTable } from '@/web/common/DataItemTable';
import { InputCardLayout } from '../InputCardLayout';

/** @param {{input: SensorInput, source: AiModel }} props */
export function SensorInputCard(props) {
  return (
    <InputCardLayout {...props} icon={<MdGraphicEq />}>
      <DataItemTable
        dense
        border={false}
        data={props.input}
        className="small fira-code"
        minWidth={100}
        headers={[
          {
            key: 'sensorType',
            builder: ({ sensorType }) => getSensorType(sensorType),
          },
          {
            key: 'sensorUnit',
            builder: ({ sensorUnit }) => getSensorUnit(sensorUnit),
          },
        ]}
      />
    </InputCardLayout>
  );
}
