import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useContext } from 'react';
import { FaNetworkWired } from 'react-icons/fa';
import { DeviceViewContext } from '../@components/DeviceViewLayout';

export function ViewDeviceNetworkPage() {
  /** @type {{device : DeviceDetail}}  */
  const { device } = useContext(DeviceViewContext);

  return (
    <>
      <BreadcrumbLayout.Header icon={<FaNetworkWired />} title="Network Information" />
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={device}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'bluetooth',
            },
            {
              key: 'cellular',
            },
            {
              key: 'ethernet',
            },
            {
              key: 'gps',
              title: 'GPS',
            },
            {
              key: 'wifi',
              title: 'WiFi',
            },
            {
              key: 'imeiNumber',
              title: 'IMEI Number',
            },
            {
              key: 'macAddress',
              title: 'MAC Address',
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
