import api from '@/api';
import { useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { getBearerToken } from '@/state/auth';
import { Navigate, useParams } from 'react-router';
import { displayError } from '@/utils/toaster';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { fetchAllLabelCategories, fetchAllLabelsByTenant, fetchAllTenants } from '@/state/cache';
import { LabelView } from './@components/LabelView';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';

export const LabelContext = createContext();

export function ViewLabelWithTenant() {
  const { labelId, tenantId } = useParams();

  const [id, setId] = useState(null);
  const updateParent = () => {
    setId(Date.now());
  };

  const [tenantsLoading, tenantsError] = useDispatchLoader(fetchAllTenants);

  const [labelCategoriesLoading, labelCategoriesError] = useDispatchLoader(fetchAllLabelCategories);

  const [labelsLoading, labelsError] = useDispatchLoader(
    () => fetchAllLabelsByTenant(tenantId),
    [tenantId]
  );

  const bearerToken = useSelector(getBearerToken);
  const [label, labelLoading, labelError] = useFutureLoader(async () => {
    const request = api.sai.v1.label.$labelId(labelId).$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, labelId]);

  let loading = null;
  if (labelLoading) {
    loading = 'Loading label...';
  } else if (labelsLoading) {
    loading = 'Loading labels...';
  } else if (tenantsLoading) {
    loading = 'Loading tenants...';
  } else if (labelCategoriesLoading) {
    loading = 'Loading label categories...';
  }

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={loading} />
      </BreadcrumbLayout>
    );
  }

  if (labelError || tenantsError || labelCategoriesError || labelsError) {
    displayError(labelError);
    displayError(tenantsError);
    displayError(labelCategoriesError);
    displayError(labelsError);
    return <Navigate replace to="./../.." />;
  }

  return (
    <BreadcrumbLayout>
      <LabelContext.Provider value={updateParent}>
        <LabelView item={label} />
      </LabelContext.Provider>
    </BreadcrumbLayout>
  );
}
