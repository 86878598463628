import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { AiModelContext } from '../../../@components/AiModelViewLayout';

export function DeleteAiModelButton(props) {
  /** @type {{item: AiModel}} */
  const { item } = useContext(AiModelContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const deleteAiModel = async () => {
    const request = api.sai.v1['ai-model'].$modelId(item.modelId).$delete();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Deleted AI Model <b>${item.name}</b>`,
      });
      navigate('./../..');
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      variant="danger"
      style={{ width: 100 }}
      disabled={loading}
      onClick={() =>
        confirmDialog({
          onConfirm: deleteAiModel,
          title: `Delete ${item.name} (${item.version})?`,
          message: 'It will affect all resources using this AI Model',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
