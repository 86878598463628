// import { Buffer } from 'buffer';
import { ArrayBuffer } from 'spark-md5';

const CHUCK_SIZE = 5 * 1024 * 1024; // 5mb

/**
 * Calculate MD5 checksum of an input file.
 *
 * @param {File} file
 * @param {BufferEncoding} [_encoding]
 * @returns {Promise<string>}
 */
export function calculateMd5sum(file, _encoding = 'base64') {
  return new Promise((resolve, reject) => {
    const spark = new ArrayBuffer();
    const fileReader = new FileReader();

    let currentChunk = 0;
    const chunks = Math.ceil(file.size / CHUCK_SIZE);
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

    const loadNext = () => {
      const start = currentChunk * CHUCK_SIZE;
      const end = start + CHUCK_SIZE >= file.size ? file.size : start + CHUCK_SIZE;

      // Selectively read the file and only store part of it in memory.
      // This allows client-side applications to process huge files without the need for huge memory
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    };

    fileReader.onload = (e) => {
      spark.append(e.target.result);
      currentChunk++;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(spark.end());
        // const buffer = Buffer.from(spark.end(), 'hex');
        // resolve(buffer.toString(encoding));
      }
    };

    fileReader.onerror = () => {
      return reject('Calculating MD5 checksum failed');
    };

    loadNext();
  });
}
