import { doLogout, getTenantId, getTokenData, getUser, getUserAvatar } from '@/state/auth';
import { selectTenant } from '@/state/auth/select-tenant';
import { getTenant } from '@/state/cache';
import { CloseButton, Image, OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

function TenantCloseButton() {
  const dispatch = useDispatch();
  const tenantId = useSelector(getTenantId);
  const tokenData = useSelector(getTokenData);
  const tenant = useSelector(getTenant(tenantId));

  function clearTenant() {
    dispatch(selectTenant());
  }

  if (tokenData?.subject_type !== 'PLATFORM_ADMIN') {
    return null;
  }

  return (
    <>
      <hr className="my-1" />
      <div className="d-flex py-1 gap-2 align-items-center justify-content-center">
        <div className="flex-lg-fill text-muted fs-6">
          Tenant: <b>{tenant?.name ?? '...'}</b>
        </div>
        <div title="Clear selection">
          <CloseButton onClick={clearTenant} />
        </div>
      </div>
    </>
  );
}

function UserMenuActions() {
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  function handleLogout() {
    dispatch(doLogout());
  }

  return (
    <>
      <div className="popover-title">{user.email}</div>
      <div className="popover-subtitle">
        {user.firstName} {user.lastName}
      </div>

      <TenantCloseButton />

      <hr className="my-1" />
      <div onClick={handleLogout} className="popover-logout">
        Logout
      </div>
    </>
  );
}

export function UserAvatar() {
  const avatar = useSelector(getUserAvatar);

  return (
    <>
      <div className="user-profile d-none d-lg-block">
        <OverlayTrigger
          rootClose={true}
          trigger="click"
          placement="bottom"
          overlay={
            <Popover id="user-profile-popover">
              <PopoverBody style={{ background: 'primary' }}>
                <UserMenuActions />
              </PopoverBody>
            </Popover>
          }
        >
          <Image roundedCircle height="32px" src={avatar} alt="profile-photo" />
        </OverlayTrigger>
      </div>
      <div className="user-profile d-lg-none">
        <hr className="my-1" />
        <UserMenuActions />
      </div>
    </>
  );
}
