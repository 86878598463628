import { FaArrowRight, FaCircle } from 'react-icons/fa';
import './index.scss';

export function HorizontalConnectionArrow(props) {
  const color = props.color || 'teal';
  return (
    <div {...props} className={`${props.className || ''} horizontal-connection-arrow`}>
      <div className="arrow-container">
        <div className="connection-line" style={{ height: 4, background: color }} />
        <FaCircle className="arrow-start" size={12} color={color} />
        <FaArrowRight className="arrow-end" scale={24} color={color} />
      </div>
    </div>
  );
}
