import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { EditTenantContext } from '../..';

/** @param {{tenant: Tenant}} props */
export function EditTenantFormRetentionPeriod(props) {
  const [tenant, setTenant] = useState(props.tenant);
  const updateParent = useContext(EditTenantContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [eventRetentionPeriod, setEventRetentionPeriod] = useState(
    tenant.eventRetentionPeriod || 0
  );
  const [logRetentionPeriod, setLogRetentionPeriod] = useState(tenant.logRetentionPeriod || 0);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);

      const request = api.sai.v1.tenant.$tenantId(tenant.tenantId)['retention-period'].$patch({
        data: {
          eventRetentionPeriod,
          logRetentionPeriod,
        },
      });
      await request.process();
      iziToast.info({
        title: `Tenant updated successfully`,
      });
      setLoading(false);
      setTenant(request.result.result);
      updateParent();
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Body>
        <InputBox
          label="Event Retention Period in Days"
          placeholder="30"
          required
          type="number"
          value={eventRetentionPeriod}
          onChange={setEventRetentionPeriod}
          min={5}
          max={365}
          step={5}
        />
        <InputBox
          label="Log Retention Period in Days"
          placeholder="10"
          required
          type="number"
          value={logRetentionPeriod}
          onChange={setLogRetentionPeriod}
          step={5}
          min={5}
          max={365}
        />

        {error && (
          <>
            <hr className="my-2" />
            <Alert variant="danger" className="my-0 py-2">
              <FaExclamation /> {error + ''}
            </Alert>
          </>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button type="submit" variant="primary" style={{ width: 200 }} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Save Retention Period'}
        </Button>
      </Card.Footer>
    </Form>
  );
}
