import { useContext } from 'react';
import { MdLogin } from 'react-icons/md';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { AiModelContext } from '../@components/AiModelViewLayout';
import { InputSourcesView } from './@components/InputSourcesView';

export function AiModelInputSources() {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item } = useContext(AiModelContext);

  return (
    <>
      <BreadcrumbLayout.Header
        icon={<MdLogin />}
        title="Input Sources"
        subtitle={
          <>
            Manage input output sources of <b>{item.name}</b>
          </>
        }
      />
      <BreadcrumbLayout.Body>
        <InputSourcesView label="Video" type={'VIDEO'} value="videoInputs" />
        <hr />
        <InputSourcesView label="Audio" type={'AUDIO'} value="audioInputs" />
        <hr />
        <InputSourcesView label="Sensor" type={'SENSOR'} value="sensorInputs" />
        <hr />
        <InputSourcesView label="Model" type={'MODEL'} value="modelInputs" />
      </BreadcrumbLayout.Body>
    </>
  );
}
