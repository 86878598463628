import { ListGroup, Modal } from 'react-bootstrap';

/** @param {{value: string, onSelect: (id: string) => any}} props */
export function SelectDeploymentType(props) {
  const { items, value, onSelect } = props;

  return (
    <Modal.Body>
      <ListGroup>
        {items.map((item, key) => (
          <ListGroup.Item action key={key} onClick={() => onSelect(key)} active={value === key}>
            <div className="fw-bold">{item.title}</div>
            <div className="small">{item.details}</div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Modal.Body>
  );
}
