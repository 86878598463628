import { fetchAllProducts } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { USER_ACCESS_FIRMWARE_CREATE } from '@/web/common/AccessRights';
import { TabsNavigation } from '@/web/common/TabsNavigation';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaFirstdraft, FaHandshakeAltSlash, FaList, FaPlus, FaRocket } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AdminDonutGroup } from '../../@components/AdminDonutGroup';
import { FirmwareListView } from './@components/FirmwareListView';

export function FirmwareListViewWithTenant() {
  useDispatchLoader(fetchAllProducts);

  return (
    <SidebarPlotLayout sidebar={<AdminDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Firmwares">
        <USER_ACCESS_FIRMWARE_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> New Firmware
          </Button>
        </USER_ACCESS_FIRMWARE_CREATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <TabsNavigation
          retain
          tabs={[
            {
              key: 'all',
              title: (
                <div>
                  <FaList /> All
                </div>
              ),
              content: <FirmwareListView />,
            },
            {
              key: 'draft',
              title: (
                <div>
                  <FaFirstdraft /> Draft
                </div>
              ),
              content: <FirmwareListView status={'DRAFT'} />,
            },
            {
              key: 'published',
              title: (
                <div>
                  <FaRocket /> Published
                </div>
              ),
              content: <FirmwareListView status={'PUBLISHED'} />,
            },
            {
              key: 'discontinued',
              title: (
                <div>
                  <FaHandshakeAltSlash /> Discontinued
                </div>
              ),
              content: <FirmwareListView status={'DISCONTINUED'} />,
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
