import storage from 'redux-persist/lib/storage';

/** @type {import('redux-persist').PersistConfig} */
export const cachePersistConfig = {
  key: 'cache',
  version: 1,
  storage,
  whitelist: [
    // add state items you want to persist in local storage
    'roles',
    'accessRights',
    'accessRightExpiresAt',
    'tenants',
    'products',
    'aiModels',
    'accelerators',
    'groups',
    'devicePools',
    'labels',
    'labelCategories',
    'triggers',
  ],
  // migrate: async (state, version) => {
  //   switch (version) {
  //     default:
  //       return state;
  //   }
  // },
};
