import { MdSensors } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProduct } from '@/state/cache';
import { OutputCardLayout } from '../../OutputCardLayout';

/** @param {{mapping: InputMapModel}} props */
export function ProductSensorOutputCard(props) {
  const { mapping } = props;
  const product = useSelector(getProduct(mapping.sourceId));
  const output = product?.sensorOutputs?.find((item) => item.code === mapping?.mappedOutputCode);

  return (
    <OutputCardLayout {...props} icon={<MdSensors />} title={output?.name}>
      {product && (
        <Link title={product.description} to={`/super-admin/products/${product.productId}`}>
          {product.name}
        </Link>
      )}
    </OutputCardLayout>
  );
}
