import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { InputSourceModalForm } from '../InputSourceModalForm';

export function AddInputSourceButton(props) {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <Button
        onClick={() => setShowForm(true)}
        variant="outline-secondary"
        style={{ height: '100%', width: '100%' }}
        className="d-flex flex-column gap-3 align-items-center justify-content-center"
      >
        <FaPlus size={64} />
        <div className="fw-bold">Add New</div>
      </Button>

      {showForm && <InputSourceModalForm {...props} onDone={() => setShowForm(false)} />}
    </>
  );
}
