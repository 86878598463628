import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { createContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { EditTenantFormPlaceholder } from './@components/EditTenantFormPlaceholder';
import { EditTenantTabs } from './@components/EditTenantTabs';

export const EditTenantContext = createContext();

export function EditTenant() {
  const { tenantId } = useParams();
  const [id, setId] = useState(Date.now());

  const updateParent = () => {
    setId(Date.now());
  };

  const bearerToken = useSelector(getBearerToken);
  const [tenant, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1.tenant.$tenantId(tenantId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, tenantId, id]);

  if (loading) {
    return (
      <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
        <EditTenantFormPlaceholder />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title className="my-1">
            Edit <b>{tenant.name}</b> / <code>{tenant.tenantCode}</code>
          </Card.Title>
        </Card.Header>
        <EditTenantContext.Provider value={updateParent}>
          <EditTenantTabs tenant={tenant} />
        </EditTenantContext.Provider>
      </Card>
    </BreadcrumbLayout>
  );
}
