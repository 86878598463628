import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { DashboardDonutGroup } from '../@components/DashboardDonutGroup';
import { EventListView } from './@components/EventListView';

export function EventListViewWithTenant() {
  return (
    <SidebarPlotLayout sidebar={<DashboardDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Events" />
      <EventListView />
    </SidebarPlotLayout>
  );
}
