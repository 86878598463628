import { useContext } from 'react';
import { FaCamera, FaCameraRetro, FaMicrophone } from 'react-icons/fa';
import { MdSensors } from 'react-icons/md';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { ProductViewContext } from '../@components/ProductViewLayout';
import { OutputSourcesView } from './@components/OutputSourcesView';

export function ViewProductPageOutputs() {
  /** @type {{product: Product, updateProduct: () => any}} */
  const { product } = useContext(ProductViewContext);
  return (
    <>
      <BreadcrumbLayout.Header
        icon={<FaCamera />}
        title={'Imager / Sensor Inputs'}
        subtitle={
          <>
            Manage audio, video, or sensor sources of <b>{product.name}</b>
          </>
        }
      />
      <BreadcrumbLayout.Body>
        <OutputSourcesView label="Video" value="videoOutputs" icon={<FaCameraRetro />} />
        <OutputSourcesView label="Audio" value="audioOutputs" icon={<FaMicrophone />} />
        <OutputSourcesView label="Sensor" value="sensorOutputs" icon={<MdSensors />} />
      </BreadcrumbLayout.Body>
    </>
  );
}
