import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { MdSensors } from 'react-icons/md';

export function DeviceSensorsView() {
  return (
    <>
      <BreadcrumbLayout.Header title="Device Sensors" icon={<MdSensors />} />
      <BreadcrumbLayout.Body>
        <div>View device sensors</div>
      </BreadcrumbLayout.Body>
    </>
  );
}
