import api from '@/api';
import { displayError, displaySuccess } from '@/utils/toaster';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { AiModelContext } from '../../../@components/AiModelViewLayout';
import { AudioInputFields } from './AudioInputFields';
import { ModelInputFields } from './ModelInputFields';
import { SensorInputFields } from './SensorInputFields';
import { VideoInputFields } from './VideoInputFields';

/** @param {{value: 'videoInputs'|'audioInputs'|'sensorInputs'|'modelInputs', item: VideoInput & AudioInput & SensorInput & ModelInput}} props */
export function InputSourceModalForm(props) {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item: model, updateItem } = useContext(AiModelContext);
  const { label, value, item, onDone } = props;

  const editing = Boolean(item);
  const [loading, setLoading] = useState(false);
  const [newItem, setNewItem] = useState(item);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);

      const inputs = editing
        ? model[value].map((el) => (el.code === item.code ? newItem : el))
        : [...model[value], newItem];
      inputs.sort((a, b) => a.name.localeCompare(b.name));

      const request = api.sai.v1['ai-model'].$modelId(model.modelId).$put({
        data: {
          [value]: inputs,
        },
      });
      await request.process();

      setLoading(false);
      onDone();
      displaySuccess(`Input source ${editing ? 'updated' : 'added'}`);
      updateItem();
    } catch (err) {
      console.error(err);
      setLoading(false);
      displayError(err);
    }
    return false;
  };

  return (
    <Modal centered size="xl" show={true} onHide={onDone}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {editing ? 'Edit' : 'Add'} {label || ''} Source
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto" style={{ maxHeight: 'calc(100vh - 250px)' }}>
          <InputSourceFields value={value} editing={editing} item={newItem} onChange={setNewItem} />
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" variant="secondary" style={{ width: 132 }} onClick={onDone}>
            Cancel
          </Button>
          <Button type="submit" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : editing ? 'Save' : 'Add'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

/** @param {{editing: boolean, onChange: () => any, value: 'videoInputs'|'audioInputs'|'sensorInputs'|'modelInputs', item: VideoInput & AudioInput & SensorInput & ModelInput}} props */
export function InputSourceFields(props) {
  const { value, item, editing, onChange } = props;
  switch (value) {
    case 'videoInputs':
      return <VideoInputFields item={item} onChange={onChange} editing={editing} />;
    case 'audioInputs':
      return <AudioInputFields item={item} onChange={onChange} editing={editing} />;
    case 'sensorInputs':
      return <SensorInputFields item={item} onChange={onChange} editing={editing} />;
    case 'modelInputs':
      return <ModelInputFields item={item} onChange={onChange} editing={editing} />;
    default:
      return null;
  }
}
