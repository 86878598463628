import api from '@/api';
import { getTenantId } from '@/state/auth';
import { getGroupsByTenantAndProduct } from '@/state/cache';
import { displayError, displaySuccess } from '@/utils/toaster';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DeviceViewContext } from '../DeviceViewLayout';

export function AssignGroupButton() {
  /** @type {{device: DeviceDetail}} */
  const { device, updateDevice } = useContext(DeviceViewContext);
  const tenantId = useSelector(getTenantId);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState('');
  const groups = useSelector(getGroupsByTenantAndProduct(tenantId, device?.productId));

  /** @param {Event} e */
  const assignGroup = async (e) => {
    e.preventDefault();
    const request = api.sai.v1['deployment-group'].$groupId(groupId).device.$post({
      data: {
        deviceId: device.deviceId,
      },
    });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      displaySuccess('Success', `Group assign successful!`);
      updateDevice();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="warning"
        style={{ width: 150 }}
        onClick={() => setShowModal(true)}
      >
        Assign Group
      </Button>

      <Modal size={'md'} show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Group</Modal.Title>
        </Modal.Header>
        <Form onSubmit={assignGroup}>
          <Modal.Body>
            <SelectBox
              label="Group"
              placeholder="Select Group"
              value={groupId}
              onChange={setGroupId}
              options={groups}
              itemValue="groupId"
              itemText="name"
              required
            />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="primary"
              disabled={loading || !groups}
              style={{ width: 100 }}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Assign'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
