import api from '@/api';
import { getProductMap } from '@/state/cache';
import { getFirmwareTypeName } from '@/utils/products';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { FirmwareFileInput } from '../../../../@components/FirmwareFileInput';

/** @param {{item: FirmwareDetailsInfo}} props */
export function EditFirmwareForm(props) {
  const { item } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(item.name || '');
  const [version, setVersion] = useState(item.version || '');
  const { version: oldVersion } = item;
  const [type, setType] = useState(item.type || '');
  const [description, setDescription] = useState(item.description || '');
  const [productId, setProductId] = useState(item.productIds?.[0] || '');
  const [packageFile, setPackageFile] = useState({ id: item.packageFileId });

  const navigate = useNavigate();
  const productMap = useSelector(getProductMap);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.firmware.$firmwareId(item.firmwareId).$patch({
        data: {
          name,
          type,
          version: oldVersion !== version ? version : null,
          description,
          packageFileId: packageFile?.id,
          pictureUrl: 'https://picsum.photos/200/300', //random picture url for now
          productIds: [productId],
          fileContainerName: 'www.google.com', //temporary
          actualFileName: 'www.google.com', //temporary
          checksum: '', //temporary
          storedFileName: 'www.google.com', //temporary,
        },
      });
      await request.process();
      iziToast.info({
        title: `Firmware updated successfully`,
      });
      setLoading(false);
      navigate('./..');
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Body>
        <SelectBox
          label="Type"
          value={type}
          placeholder="Select type"
          onChange={setType}
          options={productMap[productId]?.firmwareComponents?.map((item) => ({
            name: getFirmwareTypeName(item),
            value: item,
          }))}
          itemText="name"
          itemValue="value"
          required
        />
        <SelectBox
          label="Product"
          placeholder="Select Product"
          value={productId}
          onChange={setProductId}
          options={Object.values(productMap || {})}
          itemValue="productId"
          itemText="name"
          required
        />
        <InputBox label="Name" placeholder="Enter name" value={name} onChange={setName} required />
        <InputBox
          label="Version"
          placeholder="Enter version"
          value={version}
          onChange={setVersion}
          required
        />
        <FirmwareFileInput
          packageDetails={item.packageDetails}
          value={packageFile}
          onChange={setPackageFile}
        />
        <InputBox
          label="Description"
          placeholder="Enter description"
          value={description}
          onChange={setDescription}
          style={{ height: '75px' }}
          textarea
        />

        {error && (
          <>
            <hr className="my-2" />
            <Alert variant="danger" className="my-0 py-2">
              <FaExclamation /> {error + ''}
            </Alert>
          </>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between align-items-center">
        <div />
        <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
        </Button>
      </Card.Footer>
    </Form>
  );
}
