import storage from 'redux-persist/lib/storage';

/** @type {import('redux-persist').PersistConfig} */
export const authPersistConfig = {
  key: 'auth',
  version: 1,
  storage,
  // migrate: async (state, version) => {
  //   switch (version) {
  //     default:
  //       return state;
  //   }
  // },
};
