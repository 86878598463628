import { donutCharColors } from '@/assets/colors/autocomplete';
import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchLabelCategoryDonutData } from '@/state/chart/fetchers/label-categories';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function LabelCategoriesDonut(props) {
  const { sidebar } = props;

  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchLabelCategoryDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.labelCategories?.total || 0,
    points: [
      ['data1', 'data2'],
      ['Labels', chartData.labelCategories?.total || 0],
    ],
  };
  return (
    <DashboardItem sidebar={sidebar} header={'Label Categories'} url={'/events/label-categories'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'Label Categories' }}
        legends={[{ name: 'Label Categories', className: 'red' }]}
        colors={[donutCharColors.RED]}
        data={data.points}
      />
    </DashboardItem>
  );
}
