import api from '@/api';
import { labelTypes, labelUnits, triggerThresholdInputTypes } from '@/assets/labels/autocomplete';
import { getLabelCategoryMap, getLabelsByTenant } from '@/state/cache';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

/** @param {{item: Label}} props */
export function EditLabelForm(props) {
  const { item } = props;
  const { tenantId } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const labels = useSelector(getLabelsByTenant(tenantId));
  const labelCategoriesMap = useSelector(getLabelCategoryMap);

  const [name, setName] = useState(item.name || '');
  const [code, setCode] = useState(item.code || '');
  const [unit, setUnit] = useState(item.unit || '');
  const [minValue, setMinValue] = useState(item.minValue || '');
  const [maxValue, setMaxValue] = useState(item.minValue || '');
  const [triggerThresholdInputType, setTriggerThresholdInputType] = useState(
    item.triggerThresholdInputType || ''
  );
  const [inputStepSize, setInputStepSize] = useState(item.inputStepSize || '');
  const [aliasedLabelId, setAliasedLabelId] = useState(item.aliasedLabelId || '');
  const [source, setSource] = useState(item.source || '');
  const [labelType, setLabelType] = useState(item.labelType || '');
  const [description, setDescription] = useState(item.description || '');
  const navigate = useNavigate();

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['label'].$labelId(item.labelId).$patch({
        data: {
          name,
          description,
          code,
          unit,
          maxValue,
          minValue,
          source,
          triggerThresholdInputType,
          inputStepSize,
          labelType,
          aliasedLabelId,
          status: item.status,
        },
      });
      await request.process();
      iziToast.info({
        title: `Label updated successfully`,
      });
      navigate('./..');
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Body>
        <InputBox label="Name" placeholder="Enter name" value={name} onChange={setName} required />
        <InputBox label="Code" placeholder="Enter code" value={code} onChange={setCode} required />
        <SelectBox
          label="Type"
          value={labelType}
          onChange={setLabelType}
          options={Object.entries(labelTypes || {})}
          itemValue={0}
          itemText={1}
          required
        />
        {labelType === 'ALIAS' && (
          <SelectBox
            label="Alias Label"
            placeholder="Select Label"
            value={aliasedLabelId}
            onChange={setAliasedLabelId}
            options={Object.values(labels || {}).filter((item) => item.labelType === 'PRIMARY')}
            itemValue="labelId"
            itemText="name"
            required
          />
        )}
        <SelectBox
          label="Label Category"
          value={source}
          onChange={setSource}
          options={Object.values(labelCategoriesMap || {})}
          itemValue="id"
          itemText="name"
          required
        />
        <InputBox
          label="Min Value"
          placeholder="Enter min value"
          value={minValue}
          onChange={setMinValue}
          type="number"
          required
        />
        <InputBox
          label="Max Value"
          placeholder="Enter max value"
          value={maxValue}
          onChange={setMaxValue}
          type="number"
          required
        />
        <InputBox
          label="Input Step Size"
          placeholder="Enter step size"
          value={inputStepSize}
          onChange={setInputStepSize}
          type="number"
          required
        />
        <SelectBox
          label="Trigger Threshold Input Type"
          value={triggerThresholdInputType}
          onChange={setTriggerThresholdInputType}
          options={Object.entries(triggerThresholdInputTypes || {})}
          itemValue={0}
          itemText={1}
          required
        />
        <SelectBox
          label="Unit"
          value={unit}
          onChange={setUnit}
          options={Object.entries(labelUnits || {})}
          itemValue={0}
          itemText={1}
          required
        />
        <InputBox
          label="Description"
          placeholder="Enter description"
          value={description}
          onChange={setDescription}
          style={{ height: '75px' }}
          textarea
        />
        {error && (
          <>
            <hr className="my-2" />
            <Alert variant="danger" className="my-0 py-2">
              <FaExclamation /> {error + ''}
            </Alert>
          </>
        )}
      </Card.Body>
      <Card.Footer className="d-flex align-items-center justify-content-end">
        <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
        </Button>
      </Card.Footer>
    </Form>
  );
}
