import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useNavigate } from 'react-router';

/** @param {{item: LabelCategory}} props */
export function EditLabelCategoryForm(props) {
  const { item } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(item.name || '');
  const [description, setDescription] = useState(item.description || '');

  const navigate = useNavigate();

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['label-category'].$id(item.id).$patch({
        data: {
          name,
          description,
        },
      });
      await request.process();
      iziToast.success({
        title: `Label Category updated successfully`,
      });
      navigate('./..');
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Body>
        <InputBox label="Name" placeholder="Enter name" value={name} onChange={setName} required />
        <InputBox
          label="Description"
          placeholder="Enter description"
          value={description}
          onChange={setDescription}
          style={{ height: '75px' }}
          textarea
        />
        {error && (
          <>
            <hr className="my-2" />
            <Alert variant="danger" className="my-0 py-2">
              <FaExclamation /> {error + ''}
            </Alert>
          </>
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end align-items-center">
        <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
        </Button>
      </Card.Footer>
    </Form>
  );
}
