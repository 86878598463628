import {
  formatMilliSecondsToSec,
  formatMilliTimeToMinSec,
  formatSecondsToMinSec,
  getAIModelTitleColor,
} from '@/utils/events';
import { getPlayerSVGIcons } from '@/utils/events/svgs';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { CgSpinner } from 'react-icons/cg';
import { HiDownload } from 'react-icons/hi';
import { BoundingBox } from '../BoundingBox';
import '../../@styles/style.scss';
import '../../@styles/_custom_video.scss';

let lastBoundingBoxTime = Date.now();
let boundingBoxLabels = [];

//TODO: Will Update to functional component later
export class CustomVideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urls: null,
      showImage: true,
      currentVideoId: 0,
      totalEventTimeInSec: 0,
      videoElements: [],
      firstVideoStartTime: 0.01,
      lastVideoEndTime: 0,
      isUnableToShowVideo: false,
      errorMsg: 'No Video Found',
      isFullscreen: false,
      incrementNumber: 0,
      currentPlayingTime: 0,
      currentPlayingState: true,
      isDownloading: false,
    };
    this.renderTimerId = null;
    this.isEventTimeout = false;
    this.isVideoPaused = true;
    this.videoControlsElem = null;
    this.isMuted = false;
    this.currentVideoId = 0;
    this.playLock = false; // Prevents errors when the asynchronous play call isn't resolved yet, but you try to pause it.
    this.isPlaying = false;
    this.videoElements = [];
    this.boundingBoxCanvas = null;
    this.boundingBoxContext = null;
    this.videoData = [];
    this.videoCurrentTime = 0;
  }

  componentDidMount = async () => {
    try {
      const { id, videoData, eventStartTimestamp, eventEndTimestamp } = this.props;

      this.videoData = videoData;

      // getting the videos length
      const videosLength = videoData.length;
      // picking the first video
      const videoOne = videoData[0];

      // checking if video one start and end time same, then add 1 sec
      // requirements from camera team
      if (videoOne.fromTimestamp === videoOne.toTimestamp) {
        videoOne.toTimestamp = videoOne.toTimestamp + 1000;
      }

      // taking the first video start time
      const recordingInitialTimeForVideoSide = videoOne.fromTimestamp;
      // taking the last video end time
      const recordingEndingTimeForVideoSide = videoData[videosLength - 1].toTimestamp;
      // taking the first video duration by start and end time in sec
      // const firstVideoDuration = formatMilliSecondsToSec(videoOne.toTimestamp - videoOne.fromTimestamp);
      // first video to last video recording duration in sec
      let firstToLastVideoDuration = formatMilliSecondsToSec(
        recordingEndingTimeForVideoSide - recordingInitialTimeForVideoSide
      );
      // Event duration by start and end time of an event
      const eventTimeDifference = formatMilliSecondsToSec(eventEndTimestamp - eventStartTimestamp);

      // checking if event start first then after some period of time video start
      // calculate the diff between event start time and video start time
      // remove the calculated time from total video duration
      if (eventStartTimestamp > recordingInitialTimeForVideoSide) {
        let diff = eventStartTimestamp - recordingInitialTimeForVideoSide;
        firstToLastVideoDuration = firstToLastVideoDuration - formatMilliSecondsToSec(diff);
      }

      // checking if events ends before last video end
      // calculate the diff between event end time and video end time
      // remove the calculated time from total video duration
      if (recordingEndingTimeForVideoSide > eventEndTimestamp) {
        let diff = recordingEndingTimeForVideoSide - eventEndTimestamp;
        firstToLastVideoDuration = firstToLastVideoDuration - formatMilliSecondsToSec(diff);
      }

      // calculating total event time in sec
      // If eventTime difference is greater than all video time duration
      // In that case we pick the video duration
      // Otherwise we pick the event duration
      // this duration will be used in progress bar, video total duration setting
      // Why we calculate: Some event duration is 121s but video duration[all] is only 2s.
      const calculatedTotalEventTimeInSec =
        eventTimeDifference > firstToLastVideoDuration
          ? firstToLastVideoDuration
          : eventTimeDifference;

      // calculating first video start time for video player
      // if first video duration is greater than probable player start time then we will pick the playerVideoStartTime, other wise will take 0
      // Alternate: we can start playing video 2, but if one video available then it'll create issue, for that we need to check some condition
      // that's why we ignore the checking and playing video 1 from 0 second
      let playerVideoStartTime;
      let eventAndVideoStartTimeDifference = videoOne?.fromTimestamp - eventStartTimestamp;
      if (videoOne?.fromTimestamp < eventStartTimestamp) {
        playerVideoStartTime = formatMilliSecondsToSec(Math.abs(eventAndVideoStartTimeDifference));
      } else {
        playerVideoStartTime = 0.01;
      }

      // format the final total video duration por progress bar
      const totalFormattedEventTime = formatMilliTimeToMinSec(calculatedTotalEventTimeInSec * 1000);

      this.setState({
        firstVideoStartTime: playerVideoStartTime,
        totalEventTimeInSec: Math.floor(calculatedTotalEventTimeInSec),
        isUnableToShowVideo: Math.floor(calculatedTotalEventTimeInSec) <= 0,
      });

      this.fetchDomElements(id);
      // get all the video elements
      // and push them into state array
      // eslint-disable-next-line array-callback-return
      await videoData.map((video, index) => {
        let vid = document.getElementById(`video-${id}-${index}`);
        this.videoElements.push(vid);
        this.setState({ videoElements: this.videoElements });
      });

      this.createVideoCanvas();
      //setting some controls data
      // const { totalEventTimeInSec } = this.state;
      // this.seekElem.setAttribute("max", totalEventTimeInSec);
      // this.progressBarElem.setAttribute("max", totalEventTimeInSec);

      this.videoTotalDurationElem.innerText = `${totalFormattedEventTime.minutes}:${totalFormattedEventTime.seconds}`;
      this.videoTotalDurationElem.setAttribute(
        'datetime',
        `${totalFormattedEventTime.minutes}m ${totalFormattedEventTime.seconds}s`
      );
      this.initializeVideoData();
      this.boundingBoxCanvasElem();
    } catch (e) {}
  };

  boundingBoxCanvasElem = () => {
    const { id } = this.props;
    let canvas = document.getElementById(`canvasTwo-${id}`);
    this.boundingBoxCanvas = canvas;
    this.boundingBoxContext = canvas.getContext('2d');
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.commonVolume !== this.props.commonVolume) {
      this.setAllVideoVolume(this.props.commonVolume);
    }

    //Siblings change trigger  => from VideoControls
    if (this.props.id === 1) {
      this.props.playOne_ref.current = () => {
        this.onPlayPause();
      };
    }
    //Siblings change trigger  => from VideoControls
    if (this.props.id === 2) {
      this.props.playTwo_ref.current = () => {
        this.onPlayPause();
      };
    }

    // Seek Execute: if seek value smaller than totalEventTimeInSec
    if (
      prevProps.commonSeek !== this.props.commonSeek &&
      this.props.commonSeek < this.state.totalEventTimeInSec
    ) {
      this.positionChange(null, this.props.commonSeek);
    }
  }

  initializeVideoData = () => {
    const { firstVideoStartTime } = this.state;

    this.videoElements.forEach((video) => {
      video.addEventListener('play', () => {
        video.addEventListener('loadeddata', this.onLoadedData(video));
        this.renderTimerId = setInterval(() => {
          this.drawFrame(video);
          let totalCurrentTime = 0;
          //-> if video moves next one, currentVideoId is more 0, currentVideoId starts from 0
          for (let i = 0; i < this.currentVideoId; ++i) {
            // get the video duration
            let videoDuration = this.videoElements[i].duration;
            // calculating elapsed video time
            // for the first video need to subtract player playing start time from the total video duration
            // otherwise take the full duration
            totalCurrentTime += i === 0 ? videoDuration - firstVideoStartTime : videoDuration;
          }
          // fetching the current playing video current time
          let videoCurrentTime = this.videoElements[this.currentVideoId].currentTime;
          this.videoCurrentTime = videoCurrentTime;
          this.setMessage();
          this.setState({ incrementNumber: videoCurrentTime });
          // for the first video need to subtract player playing start time from the total video duration
          // otherwise take the full duration
          totalCurrentTime +=
            this.currentVideoId === 0 ? videoCurrentTime - firstVideoStartTime : videoCurrentTime;
          let calculateEventCurrentTime = totalCurrentTime;
          const eventTime =
            calculateEventCurrentTime && calculateEventCurrentTime > 0
              ? calculateEventCurrentTime
              : 0;

          //updating the seek and progressbar
          // this.seekElem.value = eventTime;
          // this.progressBarElem.value = eventTime;

          // sending value to common controls
          // Only one time sending the current value
          this.setState({ currentPlayingTime: eventTime });

          const time = formatSecondsToMinSec(eventTime);
          this.timeElapsedElem.innerText = `${time.minutes}:${time.seconds}`;
          this.timeElapsedElem.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);

          if (eventTime > this.state.totalEventTimeInSec) {
            this.videoPauseStatusUpdate(true);
            this.sendLastPlayingStatusToParent(false);
            this.videoElements[this.currentVideoId].pause();
            this.stopTimer();
            this.playbackIconsElem.forEach((icon) => icon.classList.toggle('hidden'));
            this.playPauseElem.setAttribute('data-title', 'Play');
            this.isPlaying = false;
            this.isEventTimeout = true;
          }
        }, 30);
      });
      video.addEventListener('ended', this.onVideoEnd);
      video.addEventListener('loadeddata', this.onLoadedData(video));
    });
  };

  updateCurrentId = (currentVideoId) => {
    this.setState({ currentVideoId: currentVideoId });
  };

  stopTimer = () => {
    clearInterval(this.renderTimerId);
  };

  onVideoEnd = () => {
    if (this.currentVideoId < this.videoElements.length) {
      ++this.currentVideoId;
      this.updateCurrentId(this.currentVideoId);
    } else {
    }
    this.stopTimer();
    if (this.currentVideoId === this.videoElements.length) {
      // in case of last video, make sure to load 1st video so that it would start from the 1st frame
      this.isVideoPaused = true;
      this.isPlaying = false;
      this.videoElements[0].load();
      this.currentVideoId = 0;
      this.updateCurrentId(0);
    } else {
      this.videoElements[this.currentVideoId].play();
    }
  };

  // send data to VideoDetails
  sendLastPlayingStatusToParent = (status) => {
    if (this.props.acknowledgementPermission) {
      this.props.setCurrentPlayingStatus(status);
    }
  };

  // Loading first video initiallly
  onLoadedData = (video) => {
    const { currentVideoId } = this.state;
    const { id } = this.props;
    return () => {
      this.videoElements[0].removeEventListener('loadeddata', this.onLoadedData);
      // Firefox Initial Frame load temporary solution
      // Poster or first frame showing currectly on chrome but not in firefox
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        // eslint-disable-next-line no-unused-expressions
        this.videoElements[currentVideoId]?.play();
        // eslint-disable-next-line no-unused-expressions
        this.videoElements[currentVideoId]?.pause();
      }
      if (video.id === `video-${id}-0`) {
        this.drawFrame(this.videoElements[0]);
      }
      // Send totalEventTimeInSec to VideoDetails -> VideoControls
      this.sendTotalVideoTimeToParenT();
    };
  };

  sendTotalVideoTimeToParenT = () => {
    const { totalEventTimeInSec } = this.state;
    const { id } = this.props;
    this.props.setTotalRecordingTime({
      totalPlayableTime: totalEventTimeInSec,
      priorityImager: id,
    });
  };

  toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitFullscreenElement) {
      // Need this to support Safari
      document.webkitExitFullscreen();
      this.videoElements[this.currentVideoId].style.height = '100%';
      this.videoElements[this.currentVideoId].style.width = '100%';
      this.videoElements[this.currentVideoId].style.height = '100%';
      this.videoElements[this.currentVideoId].style.width = '100%';
    } else if (this.videoContainerElem.requestFullscreen) {
      this.videoContainerElem.requestFullscreen();
      this.videoElements[this.currentVideoId].style.height = '100%';
      this.videoElements[this.currentVideoId].style.width = '100%';
      this.videoElements[this.currentVideoId].style.height = '100%';
      this.videoElements[this.currentVideoId].style.width = '100%';
    } else {
      // Need this to support Safari
      this.videoElements[this.currentVideoId].style.height = '100%';
      this.videoElements[this.currentVideoId].style.width = '100%';
      this.videoElements[this.currentVideoId].style.height = '100%';
      this.videoElements[this.currentVideoId].style.width = '100%';
      this.videoContainerElem.webkitRequestFullscreen();
    }
  };

  // updateFullscreenButton changes the icon of the full screen button
  // and tooltip to reflect the current full screen state of the video
  updateFullscreenButton = () => {
    boundingBoxLabels = []; //clear bounding box data, (reset)
    this.fullscreenIconsElem.forEach((icon) => icon.classList.toggle('hidden'));
    if (document.fullscreenElement) {
      this.setState({ isFullscreen: true });
      this.props.setCurrentFullscreenImager(this.props.id);
      this.fullscreenButtonElem.setAttribute('data-title', 'Exit full screen');
    } else {
      this.props.setCurrentPlayingTime(this.state.currentPlayingTime);
      this.props.setCurrentFullscreenImager(null);
      this.setState({ isFullscreen: false });
      this.fullscreenButtonElem.setAttribute('data-title', 'Full screen');
    }
  };

  // hideControls hides the video controls when not in use
  // if the video is paused, the controls must remain visible
  hideControls = () => {
    // remove this condition for single controls for multiple video player
    // if (videoElements[currentVideoId]?.paused) {
    //   return;
    // }
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    )
      return;
    this.videoControlsElem.classList.add('hide');
  };

  updateVolumeIcon = () => {
    const { currentVideoId } = this.state;
    this.volumeIconsElem.forEach((icon) => {
      icon.classList.add('hidden');
    });

    this.volumeButtonElem.setAttribute('data-title', 'Mute');
    if (
      this.videoElements[currentVideoId]?.muted ||
      (this.videoElements[currentVideoId]?.volume === 0 && this.volumeElem?.dataset?.volume !== 0)
    ) {
      if (this.videoElements[currentVideoId].volume === 0) {
        this.videoElements[currentVideoId].dataset.volume = 1;
      }
      this.volumeMuteElem.classList.remove('hidden');
      this.volumeButtonElem.setAttribute('data-title', 'Unmute');
    } else if (
      this.videoElements[currentVideoId].volume > 0 &&
      this.videoElements[currentVideoId].volume <= 0.5
    ) {
      this.volumeLowElem.classList.remove('hidden');
    } else {
      this.volumeHighElem.classList.remove('hidden');
    }
  };

  onPlayPause = () => {
    if (this.isEventTimeout) {
      this.positionChange(null, 0);
      this.isEventTimeout = false;
    }
    this.playbackIconsElem.forEach((icon) => icon.classList.toggle('hidden'));
    this.stopTimer();

    if (this.isPlaying) {
      this.setState({ currentPlayingState: false });
      this.videoPauseStatusUpdate(true);
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.videoElements[this.currentVideoId]?.pause();
      }, 0);
      this.playPauseElem.setAttribute('data-title', 'Play');
      this.isPlaying = false;
    } else {
      this.setState({ currentPlayingState: true });
      this.videoPauseStatusUpdate(false);
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.videoElements[this.currentVideoId]?.play();
      }, 0);
      this.playPauseElem.setAttribute('data-title', 'Pause');
      this.isPlaying = true;
    }
    // this.isPlaying = !this.isPlaying;
  };

  // download multiple files if available
  // currently no other way, in future we can modify if business logic change
  downloadVideo = () => {
    const { isDownloading } = this.state;
    if (isDownloading) return;
    const { videoData } = this.props;
    videoData.map(async (item, index) => {
      this.setState({ isDownloading: true });
      await this.download(item.downloadUrl);
    });
  };

  download = async (src) => {
    const { tenantName, eventReportedTime } = this.props;
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      let reader = new FileReader();
      reader.onloadend = (event) => {
        let byteCharacters = atob(reader.result.slice(reader.result.indexOf(',') + 1));
        let byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: 'video/mp4' });
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = `${tenantName} Event ${eventReportedTime}`;
        a.click();
        document.body.removeChild(a);
      };
      reader.readAsDataURL(xhr.response);
      this.setState({ isDownloading: false });
    };
    xhr.open('GET', `${src}`);
    xhr.send();
  };

  // showControls displays the video controls
  showControls = () => {
    this.videoControlsElem.classList.remove('hide');
  };

  isVideoPlaying = (video) =>
    !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

  // Seeking Position
  // first we find the changed position
  // If we have some carry(firstVideoStartTime), then we nned to add it with our input value
  // Why we have carry? -> documentation at the top ↑↑
  positionChange = (event, directSeekValue) => {
    if (this.isEventTimeout) {
      this.isEventTimeout = false;
    }
    const { currentVideoId } = this.state;
    let skipTo;
    // re-play option
    // for replay we change the position value
    if (directSeekValue === 0 || directSeekValue) {
      skipTo = directSeekValue > 0 ? directSeekValue : 0;
    } else {
      skipTo = event.target.dataset.seek ? event.target.dataset.seek : event.target.value;
    }

    const calculatedSkipToValue = parseInt(skipTo) + this.state.firstVideoStartTime;

    if (!this.playLock) {
      let newTime = calculatedSkipToValue;
      let newVideo = 0;

      // if our calculated value - current video duration, greater than zero
      // we need to subtract the video duration value from calculated value
      // then we need to move next video
      while (newTime - this.videoElements[newVideo]?.duration > 0) {
        newTime -= this.videoElements[newVideo]?.duration;
        ++newVideo;
      }

      if (!this.videoElements[currentVideoId]) return;

      this.stopTimer();
      if (this.isVideoPlaying(this.videoElements[currentVideoId])) {
        this.videoElements[currentVideoId].pause();
      }
      this.videoElements[currentVideoId].currentTime = 0;
      this.currentVideoId = newVideo;
      this.updateCurrentId(newVideo);
      this.videoElements[newVideo].currentTime = newTime;
      this.playLock = true;

      setTimeout(() => {
        this.videoElements[newVideo].play().then(() => {
          this.playLock = false;
        });
      }, 0);
    }

    // update progressbar, seek value
    // this.progressBarElem.value = calculatedSkipToValue;
    // this.seekElem.value = calculatedSkipToValue;

    // updating elapsed time value
    const time = formatSecondsToMinSec(calculatedSkipToValue);
    this.timeElapsedElem.innerText = `${time.minutes}:${time.seconds}`;
    this.timeElapsedElem.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);

    this.videoPauseStatusUpdate(false);
    // only change icon when we seek independently
    if (!this.isPlaying && directSeekValue !== 0) {
      // if video was in pause state, after changing position video'll start playing
      // in that case we'll change the icon and attributes
      this.playbackIconsElem.forEach((icon) => icon.classList.toggle('hidden'));
      this.isPlaying = true;
      this.playPauseElem.setAttribute('data-title', 'Pause');
    }
  };

  setAllVideoVolume = (volume) => {
    this.videoElements.forEach((video) => {
      video.volume = volume;
    });
    this.updateVolumeIcon();
  };

  volumeButtonSettings = () => {
    this.volumeIconsElem.forEach((icon) => {
      icon.classList.add('hidden');
    });
    this.isMuted = !this.isMuted;
    if (this.isMuted) {
      this.setAllVideoVolume(0);
      this.volumeMuteElem.classList.remove('hidden');
      this.volumeButtonElem.setAttribute('data-title', 'Mute');
    } else {
      this.volumeHighElem.classList.remove('hidden');
      this.setAllVideoVolume(this.volumeElem.value);
      this.volumeButtonElem.setAttribute('data-title', 'Unmute');
    }
    this.updateVolumeIcon();
  };

  // picking all the dom elements
  fetchDomElements = (id) => {
    this.videoControlsElem = document.getElementById(`video-controls-${id}`);
    this.playPauseElem = document.getElementById(`play-${id}`);
    this.playbackIconsElem = document.querySelectorAll(`#playback-icons-${id} use`);
    this.volumeButtonElem = document.getElementById(`volume-button-${id}`);
    this.volumeIconsElem = document.querySelectorAll(`#volume-button-${id} use`);
    this.volumeMuteElem = document.querySelector(`use[href="#volume-mute-${id}"]`);
    this.volumeLowElem = document.querySelector(`use[href="#volume-low-${id}"]`);
    this.volumeHighElem = document.querySelector(`use[href="#volume-high-${id}"]`);
    this.volumeElem = document.getElementById(`volume-${id}`);
    this.timeElapsedElem = document.getElementById(`time-elapsed-${id}`);
    this.videoTotalDurationElem = document.getElementById(`total-duration-${id}`);
    this.progressBarElem = document.getElementById(`progress-bar-${id}`);
    this.seekElem = document.getElementById(`seek-${id}`);
    this.seekTooltipElem = document.getElementById(`seek-tooltip-${id}`);
    this.fullscreenButtonElem = document.getElementById(`fullscreen-button-${id}`);
    this.videoContainerElem = document.getElementById(`video-container-${id}`);
    this.fullscreenIconsElem = this.fullscreenButtonElem.querySelectorAll('use');
    this.buttonDownloadElem = document.getElementById(`download-${id}`);
    this.playbackAnimation = document.getElementById(`playback-animation-${id}`);
    // use this canvas for bounding box showing
    this.canvasTopScreen = document.getElementById(`canvasTwo-${id}`);
    this.handleEventListener();
  };

  handleEventListener = () => {
    this.fullscreenButtonElem.addEventListener('click', this.toggleFullScreen);
    this.videoContainerElem.addEventListener('fullscreenchange', this.updateFullscreenButton);
    this.buttonDownloadElem.addEventListener('click', this.downloadVideo);
    this.canvasTopScreen.addEventListener('mouseenter', this.showControls);
    this.videoControlsElem.addEventListener('mouseleave', this.hideControls);
    // TODO:: Video Click Play Pause, will handle later
    // this.canvasTopScreen.addEventListener("click", this.onPlayPause);
    // this.canvasTopScreen.addEventListener("click", this.animatePlayback);
    this.playPauseElem.addEventListener('click', this.onPlayPause);
    this.volumeButtonElem.addEventListener('click', this.volumeButtonSettings);
    this.seekElem.addEventListener('mousemove', this.updateSeekTooltip);
    this.seekElem.addEventListener('input', this.positionChange);
    this.volumeElem.addEventListener('input', () => {
      this.setAllVideoVolume(this.volumeElem.value);
    });
  };

  // updateSeekTooltip uses the position of the mouse on the progress bar to
  // roughly work out what point in the video the user will skip to if
  // the progress bar is clicked at that point
  updateSeekTooltip = (event) => {
    const skipTo = Math.round(
      (event.offsetX / event.target.clientWidth) *
        parseInt(event.target.getAttribute('max') || 0, 10)
    );
    this.seekElem.setAttribute('data-seek', skipTo);
    const t = formatSecondsToMinSec(skipTo);
    this.seekTooltipElem.textContent = `${t.minutes}:${t.seconds}`;
    const rect = this.videoContainerElem.getBoundingClientRect();
    this.seekTooltipElem.style.left = `${event.pageX - rect.left}px`;
  };

  createVideoCanvas = () => {
    const { id } = this.props;
    this.screen = document.getElementById(`canvasOne-${id}`);
    this.ctx = this.screen.getContext('2d');
  };

  // canvas image draw
  drawFrame = (video) => {
    this.ctx.drawImage(video, 0, 0, 500, 350);
  };

  videoPauseStatusUpdate = (isPaused) => {
    this.isVideoPaused = isPaused;
  };

  stateBoundingBoxLabelDataUpdate = (label, confidence, color) => {
    // eslint-disable-next-line array-callback-return
    let newArr = boundingBoxLabels.filter((a) => a.label !== label);
    // update
    // eslint-disable-next-line no-unused-vars
    boundingBoxLabels = [...newArr, { label, confidence, color }];
  };

  setMessage = () => {
    const { boundingBoxData } = this.props;
    if (!boundingBoxData || boundingBoxData.length === 0) return;

    const { videoElements } = this.state;
    let videoElem = videoElements[this.currentVideoId];
    let currentPlayingVideoStartTimestamp = this.videoData[this.currentVideoId]?.fromTimestamp || 0;
    let theCanvas = this.boundingBoxCanvas;
    let context1 = this.boundingBoxContext;
    let currentVideoTime = currentPlayingVideoStartTimestamp + this.videoCurrentTime * 1000;

    let multipleBoundingBox = (messages) => {
      let tempMessage = messages[0];
      let unix_timestamp = tempMessage?.timestamp;
      let difference = (unix_timestamp - currentVideoTime) / 1000;

      if (difference >= 0 && difference < 0.15) {
        // context1.clearRect(0, 0, 500, 350);
        context1.clearRect(0, 0, theCanvas.width, theCanvas.height);
        this.dispatched = true;
        lastBoundingBoxTime = Date.now();
        for (let i = 0; i < messages.length; i++) {
          tempMessage = messages[i];
          // circle
          const {
            minx,
            miny,
            maxx,
            maxy,
            confidence,
            label,
            height = null,
            width = null,
          } = tempMessage;
          let scaleY = height ? height / 350 : videoElem.videoHeight / 350;
          let scaleX = width ? width / 500 : videoElem.videoWidth / 500;
          context1.beginPath();
          context1.rect(
            minx / scaleX,
            miny / scaleY,
            maxx / scaleX - minx / scaleX,
            maxy / scaleY - miny / scaleY
          );
          context1.lineWidth = 1;
          let strokeColor = getAIModelTitleColor(label);
          context1.strokeStyle = strokeColor;
          context1.stroke();
          if (this.state.isFullscreen) {
            this.stateBoundingBoxLabelDataUpdate(label, `${confidence}%`, strokeColor);
          } else {
            context1.fillStyle = 'white';
            context1.font = '10px sans';
            context1.fillText(`${label} ${confidence}`, minx / scaleX, miny / scaleY);
          }
        }
      }
    };

    let singleBoundingBox = (data) => {
      let unix_timestamp = data.ts;
      let difference = (unix_timestamp - currentVideoTime) / 1000;
      if (difference >= 0 && difference < 0.15) {
        context1.clearRect(0, 0, theCanvas.width, theCanvas.height);
        this.dispatched = true;
        lastBoundingBoxTime = Date.now();
        let { dimen, bbox, detections } = data;
        let minx = bbox[0] || 0;
        let miny = bbox[1] || 0;
        let maxx = bbox[2] || 0;
        let maxy = bbox[3] || 0;

        let height = dimen[0] || null;
        let width = dimen[1] || null;

        let scaleY = height ? height / 350 : videoElem.videoHeight / 350;
        let scaleX = width ? width / 500 : videoElem.videoWidth / 500;
        context1.beginPath();
        context1.rect(
          minx / scaleX,
          miny / scaleY,
          maxx / scaleX - minx / scaleX,
          maxy / scaleY - miny / scaleY
        );
        context1.lineWidth = 1;
        let strokeColor = '#ff8a65';
        context1.strokeStyle = strokeColor;
        context1.stroke();

        detections.forEach((item, index) => {
          let confidence = `${item[1]} ${item[2] || '%'}`;
          if (this.state.isFullscreen) {
            this.stateBoundingBoxLabelDataUpdate(item[0], confidence, strokeColor);
          } else {
            // context1.fillStyle = "white";
            // context1.font = "10px sans";
            // context1.fillText(`${item[0]} ${confidence}`, minx / scaleX, miny / scaleY);
          }
        });
      }
    };

    const drawScreen = () => {
      this.dispatched = false;
      boundingBoxData &&
        boundingBoxData.length > 0 &&
        boundingBoxData.forEach((messages, index) => {
          if (messages[0]?.hasOwnProperty('ts')) {
            singleBoundingBox(messages[0]);
          } else {
            multipleBoundingBox(messages);
          }
        });
      let boundingDiff = Date.now() - lastBoundingBoxTime;
      if (!this.dispatched && boundingDiff > 750) {
        context1.clearRect(0, 0, 500, 350);
      }
    };

    drawScreen();
  };

  componentWillUnmount() {
    boundingBoxLabels = []; //reset bounding box data
    clearInterval(this.renderTimerId);
    this.videoPauseStatusUpdate(true);
    this.setState = () => {};
  }

  render() {
    const { id, videoData } = this.props;
    const {
      firstVideoStartTime,
      isUnableToShowVideo,
      errorMsg,
      isFullscreen,
      incrementNumber,
      currentPlayingTime,
      totalEventTimeInSec,
      isDownloading,
    } = this.state;

    return !isUnableToShowVideo ? (
      <React.Fragment>
        <div>
          <div
            className="video-container"
            id={`video-container-${id}`}
            style={{ width: '500px', height: '300px' }}
          >
            <div className="playback-animation" id={`playback-animation-${id}`}>
              <svg className="playback-icons" id={`playback-icons-${id}`}>
                <use className="hidden" href={`#play-icon-${id}`}></use>
                <use href={`#pause-${id}`}></use>
              </svg>
            </div>
            <div
              className="video-canvas"
              style={{ position: 'relative', height: '100%', width: '100%' }}
            >
              <canvas
                className="canvasOne"
                style={{
                  position: 'absolute',
                  background: 'black',
                  height: '100%',
                  width: '100%',
                  zIndex: -1,
                }}
                id={`canvasOne-${id}`}
                width="500"
                height="350"
              >
                Your browser does not support HTML5 Canvas.
              </canvas>
              <canvas
                className="canvasTwo"
                style={{
                  position: 'absolute',
                  background: 'transparent',
                  height: '100%',
                  width: '100%',
                  zIndex: 0,
                }}
                id={`canvasTwo-${id}`}
                width="500"
                height="350"
              >
                Your browser does not support HTML5 Canvas.
              </canvas>
            </div>
            {videoData.map((item, index) => {
              return (
                <video
                  key={index}
                  id={`video-${id}-${index}`}
                  hidden
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'fill',
                    position: 'absolute',
                  }}
                  controls
                  className="video"
                  preload="metadata"
                >
                  <source
                    src={
                      index === 0
                        ? `${item.downloadUrl}#t=${firstVideoStartTime}`
                        : `${item.downloadUrl}#t=0.01`
                    }
                    type="video/mp4"
                  />
                </video>
              );
            })}
            {isFullscreen && (
              <BoundingBox
                key={id}
                incrementNumber={incrementNumber}
                boundingBoxLabels={boundingBoxLabels}
                isFullscreen={isFullscreen}
              />
            )}
            <div className="video-controls hide custom-video-player" id={`video-controls-${id}`}>
              <div className="video-progress">
                <progress
                  id={`progress-bar-${id}`}
                  value={currentPlayingTime}
                  max={totalEventTimeInSec}
                  className={'progress-bar'}
                />
                <input
                  className="seek"
                  id={`seek-${id}`}
                  value={currentPlayingTime}
                  min="0"
                  max={totalEventTimeInSec}
                  type="range"
                  step="1"
                  onChange={() => {}}
                />
                <div className="seek-tooltip" id={`seek-tooltip-${id}`}>
                  00:00
                </div>
              </div>

              <div className="bottom-controls">
                <div className="left-controls">
                  <button className="playButton" data-title="Play" id={`play-${id}`}>
                    <svg className="playback-icons" id={`playback-icons-${id}`}>
                      <use href={`#play-icon-${id}`} />
                      <use className="hidden" href={`#pause-${id}`} />
                    </svg>
                  </button>

                  <div className="volume-controls">
                    <button data-title="Mute" className="volume-button" id={`volume-button-${id}`}>
                      <svg>
                        <use className="hidden" href={`#volume-mute-${id}`} />
                        <use className="hidden" href={`#volume-low-${id}`} />
                        <use href={`#volume-high-${id}`} />
                      </svg>
                    </button>

                    <input
                      className="volume"
                      id={`volume-${id}`}
                      data-mute="0.5"
                      type="range"
                      max="1"
                      min="0"
                      step="0.01"
                    />
                  </div>

                  <div className="time">
                    <time id={`time-elapsed-${id}`}>00:00</time>
                    <span> / </span>
                    <time id={`total-duration-${id}`}>00:00</time>
                  </div>
                </div>

                <div className="right-controls">
                  <button data-title="Download" id={`download-${id}`}>
                    {!isDownloading ? (
                      <HiDownload size={2} />
                    ) : (
                      <CgSpinner className="fa-spin" color={'white'} size={2} />
                    )}
                  </button>
                  <button
                    data-title="Full screen"
                    className="fullscreen-button"
                    id={`fullscreen-button-${id}`}
                  >
                    <svg>
                      <use href={`#fullscreen-${id}`} />
                      <use href={`#fullscreen-exit-${id}`} className="hidden" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*{!isFullscreen && <BoundingBox boundingBoxLabels={boundingBoxLabels} isFullscreen={isFullscreen} />}*/}
        </div>
        {getPlayerSVGIcons(id)}
      </React.Fragment>
    ) : (
      <Alert style={{ width: '150px' }} variant={'warning'}>
        {errorMsg}
      </Alert>
    );
  }
}
