import api from '@/api';
import { getProductMap } from '@/state/cache';
import { getFirmwareTypeName } from '@/utils/products';
import { InputBox } from '@/web/common/forms/InputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import iziToast from 'izitoast';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FirmwareFileInput } from '../../../@components/FirmwareFileInput';

export function CreateFirmwareForm() {
  const { tenantId: ownerTenantId } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [version, setVersion] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [productId, setProductId] = useState('');
  const [packageFile, setPackageFile] = useState('');

  useEffect(() => {
    setType('');
  }, [productId]);

  const navigate = useNavigate();
  const productMap = useSelector(getProductMap);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.firmware.$post({
        data: {
          name,
          version,
          type,
          ownerTenantId,
          description,
          packageFileId: packageFile?.id,
          pictureUrl: 'https://picsum.photos/200/300', //random picture url for now
          productIds: [productId],
          fileContainerName: 'www.google.com', //temporary
          actualFileName: 'www.google.com', //temporary
          checksum: '', //temporary
          storedFileName: 'www.google.com', //temporary
        },
      });
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Created Firmware`,
      });
      navigate({ pathname: './..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Create Firmware</Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <InputBox
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
            required
          />
          <InputBox
            label="Version"
            placeholder="Enter version"
            value={version}
            onChange={setVersion}
            required
          />
          <SelectBox
            label="Product"
            placeholder="Select Product"
            value={productId}
            onChange={setProductId}
            options={Object.values(productMap || {})}
            itemValue="productId"
            itemText="name"
            required
          />
          <SelectBox
            label="Type"
            value={type}
            placeholder="Select type"
            onChange={setType}
            options={productMap[productId]?.firmwareComponents?.map((item) => ({
              name: getFirmwareTypeName(item),
              value: item,
            }))}
            itemText="name"
            itemValue="value"
            required
          />
          <FirmwareFileInput value={packageFile} onChange={setPackageFile} />
          <InputBox
            label="Description"
            placeholder="Enter description"
            value={description}
            onChange={setDescription}
            style={{ height: '75px' }}
            textarea
          />

          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-end">
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
