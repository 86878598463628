import api from '@/api';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { TokenBasedPagination } from '@/web/common/pagination/TokenBasedPagination';
import { LabelCategoryTableView } from '../LabelCategoryTableView';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenLabelCategory>} RequestType */

export function LabelCategoryListView() {
  return (
    <TokenBasedPagination
      request={(limit, token) =>
        api.sai.v1['label-category'].$get({
          params: {
            pageSize: limit,
            continuationToken: token,
          },
        })
      }
      nextToken={
        /** @param {RequestType} request */
        (request) => request.result.result.continuationToken
      }
      success={
        /** @param {RequestType} request */
        (request) => {
          const labelCategories = request.result.result.results;
          if (labelCategories.length === 0) {
            return <PageLoadingFailure message="No label categories" />;
          }
          return <LabelCategoryTableView labelCategories={labelCategories} />;
        }
      }
    />
  );
}
