import { AccessRightGroups } from '@/web/common/AccessRights';
import { SearchBox } from '@/web/common/forms/SearchBox';
import { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { FaCheckSquare, FaChevronDown, FaChevronUp, FaRegSquare } from 'react-icons/fa';
import './index.scss';

/**
 * @typedef {object} AccessRightNode
 * @property {Array.<number>} key
 * @property {string} name
 * @property {boolean} [hidden]
 * @property {boolean} [checked]
 * @property {Array.<AccessRightNode>} [children]
 */

/**
 * @typedef {object} AccessRightSelectionProps
 * @property {Array.<string>} rights
 * @property {(value: { added: Array.<string>, removed: Array.<string>, selection: Array.<string> }) => void} onChange
 */

/** @param {AccessRightSelectionProps} props */
export function AccessRightSelection(props) {
  const { rights, onChange } = props;

  const [filter, setFilter] = useState(null);

  /** @type {[AccessRightNode, (v: AccessRightNode) => any]} */
  const [root, setRoot] = useState({ name: 'ALL' });

  useEffect(() => {
    const currentRights = new Set(rights);

    /**
     * Builds the access right selection tree.
     * If the parent is checked, all the children will also be checked.
     * Root of the tree is a dummy access right to select all together.
     *
     * @param {Object<string, any>} group
     * @param {AccessRightNode} [parent]
     */
    const process = (group, parent) => {
      /** @type {AccessRightNode} */
      parent ||= {
        key: [],
        name: 'ALL',
        children: [],
      };
      let hiddenItems = 0;
      let checkedItems = 0;
      const items = Object.entries(group);
      items.forEach(([name, children], index) => {
        /** @type {AccessRightNode} */
        const node = {
          name,
          children: [],
          key: [...parent.key, index],
          checked: parent.checked || currentRights.has(name),
        };
        process(children, node);
        if (node.checked) checkedItems++;
        if (node.hidden) hiddenItems++;
        node.dirty = node.checked;
        parent.children.push(node);
      });
      if (checkedItems === items.length && items.length > 0) {
        parent.checked = true;
      }
      if (filter && hiddenItems === items.length) {
        parent.hidden = parent.name.toLowerCase().indexOf(filter) < 0;
      }
      parent.dirty = parent.checked;
      return parent;
    };
    setRoot(process(AccessRightGroups));
  }, [rights, filter]);

  /** @param {AccessRightNode} node */
  const notifyChange = (node) => {
    setRoot(node);

    const added = [];
    const removed = [];
    const selection = [];
    const currentRights = new Set(rights);

    /** @param {Array.<AccessRightNode>} data */
    const process = (data) => {
      for (const item of data) {
        if (item.checked) {
          if (!currentRights.has(item.name)) {
            added.push(item.name);
          }
          selection.push(item.name);
        } else {
          if (currentRights.has(item.name)) {
            removed.push(item.name);
          }
          if (item.children) {
            process(item.children);
          }
        }
      }
    };
    process(node.children);

    onChange({ added, removed, selection });
  };

  return (
    <div className="access-right-selection-control">
      <SearchBox
        value={filter}
        onChange={setFilter}
        clearable
        ignoreCase
        placeholder="Filter rights..."
      />
      <ListGroup className="access-rights">
        <AccessRightTreeNode root={root} onChange={notifyChange} />
      </ListGroup>
    </div>
  );
}

/**
 * @typedef {object} AccessRightTreeNodeProps
 * @property {Array.<number>} [path]
 * @property {AccessRightNode} root
 * @property {(root: AccessRightNode) => any} onChange
 */

/** @param {AccessRightTreeNodeProps} props */
function AccessRightTreeNode(props) {
  const { path = [], root, onChange } = props;
  const [expand, setExpand] = useState(true);

  const level = path.length + 1;
  const node = atNodeItem(root, path);
  const hasChildren = Boolean(node?.children?.length);

  if (!node || node.hidden) {
    return null;
  }

  /** @param {Event} e */
  const toggleExpand = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpand(!expand);
  };

  /** @param {Event} e */
  const toggleItemCheck = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const change = { ...root };
    atNodeItem(change, path, (item, parents) => {
      item.checked = !item.checked;
      // upward process
      parents?.forEach((x) => {
        x.checked = x.children?.every((y) => y.checked);
      });
      // downward process
      const downwardProcess = (nodes) => {
        nodes?.forEach((x) => {
          x.checked = item.checked;
          downwardProcess(x.children);
        });
      };
      downwardProcess(item.children);
    });

    onChange(change);
  };

  return (
    <ListGroup.Item onClick={toggleExpand}>
      <div className="d-flex align-items-center justify-content-start">
        <div
          onClick={toggleItemCheck}
          style={{ color: node.dirty !== node.checked ? 'green' : 'black' }}
        >
          {node.checked ? <FaCheckSquare /> : <FaRegSquare />} {node.name}{' '}
          {hasChildren && `(${node.children.length || 0} items)`}
        </div>
        <div className="flex-fill" />
        {hasChildren && (expand ? <FaChevronUp /> : <FaChevronDown />)}
      </div>
      {hasChildren && expand && (
        <ListGroup style={{ margin: '10px', marginLeft: `${level * 10}px` }}>
          {node.children.map((item) => (
            <AccessRightTreeNode key={item.key.join('.')} {...props} path={item.key} />
          ))}
        </ListGroup>
      )}
    </ListGroup.Item>
  );
}

/**
 * @param {AccessRightNode} root
 * @param {Array.<number>} path
 * @param {(node: AccessRightNode, parents: Array.<AccessRightNode>) => any} [callback]
 */
function atNodeItem(root, path, callback) {
  const parents = [];
  for (const index of path) {
    if (!root) return null;
    parents.push(root);
    root = root.children[index];
  }
  if (callback) {
    parents.reverse();
    callback(root, parents);
  }
  return root;
}
