import { Button, Modal } from 'react-bootstrap';
import { PageLoadingSpinner } from '../../PageLoadingSpinner';

/** @param {{onCancel: () => any}} props */
export function FileValidatingModalBody(props) {
  const { onCancel } = props;
  return (
    <>
      <Modal.Body>
        <PageLoadingSpinner status="Checking file..." />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{ width: 150 }} onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}
