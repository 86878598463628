import {
  USER_ACCESS_ROLE_LIST_READ,
  USER_ACCESS_TENANT_LIST_READ,
} from '@/web/common/AccessRights';
import { RolesRightsDonut } from '../RolesRightsDonut';
import { TenantsDonut } from '../TenantsDonut';

export function SettingsDonutGroup(props) {
  return (
    <>
      <USER_ACCESS_ROLE_LIST_READ>
        <RolesRightsDonut {...props} />
      </USER_ACCESS_ROLE_LIST_READ>
      <USER_ACCESS_TENANT_LIST_READ>
        <TenantsDonut {...props} />
      </USER_ACCESS_TENANT_LIST_READ>
    </>
  );
}
