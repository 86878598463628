import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

/** @param {{item: AiContainer, disabled: boolean}} props */
export function RemoveShareAiContainerButton(props) {
  const { item } = props;
  const [loading, setLoading] = useState(false);
  const { tenantId } = useParams();

  const navigate = useNavigate();

  const removeShareAiContainer = async () => {
    const request = api.sai.v1['ai-container']
      .$containerId(item.containerId)
      .share.$tenantId(tenantId)
      .$delete({});
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Removed AI Container share <b>${item.name}</b>`,
      });
      navigate({ pathname: './..' });
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      size="sm"
      type="button"
      variant="outline-danger"
      style={{ width: 120 }}
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: removeShareAiContainer,
          title: `Remove ${item.name} (${item.version}) share?`,
          message: 'It will affect all resources using this AI Container',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Remove Share'}
    </Button>
  );
}
