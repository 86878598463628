import api from '@/api';
import { useParams, useNavigate } from 'react-router';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { formatTriggerType } from '@/utils/triggers';
import { makeColorFrom } from '@/utils';
import { DeployTriggerButton } from '@/web/pages/ai/@components/DeployTriggerButton';

/** @typedef {import('@/utils/register-api').ApiRequest<SuccessResponseListWithContinuationTokenSharedAiContainerDetailModel>} RequestType */

export function SharedTriggerListView(props) {
  const { tenantId } = useParams();
  const { status } = props;
  const navigate = useNavigate();
  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (limit, token) => {
        const request = api.sai.v1['trigger-share'].$get({
          params: {
            pageSize: limit,
            continuationToken: token,
            tenantId,
            status,
          },
        });
        await request.process();
        let triggers = request.result.result.results;
        triggers = triggers.map((item) => ({ ...item, ...item.trigger }));
        return {
          results: triggers,
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {Trigger} [trigger] */
        (trigger) => navigate(`./${trigger.triggerId}`)
      }
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          width: 125,
          title: 'Type',
          align: 'center',
          builder: ({ triggerDefinition }) => (
            <span>{formatTriggerType(triggerDefinition.root.type)}</span>
          ),
        },
        {
          width: 125,
          title: 'Status',
          align: 'center',
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          title: 'Actions',
          disableAction: true,
          width: 150,
          builder: (item) => {
            return (
              <div className="text-center">
                {item.status === 'DISCONTINUED' ? null : <DeployTriggerButton item={item} />}
              </div>
            );
          },
        },
      ]}
    />
  );
}
