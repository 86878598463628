import api from '@/api';
import { fetchAllTenants, getTenantList } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function CreateTenantForm() {
  const navigate = useNavigate();
  useDispatchLoader(fetchAllTenants);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [tenantCode, setTenantCode] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [picture, setPicture] = useState('');
  const [tenantStatus, setTenantStatus] = useState('ACTIVE');
  const [allowOpenRegistration, setAllowOpenRegistration] = useState(true);
  const [parentTenantId, setParentTenantId] = useState('00000000-0000-0000-0000-000000000000');

  const tenants = useSelector(getTenantList);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.tenant.$post({
        data: {
          tenantCode,
          name,
          address,
          picture,
          tenantStatus,
          allowOpenRegistration,
          parentTenantId,
        },
      });
      await request.process();
      setLoading(false);
      iziToast.info({
        title: `Tenant ${name} [${tenantCode}] has been created`,
      });
      navigate({ pathname: '/settings/tenants' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Body>
        <InputBox
          label="Tenant Code"
          type="text"
          placeholder="Enter tenant code"
          value={tenantCode}
          onChange={setTenantCode}
          required
        />
        <InputBox
          label="Name"
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={setName}
          required
        />
        <InputBox
          label="Picture URL"
          type="text"
          placeholder="Enter picture url"
          value={picture}
          onChange={setPicture}
        />
        <InputBox
          label="Address"
          type="text"
          placeholder="Enter address"
          value={address}
          onChange={setAddress}
          required
        />
        <FloatingLabel label="Parent Tenant" className="mb-2">
          <Form.Select
            value={parentTenantId}
            placeholder="Select tenant"
            onChange={(e) => setParentTenantId(e.currentTarget.value)}
            required
          >
            <option value={'00000000-0000-0000-0000-000000000000'}>Default</option>
            {tenants?.map((tenant) => (
              <option key={tenant.tenantId} value={tenant.tenantId}>
                {tenant.name}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Status*" className="mb-2">
          <Form.Select
            type="text"
            placeholder="Enter tenant status"
            value={tenantStatus}
            onInput={(e) => setTenantStatus(e.currentTarget.value)}
            required
          >
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </Form.Select>
        </FloatingLabel>
        <Form.Check
          type="switch"
          label="Allow open registrations"
          checked={allowOpenRegistration}
          onChange={(e) => setAllowOpenRegistration(e.currentTarget.checked)}
          className="mb-2 py-2"
        />
        {error && <Alert variant="danger"> {error + ''}</Alert>}
      </Card.Body>
      <Card.Footer className="d-flex align-items-center justify-content-between">
        <div style={{ flexFlow: 1 }} />
        <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
        </Button>
      </Card.Footer>
    </Form>
  );
}
