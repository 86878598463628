import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';

/**
 * @param {{user: PlatformAdmin, disabled: boolean}} props
 */
export function DeletePlatformAdminButton(props) {
  const { user, disabled } = props;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const deleteUser = async () => {
    const request = api.sai.v1['platform-admin'].$id(user.id).$delete();
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.info({
        title: 'Success',
        message: `Deleted platform admin <b>${user.email}</b>`,
      });
      navigate('./..');
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      type="button"
      variant="danger"
      style={{ width: 132 }}
      disabled={disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: deleteUser,
          title: `Delete ${user.username}?`,
          message: 'It will affect all resources using this user',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Delete'}
    </Button>
  );
}
