import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

export function CreateAiAcceleratorWithTenant() {
  const navigate = useNavigate();
  const { ownerTenantId } = useParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [codeName, setCodeName] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [vendor, setVendor] = useState('');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1['ai-accelerator'].$post({
        data: {
          ownerTenantId,
          codeName,
          description,
          name,
          type,
          vendor,
        },
      });
      await request.process();
      setLoading(false);
      navigate({ pathname: './../..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <BreadcrumbLayout style={{ maxWidth: '825px', margin: '0 auto' }}>
      <Card>
        <Card.Header>
          <Card.Title className="my-1">Create AI Accelarator</Card.Title>
        </Card.Header>
        <Form onSubmit={handleSubmit}>
          <Card.Body>
            <InputBox
              label="Code Name"
              type="text"
              placeholder="Enter code name"
              value={codeName}
              onChange={setCodeName}
              required
            />
            <InputBox
              label="Name"
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={setName}
              required
            />
            <InputBox
              label="Type"
              type="text"
              placeholder="Enter type"
              value={type}
              onChange={setType}
            />
            <InputBox
              label="Vendor"
              type="text"
              placeholder="Enter vendor"
              value={vendor}
              onChange={setVendor}
            />
            <InputBox
              label="Description"
              textarea
              placeholder="Enter description"
              value={description}
              onChange={setDescription}
              style={{ height: '150px' }}
            />
            {error && (
              <>
                <hr className="my-2" />
                <Alert variant="danger" className="my-0 py-2">
                  <FaExclamation /> {error + ''}
                </Alert>
              </>
            )}
          </Card.Body>
          <Card.Footer className="d-flex align-items-center justify-content-end">
            <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </BreadcrumbLayout>
  );
}
