import { fetchAllProducts } from '@/state/cache';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { USER_ACCESS_DEPLOYMENT_GROUP_CREATE } from '@/web/common/AccessRights';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AdminDonutGroup } from '../../@components/AdminDonutGroup';
import { GroupListView } from './@components/GroupListView';

export function GroupListviewWithTenant() {
  useDispatchLoader(fetchAllProducts);

  return (
    <SidebarPlotLayout sidebar={<AdminDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Groups">
        <USER_ACCESS_DEPLOYMENT_GROUP_CREATE>
          <Button as={Link} variant="outline-primary" to={'./create'}>
            <FaPlus /> Groups
          </Button>
        </USER_ACCESS_DEPLOYMENT_GROUP_CREATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <GroupListView />
      </BreadcrumbLayout.Body>
    </SidebarPlotLayout>
  );
}
