import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ContainerComponentForm } from '../../ContainerComponentForm';

/** @param {{item: AiContainer, index: number }} props */
export function EditComponentButton(props) {
  const { item, index } = props;

  const [edit, setEdit] = useState(false);

  return (
    <>
      <Button variant="primary" style={{ width: 120 }} onClick={() => setEdit(true)}>
        Edit
      </Button>
      {edit && (
        <ContainerComponentForm item={item} editIndex={index} onDone={() => setEdit(false)} />
      )}
    </>
  );
}
