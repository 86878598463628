import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import { useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FaKey } from 'react-icons/fa';

/** @param {{user: PlatformAdmin}} props */
export function ChangePlatformAdminPasswordModal(props) {
  const user = props.user;

  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [changeId, setChangeId] = useState(0);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const hideModal = () => {
    setShow(false);
    setChangeId(changeId + 1);
  };

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      if (newPassword && repeatPassword && newPassword !== repeatPassword) {
        throw new Error('New password is not repeated correctly');
      }
      const request = api.sai.v1['platform-admin'].$id(user.id).$patch({
        data: {
          password,
          newPassword,
        },
      });
      await request.process();
      setLoading(false);
      hideModal();
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Button style={{ width: 200 }} variant="outline-primary" onClick={() => setShow(true)}>
        <FaKey /> Change Password
      </Button>

      <Modal key={changeId} show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <InputBox
              label="User Email"
              type="email"
              autoComplete="username"
              value={user.email}
              readOnly
              className="mb-2"
            />
            <InputBox
              label="Current Password"
              type="password"
              onChange={setPassword}
              autoComplete="current-password"
              required
            />
            <InputBox
              label="New Password"
              type="password"
              onChange={setNewPassword}
              autoComplete="new-password"
              required
            />
            <InputBox
              label="Repeat New Password"
              type="password"
              onChange={setRepeatPassword}
              autoComplete="new-password"
              required
            />
            {error && <Alert variant="danger"> {error + ''}</Alert>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="secondary"
              onClick={hideModal}
              style={{ width: 100 }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" style={{ width: 100 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
