import { Link } from 'react-router-dom';
import { Card, NavLink } from 'react-bootstrap';
import { LoginPageLayout } from '@/web/layouts/LoginPageLayout';
import { FaChevronLeft } from 'react-icons/fa';
import './index.scss';

export function LoginRecover() {
  return (
    <LoginPageLayout>
      <Card id="recover-card" className="shadow">
        <NavLink as={Link} to="/login" className="back-button">
          <FaChevronLeft /> Log In Page
        </NavLink>
        <hr />
        <Card.Title>RECOVER PASSWORD</Card.Title>
        <Card.Body>
          <div>Under construction</div>
        </Card.Body>
      </Card>
    </LoginPageLayout>
  );
}
