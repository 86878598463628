import { Link } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';
import { DataItemTable } from '@/web/common/DataItemTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';

/** @param {{item: LabelCategory}} props */
export function LabelCategoryView(props) {
  const { item } = props;

  return (
    <>
      <BreadcrumbLayout.Header title={<span>General Information</span>}>
        <Stack className="justify-content-end" direction="horizontal" gap={2}>
          <Button as={Link} to="./edit" variant="primary" style={{ width: 132 }}>
            Edit
          </Button>
        </Stack>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={item}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'name',
              title: 'Name',
            },
            {
              key: 'description',
              title: 'Description',
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
