import api from '@/api';
import { useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { getBearerToken } from '@/state/auth';
import { Navigate, useParams } from 'react-router';
import { displayError } from '@/utils/toaster';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { fetchAllProducts, fetchAllTenants } from '@/state/cache';
import { FirmwareView } from './@components/FirmwareView';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';

export const FirmwareContext = createContext();

export function ViewFirmwareWithTenant() {
  const { firmwareId } = useParams();

  const [id, setId] = useState(null);
  const updateParent = () => {
    setId(Date.now());
  };

  const [tenantsLoading, tenantsError] = useDispatchLoader(fetchAllTenants);
  const [productsLoading, productsError] = useDispatchLoader(fetchAllProducts);

  const bearerToken = useSelector(getBearerToken);
  const [firmware, firmwareLoading, firmwareError] = useFutureLoader(async () => {
    const request = api.sai.v1.firmware.$firmwareId(firmwareId).$get();
    await request.process();
    return request.result.result;
  }, [id, bearerToken, firmwareId]);

  let loading = null;
  if (firmwareLoading) {
    loading = 'Loading firmware...';
  } else if (productsLoading) {
    loading = 'Loading products...';
  } else if (tenantsLoading) {
    loading = 'Loading tenants...';
  }

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={loading} />
      </BreadcrumbLayout>
    );
  }

  if (firmwareError || productsError || tenantsError) {
    displayError(firmwareError);
    displayError(productsError);
    displayError(tenantsError);
    return <Navigate replace to="./../.." />;
  }

  return (
    <BreadcrumbLayout>
      <FirmwareContext.Provider value={updateParent}>
        <FirmwareView item={firmware} />
      </FirmwareContext.Provider>
    </BreadcrumbLayout>
  );
}
