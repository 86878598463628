import { useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { MdTune } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { DataListTable } from '@/web/common/DataListTable';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { AiModelContext } from '../@components/AiModelViewLayout';
import { AddConfigurationButton } from './@components/AddConfigurationButton';
import { DeleteConfigurationButton } from './@components/DeleteConfigurationButton';
import { EditConfigurationButton } from './@components/EditConfigurationButton';

export function AiModelConfigParams(props) {
  /** @type {{item: AiModel, updateItem: () => any}} */
  const { item: model } = useContext(AiModelContext);

  const { tenantId } = useParams();
  const { configurationParameters } = model;

  const isShared = model.ownerTenantId !== tenantId;
  const canModify = model.status === 'DRAFT' && !isShared;

  return (
    <>
      <BreadcrumbLayout.Header
        icon={<MdTune />}
        title="Configuration Parameters"
        subtitle={
          <>
            Manage configuration parameters of <b>{model.name}</b>
          </>
        }
      >
        {canModify && <AddConfigurationButton />}
      </BreadcrumbLayout.Header>

      <BreadcrumbLayout.Body>
        {configurationParameters?.length === 0 ? (
          <PageLoadingFailure message="No configuration parameters has been added" />
        ) : (
          <DataListTable
            data={configurationParameters}
            headers={[
              {
                key: 'type',
                minWidth: 120,
              },
              {
                key: 'name',
                minWidth: 150,
              },
              {
                key: 'configurationValue',
                title: 'Value',
                minWidth: 150,
              },
              canModify && {
                key: 'actions',
                title: 'Actions',
                align: 'center',
                width: 170,
                builder: (item) => (
                  <ButtonGroup size="sm">
                    <EditConfigurationButton item={item} />
                    <DeleteConfigurationButton item={item} />
                  </ButtonGroup>
                ),
              },
            ]}
          />
        )}
      </BreadcrumbLayout.Body>
    </>
  );
}
