import isFunction from 'lodash/isFunction';
import { useEffect, useMemo } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

/**
 * @template T
 * @typedef {object} SelectOption<T>
 * @property {T} value
 * @property {string} text
 * @property {boolean} disabled
 */

/**
 * @template T
 * @typedef {object} SelectBoxBaseProps<T>
 * @property {Array.<T & object>} options
 * @property {string} label
 * @property {T} value
 * @property {(T) => any} onChange
 * @property {string} [emptyValueText]
 * @property {string & (option: T & object) => any} [itemKey]
 * @property {string & (option: T & object) => T} [itemValue]
 * @property {string & (option: T & object) => string} [itemText]
 * @property {string & (option: T & object) => boolean} [itemDisabled]
 * @property {(option: SelectOption.<T>) => any} [optionRenderer]
 */

/**
 * @template T
 * @typedef {SelectBoxBaseProps<T> & import('react-bootstrap').FormSelectProps} SelectBoxProps<T>
 */

/**
 * @template T
 * @param {SelectBoxProps<T>} props
 */
export function SelectBox(props) {
  let {
    label = 'Please select an option',
    value,
    onChange,
    options = [],
    itemValue,
    itemText,
    itemDisabled,
    optionRenderer,
    emptyValueText,
    required,
    hideLabel,
    ...extraProps
  } = props;

  if (label && required && !label.endsWith('*')) {
    label = label + '*';
  }

  /** @type {Array.<SelectOption<T>>} */
  options = useMemo(() => {
    const builder = (item, getter, defVal) => {
      if (isFunction(getter)) {
        return getter(item) ?? defVal;
      } else if (typeof item == 'object') {
        return item[getter] ?? defVal;
      } else {
        return item ?? defVal;
      }
    };

    return options.map((item) => {
      const value = builder(item, itemValue, item);
      const text = builder(item, itemText || itemValue, item) + '';
      const disabled = builder(item, itemDisabled, false);
      return { text, value, disabled };
    });
  }, [options, itemValue, itemText, itemDisabled]);

  useEffect(() => {
    if (options.length > 0 && !options.find((e) => e.value === value)) {
      if (emptyValueText) {
        if (value) onChange(null);
      } else {
        onChange(options[0].value);
      }
    }
  }, [onChange, emptyValueText, value, options]);

  /** @param {import('react').FormEvent<HTMLSelectElement>} e */
  const handleInput = (e) => {
    const newValue = e.currentTarget.value;
    if (newValue !== value) {
      onChange(newValue);
    }
  };

  const buildOption =
    optionRenderer ||
    (({ value, text, disabled }) => (
      <option key={value} value={value} disabled={disabled}>
        {text}
      </option>
    ));

  const mainSelectBox = (
    <Form.Select
      {...extraProps}
      value={value || ''}
      onInput={handleInput}
      style={{
        padding: hideLabel ? '12px' : null,
        marginBottom: hideLabel ? null : '10px',
        ...extraProps.style,
      }}
    >
      {emptyValueText && (
        <option value={''} disabled>
          {emptyValueText}
        </option>
      )}
      {options.map(buildOption)}
    </Form.Select>
  );

  return hideLabel ? mainSelectBox : <FloatingLabel label={label}>{mainSelectBox}</FloatingLabel>;
}
