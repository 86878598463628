import api from '@/api';
import { getBearerToken } from '@/state/auth';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { displayError } from '@/utils/toaster';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { PlatformAdminView } from './@components/PlatformAdminView';

export function ViewPlatformAdmin() {
  const bearerToken = useSelector(getBearerToken);
  const { userId } = useParams();

  const [user, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['platform-admin'].$id(userId).$get();
    await request.process();
    return request.result.result;
  }, [bearerToken, userId]);

  if (loading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status="Loading user..." />
      </BreadcrumbLayout>
    );
  }

  if (error) {
    displayError(error);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout>
      <PlatformAdminView user={user} />
    </BreadcrumbLayout>
  );
}
