import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { FaChartArea } from 'react-icons/fa';

export function DeviceRecordListView() {
  return (
    <>
      <BreadcrumbLayout.Header title="Device Records" icon={<FaChartArea />} />
      <BreadcrumbLayout.Body>
        <div>View device records</div>
      </BreadcrumbLayout.Body>
    </>
  );
}
