import api from '@/api';
import { displayError, displaySuccess } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FaDesktop } from 'react-icons/fa';
import { DevicePoolViewContext } from '../../../@components/DevicePoolViewLayout';

/** @param {{devicePool: DevicePool, onDone: () => any}} props */
export function DevicePoolEditForm(props) {
  const { devicePool, onDone } = props;

  const { updateDevicePool } = useContext(DevicePoolViewContext);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(devicePool.name);
  const [description, setDescription] = useState(devicePool.description);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      if (loading) return;
      setLoading(true);
      if (!name.trim()) {
        iziToast.warning({ title: 'Invalid', message: 'Device pool name can not be empty' });
      }
      const request = api.sai.v1['device-pool'].$poolId(devicePool.poolId).$patch({
        data: {
          name,
          description,
        },
      });
      await request.process();
      setLoading(false);
      displaySuccess('Saved all changes');
      updateDevicePool();
      onDone();
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
    return false;
  };

  return (
    <Modal size="lg" show={true} onHide={onDone}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FaDesktop /> General Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputBox label="Name" value={name} required onChange={setName} />
          <InputBox label="Description" value={description} required onChange={setDescription} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div />
          <Button type="submit" style={{ width: 130 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
