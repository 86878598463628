import api from '@/api';
import { USER_ACCESS_ROLE_DELETE } from '@/web/common/AccessRights';
import { InputBox } from '@/web/common/forms/InputBox';
import { useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { AccessRightSelection } from '../AccessRightSelection';
import { DeleteRoleButton } from '../DeleteRoleButton';

/**
 * @param {{role: Role, onHide: () => void, onDone: (role: Role) => void}} props
 */
export function EditRoleModal(props) {
  const { role, onHide, onDone } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [{ added, removed, selection }, setRights] = useState({});
  const [description, setDescription] = useState(role.description);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);

      const request = api.sai.v1.role.$name(role.name).$patch({
        data: {
          description,
          type: role.type,
          addRights: added,
          removeRights: removed,
        },
      });
      await request.process();

      setLoading(false);
      onDone({
        ...role,
        description,
        rights: selection,
      });
      onHide();
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Modal show={true} size="lg" onHide={onHide} centered>
      <Modal.Header closeButton className="px-4">
        <Modal.Title>
          Edit <b>{role.name}</b>
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <InputBox
            label="Role Description"
            className="mb-3"
            textarea
            placeholder="Enter description"
            value={description}
            onChange={setDescription}
          />

          <AccessRightSelection rights={role.rights} onChange={setRights} />

          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="justify-content-between">
          <USER_ACCESS_ROLE_DELETE>
            <DeleteRoleButton disabled={loading} name={role.name} />
          </USER_ACCESS_ROLE_DELETE>
          <div className="d-flex">
            <Button
              type="button"
              variant="secondary"
              onClick={onHide}
              style={{ width: 100 }}
              disabled={loading}
            >
              Cancel
            </Button>
            <div className="px-1" />
            <Button type="submit" variant="primary" style={{ width: 100 }} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
