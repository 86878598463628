import api from '@/api';
import { doLogout, getTokenData } from '@/state/auth';
import { selectTenant } from '@/state/auth/select-tenant';
import { useFutureLoader } from '@/utils/hooks/FutureLoader';
import { PageLoadingFailure } from '@/web/common/PageLoadingFailure';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { LoginPageLayout } from '@/web/layouts/LoginPageLayout';
import { useMemo, useState } from 'react';
import { Button, Card, CloseButton, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './index.scss';

export function LoginTenant() {
  const tokenData = useSelector(getTokenData);
  const [tenants, loading, error] = useFutureLoader(async () => {
    const request = api.sai.v1['platform-admin'].$id(tokenData.user_id)['tenant-access'].$get();
    const result = await request.process();
    const list = result.result.tenantAccessList;
    list.sort((a, b) => a.tenantName.localeCompare(b.tenantName));
    return list;
  }, [tokenData]);

  return (
    <LoginPageLayout>
      {loading ? (
        <PageLoadingSpinner status="Loading tenants..." />
      ) : error ? (
        <PageLoadingFailure title="Failed to fetch tenants" message={error + ''} />
      ) : (
        <TenantSelection tenants={tenants} />
      )}
    </LoginPageLayout>
  );
}

/** @param {{tenants: Array.<TenantAccessDetails>}} props */
export function TenantSelection(props) {
  const { tenants } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** @type {[string, (val: string) => any]} */
  const [filter, setFilter] = useState();

  const filteredTenants = useMemo(() => {
    return tenants.filter((el) => {
      if (!filter) return true;
      return el.tenantName.toLowerCase().includes(filter);
    });
  }, [filter, tenants]);

  const handleTenantSelect = (tenantId) => {
    dispatch(selectTenant(tenantId));
  };

  const handleLogout = async () => {
    await dispatch(doLogout());
    navigate('/');
  };

  return (
    <Card id="tenant-selection" className="shadow">
      <Card.Header>
        <Card.Title>SELECT TENANT</Card.Title>
      </Card.Header>
      <Card.Header>
        <div className="d-flex align-items-center gap-2">
          <Form.Control
            value={filter || ''}
            onInput={(e) => setFilter(e.currentTarget.value?.trim()?.toLowerCase())}
            placeholder="Filter tenants..."
          />
          {filter && <CloseButton onClick={() => setFilter()} />}
        </div>
      </Card.Header>
      <Card.Body>
        {filteredTenants.map((el) => (
          <Button
            key={el.tenantId}
            className="tenant-item"
            variant="outline-primary"
            onClick={() => handleTenantSelect(el.tenantId)}
          >
            {el.tenantName}
            {el.tenantSpecificAccess && '*'}
            {/* <small className="tenant-role">{el.role}</small> */}
          </Button>
        ))}
      </Card.Body>
      <Card.Footer>
        <div>Wrong account?</div>
        <div className="logout-link" onClick={handleLogout}>
          Logout
        </div>
      </Card.Footer>
      {/* <Card.Footer className="flex-column justify-content-center">
        <Button variant="secondary" style={{ width: '100%' }} onClick={() => handleTenantSelect()}>
          Skip
        </Button>
        <div className="text-muted small">Enable access to the platform specific pages</div>
      </Card.Footer> */}
    </Card>
  );
}
