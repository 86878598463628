import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { useDispatchLoader } from '@/utils/hooks/DispatchLoader';
import { fetchAllProducts } from '@/state/cache';
import { Navigate } from 'react-router-dom';
import { CreateDevicePoolForm } from './@components/CreateDevicePoolForm';
import { PageLoadingSpinner } from '@/web/common/PageLoadingSpinner';
import { displayError } from '@/utils/toaster';

export function CreateDevicePoolWithTenant() {
  const [productsLoading, productsError] = useDispatchLoader(fetchAllProducts);

  if (productsLoading) {
    return (
      <BreadcrumbLayout>
        <PageLoadingSpinner status={'Loading products...'} />
      </BreadcrumbLayout>
    );
  }

  if (productsError) {
    displayError(productsError);
    return <Navigate replace to="./.." />;
  }

  return (
    <BreadcrumbLayout>
      <CreateDevicePoolForm />
    </BreadcrumbLayout>
  );
}
