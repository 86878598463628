import api from '@/api';
import { getTenantMap } from '@/state/cache';
import { makeColorFrom } from '@/utils';
import { TokenBasedInfiniteScrollTable } from '@/web/common/pagination/TokenBasedInfiniteScrollTable';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

export function SharedAiModelListView() {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const tenantMap = useSelector(getTenantMap);

  return (
    <TokenBasedInfiniteScrollTable
      fetcher={async (pageSize, continuationToken) => {
        const request = api.sai.v1['ai-model-share'].$get({
          params: {
            pageSize,
            continuationToken,
            tenantId,
          },
        });
        await request.process();
        return {
          results: request.result.result.results.map((item) => ({
            ...item,
            ...item.aiModel,
          })),
          token: request.result.result.continuationToken,
        };
      }}
      onClick={
        /** @param {SharedAiModelDetailModel & AiModel} item */
        (item) => navigate(`/ai/ai-models/${tenantId}/${item.modelId}`)
      }
      headers={[
        {
          key: 'name',
          minWidth: 150,
        },
        {
          key: 'version',
          align: 'center',
          width: 120,
        },
        {
          key: 'type',
          align: 'center',
          width: 120,
        },
        {
          key: 'status',
          align: 'center',
          width: 120,
          builder: ({ status }) => (
            <span className="badge" style={{ background: makeColorFrom(status, 73) }}>
              {status}
            </span>
          ),
        },
        {
          key: 'vendor',
          minwidth: 150,
        },
        {
          key: 'description',
          minWidth: 200,
        },
        {
          key: 'accessLevel',
        },
        {
          key: 'ownerTenantId',
          title: 'Owner Tenant',
          minWidth: 200,
          builder: ({ ownerTenantId }) =>
            tenantMap &&
            tenantMap[ownerTenantId] && (
              <Link to={`/settings/tenants/${ownerTenantId}`}>{tenantMap[ownerTenantId].name}</Link>
            ),
        },
      ]}
    />
  );
}
