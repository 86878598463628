import api from '@/api';
import { displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { AiContainerContext } from '../..';
import { InputMappingEditor } from './InputMappingEditor';

/** @param {{item: AiContainer, editIndex: number?, onDone: () => any}} props */
export function ContainerComponentForm(props) {
  const { item, editIndex, onDone } = props;

  const updateParent = useContext(AiContainerContext);
  const editing = item.components[editIndex];

  const [loading, setLoading] = useState(false);
  const [component, setComponent] = useState(editing);

  /** @param {Event} e */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);

      const components = [];
      if (editing) {
        components.push(...item.components.slice(0, editIndex));
        components.push(component);
        components.push(...item.components.slice(editIndex + 1));
      } else {
        components.push(...item.components);
        components.push(component);
      }

      const request = api.sai.v1['ai-container'].$containerId(item.containerId).$put({
        data: {
          components,
        },
      });

      await request.process();
      iziToast.success({ title: 'Success', message: 'Saved changes' });
      setLoading(false);

      onDone();
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
  };

  return (
    <Modal centered size="xl" show={true} onHide={onDone} fullscreen="lg-down">
      <Modal.Header closeButton>
        <Modal.Title>
          {editing ? 'Edit component of' : 'Add new components to'} <b>{item.name}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputMappingEditor
          editing={editing}
          container={item}
          component={component}
          onChange={setComponent}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} variant="secondary" onClick={onDone} style={{ width: 132 }}>
          Cancel
        </Button>
        <Button disabled={loading} variant="primary" onClick={handleSubmit} style={{ width: 132 }}>
          {loading ? <Spinner size="sm" animation="border" /> : editing ? 'Save' : 'Add'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
