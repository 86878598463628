import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link, useLocation, useResolvedPath } from 'react-router-dom';
import './index.scss';

export function DashboardItem(props) {
  const { sidebar, url, header, children } = props;

  const location = useLocation();
  const resolved = useResolvedPath(url);
  const active = location.pathname.startsWith(resolved.pathname);

  return (
    <Col xs={12} lg={sidebar ? 12 : 6}>
      <Card className={sidebar ? 'sidebar-item' : 'dashboard-item'}>
        <Card.Header as={Link} to={resolved} className={`${active ? 'active' : ''}`}>
          <Card.Title className="dashboard-title" data-title={header}>
            {header}
          </Card.Title>
          <img className="header-arrow" src="/images/widget_arrow.svg" alt="header arrow" />
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Col>
  );
}
