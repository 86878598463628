import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { SidebarPlotLayout } from '@/web/layouts/SidebarPlotLayout';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { EventDonutGroup } from '../@components/EventDonutGroup';
import { LabelCategoryListView } from './@components/LabelCategoryListView';

export function LabelCategoryListViewPage() {
  return (
    <SidebarPlotLayout sidebar={<EventDonutGroup sidebar={true} />}>
      <BreadcrumbLayout.Header title="Label Categories">
        <Button as={Link} variant="outline-primary" to={'./create'}>
          <FaPlus /> New Label Category
        </Button>
      </BreadcrumbLayout.Header>
      <LabelCategoryListView />
    </SidebarPlotLayout>
  );
}
