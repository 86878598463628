import api from '@/api'; // eslint-disable-line
import { Link } from 'react-router-dom';
import { Badge, ListGroup } from 'react-bootstrap';
import { takeTextPortion } from '@/utils/formatting';

/** @param {{item: AiAccelerator}} props */
export function AiAccelaratorListItem(props) {
  const item = props.item;
  return (
    <>
      <ListGroup.Item action as={Link} to={`./${encodeURIComponent(item.codeName)}`}>
        <div className="ms-lg-2">
          <Badge bg="success" pill title="Code Name">
            {item.codeName}
          </Badge>
          <div className="my-1">
            <span className="fw-bold">{item.name}</span> &nbsp;
            {item.vendor && <code title="Vendor">({item.vendor})</code>}
          </div>
          <div className="text-muted">
            <small>{takeTextPortion(item.description, 250)}</small>
          </div>
        </div>
        <Badge bg="secondary" title="Type" style={{ position: 'absolute', top: 10, right: 16 }}>
          {item.type}
        </Badge>
      </ListGroup.Item>
    </>
  );
}
