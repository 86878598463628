import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import { PasswordInputBox } from '@/web/common/forms/PasswordInputBox';
import iziToast from 'izitoast';
import { useState } from 'react';
import { Alert, Button, Card, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

/**
 * @param {{ tenant: Tenant }} props
 */
export function CreateEndUserForm(props) {
  const tenant = props.tenant;
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [picture, setPicture] = useState('');
  const [status, setStatus] = useState('ACTIVE');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.tenant.$tenantId(tenant.tenantId)['end-user'].$post({
        data: {
          email,
          username,
          password,
          name,
          picture,
          status,
        },
      });
      await request.process();
      setLoading(false);
      iziToast.info({
        title: 'Success',
        message: 'Created a new end user',
      });
      //const id = request.result.result.id;
      navigate({ pathname: './..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">
          Create End User for <Link to={`/settings/tenants/${tenant.tenantId}`}>{tenant.name}</Link>
        </Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <FloatingLabel label="Status*" className="mb-2">
            <Form.Select
              value={status}
              placeholder="Select a status"
              onChange={(e) => setStatus(e.currentTarget.value)}
              required
            >
              <option value="ACTIVE">Active</option>
              <option value="PENDING">Pending</option>
              <option value="INACTIVE">Inactive</option>
            </Form.Select>
          </FloatingLabel>
          <InputBox
            label="Email Address"
            type="email"
            value={email}
            placeholder="Email Address"
            onChange={setEmail}
            required
          />
          <InputBox
            label="Username"
            value={username}
            placeholder="Username"
            onChange={setUsername}
            required
          />
          <PasswordInputBox
            label="Password"
            value={password}
            placeholder="Password"
            onChange={setPassword}
            required
          />
          <InputBox
            label="Full Name"
            type="text"
            value={name}
            placeholder="Full Name"
            onChange={setName}
          />
          <InputBox
            label="Picture URL"
            type="text"
            value={picture}
            placeholder="Picture URL"
            onChange={setPicture}
          />
          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div style={{ flexFlow: 1 }} />
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
