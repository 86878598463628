export const triggerTypes = {
  BASIC: 'Job',
  COMPLEX: 'Workflow',
};

export const mediaTypes = {
  NONE: 'None',
  SNAPSHOT: 'Snapshot',
  VIDEO: 'Video',
  SNAPSHOT_AND_VIDEO: 'Snapshot and video',
};

export const chimes = {
  none: 'None',
  chime1: 'Chime 1',
  chime2: 'Chime 2',
  chime3: 'Chime 3',
};

export const labelOperators = {
  GREATER_THAN: 'Greater than >',
  GREATER_THAN_OR_EQUAL: 'Greater than or equal to ≥',
  LESS_THAN: 'Lesser than <',
  LESS_THAN_OR_EQUAL: 'Lesser than or equal to≤',
  EQUAL: 'Equal to =',
  NOT_EQUAL: 'Not equal to ≠',
};

export const basicOperators = {
  GREATER_THAN: '>',
  GREATER_THAN_OR_EQUAL: '≥',
  LESS_THAN: '<',
  LESS_THAN_OR_EQUAL: '≤',
  EQUAL: '=',
  NOT_EQUAL: '≠',
};

export const triggerOperators = {
  AND: 'AND',
  OR: 'OR',
  XOR: 'XOR',
};
