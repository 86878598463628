import api from '@/api';
import { chimes, mediaTypes, triggerTypes } from '@/assets/triggers/autocomplete';
import { displayError, displaySuccess } from '@/utils/toaster';
import { CheckBox } from '@/web/common/forms/CheckBox';
import { InputBox } from '@/web/common/forms/InputBox';
import { InputSlider } from '@/web/common/forms/InputSlider';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { TriggerContext } from '../..';
import { BasicTypeComponentEdit } from '../../../@components/BasicTypeComponentEdit';
import { ComplexTypeComponentEdit } from '../../../@components/ComplexTypeComponentEdit';

/** @param {{item: TriggerWithLabels} & import('react-bootstrap').ModalProps} props */
export function EditTriggerModal(props) {
  const { item, ...modalProps } = props;
  const updateParent = useContext(TriggerContext);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(item.name);
  const [tts, setTts] = useState(item.tts);
  const [chime, setChime] = useState(item.chime);
  const [critical, setCritical] = useState(item.critical);
  const [mediaType, setMediaType] = useState(item.mediaType);
  const [coolDownTimer, setCoolDownTimer] = useState(item.coolDownTimer);
  const [triggerDurationThreshold, setTriggerDurationThreshold] = useState(
    item.triggerDurationThreshold
  );

  const [type, setType] = useState(item.triggerDefinition?.root?.type);
  const [triggerDefinition, setTriggerDefinition] = useState(item.triggerDefinition);

  useEffect(() => {
    if (type === item.triggerDefinition?.root?.type) {
      setTriggerDefinition(item.triggerDefinition);
    } else {
      setTriggerDefinition({});
    }
  }, [item, type]);

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      const request = api.sai.v1['trigger'].$triggerId(item.triggerId).$patch({
        data: {
          chime,
          coolDownTimer,
          critical,
          mediaType,
          name,
          triggerDefinition,
          triggerDurationThreshold,
          tts,
        },
      });
      await request.process();
      setLoading(false);
      displaySuccess('Success', 'Saved changes to trigger');
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(err);
    }
  };

  return (
    <Modal {...modalProps}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Trigger</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputBox
            label="Name"
            placeholder="Enter name"
            value={name}
            onChange={setName}
            required
          />
          <InputSlider
            min={1}
            max={3600}
            label="Cooldown Timer"
            unit={'seconds'}
            value={coolDownTimer}
            onChange={setCoolDownTimer}
          />
          <InputSlider
            min={0}
            max={20}
            label="Trigger Duration Threshold"
            unit={'seconds'}
            value={triggerDurationThreshold}
            onChange={setTriggerDurationThreshold}
          />
          <SelectBox
            label="Media Type"
            placeholder="Select Media Type"
            value={mediaType}
            onChange={setMediaType}
            options={Object.entries(mediaTypes || {})}
            itemValue={0}
            itemText={1}
            required
          />
          <InputBox
            label="Text to play in dashcam"
            placeholder="Put a message from your driver"
            value={tts}
            onChange={setTts}
            required
          />
          <SelectBox
            label="Chime"
            placeholder="Select Chime"
            value={chime}
            onChange={setChime}
            options={Object.entries(chimes || {})}
            itemValue={0}
            itemText={1}
            required
          />
          <CheckBox label="Critical" checked={critical} onChange={setCritical} />

          <hr />

          <SelectBox
            label="Type"
            options={Object.entries(triggerTypes || {})}
            itemValue={0}
            itemText={1}
            value={type}
            onChange={setType}
            required
          />
          {type === 'BASIC' && (
            <BasicTypeComponentEdit value={triggerDefinition} onChange={setTriggerDefinition} />
          )}
          {type === 'COMPLEX' && (
            <ComplexTypeComponentEdit value={triggerDefinition} onChange={setTriggerDefinition} />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end">
          <Button
            type="submit"
            variant="primary"
            style={{ width: 132 }}
            disabled={loading || !triggerDefinition?.root?.type}
          >
            {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
