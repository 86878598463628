import { FaArrowRight, FaRobot, FaToolbox } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getAiModel } from '@/state/cache';
import { makeColorFrom } from '@/utils';

/** @param {{component: AiContainerComponentModel}} props */
export function ComponentItem(props) {
  const { component } = props;

  const model = useSelector(getAiModel(component.modelId));
  if (!model) return null;

  const firstMapping = Object.values(component.inputMapping)[0];

  return (
    <div className="p-1" style={{ width: 200, position: 'relative' }}>
      <div className="m-1 my-3">
        <ComponentIcon mapping={firstMapping} />
        <FaArrowRight size={24} color="teal" />
        <FaRobot size={64} color="#558b5b" />
      </div>
      <div className="text-capitalize roboto-slab" style={{ fontWeight: 500 }}>
        {model.name}
      </div>
      <div
        className="badge"
        style={{
          background: makeColorFrom(model.status, 73),
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        {model.status}
      </div>
    </div>
  );
}

/** @param {{mapping: InputMapModel}} props */
function ComponentIcon(props) {
  switch (props.mapping.inputSource) {
    case 'MODEL':
      return <FaRobot size={64} color="#48538d" />;
    case 'PRODUCT':
    default:
      return <FaToolbox size={48} color="#48538d" />;
  }
}
