import { ApiRequestProgress } from '@/utils/register-api';
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';

/**
 * @typedef {object} FileUploadingModalBodyProps
 * @property {import('@/utils/register-api').ApiRequestProgress} progress
 * @property {() => any} onCancel
 */

/** @param {FileUploadingModalBodyProps} props */
export function FileUploadingModalBody(props) {
  const { onCancel } = props;
  const progress = props.progress || new ApiRequestProgress();

  return (
    <>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label>Uploading file...</Form.Label>
          <ProgressBar
            style={{ height: 30 }}
            variant="success"
            max={progress.max}
            now={progress.now}
            label={progress.progress > 5 ? progress.label : null}
          />
          <div className="d-flex justify-content-between">
            <small>Remaining {progress.remaining}</small>
            <small className="fw-bold">{progress.speed}</small>
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" style={{ width: 125 }} onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
}
