import { BOUNDING_BOX_LABEL_COLORS } from '@/assets/events';

/**
 * Format Seconds to minutes and seconds
 *
 * @param {string} timeInSeconds
 * @returns {{seconds: string, minutes: string}}
 */
export function formatSecondsToMinSec(timeInSeconds) {
  if (isNaN(timeInSeconds)) return;
  let minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0');
  let seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0')
    .slice(0, 2);
  return {
    minutes,
    seconds,
  };
}

/**
 * Format Seconds to mm:ss style
 *
 * @param {string} timeInSeconds
 * @returns {string}
 */
export function secondsToTimeStyle(timeInSeconds) {
  if (isNaN(timeInSeconds)) return;
  let minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0');
  let seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0')
    .slice(0, 2);
  return `${minutes}:${seconds}`;
}

/**
 * Format MilliSeconds to minutes and seconds
 *
 * @param {string} timeInMilliSec
 * @returns {{seconds: string, minutes: string}}
 */
export function formatMilliTimeToMinSec(timeInMilliSec) {
  if (isNaN(timeInMilliSec)) return;

  let minutes = Math.floor(timeInMilliSec / 60000)
    .toString()
    .padStart(2, '0');
  let seconds = Math.floor(timeInMilliSec / 1000)
    .toString()
    .padStart(2, '0')
    .slice(0, 2);
  return {
    minutes,
    seconds,
  };
}

/**
 * Format MilliSeconds to Seconds
 *
 * @param {string} timeInMilliSec
 * @returns {number}
 */
export function formatMilliSecondsToSec(timeInMilliSec) {
  if (isNaN(timeInMilliSec)) return 0;
  return Math.floor(timeInMilliSec / 1000);
}

/**
 * Constant to Human Readable
 *
 * @param {string} string
 * @returns {string}
 */
export function constantToHumanReadable(string) {
  if (string === 'REST_API') return 'REST API';
  if (string === 'EMERGENCY_RECORD') return 'Emergency Record';
  const str = string.replace(/_/g, ' ');
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');
}

/**
 * Sort any list By date
 *
 * @param {Array.<any>} list
 * @param {string} key
 * @returns {number}
 */
export function sortListByDate(list = [], key) {
  if (!list || list.length === 0) return list;

  return list.sort(function (a, b) {
    var keyA = new Date(a[key]),
      keyB = new Date(b[key]);
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
}

/**
 * Check String is ASCII or not
 *
 * @param {string} str
 * @returns {boolean}
 */
// eslint-disable-next-line no-control-regex
const isASCII = (str) => /^[\x00-\x7F]*$/.test(str);

/**
 * Get Get AI model (Bounding Box Label) title color by label
 *
 * @param {string} str
 * @returns {string}
 */
export function getAIModelTitleColor(str) {
  let power = 31;
  let mod = BOUNDING_BOX_LABEL_COLORS.length;
  let sum = 0;
  let n = 1;
  for (let ch of str) {
    if (isASCII(ch)) {
      sum = (sum + ((ch.codePointAt(0) * n) % mod)) % mod;
    }
    n = (n * power) % mod;
  }
  return BOUNDING_BOX_LABEL_COLORS[sum % mod];
}
