import api from '@/api';
import { checkIsCacheValid, updateData } from '..';

/**
 * Fetch and cache Product donut chart data
 *
 * @param {boolean} refresh Force refresh cache
 * @returns {(dispatch: (action: any) => any, state: () => import('@/state').StoreState) => Promise<any>}
 */
export const fetchProductDonutData = (refresh) => async (dispatch, getState) => {
  const state = getState();

  const { login, tenantId } = state.auth;
  if (!login?.accessToken) return;

  if (!refresh && checkIsCacheValid('products')(state)) return;

  const request = api.sai.v1['product'][':count'].$get({
    params: {
      tenantId,
    },
  });
  await request.process();

  /** @type {import('..').ChartData} */
  const chartData = {
    products: {
      tenantId,
      total: request.result.result.count,
    },
  };

  await dispatch(updateData(chartData));
};
