import { donutCharColors } from '@/assets/colors/autocomplete';
import { getBearerToken } from '@/state/auth';
import { getChartData } from '@/state/chart';
import { fetchRoleDonutData } from '@/state/chart/fetchers/roles';
import { usePeriodicDispatcher } from '@/utils/hooks/PeriodicDispatcher';
import { DashboardItem } from '@/web/common/DashboardItem';
import DonutChart from '@/web/common/DashboardItem/charts/donut';
import { useSelector } from 'react-redux';

export function RolesRightsDonut(props) {
  const { sidebar } = props;
  const chartData = useSelector(getChartData);
  const bearerToken = useSelector(getBearerToken);
  usePeriodicDispatcher(fetchRoleDonutData, [bearerToken], 10 * 60 * 1000);

  const data = {
    count: chartData.roles?.total || 0,
    points: [
      ['data1', 'data2'],
      ['Roles', chartData.roles?.total || 0],
    ],
  };
  return (
    <DashboardItem sidebar={sidebar} header={'Role'} url={'/settings/roles'}>
      <DonutChart
        sidebar={sidebar}
        label={{ header: data.count, subHeader: 'Roles' }}
        legends={[{ name: 'Roles', className: 'blue' }]}
        colors={[donutCharColors.BLUE]}
        data={data.points}
      />
    </DashboardItem>
  );
}
