import { Spinner } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import Label from '../label';
import ModifiedDonutLegend from '../legends/modifiedDonut';

const DUMMY_DATA = [
  ['key', 'val'],
  ['empty', 1],
];

export default function DonutChart(props) {
  if (props.sidebar) {
    return SidebarDonut(props);
  } else {
    return DashboardDonut(props);
  }
}

function DashboardDonut(props) {
  let { data, colors, legends, label } = props;
  const donutEmpty = isDonutEmpty(data);

  return (
    <div className="overlay" style={{ height: '25vh', minHeight: 200 }}>
      {/* <div id={id} style={{ position: "absolute", zIndex: "300" }}></div> */}
      <Label label={label} />
      <ModifiedDonutLegend legends={legends} />

      <Chart
        className="donut-chart"
        chartType="PieChart"
        loader={
          <div className="overlay-content">
            <Spinner
              animation="border"
              role="status"
              style={{
                color: '#F2A010',
                height: 'calc(25vh - 15px)',
                width: 'calc(25vh - 15px) ',
              }}
            />
          </div>
        }
        data={donutEmpty ? DUMMY_DATA : data}
        options={{
          legend: { position: 'none' },
          chartArea: {
            height: '100%',
            width: '65%',
            left: '7%',
            top: '7%',
          },
          enableInteractivity: !donutEmpty,
          height: '100%',
          width: '80%',
          pieHole: 0.8,
          pieSliceBorderColor: 'transparent',
          backgroundColor: {
            strokeWidth: 0,
          },
          tooltip: {
            isHtml: false,
            text: 'value',
            textStyle: {
              fontName: 'Open Sans',
              color: 'transparent',
              fontSize: 16,
              bold: false,
            },
          },
          colors: colors,
          pieSliceText: 'none',
          fontName: 'Open Sans',
        }}
      />
    </div>
  );
}

function SidebarDonut(props) {
  const { data, colors, legends, label } = props;
  const donutEmpty = isDonutEmpty(data);

  return (
    <div className="overlay" style={{ height: 140 }}>
      <Label label={label} sidebar disableLegend={false} />
      <ModifiedDonutLegend legends={legends} />

      <Chart
        className="donut-chart"
        chartType="PieChart"
        loader={
          <div className="overlay-content pt-1 pe-2">
            <Spinner
              animation="border"
              role="status"
              style={{
                color: '#F2A010',
                height: 110,
                width: 110,
              }}
            />
          </div>
        }
        data={donutEmpty ? DUMMY_DATA : data}
        options={{
          enableInteractivity: !donutEmpty,
          legend: { position: 'none' },
          chartArea: {
            height: 115,
            width: 115,
          },
          height: 140,
          width: 140,
          title: '',
          pieHole: 0.8,
          pieSliceBorderColor: 'transparent',
          backgroundColor: {
            strokeWidth: 0,
          },
          tooltip: {
            isHtml: false,
            text: 'value',
            textStyle: { fontName: 'Open Sans', fontSize: 16, bold: false },
          },
          colors: colors,
          pieSliceText: 'none',
          fontName: 'Open Sans',
        }}
      />
    </div>
  );
}

/** @param {Array.<[any, number]>} data */
function isDonutEmpty(data) {
  for (let i = 1; i < data.length; i++) {
    if (data[i][1]) return false;
  }
  return true;
}
