import { useEffect } from 'react';
import './index.scss';

/**
 * @typedef {object} InputSliderProps
 * @property {number} [value] The input value
 * @property {(value: number) => any} [onChange] On change handler
 * @property {string} [label] Label of the slider
 * @property {number} [min] Minimum value of the slider
 * @property {number} [max] Maxmimum value of the slider
 * @property {number} [step] Step size of the slider
 * @property {string} [unit] Unit of the slider item to be shown beside output
 * @property {boolean} [hideLabel] Whether to hide the label of the slider
 */

/** @param {InputSliderProps & import('react-bootstrap').FormControlProps} props */
export function InputSlider(props) {
  let {
    value,
    onChange,
    label,
    min = 0,
    max = 100,
    step = 1,
    hideLabel,
    required,
    unit,
    ...extraProps
  } = props;
  if (label && required && !label.endsWith('*')) {
    label = label + '*';
  }

  useEffect(() => {
    if (typeof value === undefined || value < min || value > max) {
      onChange(min);
    }
  }, [min, max, value, onChange]);

  return (
    <div className="slider-input mb-2">
      <label className="slider-label">{label}</label>
      <div className="d-flex align-items-center gap-4">
        <input
          className="flex-fill"
          type="range"
          {...extraProps}
          min={min}
          max={max}
          step={step}
          defaultValue={value || min}
          onChange={(e) => onChange(Number(e.target.value))}
          required={required}
        />
        {!hideLabel && (
          <div className="slider-output">
            {value || min} {unit}
          </div>
        )}
      </div>
    </div>
  );
}
