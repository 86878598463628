import { nanoid } from '@reduxjs/toolkit';
import isFunction from 'lodash/isFunction';
import startCase from 'lodash/startCase';
import { useMemo } from 'react';
import Table from 'react-bootstrap/Table';
import './index.scss';

const idkey = Symbol('id');

/**
 * @template T
 * @typedef {object} DataItemTableHeader<T>
 * @property {string} key ID of the table header and data key for the column.
 * @property {string} [title] Title of the table header.
 * @property {(item: T) => import('react').Component} [builder] Optional builder function to build the column.
 * @property {boolean} [hidden] Whether to show this item.
 */

/**
 * @template T
 * @typedef {object} DetailsViewTableProps<T>
 * @property {T} data Data to display into the table.
 * @property {Array.<string | DataItemTableHeader.<T>>} [headers] Label headers of the table.
 * @property {'sm' | 'md' | 'lg' | 'xl'} [responsive]
 * @property {'light'|'dark'|'primary'|'secondary'|'info'|'warning'|'danger'} [variant] Table color variant
 * @property {boolean} [dense]
 * @property {boolean} [border]
 * @property {boolean} [striped]
 * @property {number} width Width of the table header
 * @property {number} minWidth Minimum width of the table header.
 * @property {number} maxWidth Minimum width of the table header.
 */

/**
 * @template T
 * @param {DetailsViewTableProps.<T>} props
 */
export function DataItemTable(props) {
  const headers = useMemo(() => {
    return (props.headers || Object.keys(props.data) || []).map((header) => {
      if (typeof header === 'string') {
        header = { key: header };
      }
      if (!header[idkey]) {
        header[idkey] = nanoid();
      }
      if (!isFunction(header.builder)) {
        header.builder = (item) => '' + (item[header.key] || '');
      }
      if (!header.title) {
        header.title = startCase(header.key);
      }
      return header;
    });
  }, [props.headers, props.data]);

  const getMinWidth = () => {
    if (props.minWidth || props.maxWidth) {
      return props.minWidth;
    }
    return props.width;
  };

  const getMaxWidth = () => {
    if (props.minWidth || props.maxWidth) {
      return props.maxWidth;
    }
    return props.width;
  };

  /** @type {import('react').CSSProperties} */
  const headerStyle = {
    width: props.width,
    minWidth: getMinWidth(),
    maxWidth: getMaxWidth(),
    verticalAlign: 'middle',
  };

  /** @type {import('react-bootstrap').TableProps} */
  const tableProps = {
    borderless: true,
    bordered: props.border ?? true,
    variant: props.variant,
    size: props.dense ? 'sm' : null,
    striped: props.striped ?? false,
    responsive: props.responsive ?? true,
    className: `details-view-table ${props.dense ? 'mb-0' : ''} ${props.className || ''}`,
    style: { background: 'white', ...props.style },
  };

  return (
    <Table {...tableProps}>
      <tbody>
        {headers.map((header) => {
          if (header.hidden) return null;
          let body = header.builder(props.data);
          if (typeof body === 'string' && !body) body = null;
          if (typeof body === 'boolean' && !body) body = null;
          if (props.dense && (body === null || body === undefined)) return null;
          return (
            <tr key={header[idkey]}>
              <th style={headerStyle}>{header.title}</th>
              <td>{body ?? <code>N/A</code>}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
