import { MdModelTraining } from 'react-icons/md';
import { getParamName } from '@/utils/ai-models';
import { InputCardLayout } from '../InputCardLayout';

/** @param {{input: ModelInput, source: AiModel }} props */
export function ModelInputCard(props) {
  const { input, color, ...extraProps } = props;
  return (
    <InputCardLayout {...extraProps} title={input.description}>
      <div className="py-3 d-flex flex-column gap-3 justify-content-center align-items-center">
        <MdModelTraining size={48} color={color} />
        <div className="fw-bold">{getParamName(props.input.name)}</div>
      </div>
    </InputCardLayout>
  );
}
