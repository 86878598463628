import api from '@/api';
import { getRoles } from '@/state/cache';
import { displaySuccess } from '@/utils/toaster';
import { InputBox } from '@/web/common/forms/InputBox';
import { PasswordInputBox } from '@/web/common/forms/PasswordInputBox';
import { SelectBox } from '@/web/common/forms/SelectBox';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

/**
 * @param {{ tenant: Tenant }} props
 */
export function CreateTenantAdminForm(props) {
  const { tenant } = props;
  const navigate = useNavigate();
  const roles = useSelector(getRoles);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [company, setCompany] = useState('');
  const [description, setDescription] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.tenant.$tenantId(tenant.tenantId).admin.$post({
        data: {
          address,
          company,
          description,
          displayName,
          email,
          firstName,
          lastName,
          phone,
          password,
          role,
          username,
          // TODO: pictureFileId,
        },
      });
      await request.process();
      setLoading(false);
      displaySuccess('Success', 'Created a new tenant admin');
      //const id = request.result.result.id;
      navigate({ pathname: './..' });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">
          Create Tenant Admin for{' '}
          <Link to={`/settings/tenants/${tenant.tenantId}`}>{tenant.name}</Link>
        </Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <SelectBox
            label="Role"
            value={role}
            onChange={setRole}
            options={roles}
            itemValue="name"
            disabled={!roles?.length}
            required
          />
          <div className="d-lg-flex gap-2">
            <div className="flex-fill">
              <InputBox value={firstName} onChange={setFirstName} label="First Name" />
            </div>
            <div className="flex-fill">
              <InputBox value={lastName} onChange={setLastName} label="Last Name" />
            </div>
          </div>
          <InputBox required type="email" label="Email Address" value={email} onChange={setEmail} />
          <InputBox
            label="Username"
            value={username}
            onChange={setUsername}
            autoComplete="username"
            required
          />
          <PasswordInputBox
            label="Password"
            value={password}
            onChange={setPassword}
            autoComplete="new-password"
            required
          />
          <InputBox label="Company name" value={company} onChange={setCompany} />
          <InputBox
            textarea
            label="Description"
            value={description}
            onChange={setDescription}
            style={{ height: 100 }}
          />
          <InputBox label="Phone number" value={phone} onChange={setPhone} />
          <InputBox label="Display Name" value={displayName} onChange={setDisplayName} />
          <InputBox
            textarea
            label="Address"
            value={address}
            onChange={setAddress}
            style={{ height: 100 }}
          />
          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div style={{ flexFlow: 1 }} />
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
