import api from '@/api';
import { confirmDialog, displayError } from '@/utils/toaster';
import iziToast from 'izitoast';
import { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { DeployedFirmwareContext } from '../DeployedListView';

/** @param {{item: FirmwareDeploymentModel, disabled: boolean}} props */
export function UndeployFirmwareButton(props) {
  const [loading, setLoading] = useState(false);
  const updateParent = useContext(DeployedFirmwareContext);

  const item = props.item;
  const { poolId } = useParams();

  const undeployFirmware = async () => {
    const request = api.sai.v1['device-pool']
      .$poolId(poolId)
      ['firmware-deployment'].$firmwareType(item.firmwareType)
      .$delete({
        data: {
          firmwareId: item?.firmwareId,
        },
      });
    try {
      setLoading(true);
      await request.process();
      setLoading(false);
      iziToast.success({
        title: 'Success',
        message: `Undeployed Firmware from device pool!`,
      });
      updateParent();
    } catch (err) {
      setLoading(false);
      displayError(request.error);
    }
  };

  return (
    <Button
      size="sm"
      type="button"
      variant="outline-danger"
      disabled={props.disabled || loading}
      onClick={() =>
        confirmDialog({
          onConfirm: undeployFirmware,
          title: `Undeploy ${item.firmwareName} (${item.firmwareVersion}) from this device pool?`,
          message: 'It will affect all resources using this Firmware',
        }).catch(() => {})
      }
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Undeploy'}
    </Button>
  );
}
