import { useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import { FaGlobe } from 'react-icons/fa';
import { DomainInputForm } from '../DomainInputForm';

/**
 * @typedef {object} DomainItemViewProps
 * @property {string} value
 * @property {(value: string, previous: string) => any} onChange
 * @property {string} color Text color
 * @property {boolean} disabled Whether the item is disabled
 */

/** @param {DomainItemViewProps} props */
export function DomainItemView(props) {
  const { value, onChange, color, disabled } = props;

  const [editMode, setEditMode] = useState(false);

  if (editMode) {
    return (
      <ListGroup.Item>
        <DomainInputForm value={value} onSubmit={onChange} onCancel={() => setEditMode(false)} />
      </ListGroup.Item>
    );
  }

  return (
    <ListGroup.Item
      as="div"
      action
      onClick={() => (disabled ? onChange(value) : setEditMode(true))}
      className="d-flex align-items-center p-0"
    >
      <div className="w-100 py-2 px-3" style={{ color: disabled ? 'grey' : color }}>
        <FaGlobe style={{ color: 'teal' }} /> {value}
      </div>
      {!disabled && (
        <Button variant="light" className="rounded-0" onClick={() => onChange(null, value)}>
          <BsX style={{ fontSize: '1.8em', color: 'red' }} />
        </Button>
      )}
    </ListGroup.Item>
  );
}
