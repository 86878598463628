import { PagedNavigation } from '@/web/common/PagedNavigation';
import { DevicePoolViewLayout } from './@components/DevicePoolViewLayout';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';

export function ViewDevicePoolWithTenant() {
  return (
    <DevicePoolViewLayout>
      <PagedNavigation
        layout={(props) => (
          <>
            <BreadcrumbLayout.Header subtitle={'Select a section to view more'} />
            <BreadcrumbLayout.Body>{props.children}</BreadcrumbLayout.Body>
          </>
        )}
      />
    </DevicePoolViewLayout>
  );
}
