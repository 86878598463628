import { getTenantMap } from '@/state/cache';
import { formatDate } from '@/utils/formatting';
import {
  USER_ACCESS_PLATFORM_ADMIN_DELETE,
  USER_ACCESS_PLATFORM_ADMIN_TENANT_ACCESS_UPDATE,
  USER_ACCESS_PLATFORM_ADMIN_UPDATE,
} from '@/web/common/AccessRights';
import { DataItemTable } from '@/web/common/DataItemTable';
import { DataListTable } from '@/web/common/DataListTable';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DeletePlatformAdminButton } from '../DeletePlatformAdminButton';

/** @param {{user: PlatformAdmin }} props */
export function PlatformAdminView(props) {
  const { user } = props;
  const tenantMap = useSelector(getTenantMap);

  return (
    <>
      <BreadcrumbLayout.Header title={user.displayName || user.username} subtitle={user.email}>
        <USER_ACCESS_PLATFORM_ADMIN_DELETE>
          <DeletePlatformAdminButton user={user} />
        </USER_ACCESS_PLATFORM_ADMIN_DELETE>
        <USER_ACCESS_PLATFORM_ADMIN_UPDATE>
          <Button as={Link} to="./edit" variant="primary" style={{ width: 132 }}>
            Edit
          </Button>
        </USER_ACCESS_PLATFORM_ADMIN_UPDATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={user}
          headers={[
            {
              key: 'id',
              title: 'ID',
            },
            {
              key: 'role',
            },
            {
              key: 'username',
            },
            {
              key: 'email',
            },
            {
              key: 'firstName',
            },
            {
              key: 'lastName',
            },
            {
              key: 'displayName',
            },
            {
              key: 'description',
            },
            {
              key: 'company',
            },
            {
              key: 'phone',
            },
            {
              key: 'address',
            },
          ]}
        />
      </BreadcrumbLayout.Body>

      <BreadcrumbLayout.Header title={<span className="fs-6">Tenant Access</span>}>
        <USER_ACCESS_PLATFORM_ADMIN_TENANT_ACCESS_UPDATE>
          <Button
            as={Link}
            to="./edit?tab=tenant-access"
            variant="secondary"
            style={{ width: 132 }}
          >
            Edit List
          </Button>
        </USER_ACCESS_PLATFORM_ADMIN_TENANT_ACCESS_UPDATE>
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataListTable
          data={user.tenantAccessList}
          headers={[
            {
              title: 'Tenant',
              builder: ({ tenantId }) => (
                <Link to={`/settings/tenants/${tenantId}`}>
                  {tenantMap[tenantId]?.name || '...'}
                </Link>
              ),
            },
            {
              key: 'role',
              title: 'Role',
            },
            {
              title: 'Expiration',
              builder: ({ expiration }) => formatDate(expiration),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
