import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/**
 * A hook to dispatch an action inside `useEffect` hook.
 *
 * Returns a stateful loading and error.
 *
 * @param {() => any} [action] A builder for action to dispatch.
 * @param {import('react').DependencyList} [deps] If present, effect will only activate if the values in the list change. If absent, effect will only activate once per component lifetime.
 * @returns {[boolean, Error?]} [loading, error]
 */
export function useDispatchLoader(action, deps) {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      let mounted = true;
      dispatch(action())
        .catch((err) => mounted && setError(err))
        .finally(() => mounted && setLoading(false));
      return () => (mounted = false);
    },
    // eslint-disable-next-line
    [...(deps ?? [action]), dispatch]
  );

  return [loading, error];
}
