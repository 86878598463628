import { Card } from 'react-bootstrap';

export function OutputCardLayout(props) {
  const { icon, title, children, mapping, color, hideLink, ...otherProps } = props;

  return (
    <Card
      {...otherProps}
      style={{
        minWidth: 180,
        maxWidth: 300,
        boxShadow: '0 0 4px #00000025',
        ...otherProps.style,
      }}
    >
      {title && (
        <Card.Body className="text-capitalize text-center fw-bold py-2">
          <div style={{ fontSize: 48, color }}>{icon}</div>
          {title && <div className="pt-1">{title}</div>}
        </Card.Body>
      )}
      {children && !hideLink && (
        <Card.Footer className="text-center text-capitalize">{children}</Card.Footer>
      )}
    </Card>
  );
}
