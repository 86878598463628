import { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BreadcrumbLayout from '@/web/layouts/BreadcrumbLayout';
import { Button } from 'react-bootstrap';
import { getProduct, getTenant } from '@/state/cache';
import { DataItemTable } from '@/web/common/DataItemTable';
import { DevicePoolEditForm } from './@components/DevicePoolEditForm';
import { FaDesktop } from 'react-icons/fa';
import { DevicePoolViewContext } from '../@components/DevicePoolViewLayout';
import { USER_ACCESS_DEVICE_POOL_UPDATE } from '@/web/common/AccessRights';

export function ViewDevicePoolPageGeneral() {
  const { devicePool } = useContext(DevicePoolViewContext);
  const { tenantId } = useParams();

  const owner = useSelector(getTenant(tenantId));
  const product = useSelector(getProduct(devicePool.productId));
  const [showEdit, setShowEdit] = useState(false);
  return (
    <>
      <BreadcrumbLayout.Header icon={<FaDesktop />} title={<span>General Information</span>}>
        <USER_ACCESS_DEVICE_POOL_UPDATE>
          <Button onClick={() => setShowEdit(true)} variant="primary" style={{ width: 132 }}>
            Edit
          </Button>
        </USER_ACCESS_DEVICE_POOL_UPDATE>
        {showEdit && (
          <DevicePoolEditForm devicePool={devicePool} onDone={() => setShowEdit(false)} />
        )}
      </BreadcrumbLayout.Header>
      <BreadcrumbLayout.Body>
        <DataItemTable
          data={devicePool}
          width={225}
          minWidth={150}
          headers={[
            {
              key: 'name',
              title: 'Name',
            },
            {
              key: 'description',
              title: 'Description',
            },
            {
              key: 'tenantId',
              title: 'Owner Tenant',
              builder: ({ tenantId }) =>
                owner && <Link to={`/settings/tenants/${tenantId}`}>{owner.name}</Link>,
            },
            {
              key: 'productId',
              title: 'Product',
              align: 'center',
              builder: ({ productId }) => (
                <>
                  {product && <Link to={`/super-admin/products/${productId}`}>{product.name}</Link>}
                </>
              ),
            },
          ]}
        />
      </BreadcrumbLayout.Body>
    </>
  );
}
