import api from '@/api';
import { InputBox } from '@/web/common/forms/InputBox';
import { useState } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { FaExclamation } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ChangeEndUserPasswordModal } from '../ChangeEndUserPasswordModal';

/** @param {{user: EndUser }} props */
export function EditEndUserForm(props) {
  const user = props.user;
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState(user.username || '');
  const [name, setName] = useState(user.name || '');
  const [picture, setPicture] = useState(user.picture || '');

  /**
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setError(null);
      setLoading(true);
      const request = api.sai.v1.tenant
        .$tenantId(user.tenantId)
        ['end-user'].$userId(user.userId)
        .$patch({
          data: {
            name: name !== user.name ? name : null,
            picture: picture !== user.picture ? picture : null,
            username: username !== user.username ? username : null,
          },
        });
      await request.process();
      setLoading(false);
      navigate({
        pathname: './..',
      });
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-1">
          <b>{user.email}</b> / <code>{user.username}</code>
        </Card.Title>
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <Card.Body>
          <InputBox
            label="Username"
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={setUsername}
          />
          <InputBox
            label="Full name"
            type="text"
            placeholder="Enter full name"
            value={name}
            onChange={setName}
          />
          <InputBox
            label="Picture URL"
            type="text"
            placeholder="Enter picture url"
            value={picture}
            onChange={setPicture}
          />
          {error && (
            <>
              <hr className="my-2" />
              <Alert variant="danger" className="my-0 py-2">
                <FaExclamation /> {error + ''}
              </Alert>
            </>
          )}
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <ChangeEndUserPasswordModal user={user} />
          <Button type="submit" variant="primary" style={{ width: 132 }} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
