import { getTenantId } from '@/state/auth';
import { getGroup } from '@/state/cache';
import {
  USER_ACCESS_DEVICE_ADD_TO_DEPLOYMENT_GROUP,
  USER_ACCESS_DEVICE_REBOOT,
  USER_ACCESS_DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP,
  USER_ACCESS_DEVICE_RESET,
} from '@/web/common/AccessRights';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AssignGroupButton } from '../AssignGroupButton';
import { DeviceViewContext } from '../DeviceViewLayout';
import { RebootDeviceButton } from '../RebootDeviceButton';
import { RemoveGroupButton } from '../RemoveGroupButton';
import { ResetDeviceButton } from '../ResetDeviceButton';

export function DeviceDetailsActions() {
  const tenantId = useSelector(getTenantId);

  /** @type {{device : DeviceDetail}}  */
  const { device } = useContext(DeviceViewContext);
  const group = useSelector(
    getGroup(
      device?.onboardedDevice?.deploymentGroupIds?.[
        device?.onboardedDevice?.deploymentGroupIds?.length - 1
      ],
      tenantId
    )
  );
  const showAssignGroupButton = !Object.keys(group).length;
  const showRemoveGroupButton = !showAssignGroupButton;

  return (
    <>
      <USER_ACCESS_DEVICE_RESET>
        <ResetDeviceButton />
      </USER_ACCESS_DEVICE_RESET>
      <USER_ACCESS_DEVICE_REBOOT>
        <RebootDeviceButton />
      </USER_ACCESS_DEVICE_REBOOT>
      {showAssignGroupButton && (
        <USER_ACCESS_DEVICE_ADD_TO_DEPLOYMENT_GROUP>
          <AssignGroupButton />
        </USER_ACCESS_DEVICE_ADD_TO_DEPLOYMENT_GROUP>
      )}
      {showRemoveGroupButton && (
        <USER_ACCESS_DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP>
          <RemoveGroupButton groupId={group.groupId} />
        </USER_ACCESS_DEVICE_REMOVE_FROM_DEPLOYMENT_GROUP>
      )}{' '}
    </>
  );
}
